export enum AdvancedFilters {
  Order = 1,
  Travel = 2,
  OrderDate = 3,
  Animals = 4,
  AnimalCategory = 5,
  Weight = 6,
  DestinyRancher = 7,
  FarmDestiny = 8,
  OriginRancher = 9,
  OriginFarm = 10,
  Truck = 11,
  Cart = 12,
  TruckType = 13,
  TransitFacility = 14,
  Driver = 15,
  ScheduleEmbarking = 16,
  ScheduleDisembarking = 17,
  ArrivalEmbarking = 18,
  GpsArrivalEmbarking = 100,
  InitEmbarking = 19,
  GpsInitEmbarking = 101,
  EndEmbarking = 20,
  GpsEndEmbarking = 102,
  SkipEmbarking = 21,
  GpsSkipEmbarking = 103,
  ArrivalDisembarking = 22,
  InitDisembarking = 23,
  EndDisembarking = 24,
  SkipDisembarking = 25,
  PredictedKm = 26,
  PredictedValue = 27,
  RealizedKm = 28,
  RealizedValue = 29,
  internalCode = 30,
  TaxStatus = 31,
}

export const AdvancedFiltersDefinitions = {
	[ AdvancedFilters.Order ]: {
		key: 'pedido',
		label: 'Pedido',
	},
	[ AdvancedFilters.Travel ]: {
		key: 'viagem',
		label: 'Viagem',
	},
	[ AdvancedFilters.OrderDate ]: {
		key: 'dataPedido',
		label: 'Data Pedido',
	},
	[ AdvancedFilters.Animals ]: {
		key: 'qtdAnimais',
		label: 'Qtd Animais',
	},
	[ AdvancedFilters.AnimalCategory ]: {
		key: 'categoriaAnimais',
		label: 'Categoria Animais',
	},
	[ AdvancedFilters.Weight ]: {
		key: 'peso',
		label: 'Peso',
	},
	[ AdvancedFilters.DestinyRancher ]: {
		key: 'pecuaristaDestino',
		label: 'Pecuarista/ Empresa Destino',
	},
	[ AdvancedFilters.FarmDestiny ]: {
		key: 'fazendaDestino',
		label: 'Unidade/ Fazenda Destino',
	},
	[ AdvancedFilters.OriginRancher ]: {
		key: 'pecuaristaOrigem',
		label: 'Pecuarista/ Empresa Origem',
	},
	[ AdvancedFilters.OriginFarm ]: {
		key: 'fazendaOrigem',
		label: 'Unidade/ Fazenda Origem',
	},
	[ AdvancedFilters.Truck ]: {
		key: 'placaCavalo',
		label: 'Placa Cavalo',
	},
	[ AdvancedFilters.Cart ]: {
		key: 'placaCarreta',
		label: 'Placa Carreta',
	},
	[ AdvancedFilters.TruckType ]: {
		key: 'tipoVeiculo',
		label: 'Tipo Veículo',
	},
	[ AdvancedFilters.TransitFacility ]: {
		key: 'unidade',
		label: 'Transportadora/ Unidade',
	},
	[ AdvancedFilters.Driver ]: {
		key: 'motorista',
		label: 'Motorista',
	},
	[ AdvancedFilters.ScheduleEmbarking ]: {
		key: 'embarqueProgramado',
		label: 'Embarque Programado',
	},
	[ AdvancedFilters.ScheduleDisembarking ]: {
		key: 'desembarqueProgramado',
		label: 'Desembarque Programado',
	},
	[ AdvancedFilters.ArrivalEmbarking ]: {
		key: 'chegadaEmbarque',
		label: 'Chegada Embarque',
	},
	[ AdvancedFilters.GpsArrivalEmbarking ]: {
		key: 'gpsChegadaEmbarque',
		label: 'GPS Chegada Embarque',
	},
	[ AdvancedFilters.InitEmbarking ]: {
		key: 'inicioEmbarque',
		label: 'Início Embarque',
	},
	[ AdvancedFilters.GpsInitEmbarking ]: {
		key: 'gpsInicioEmbarque',
		label: 'GPS Início Embarque',
	},
	[ AdvancedFilters.EndEmbarking ]: {
		key: 'fimEmbarque',
		label: 'Fim Embarque',
	},
	[ AdvancedFilters.GpsEndEmbarking ]: {
		key: 'gpsFimEmbarque',
		label: 'GPS Fim Embarque',
	},
	[ AdvancedFilters.SkipEmbarking ]: {
		key: 'saidaEmbarque',
		label: 'Saída Embarque',
	},
	[ AdvancedFilters.GpsSkipEmbarking ]: {
		key: 'gpsSaidaEmbarque',
		label: 'GPS Saída Embarque',
	},
	[ AdvancedFilters.ArrivalDisembarking ]: {
		key: 'chegadaDesembarque',
		label: 'Chegada Desembarque',
	},
	[ AdvancedFilters.InitDisembarking ]: {
		key: 'inicioDesembarque',
		label: 'Inicio Desembarque',
	},
	[ AdvancedFilters.EndDisembarking ]: {
		key: 'fimDesembarque',
		label: 'Fim Desembarque',
	},
	[ AdvancedFilters.SkipDisembarking ]: {
		key: 'saidaDesembarque',
		label: 'Saída Desembarque',
	},
	[ AdvancedFilters.PredictedKm ]: {
		key: 'kmPrevisto',
		label: 'Km Previsto',
	},
	[ AdvancedFilters.PredictedValue ]: {
		key: 'valorPrevisto',
		label: 'Valor Previsto',
	},
	[ AdvancedFilters.RealizedKm ]: {
		key: 'kmRealizado',
		label: 'Km Realizado',
	},
	[ AdvancedFilters.RealizedValue ]: {
		key: 'valorRealizado',
		label: 'Valor Realizado',
	},
	[ AdvancedFilters.internalCode ]: {
		key: 'codigoInterno',
		label: 'Código Interno',
	},
	[ AdvancedFilters.TaxStatus ]: {
		key: 'statusFiscal',
		label: 'Status Fiscal',
	},
	[ AdvancedFilters.RealizedKm ]: {
		key: 'kmRealizado',
		label: 'Km Realizado',
	},
}
