
















































import { Component, Vue, Prop } from "vue-property-decorator";
import Button from '@/components/widgets/atoms/Button/Button.vue';
import TruckIcon from "@/components/icons/Truck.vue";
import AcceptIcon from "@/components/icons/Accept.vue";
import CameraIcon from "@/components/icons/Camera.vue";

@Component({
  components: {
    Button,
    TruckIcon,
    AcceptIcon,
    CameraIcon
  },
})

export default class Tracking extends Vue {
  /* Props */
  @Prop({ required: true })
  public data!: any;

  @Prop({ required: true })
  public isLoaded!: boolean;

  @Prop({ required: false, default: false })
  public isHistoric!: boolean;
}
