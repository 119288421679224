/* Imports */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';

/* Modules Import */
import auth from './auth';
import filters from './filters';
import orders from './orders';
import locations from './locations';
import travels from './travels';
import photos from './photos';
import permissions from './permissions';
import transitFacility from './TransitFacility';

/* Vuex Setup */
Vue.use(Vuex);

// TODO: Replace vuex-user-auth with actual cookie key
export default new Vuex.Store({
	modules: {
		auth,
		filters,
		orders,
		photos,
		locations,
		travels,
		...permissions,
		...transitFacility,
	},
	plugins: [
		createPersistedState({
			key: 'vuex-user-auth',
			paths: ['auth'],
			storage: {
				getItem: key => Cookies.get(key),
				removeItem: key => Cookies.remove(key),
				setItem: (key, value) => Cookies.set(key, value),
			},
		}),
	],
});
