






import {Vue, Component, Prop} from "vue-property-decorator";

@Component({})

export default class Loader extends Vue {
   /* Props */
  @Prop({ default: false })
  public fixed!: boolean;
}
