
























import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})

export default class Button extends Vue {
  /* Props */
  @Prop({ default: 'button'})
  public component!: string;

  @Prop({ default: 'link'})
  public variant!: string;

  @Prop()
  public title!: string;

  @Prop({ default: false})
  public disabled!: boolean;

  @Prop({ default: false})
  public loading!: boolean;

  @Prop({ default: false})
  public isFile!: boolean;

  /* Computed */
  get variantClass() {
    return `button--${this.variant}`;
  }
}
