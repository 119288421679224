









































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Disclosure from '@/components/widgets/molecules/Disclosure/Disclosure.vue';
import FormInput from '@/components/widgets/molecules/FormInput/FormInput.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';
import Button from '@/components/widgets/atoms/Button/Button.vue';
import AcceptIcon from '@/components/icons/Accept.vue';
import PencilIcon from '@/components/icons/Pencil.vue';

import {
	TRANSIT_GET_DOWNLOAD_FILE,
	TRANSIT_UPLOAD_FILE,
	TRANSIT_POST_PROCESS_CTE,
} from '@/store/TransitFacility/travels/actions';

@Component({
	components: {
		Disclosure,
		FormInput,
		ButtonCustom,
		Button,
		AcceptIcon,
		PencilIcon,
	},
})
export default class DisclosureInvoice extends Vue {
	/* Props */
	@Prop({ default: {} })
	public fields!: any;

	/* Data */
	private busy: any = {
		nf: { download: false, upload: false },
		cte: { upload: false },
	};

	private isLoading: boolean = false;
	private url_file: any = null;
	private chaveAcessoCTE: any = null;
	private validacaoNf: boolean = true;
	private validacaoNfKey: boolean = true;
	private validacaoNfValue: boolean = true;
	private isUploaded: boolean = false;

	/* Computed */
	get selectedTravel() {
		return this.$store.getters.getSelectedTransitTravel;
	}
	get animalsTotal() {
		this.fields.totalAnimals =
			this.fields.bois + this.fields.vacas + this.fields.novilhas + this.fields.bezerros;
		return this.fields.totalAnimals;
	}

	/* mounted() {
		if (this.fields.nfNumber && this.fields.nfKey && this.fields.nfValue) {
			return (this.isUploaded = true);
		}
	}
 */
	/* Watcher */

	@Watch('fields.nfNumber')
	onFieldsChanged(newVale) {
		this.fields.nfNumber = newVale;
		this.isUploaded = false;
	}
	@Watch('fields.nfKey')
	onFieldsChanged2(newVale) {
		this.fields.nfKey = newVale;
		this.isUploaded = false;
	}

	@Watch('fields.nfValue')
	onFieldsChanged3(newVale) {
		this.fields.nfValue = newVale;
		this.isUploaded = false;
	}

	/* Mehotds */
	habilitaNf() {
		this.validacaoNf = true;
	}

	desabilitaNf() {
		this.validacaoNf = false;
	}

	habilitaNfKey() {
		this.validacaoNfKey = true;
	}

	desabilitaNfKey() {
		this.validacaoNfKey = false;
	}

	habilitaNfValue() {
		this.validacaoNfValue = true;
	}

	desabilitaNfValue() {
		this.validacaoNfValue = false;
	}

	uploadFile(event, name, type) {
		this.isUploaded = false;
		this.busy[name].upload = true;
		const fd = new FormData();

		fd.append('File', event.target.files[0]);
		fd.append('TipoArquivo', type);
		const params = {
			idViagem: this.selectedTravel,
			formData: fd,
		};
		this.$store
			.dispatch(TRANSIT_UPLOAD_FILE, params)
			.then(response => {
				Vue.$toast.success('Upload do arquivo realizado com sucesso!');
				const data = response;
				this.fields.nfKey = data.chaveNF;
				this.chaveAcessoCTE = data.chaveAcessoUploadedNF;
				this.fields.nfNumber = data.numeroNF;
				this.fields.nfValue = data.valorNF;
				this.url_file = data.Url;
			})
			.catch(() => {
				Vue.$toast.error('Erro ao realizar upload do arquivo!');
			})
			.finally(() => {
				this.busy[name].upload = false;
				this.isUploaded = true;
			});
	}

	processCTE() {
		this.busy.cte.upload = true;
		const params_cte = {
			idViagem: this.selectedTravel,
			chaveAcesso: this.chaveAcessoCTE,
		};
		this.$store
			.dispatch(TRANSIT_POST_PROCESS_CTE, params_cte)
			.then(() => {
				Vue.$toast.success('Upload do arquivo para processamento realizado com sucesso!');
			})
			.catch(() => {
				Vue.$toast.error('Não foi possível realizar o upload do arquivo para processamento!');
			})
			.finally(() => {
				this.busy.cte.upload = false;
			});
	}

	downloadFile(name, type) {
		this.busy[name].download = true;
		const params = {
			idViagem: this.selectedTravel,
			tipoArquivo: type,
			nome: name,
		};
		this.$store
			.dispatch(TRANSIT_GET_DOWNLOAD_FILE, params)
			.then(() => {
				Vue.$toast.success('Download do arquivo realizado com sucesso!');
			})
			.catch(() => {
				Vue.$toast.error('Erro ao realizar download do arquivo!');
			})
			.finally(() => {
				this.busy[name].download = false;
			});
	}
}
