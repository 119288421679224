
























import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})

export default class PhotosIcon extends Vue {
  /* Props */
  @Prop({ default: "#4B4B4B" })
  public color!: string;
}
