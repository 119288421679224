

















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})

export default class Input extends Vue {
  /* Props */
  @Prop()
  public value!: string;

  @Prop({ default: 'input' })
  public name!: string;

  @Prop({ default: 'text' })
  public type!: string;

  @Prop({ default: 'default'})
  public variant!: string;

  @Prop({ default: null })
  public min!: number | null;

  @Prop({ default: null })
  public max!: number | null;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: false })
  public required!: boolean;

  @Prop()
  public placeholder!: string;

  /* Computed */
  get variantClass() {
    return `input--${this.variant}`;
  }
}
