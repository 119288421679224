/* Imports */
import TravelState from '@/models/travel-state/travel-state.interface';
import api from '@/shared/util/api';
const { http } = api.getInstance();

/* Actions */
import {
	LOAD_TRAVEL_REQUEST,
	LOAD_TRAVEL_SUCCESS,
	LOAD_TRAVEL_FAILED,
	TRAVEL_DETAIL_REQUEST,
	TRAVEL_DETAIL_SUCCESS,
	TRAVEL_DETAIL_FAILED,
	GET_DOWNLOAD_IMAGE,
	GET_COLUMNS,
	GET_KM_OCCURRENCE,
	GET_ANIMAL_OCCURRENCE,
	GET_TRUCK_OCCURRENCE,
	GET_MAP_PATH,
	GET_ACTUAL_LOCALIZATION,
	PUT_COLUMNS,
	POST_KM_OBSERVATION,
	POST_ANIMAL_BOARDING_OBSERVATION,
	POST_ANIMAL_TRAVEL_OBSERVATION,
	POST_ANIMAL_LANDING_OBSERVATION,
	POST_TRUCK_OBSERVATION,
	SELECT_DETAIL_ORDER,
	SELECT_MAP,
	SELECT_OCCURRENCE,
} from './actions';

/* Initial AuthState object */
const state: TravelState = {
	loadTravelStatus: '',
	loadTravelDetailStatus: '',
	selectedOrder: null,
	selectedMap: null,
	selectedOccurence: null,
};

/* Vuex Auth Getters */
const getters = {
	isTravelLoading: (state: TravelState): string => state.loadTravelStatus,
	isTravelDetailLoading: (state: TravelState): boolean =>
		state.loadTravelDetailStatus === 'loading',
	hasSelectedDetailOrder: (state: TravelState): boolean => state.selectedOrder !== null,
	getSelectedOrder: state => state.selectedOrder,
	hasSelectedMap: (state: TravelState): boolean => state.selectedMap !== null,
	getSelectedMap: state => state.selectedMap,
	hasSelectedOccurence: (state: TravelState): boolean => state.selectedOccurence !== null,
	getSelectedOccurence: (state: TravelState) => state.selectedOccurence,
};

/* Vuex Auth Mutations */
const mutations = {
	[LOAD_TRAVEL_REQUEST]: (state: TravelState) => {
		state.loadTravelStatus = 'loading';
	},
	[LOAD_TRAVEL_SUCCESS]: (state: TravelState) => {
		state.loadTravelStatus = 'success';
	},
	[LOAD_TRAVEL_FAILED]: (state: TravelState) => {
		state.loadTravelStatus = 'error';
	},
	[TRAVEL_DETAIL_REQUEST]: (state: TravelState) => {
		state.loadTravelDetailStatus = 'loading';
	},
	[TRAVEL_DETAIL_SUCCESS]: (state: TravelState) => {
		state.loadTravelDetailStatus = 'success';
	},
	[TRAVEL_DETAIL_FAILED]: (state: TravelState) => {
		state.loadTravelDetailStatus = 'error';
	},
	[SELECT_DETAIL_ORDER]: (state, order) => {
		state.selectedOrder = order;
	},
	[SELECT_MAP]: (state, map) => {
		state.selectedMap = map;
	},
	[SELECT_OCCURRENCE]: (state, occurence) => {
		state.selectedOccurence = occurence;
	},
};

/* Vuex Auth Actions */
const actions = {
	[LOAD_TRAVEL_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
			Object.keys(requestData).forEach(data => {
				if (requestData[data] && data !== 'page' && data !== 'size' && data !== 'colunas') {
					queryParams.push(`${data}=${requestData[data]}`);
				}
			});
			if (requestData?.colunas && requestData.colunas.length > 0) {
				requestData.colunas.forEach(coluna => {
					queryParams.push(`colunas=${coluna}`);
				});
			}
			commit(LOAD_TRAVEL_REQUEST);
			http({
				method: 'get',
				url: `/uboiweb/jbsviagem?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(LOAD_TRAVEL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(LOAD_TRAVEL_FAILED);
					reject(error);
				});
		});
	},
	[TRAVEL_DETAIL_REQUEST]: ({ commit }: any, viagemId: number) => {
		return new Promise((resolve, reject) => {
			commit(TRAVEL_DETAIL_REQUEST);

			http({
				method: 'get',
				url: `/uboiweb/jbsviagem/detalhe/${viagemId}`,
			})
				.then(({ data }) => {
					commit(TRAVEL_DETAIL_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(TRAVEL_DETAIL_FAILED);
					reject(error);
				});
		});
	},
	[GET_DOWNLOAD_IMAGE]: ({ commit }: any, params: any) => {
		return new Promise((resolve, reject) => {
			const { formId, formType, ImageType, name } = params;
			http({
				method: 'get',
				url: `/uboiweb/jbsviagem/rastreamento/download/${formId}/${formType}/${ImageType}`,
				responseType: 'blob',
			})
				.then(({ data }) => {
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${name}.png`);
					document.body.appendChild(link);
					link.click();
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[GET_COLUMNS]: ({ commit }: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/uboiweb/jbsviagem/colunas`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[PUT_COLUMNS]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'put',
				url: `/uboiweb/jbsviagem/colunas/salvar`,
				data: requestData,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[GET_KM_OCCURRENCE]: ({ commit }: any, viagemId: number) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/uboiweb/jbsviagem/ocorrencia/quilometragem/${viagemId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[GET_ANIMAL_OCCURRENCE]: ({ commit }: any, viagemId: number) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/uboiweb/jbsviagem/ocorrencia/animal/${viagemId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[GET_TRUCK_OCCURRENCE]: ({ commit }: any, viagemId: number) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/uboiweb/jbsviagem/ocorrencia/caminhao/${viagemId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[GET_MAP_PATH]: ({ commit }: any, viagemId: number) => {
		return new Promise((resolve, reject) => {
			http({
				method: 'get',
				url: `/uboiweb/jbsviagem/trajeto/mapa/${viagemId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[GET_ACTUAL_LOCALIZATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { idViagem, placaCavalo, motoristaId, pecuaristaOrigemId } = requestData;
			http({
				method: 'get',
				url: `/uboiweb/jbsviagem/mapa/localizacao-atual?idViagem=${idViagem}&placaCavalo=${placaCavalo}&motoristaId=${motoristaId}&pecuaristaOrigemId=${pecuaristaOrigemId}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[POST_KM_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { idViagem, tratativa } = requestData;
			http({
				method: 'post',
				url: '/uboiweb/jbsviagem/ocorrencia/quilometragem/tratativa',
				data: {
					idViagem,
					tratativa,
				},
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[POST_ANIMAL_BOARDING_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { idOcorrenciaEmbarque, tratativa } = requestData;
			http({
				method: 'post',
				url: '/uboiweb/jbsviagem/ocorrencia/animal/embarque/tratativa',
				data: {
					idOcorrenciaEmbarque,
					tratativa,
				},
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[POST_ANIMAL_TRAVEL_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { idOcorrenciaParadaAnimal, tratativa } = requestData;
			http({
				method: 'post',
				url: '/uboiweb/jbsviagem/ocorrencia/animal/transporte/tratativa',
				data: {
					idOcorrenciaParadaAnimal,
					tratativa,
				},
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[POST_ANIMAL_LANDING_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { idOcorrenciaDesembarque, tratativa } = requestData;
			http({
				method: 'post',
				url: '/uboiweb/jbsviagem/ocorrencia/animal/desembarque/tratativa',
				data: {
					idOcorrenciaDesembarque,
					tratativa,
				},
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[POST_TRUCK_OBSERVATION]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { idOcorrencia, tratativa } = requestData;
			http({
				method: 'post',
				url: '/uboiweb/jbsviagem/ocorrencia/caminhao/tratativa',
				data: {
					idOcorrencia,
					tratativa,
				},
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[SELECT_DETAIL_ORDER]: ({ commit }: any, order) => {
		commit(SELECT_DETAIL_ORDER, order);
	},
	[SELECT_MAP]: ({ commit }: any, map) => {
		commit(SELECT_MAP, map);
	},
	[SELECT_OCCURRENCE]: ({ commit }: any, occurence) => {
		commit(SELECT_OCCURRENCE, occurence);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
