





































































import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/ModuleLayout/ModuleLayout.vue';
import Header from '@/components/widgets/organisms/Header/Header.vue';
import Filters from './organisms/Filter/Filter.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue';

import AcceptanceTermModal from './organisms/Modals/AcceptanceTerm/AcceptanceTerm.vue';
import TrackingModal from './organisms/Modals/OrderDetail/OrderDetail.vue';
import RatingModal from './organisms/Modals/Rating/Rating.vue';
import NewOrderModal from './organisms/Modals/NewOrder/NewOrder.vue';
import ContractsModal from './organisms/Modals/Contracts/Contracts.vue';

import MapModal from '@/components/modals/rancher/MapModal/MapModal.vue';
import CarouselModal from '@/components/modals/CarouselModal/CarouselModal.vue';
import BooleanModal from '@/components/modals/BooleanModal/BooleanModal.vue';

import { AvailableFilters } from '@/models/filters/available-filters.interface';
import { saveXLSX } from '@/helpers/ExportXLSX';
import { situationlabel } from '@/constants/operationalSituationLabels';
import { formatDateHour, formatDateISO } from '@/shared/util/formatDate';
import formatMoney from '@/shared/util/formatMoney';
import formatNumber from '@/shared/util/formatNumber';
import { SYSTEM_PERMISSIONS } from '@/constants/permissions';

import {
	GET_DOWNLOAD_IMAGE,
	LOAD_ORDER_REQUEST,
	POST_NEW_ORDER,
	DELETE_SKETCH,
	SELECT_RATE_ORDER,
	SELECT_CONTRACTS,
	SELECT_ACCEPTANCE_TERM,
	SELECT_ORDER_DETAIL,
	SELECT_SKETCH,
} from '@/store/orders/actions';

@Component({
	components: {
		Layout,
		Header,
		Filters,
		Table,
		Pagination,
		AcceptanceTermModal,
		TrackingModal,
		RatingModal,
		NewOrderModal,
		ContractsModal,
		MapModal,
		CarouselModal,
		BooleanModal,
	},
})
export default class RancherModuleOrder extends Vue {
	/* Data */
	private availableFilters: any = AvailableFilters;

	private busy: any = {
		image: false,
	};

	private modal: any = {
		title: '',
		subtitle: '',
		content: '',
		data: null,
		busy: false,
	};

	private isLoaded: boolean = true;

	private isDownloading: boolean = false;

	private totalPages: number = 0;

	private params: any = {
		page: 1,
		size: 16,
		pedidoId: '',
		dataInicio: '',
		dataFim: '',
		pecuaristaOrigemId: '',
		pecuaristaDestinoId: '',
		fazendaOrigemId: '',
		fazendaDestinoId: '',
		rastreamentoTipoEventoId: '',
		codigoSituacaoOperacional: '',
	};

	private header: any = [
		{ key: 'pecuaristaDestino', label: 'Pecuarista /Empresa Destino' },
		{ key: 'fazendaDestino', label: 'Unidade /Fazenda Destino' },
		{ key: 'pecuaristaOrigem', label: 'Pecuarista /Empresa Origem' },
		{ key: 'fazendaOrigem', label: 'Unidade /Fazenda Origem' },
		{ key: 'kmEntrePontos', label: 'KM entre pontos' },
		{ key: 'qtdAnimais', label: 'Quantidade Animais' },
		{ key: 'categoriaAnimais', label: 'Categoria' },
		{ key: 'peso', label: 'Peso Médio' },
		{ key: 'codigoInterno', label: 'Código Interno' },
		{ key: 'previsaoEmbarque', label: 'Previsão Embarque' },
		{ key: 'previsaoDesembarque', label: 'Previsão Desembarque' },
		{ key: 'pedido', label: 'Número Pedido', thClass: 'dark-header' },
		{ key: 'dataPedido', label: 'Data Pedido', thClass: 'dark-header' },
		{ key: 'valorCotacao', label: 'Valor Cotação', thClass: 'dark-header' },
		{
			key: 'situacaoOperacional',
			label: 'Situação Operacional',
			thClass: 'dark-header',
		},
		{
			key: 'actions',
			label: 'Ações',
			thClass: 'gray-header',
			tdClass: 'td-action',
		},
	];

	private rows: any = [];

	/* Computed */
	get permissions() {
		return this.$store.getters.getUserPermissions;
	}

	get systemPermissions() {
		return SYSTEM_PERMISSIONS.RANCHER.ORDER;
	}

	/* Lifecycle */
	created() {
		this.getOrders(this.params);
	}

	/* Methods */
	submitFilter(filterDefinition: any): void {
		Object.keys(filterDefinition).forEach(index => {
			const numberIndex = +index;
			switch (numberIndex) {
				case this.availableFilters.Date:
					this.params.dataInicio = formatDateISO(filterDefinition[numberIndex].startDateSelected);
					this.params.dataFim = formatDateISO(filterDefinition[numberIndex].endDateSelected);
					break;
				case this.availableFilters.Origin:
					this.params.pecuaristaOrigemId =
						filterDefinition[numberIndex].rancherSelected?.value || '';
					this.params.fazendaOrigemId = filterDefinition[numberIndex].farmSelected?.value || '';
					break;
				case this.availableFilters.Destiny:
					this.params.pecuaristaDestinoId =
						filterDefinition[numberIndex].rancherSelected?.value || '';
					this.params.fazendaDestinoId = filterDefinition[numberIndex].farmSelected?.value || '';
					break;
				case this.availableFilters.Tracking:
					this.params.rastreamentoTipoEventoId =
						filterDefinition[numberIndex].trackingSelected?.value || '';
					break;
				case this.availableFilters.Situation:
					this.params.codigoSituacaoOperacional =
						filterDefinition[numberIndex].situationSelected?.value || '';
					break;
				case this.availableFilters.Order:
					this.params.pedidoId = filterDefinition[numberIndex].orderSelected;
					break;
			}
		});
		this.params.page = 1;
		this.getOrders(this.params);
	}

	changePage(page) {
		this.params.page = page;
		this.getOrders(this.params);
	}

	changeSize(size) {
		this.params.size = size;
		this.params.page = 1;
		this.getOrders(this.params);
	}

	getOrders(params: any) {
		(this.isLoaded = false),
			this.$store
				.dispatch(LOAD_ORDER_REQUEST, params)
				.then(({ totalCount, results }) => {
					if (results.length == 0) return this.$toast.error('Nenhum pedido encontrado');
					this.totalPages = totalCount;
					this.rows = results.map(i => {
						const situation = i?.situacaoOperacional
							? situationlabel[i.situacaoOperacional]?.label || i.situacaoOperacional
							: '';
						let buttons: Array<any> = [];
						if (i.temContratos) buttons.push('contract');
						if (i.contratoAceito) {
							buttons = buttons.filter(button => button !== 'contract');
							if (this.permissions.includes(this.systemPermissions.ORDER_RESUME.SHOW)) {
								buttons.push('detail');
							}
							if (this.permissions.includes(this.systemPermissions.ACCEPTANCE_TERM.SHOW)) {
								buttons.push({
									icon: 'term',
									filled: i.contratoAceito,
									color: i.contratoAceito ? '#25B883' : '#865946',
								});
							}
						}
						if (
							['G', 'H', 'F', 'C'].includes(i.situacaoOperacional) &&
							this.permissions.includes(this.systemPermissions.ASSESSMENT.SHOW)
						) {
							buttons.push({
								icon: 'rate',
								filled: i.pedidoAvaliado,
								color: i.pedidoAvaliado ? '#25B883' : '#865946',
							});
						}
						if (i.tipoDescricao == 'PedidoRascunho') {
							buttons = [];
							if (
								this.permissions.includes(this.systemPermissions.ORDER_SKETCH.EDIT) ||
								this.permissions.includes(this.systemPermissions.ORDER_SKETCH.SHOW)
							) {
								buttons.push('edit');
							}
							if (this.permissions.includes(this.systemPermissions.APPROVE_ORDER.APPROVE)) {
								buttons.push('accept');
							}
							if (this.permissions.includes(this.systemPermissions.APPROVE_ORDER.REJECT)) {
								buttons.push('cancel');
							}
						}
						delete i.situacaoOperacional;
						return {
							...i,
							dataPedido: formatDateHour(i.dataPedido),
							previsaoEmbarque: formatDateHour(i.previsaoEmbarque),
							previsaoDesembarque: formatDateHour(i.previsaoDesembarque),
							valorCotacao: formatMoney(i.valorCotacao),
							peso: formatNumber(i.peso),
							kmEntrePontos: formatNumber(i.kmEntrePontos),
							situacaoOperacional: situation,

							actions: {
								id: i.pedido || i.pedidoRascunho,
								hasAcceptTerm: i.contratoAceito,
								buttons,
							},
						};
					});
				})
				.catch(e => {
					this.$toast.error(e);
				})
				.finally(() => {
					this.isLoaded = true;
				});
	}

	message(message, variant, reload = false) {
		if (reload) this.getOrders(this.params);
		this.$nextTick(() => {
			this.$toast.open({
				message,
				type: variant,
			});
		});
	}

	exportXLS() {
		const params = { ...this.params };
		params.size = this.totalPages;
		this.isDownloading = true;
		let body = [];
		const header = this.header.map(item => {
			return { label: item?.label || item?.key, value: item?.key };
		});
		header.pop();
		this.$store
			.dispatch(LOAD_ORDER_REQUEST, params)
			.then(({ results }) => {
				if (results.length == 0) {
					return;
				}
				body = results.map(i => {
					const situation = i?.situacaoOperacional
						? situationlabel[i.situacaoOperacional]?.label || i.situacaoOperacional
						: '';
					delete i.situacaoOperacional;
					return {
						...i,
						dataPedido: formatDateHour(i.dataPedido),
						previsaoEmbarque: formatDateHour(i.previsaoEmbarque),
						previsaoDesembarque: formatDateHour(i.previsaoDesembarque),
						situacaoOperacional: situation,
					};
				});
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.isDownloading = false;
				saveXLSX(header, body, 'tabela', 'Lista-pedidos');
			});
	}

	OpenAccept(pedido) {
		this.modal = {
			title: 'Confirmar',
			subtitle: 'Confimar o pedido',
			content: 'Você está prestes a enviar o pedido, você tem certeza?',
			data: { id: pedido.id, accept: true },
			busy: false,
		};
		this.$bvModal.show('BooleanModal');
	}

	OpenCancel(pedido) {
		this.modal = {
			title: 'Cancelar',
			subtitle: 'Cancelar o pedido',
			content: 'Você está prestes a cancelar o pedido, você tem certeza?',
			data: { id: pedido.id, accept: false },
			busy: false,
		};
		this.$bvModal.show('BooleanModal');
	}

	onAccept(data) {
		this.modal.busy = true;
		if (data.accept) {
			this.$store
				.dispatch(POST_NEW_ORDER, data.id)
				.then(() => {
					this.$emit('message', 'Pedido enviado com sucesso', 'success', true);
				})
				.catch(() => {
					this.$emit('message', 'Erro ao enviar pedido', 'error');
				})
				.finally(() => {
					this.modal.busy = false;
					this.getOrders(this.params);
					this.$bvModal.hide('BooleanModal');
				});
			return;
		}
		this.$store
			.dispatch(DELETE_SKETCH, data.id)
			.then(() => {
				this.$emit('message', 'Pedido cancelado com sucesso', 'success', true);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao cancelar pedido', 'error');
			})
			.finally(() => {
				this.modal.busy = false;
				this.getOrders(this.params);
				this.$bvModal.hide('BooleanModal');
			});
	}

	downloadImage(params) {
		this.busy.image = true;
		this.$store
			.dispatch(GET_DOWNLOAD_IMAGE, params)
			.then(() => {
				this.$toast.success('Download realizado com sucesso!');
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.busy.image = false;
			});
	}

	openDetail(pedido) {
		this.$store.dispatch(SELECT_ORDER_DETAIL, pedido.id);
	}

	openRate(pedido) {
		this.$store.dispatch(SELECT_RATE_ORDER, pedido.id);
	}

	openContracts(pedido) {
		this.$store.dispatch(SELECT_CONTRACTS, pedido.id);
	}

	openTerm(pedido) {
		this.$store.dispatch(SELECT_ACCEPTANCE_TERM, {
			id: pedido.id,
			isProposal: false,
			hasAcceptTerm: pedido.hasAcceptTerm,
		});
	}

	openEdit(pedido) {
		this.$store.dispatch(SELECT_SKETCH, pedido.id);
	}

	newOrder() {
		this.$bvModal.show('NewOrderModal');
	}
}
