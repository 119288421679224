const RANCHER_PERMISSIONS = {
	RANCHER: {
		ORDER: {
			REPORT: {
				FILTER: 4,
				EXPORT: 5,
			},
			NEW_ORDER: {
				CREATE: 6,
			},
			ACCEPTANCE_TERM: {
				SHOW: 7,
				SHOW_PDF: 8,
				APPROVE: 9,
			},
			ASSESSMENT: {
				SHOW: 10,
				EDIT: 11,
			},
			ORDER_RESUME: {
				SHOW: 12,
			},
			ORDER_SKETCH: {
				SHOW: 21,
				EDIT: 13,
			},
			APPROVE_ORDER: {
				APPROVE: 14,
				REJECT: 15,
			},
			ALL: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 21],
		},
		TRAVEL: {
			REPORT: {
				EXPORT: 17,
			},
			TRAVEL_RESUME: {
				SHOW: 18,
			},
			MAP_STATUS: {
				SHOW: 22,
			},
			ALL: [17, 18, 22],
		},
		MAP: {
			REAL_TIME_MAP: {
				SHOW: 20,
			},
			ALL: [20],
		},
		PERMISSIONS: {
			ALL: ['admin'],
		},
	},
};

const TRANSIT_FACILITY_PERMISSIONS = {
	TRANSIT_FACILITY: {
		ORDER: {
			ALL: [],
		},
		TRAVEL: {
			ALL: [],
		},
		MAP: {
			ALL: [],
		},
		REGISTER: {
			ALL: [],
		},
		MANAGEMENT: {
			ALL: [],
		},
		PERMISSIONS: {
			ALL: [],
		},
	},
};

export const SYSTEM_PERMISSIONS = Object.freeze({
	...RANCHER_PERMISSIONS,
	...TRANSIT_FACILITY_PERMISSIONS,
});
