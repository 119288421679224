















































































































































































































import { Vue, Component } from "vue-property-decorator";
import Header from '@/components/widgets/organisms/Header/Header.vue';
import Container from "@/components/layouts/Container/Container.vue";

@Component({
  components: {
    Header,
    Container,
  },
})
export default class Terms extends Vue {}
