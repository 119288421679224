





















import { Component, Vue } from "vue-property-decorator";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue';

import NewTruckModal from "../../organisms/Modals/NewTruck/NewTruck.vue";

import {
  TRANSIT_REGISTER_GET_TRUCKS,
  SELECT_REGISTER_TRUCK,
} from "@/store/TransitFacility/registers/actions";

import { SELECT_DOCUMENTS } from "@/store/photos/actions";

@Component({
  components: {
    Table,
    Pagination,
    NewTruckModal,
  },
})

export default class RegisterTrucks extends Vue {
  /* Data */
  private isLoaded: boolean = true;

  private params: any = {
    page: 1,
    size: 14,
  };

  private fixedHeader: any = [
    { key: "placa", label: "Placa", thClass: "dark-header" },
    { key: "tipo", label: "Tipo", thClass: "dark-header" },
    { key: "marca", label: "Marca", thClass: "dark-header" },
    { key: "modelo", label: "Modelo", thClass: "dark-header" },
    { key: "ano", label: "Ano", thClass: "dark-header" },
    { key: "descricao", label: "Documentação", thClass: "dark-header" },
    { key: "rastreador", label: "Rastreador", thClass: "dark-header" },
    { key: "foto", label: "Fotos", thClass: "dark-header", tdClass: "td-photo"},
    { key: "actions", label: "Ações", tdClass: "td-action" },
  ]

  private rows: any = [];

  private totalPages: number = 0;

  /* Lifecycle */
  created() {
    this.getTrucks(this.params);
  }
  
  /* Methods */
  getTrucks(params: any) {
    this.isLoaded = false
    this.$store
      .dispatch(TRANSIT_REGISTER_GET_TRUCKS, params)
      .then(({totalCount, results}) => {
        if (results.length == 0) {
          this.$toast.error("Nenhum caminhão encontrado");
          return;
        }
        this.totalPages = totalCount;
        this.rows = results.map((i) => {
          let textRastreador;
          if(i.tipoRastreador) {
            textRastreador = i.rastreador ? `Sim, ${i.tipoRastreador}` : "Não"
          } else {
            textRastreador = i.rastreador ? "Sim" : "Não"
          }

          const truckPhotos = i.fotos.filter((foto) =>  ["LATERAL_MOTORISTA", "LATERAL_CARONA", "FRENTE", "TRASEIRA"].includes(foto.descricao));

          return {
            ...i,
            rastreador: textRastreador,
            foto: { docs: truckPhotos },
            actions: {
              id: i.idCaminhao,
              buttons: ["edit"],
            },
          };
        });
      })
      .catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.isLoaded = true;
      });
  }

  message(message, variant, reload = false) {
    if (reload) this.getTrucks(this.params);
    this.$nextTick(() => {
      this.$toast.open({
        message,
        type: variant,
      });
    });
  }

  onChangePage(page) {
    this.params.page = page;
    this.getTrucks(this.params);
  }

  onChangeSize(size) {
    this.params.size = size;
    this.params.page = 1;
    this.getTrucks(this.params);
  }

  openDocuments(documents){
    this.$store.dispatch(SELECT_DOCUMENTS, documents);
  }

  openEdit(value) {
    this.$store.dispatch(SELECT_REGISTER_TRUCK, value.id);
  }
}
