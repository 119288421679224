





































































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import Tree from "@/components/widgets/molecules/Tree/Tree.vue";
import Multiselect from "@/components/widgets/atoms/Multiselect/Multiselect.vue";

import {
  GET_PROFILE_REQUEST,
  GET_USERS_REQUEST,
  GET_PERMISSIONS_REQUEST,
  POST_PROFILE,
  PUT_PROFILE,
  SELECT_PROFILE
} from "@/store/permissions/profile/actions";

@Component({
  components: {
    Modal,
    ButtonCustom,
    Tree,
    Multiselect
  },
})

export default class NewProfileModal extends Vue {
  private selectOptions: any = {
    users: []
  }

  private form: any = {
    name: "",
    users: [],
    permissions: [],
  };

  private treeData: Array<any> = [];

  private busy: boolean = false;

  private isLoading: boolean = false;

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedProfile;
  }

  get selectedProfile() {
    return this.$store.getters.getSelectedProfile;
  }

  get isLoadingUsers() {
    return this.$store.getters.isUsersLoading;
  }

  get title() {
    if (this.showModal) return "Editar Perfil";
    return "Novo Perfil";
  }

  get validation() {
    const permissions = this.form.permissions?.checked ? this.form.permissions?.checked.filter(el => el.children.length === 0) : [];
    if (
      this.form.name &&
      permissions.length > 0
    ) return false;
    return true;
  }

  /* Methods */
  resetInfo() {
    this.form = {
      name: "",
      users: [],
      permissions: [],
    };
    this.treeData = [];
    this.$store.dispatch(SELECT_PROFILE, null);
  }

  initInfo() {
    this.getUsers();
    if (this.showModal) {
      this.isLoading = true;
      this.$store
        .dispatch(GET_PROFILE_REQUEST, this.selectedProfile)
        .then((profile) => {
          this.treeData = profile.modulo.map(m => {
            const submodule = m.submodulo.map(sm => {
              const permissions = sm.permissao.map(p => ({
                text: p.descricao,
                data: { id: p.idPermissao },
                active: p.ativo
              }))
              return {
                text: sm.nome,
                children: permissions
              }
            })
            return {
              text: m.nome,
              children: submodule
            }
          });
          
          this.form.name = profile.nome;
          this.form.users = profile.usuarios.map((user) => ({ value: user.idUsuario, text: user.nome }));
        })
        .catch(() => {
          this.$toast.error("Erro ao carregar dados do perfil");
        })
        .finally(() => {
          this.isLoading = false;
        })
      return
    }
    this.getPermissions();
  }

  getPermissions() {
    this.isLoading = true;
    this.$store
      .dispatch(GET_PERMISSIONS_REQUEST)
      .then((permissions) => {
        this.treeData = permissions.modulo.map(m => {
          const submodule = m.submodulo.map(sm => {
            const permissions = sm.permissao.map(p => ({
              text: p.descricao,
              data: { id: p.idPermissao },
              active: p.ativo
            }))
            return {
              text: sm.nome,
              children: permissions
            }
          })
          return {
            text: m.nome,
            children: submodule
          }
        });
      })
      .catch(() => {
        this.$toast.error("Erro ao carregar permissões disponiveis");
      })
      .finally(() => {
        this.isLoading = false;
      })
  }

  getUsers(search = null) {
    const params = {};
    if (search) params['search'] = search;
    this.$store
      .dispatch(GET_USERS_REQUEST, params)
      .then(({ results }) => {
        this.selectOptions.users = results.map((user) => ({ value: user.idUsuario, text: user.nome }));
      })
      .catch(() => {
        this.$toast.error("Erro ao carregar os usuários");
      })
  }

  removeUser(tag){
    this.form.users = this.form.users.filter(user => user.value !== tag.value)
  }

  onSave(){
    const permissions = this.form.permissions?.checked ? this.form.permissions?.checked.filter(el => el.children.length === 0) : []
    const requestData = {
      nome: this.form.name,
      idsUsuario: this.form.users.map(user => user.value),
      idsPermissao: permissions.map(permission => permission.data.id),
    };
    this.busy = true;
    if (this.showModal) {
      requestData['idPerfil'] = this.selectedProfile;
      this.$store
        .dispatch(PUT_PROFILE, requestData)
        .then(() => {
          this.$emit("message", "Perfil atualizado com sucesso", "success", true);
        })
        .catch(() => {
          this.$emit("message", "Erro ao atualizar perfil", "error");
        })
        .finally(() => {
          this.close();
          this.busy = false;
        });
      return;
    }
    this.$store
      .dispatch(POST_PROFILE, requestData)
      .then(() => {
        this.$emit("message", "Perfil criado com sucesso", "success", true);
      })
      .catch(() => {
        this.$emit("message", "Erro ao criar novo perfil", "error");
      }).finally(() => {
        this.busy = false;
        this.close()
      })
  }

  close() {
    this.$bvModal.hide("NewProfileModal");
  }
}
