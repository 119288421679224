






























import { Component, Vue } from "vue-property-decorator";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue'

import BooleanModal from "@/components/modals/BooleanModal/BooleanModal.vue";

import { formatCNPJ, formatCPF } from "@/shared/util/farmatCPF";

import {
  GET_CONSTROL_USERS_REQUEST,
  PUT_CONTROL_STATUS
} from "@/store/permissions/control/actions";

@Component({
  components: {
    Table,
    Pagination,
    BooleanModal,
  },
})
export default class RancherControl extends Vue {
  /* Data */
  private modal: any = {
    title: "",
    content: "",
    data: null,
    busy: false,
  };

  private params: any = {
    page: 1,
    size: 14,
  };

  private fixedHeader: any = [
    { key: "nome", label: "Nome", thClass: "dark-header" },
    { key: "identificacao", label: "CPF/CNPJ", thClass: "dark-header" },
    { key: "perfil", label: "Perfis", thClass: "dark-header", tdClass: "no-overflow" },
  ]

  private pagesHeader: Array<string> = [];

  private rows: any = [];

  private totalPages: number = 0;

  /* Computed */
  get isLoading() {
    return this.$store.getters.isAllControlUsersLoading;
  }

  /* Lifecycle */
  mounted() {
    this.getControlUsers(this.params);
  }
  
  /* Methods */
  getControlUsers(params: any) {
    this.resetHeader();
    this.$store
      .dispatch(GET_CONSTROL_USERS_REQUEST, params)
      .then(({ totalCount, results }) => {
        if (results.length == 0) this.$toast.error("Nenhum usuário encontrado");
        this.totalPages = totalCount;
        this.setHeader(results);
        this.rows = results.map((user) => {
          let identification = "";
          if (user.codcpf) {
            identification = formatCPF(user.codcpf);
          } else if (user.codcgc) {
            identification = formatCNPJ(user.codcgc);
          }
          const userRow = {
            nome: user.nome,
            identificacao: identification,
            perfil: user.perfis.length > 0 ? user.perfis.map(profile => profile.perfil).join(', ') : '-',
            ativo: {
              id: user.idUsuario,
              status: user.ativo,
            },
          }
          this.pagesHeader.forEach(page => {
            userRow[page.toLowerCase()] = "Sem permissão";
          })
          user.perfis.forEach(profile => {
            profile.permissoes.forEach(permission => {
              userRow[permission.modulo.toLowerCase()] = permission.permissao;
            });
          })
          return userRow;
        });
      })
      .catch((e) => {
        this.$toast.error(e);
      })
  }

  resetHeader() {
    this.fixedHeader = [
      { key: "nome", label: "Nome", thClass: "dark-header" },
      { key: "identificacao", label: "CPF/CNPJ", thClass: "dark-header" },
      { key: "perfil", label: "Perfis", thClass: "dark-header", tdClass: "no-overflow" },
    ];
    this.pagesHeader = [];
  }

  setHeader(data) {
    /* Filter all pages that header needs */
    data.forEach(users => {
      users.perfis.forEach(profile => {
        profile.permissoes.forEach(permission => {
          if (!this.pagesHeader.includes(permission.modulo.toLowerCase())) {
            this.pagesHeader.push(permission.modulo.toLowerCase());
            this.fixedHeader.push({
              key: permission.modulo.toLowerCase(),
              label: permission.modulo,
              thClass: "dark-header"
            });
          }
        });
      })
    });
    this.fixedHeader.push({
      key: "ativo",
      label: "Ativo",
      tdClass: "td-active"
    });
  }

  onChangePage(page) {
    this.params.page = page;
    this.getControlUsers(this.params);
  }

  onChangeSize(size) {
    this.params.size = size;
    this.params.page = 1;
    this.getControlUsers(this.params);
  }

  openActive(value) {
    if(value.status) {
      this.modal = {
        title: "Desativar Usuário",
        content:"Deseja confirmar o status do usuário para inativo?",
        data: { id: value.id, status: !value.status },
        busy: false,
      };
    } else {
      this.modal = {
        title: "Ativar Usuário",
        content:"Deseja confirmar o status do usuário para ativo?",
        data: { id: value.id, status: !value.status },
        busy: false,
      };
    }
    this.$bvModal.show("BooleanModal");
  }

  onAccept(data) {
    this.modal.busy = true;
    const requestData = {
      idUsuario: data.id,
      ativo: data.status,
    }
    this.$store
      .dispatch(PUT_CONTROL_STATUS, requestData)
      .then(() => {
        this.$toast.success("Status alterado com sucesso");
        this.getControlUsers(this.params);
      })
      .catch(() => {
        this.$toast.error("Erro ao alterar status");
      }).finally(() => {
        this.modal.busy = false;
        this.$bvModal.hide("BooleanModal");
      })
  }
}
