
















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})

export default class InputDate extends Vue {
	@Prop({ required: true })
  public type!: string;

  @Prop({ default: 'Selecionar' })
  public placeholder!: string;

	@Prop({ required: true})
  public value!: any;

  @Prop({ default: 'mx-input'})
  public inputClass!: string;

  @Prop({ default: 'DD/MM/YYYY HH:mm'})
  public format!: string;

  @Prop({ default: ''})
  public disableDate!: string;

  @Prop({ default: false})
  public disabled!: boolean;

	private weekdaysShort: any =  {
    formatLocale: { weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'] }
  }

  private minutesOptions: any = ['00', '15', '30', '45'];

	get model() {
    if(!this.value) return " "
		return new Date(this.value)
	}

	set model(value) {
		this.$emit('input', value);
	}

  get defaultValue() {
    const today = new Date();
    if(this.disableDate === "overEighteen"){
      return today.setFullYear(today.getFullYear() - 18);
    }
    return today;
	}

  getDisableDate(date) {
    const today = new Date();
    if(this.disableDate === "beforeToday"){
      return date < new Date(today.setHours(0, 0, 0, 0));
    }
    if(this.disableDate === "overEighteen"){
      return date >= today.setFullYear(today.getFullYear() - 18);
    }
    return false
  }

}
