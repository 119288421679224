



















































import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/ModuleLayout/ModuleLayout.vue';
import Header from '@/components/widgets/organisms/Header/Header.vue';
import Filters from './organisms/Filter/Filter.vue';
import AdvancedFilters from '@/components/widgets/organisms/AdvancedFilters/AdvancedFilters.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue';

import OccurrencesModal from './organisms/Modals/Occurrences/Occurrences.vue';
import TravelDetailModal from './organisms/Modals/TravelDetail/TravelDetail.vue';
import HistoricalModal from './organisms/Modals/Historical/Historical.vue';
import ReportModal from '@/components/modals/transitFacility/ReportModal/ReportModal.vue';
import TravelMapModal from '@/components/modals/transitFacility/MapModal/MapModal.vue';
import CarouselModal from '@/components/modals/CarouselModal/CarouselModal.vue';

import { AvailableFilters } from '@/models/filters/available-filters.interface';
import { AdvancedFiltersDefinitions } from '@/models/filters/advanced-filters.interface';
import { saveXLSX } from '@/helpers/ExportXLSX';
import { formatDateHour, formatDateISO } from '@/shared/util/formatDate';
import formatMoney from '@/shared/util/formatMoney';
import formatNumber from '@/shared/util/formatNumber';

import {
	TRANSIT_LOAD_TRAVEL_REQUEST,
	TRANSIT_GET_DOWNLOAD_IMAGE,
	TRANSIT_GET_COLUMNS,
	TRANSIT_PUT_COLUMNS,
	SELECT_TRANSIT_TRAVEL_DETAIL,
	SELECT_TRANSIT_MAP,
	SELECT_TRANSIT_OCCURRENCE,
	SELECT_TRANSIT_RESUME,
	HANDLE_OUTSYSTEM_STATUS,
} from '@/store/TransitFacility/travels/actions';

@Component({
	components: {
		Layout,
		Header,
		Filters,
		Table,
		Pagination,
		AdvancedFilters,
		ReportModal,
		OccurrencesModal,
		CarouselModal,
		TravelMapModal,
		TravelDetailModal,
		HistoricalModal,
	},
})
export default class TransitModuleTravel extends Vue {
	/* Data */
	private availableFilters: any = AvailableFilters;

	private isLoaded: boolean = true;

	private busy: any = {
		xlsx: false,
		image: false,
	};

	private params: any = {
		page: 1,
		size: 16,
		dataInicio: '',
		dataFim: '',
		pedidoId: '',
		viagemId: '',
		pecuaristaOrigemId: '',
		pecuaristaDestinoId: '',
		fazendaOrigemId: '',
		fazendaDestinoId: '',
		rastreamentoTipoEventoId: '',
		codigoSituacaoOperacional: '',
		motoristaId: '',
		ocorrencia: '',
		placaCavalo: '',
		placaCarreta: '',
		colunas: [],
	};

	private fixedHeader: any = [
		{ key: 'pedido', label: 'Pedido Uboi', thClass: 'dark-header' },
		{ key: 'viagem', label: 'Viagem Uboi', thClass: 'dark-header' },
		{ key: 'status', label: 'Status', thClass: 'dark-header' },
		{ key: 'statusFiscal', label: 'Status Fiscal', thClass: 'dark-header' },
		{ key: 'ocorrencias', label: 'Ocorrências', thClass: 'dark-header' },
		{ key: 'integracaoOutsystem', label: 'Integração Outsystem', thClass: 'dark-header' },
		{
			key: 'actions',
			label: 'Ações',
			thClass: 'gray-header',
			tdClass: 'td-action',
		},
	];

	private header: any = [];

	private columns: Array<any> = [];

	private rows: any = [];

	private totalPages: number = 0;

	/* Lifecycle */
	created() {
		this.getColumns();
	}

	/* Methods */
	submitAdvancedFilter(selectedAdvacedFilters: any) {
		this.params.colunas = selectedAdvacedFilters;
		this.$store
			.dispatch(TRANSIT_PUT_COLUMNS, selectedAdvacedFilters)
			.then(() => {
				this.$toast.success('Filtros salvos!');
			})
			.catch(() => {
				this.$toast.error('Erro ao aplicar filtros!');
			});
		this.getTravels(this.params);
	}

	submitFilter(filterDefinition: any): void {
		Object.keys(filterDefinition).forEach(index => {
			const numberIndex = +index;
			switch (numberIndex) {
				case this.availableFilters.Date:
					this.params.dataInicio = formatDateISO(filterDefinition[numberIndex].startDateSelected);
					this.params.dataFim = formatDateISO(filterDefinition[numberIndex].endDateSelected);
					break;
				case this.availableFilters.Origin:
					this.params.pecuaristaOrigemId =
						filterDefinition[numberIndex].rancherSelected?.value || '';
					this.params.fazendaOrigemId = filterDefinition[numberIndex].farmSelected?.value || '';
					break;
				case this.availableFilters.Destiny:
					this.params.pecuaristaDestinoId =
						filterDefinition[numberIndex].rancherSelected?.value || '';
					this.params.fazendaDestinoId = filterDefinition[numberIndex].farmSelected?.value || '';
					break;
				case this.availableFilters.Tracking:
					this.params.rastreamentoTipoEventoId =
						filterDefinition[numberIndex].trackingSelected?.value || '';
					break;
				case this.availableFilters.Travel:
					this.params.viagemId = filterDefinition[numberIndex].travelSelected || '';
					break;
				case this.availableFilters.Order:
					this.params.pedidoId = filterDefinition[numberIndex].orderSelected;
					break;
				case this.availableFilters.Situation:
					this.params.codigoSituacaoOperacional =
						filterDefinition[numberIndex].situationSelected?.value || '';
					break;
				case this.availableFilters.Driver:
					this.params.motoristaId = filterDefinition[numberIndex].driverSelected?.value || '';
					break;
				case this.availableFilters.Truck:
					this.params.placaCavalo = filterDefinition[numberIndex]?.truckSelected || '';
					break;
				case this.availableFilters.Cart:
					this.params.placaCarreta = filterDefinition[numberIndex]?.cartSelected || '';
					break;
			}
		});
		this.params.page = 1;
		this.getTravels(this.params);
	}

	getTravels(params: any) {
		this.isLoaded = false;
		this.$store
			.dispatch(TRANSIT_LOAD_TRAVEL_REQUEST, params)
			.then(({ totalCount, results }) => {
				if (results.length == 0) return this.$toast.error('Nenhuma viagem encontrada');
				let auxHeader = params.colunas.map(column => {
					return { ...AdvancedFiltersDefinitions[column] };
				});
				auxHeader = auxHeader.filter(i => {
					return (
						i.key != 'pedido' &&
						i.key != 'viagem' &&
						i.key != 'ocorrencias' &&
						i.key != 'status' &&
						i.key != 'statusFiscal'
					);
				});

				auxHeader.push(...this.fixedHeader);
				this.header = auxHeader;
				this.totalPages = totalCount;
				this.rows = results.map(i => {
					return {
						...i,
						dataPedido: formatDateHour(i.dataPedido),
						chegadaDesembarque: formatDateHour(i.chegadaDesembarque),
						chegadaEmbarque: formatDateHour(i.chegadaEmbarque),
						gpsChegadaEmbarque: formatDateHour(i.gpsChegadaEmbarque),
						desembarqueProgramado: formatDateHour(i.desembarqueProgramado),
						embarqueProgramado: formatDateHour(i.embarqueProgramado),
						fimDesembarque: formatDateHour(i.fimDesembarque),
						fimEmbarque: formatDateHour(i.fimEmbarque),
						gpsFimEmbarque: formatDateHour(i.gpsFimEmbarque),
						inicioDesembarque: formatDateHour(i.inicioDesembarque),
						inicioEmbarque: formatDateHour(i.inicioEmbarque),
						gpsInicioEmbarque: formatDateHour(i.gpsInicioEmbarque),
						saidaDesembarque: formatDateHour(i.saidaDesembarque),
						saidaEmbarque: formatDateHour(i.saidaEmbarque),
						gpsSaidaEmbarque: formatDateHour(i.gpsSaidaEmbarque),
						valorPrevisto: formatMoney(i.valorPrevisto),
						peso: formatNumber(i.peso),
						kmRealizado: formatNumber(i.kmRealizado),
						kmEntrePontos: formatNumber(i.kmEntrePontos),
						kmPrevisto: formatNumber(i.kmPrevisto),
						actions: {
							id: i.viagem,
							buttons: ['edit', 'detail', 'occurrences', 'map'],
						},
						integracaoOutsystem: i.integracaoOutsystem,
					};
				});
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.isLoaded = true;
			});
	}

	getColumns() {
		this.isLoaded = false;
		this.$store
			.dispatch(TRANSIT_GET_COLUMNS)
			.then(response => {
				response[5].descricao = 'Peso Médio';
				this.columns = response.filter(i => {
					if (
						![
							'Pedido',
							'Viagem',
							'Status',
							'Status Fiscal',
							'Ocorrências',
							'Integração Outsystem',
						].includes(i.descricao)
					) {
						return i;
					}
				});
				this.params.colunas = this.columns.filter(i => i.selected).map(i => i.codigo);
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.getTravels(this.params);
			});
	}

	message(message, variant) {
		this.$nextTick(() => {
			this.$toast.open({
				message,
				type: variant,
			});
		});
	}

	changePage(page) {
		this.params.page = page;
		this.getTravels(this.params);
	}

	changeSize(size) {
		this.params.size = size;
		this.params.page = 1;
		this.getTravels(this.params);
	}

	exportXLS() {
		const params = { ...this.params };
		params.size = this.totalPages;
		this.busy.xlsx = true;
		let body = [];
		const header = this.header.map(item => {
			return { label: item?.label || item?.key, value: item?.key };
		});
		header.pop();
		this.$store
			.dispatch(TRANSIT_LOAD_TRAVEL_REQUEST, params)
			.then(({ results }) => {
				if (results.length == 0) {
					return;
				}
				body = results.map(i => {
					return {
						...i,
						status: i?.status || '',
						dataPedido: formatDateHour(i.dataPedido),
						chegadaDesembarque: formatDateHour(i.chegadaDesembarque),
						chegadaEmbarque: formatDateHour(i.chegadaEmbarque),
						desembarqueProgramado: formatDateHour(i.desembarqueProgramado),
						embarqueProgramado: formatDateHour(i.embarqueProgramado),
						fimDesembarque: formatDateHour(i.fimDesembarque),
						fimEmbarque: formatDateHour(i.fimEmbarque),
						inicioDesembarque: formatDateHour(i.inicioDesembarque),
						inicioEmbarque: formatDateHour(i.inicioEmbarque),
						saidaDesembarque: formatDateHour(i.saidaDesembarque),
						saidaEmbarque: formatDateHour(i.saidaEmbarque),
						valorPrevisto: formatMoney(i.valorPrevisto),
					};
				});
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.busy.xlsx = false;
				saveXLSX(header, body, 'tabela', 'Lista-viagens');
			});
	}

	downloadImage(params) {
		this.busy.image = true;
		this.$store
			.dispatch(TRANSIT_GET_DOWNLOAD_IMAGE, params)
			.then(() => {
				this.$toast.success('Download realizado com sucesso!');
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.busy.image = false;
			});
	}

	openEdit(viagem) {
		this.$store.dispatch(SELECT_TRANSIT_TRAVEL_DETAIL, viagem.id);
	}

	openDetail(viagem) {
		this.$store.dispatch(SELECT_TRANSIT_RESUME, viagem.id);
	}

	openOccurrences(viagem) {
		this.$store.dispatch(SELECT_TRANSIT_OCCURRENCE, viagem.id);
	}

	openMap(viagem) {
		this.$store.dispatch(SELECT_TRANSIT_MAP, viagem.id);
	}

	handleOutsystem(row) {
		const idViagem = row.item.viagem;

		this.$store.dispatch(HANDLE_OUTSYSTEM_STATUS, idViagem).catch(() => {
			this.$toast.error('Erro ao aplicar integração!');
		});
	}
}
