/* Imports */
import ContractsState from '@/models/TransitFacility/contracts/state.interface';
import api from '@/shared/util/api';

const { http } = api.getInstance();
const URL = '/transportadora/jbspedido/lancamento';

/* Actions */
import {
  SET_ROAD_MAP,
} from './actions';

/* Initial AuthState object */
const state: ContractsState = {
  roadmap: '',
};

/* Vuex Auth Getters */
const getters = {
  ContractRoadMap: (state: ContractsState): string => state.roadmap,
};

/* Vuex Auth Mutations */
const mutations = {
  [SET_ROAD_MAP]: (state: ContractsState, value) => state.roadmap = value,
};

/* Vuex Auth Actions */
const actions = {
};

export default {
  state,
  getters,
  actions,
  mutations,
};