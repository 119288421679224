import { 
  ORDER_ORIGIN_RANCHER_REQUEST,
  ORDER_DESTINY_RANCHER_REQUEST,
  ORDER_ORIGIN_FARM_REQUEST,
  ORDER_DESTINY_FARM_REQUEST,
	TRACKING_TYPES_REQUEST, 
	OPERATIONAL_SITUATION_REQUEST, 
} from '@/store/filters/actions';

import {
  AvailableFilters
} from "@/models/filters/available-filters.interface";

// This must be a function so the state of the filters can be erased from one page to the next
export const AvailableFiltersDefinitions = () => {
	const definition = {};

	definition[ AvailableFilters.Date ] = {
		startDateSelected: '',
		endDateSelected: '',
	}

	definition[ AvailableFilters.Origin ] = {
		rancherSelected: '',
		farmSelected: '',
    rancherDispatch: ORDER_ORIGIN_RANCHER_REQUEST,
    farmDispatch: ORDER_ORIGIN_FARM_REQUEST,
		isLoading: false,
	}

	definition[ AvailableFilters.Destiny ] = {
		rancherSelected: '',
		farmSelected: '',
    rancherDispatch: ORDER_DESTINY_RANCHER_REQUEST,
    farmDispatch: ORDER_DESTINY_FARM_REQUEST,
		isLoading: false,
	}

	definition[ AvailableFilters.Tracking ] = {
		trackingSelected: '',
		trackingDispatch: TRACKING_TYPES_REQUEST,
		isLoading: false,
	}

	definition[ AvailableFilters.Situation ] = {
		situationSelected: '',
    situationDispatch: OPERATIONAL_SITUATION_REQUEST,
		isLoading: false,
	}

	definition[ AvailableFilters.Order ] = {
		orderSelected: '',
	}

	return definition;
}