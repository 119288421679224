export const GET_ALL_PROFILES_REQUEST = 'GET_ALL_PROFILES_REQUEST';
export const GET_ALL_PROFILES_SUCCESS = 'GET_ALL_PROFILES_SUCCESS';
export const GET_ALL_PROFILES_FAILED = 'GET_ALL_PROFILES_FAILED';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILED = 'GET_PERMISSIONS_FAILED';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const POST_PROFILE = 'POST_PROFILE';

export const PUT_PROFILE = 'PUT_PROFILE';

export const DELETE_PROFILE = 'DELETE_PROFILE';

export const SELECT_PROFILE = 'SELECT_PROFILE';
