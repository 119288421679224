import {
    TRANSIT_TRAVEL_DRIVER_DETACHED_FORM_REQUEST
  } from '@/store/TransitFacility/filters/actions';
  
  import {
    AvailableFilters
  } from "@/models/filters/available-filters.interface";
  
  // This must be a function so the state of the filters can be erased from one page to the next
  export const AvailableFiltersDefinitions = () => {
      const definition = {};
  
      definition[ AvailableFilters.Date ] = {
          startDateSelected: '',
          endDateSelected: '',
      }
  
      definition[ AvailableFilters.DriverDetachedForm ] = {
          driverSelected: '',
          driverDispatch: TRANSIT_TRAVEL_DRIVER_DETACHED_FORM_REQUEST,
          isLoading: false,
      }
  
      return definition;
  }