






/* Imports */
import { Vue, Component } from 'vue-property-decorator';

/* force pull request */
/* Main App Class */
@Component({})

export default class App extends Vue {}
