/* Imports */
import LocationsState from '@/models/locations-state/locations-state.interface';
import axios from "axios";

/* Actions */
import {
  GET_UF_REQUEST,
  GET_UF_SUCCESS,
  GET_UF_FAILED,
  GET_CITY_REQUEST,
  GET_CITY_SUCCESS,
  GET_CITY_FAILED,
  GET_CEP_REQUEST,
  GET_CEP_SUCCESS,
  GET_CEP_FAILED
} from "./actions";

const state: LocationsState = {
  ufStatus: '',
  cityStatus: '',
  cepStatus: ''
};

/* Vuex Auth Getters */
const getters = {
  isUfLoading: (state: LocationsState): boolean => state.ufStatus === 'loading',
  isCityLoading: (state: LocationsState): boolean => state.cityStatus === 'loading',
  isCepLoading: (state: LocationsState): boolean => state.cepStatus === 'loading',
};

/* Vuex Auth Mutations */
const mutations = {
  [GET_UF_REQUEST]: (state: LocationsState) => state.ufStatus = 'loading',
  [GET_UF_SUCCESS]: (state: LocationsState) => state.ufStatus = 'success',
  [GET_UF_FAILED]: (state: LocationsState) => state.ufStatus = 'error',
  [GET_CITY_REQUEST]: (state: LocationsState) => state.cityStatus = 'loading',
  [GET_CITY_SUCCESS]: (state: LocationsState) => state.cityStatus = 'success',
  [GET_CITY_FAILED]: (state: LocationsState) => state.cityStatus = 'error',
  [GET_CEP_REQUEST]: (state: LocationsState) => state.cepStatus = 'loading',
  [GET_CEP_SUCCESS]: (state: LocationsState) => state.cepStatus = 'success',
  [GET_CEP_FAILED]: (state: LocationsState) => state.cepStatus = 'error',
};

/* Vuex Auth Actions */
const actions = {
  [GET_UF_REQUEST]: ({ commit }: any) => {
    return new Promise((resolve, reject) => {
      commit(GET_UF_REQUEST);
      axios.get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then(response => {
          commit(GET_UF_SUCCESS);
          resolve(response);
        })
        .catch(error => {
          commit(GET_UF_FAILED);
          reject(error);
        })
    });
  },
  [GET_CITY_REQUEST]: ({ commit }: any, uf: any) => {
    return new Promise((resolve, reject) => {
      commit(GET_UF_REQUEST);
      axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
        .then(response => {
          commit(GET_UF_SUCCESS);
          resolve(response)
        })
        .catch(error => {
          commit(GET_UF_FAILED);
          reject(error)
        })
    });
  },
  [GET_CEP_REQUEST]: ({ commit }: any, cep: any) => {
    return new Promise((resolve, reject) => {
      commit(GET_CEP_REQUEST);
      axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => {
          commit(GET_CEP_SUCCESS);
          resolve(response)
        })
        .catch(error => {
          commit(GET_CEP_FAILED);
          reject(error)
        })
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};