import { render, staticRenderFns } from "./Disclosure.vue?vue&type=template&id=66e2b15a&scoped=true&"
import script from "./Disclosure.vue?vue&type=script&lang=ts&"
export * from "./Disclosure.vue?vue&type=script&lang=ts&"
import style0 from "./Disclosure.vue?vue&type=style&index=0&id=66e2b15a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e2b15a",
  null
  
)

export default component.exports