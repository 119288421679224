




















import {Vue, Component, Prop } from "vue-property-decorator";
import CrossIcon from "@/components/icons/Cross.vue";
import CloseIcon from "@/components/icons/Close.vue";

@Component({
  components: {
    CrossIcon,
    CloseIcon
  },
})

export default class ButtonPictures extends Vue {
  /* Props */
  @Prop({ default: false })
  public isSelfie!: boolean;

  @Prop({ required: true })
  public isSelected!: any;

  /* Computed */
  get filePath(){
    if(typeof this.isSelected == "object"){
      return URL.createObjectURL(this.isSelected)
    }
    return this.isSelected;
  }

  /* Methods */
  handleFile(event){
    this.$emit("onFileSelected", event);
  }
}
