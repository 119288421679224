import DetailIcon from '@/components/icons/Detail.vue';
import EditIcon from '@/components/icons/Pencil.vue';
import TermIcon from '@/components/icons/HandShake.vue';
import StarIcon from '@/components/icons/Star.vue';
import OccurrencesIcon from '@/components/icons/Paper.vue';
import PointMapIcon from '@/components/icons/PointMap.vue';
import TrashIcon from '@/components/icons/Trash.vue';
import PhotosIcon from '@/components/icons/Photos.vue';
import SelfieIcon from '@/components/icons/Selfie.vue';
import InfoIcon from '@/components/icons/InfoIcon.vue';
import AcceptIcon from '@/components/icons/Accept.vue';
import CancelIcon from '@/components/icons/Cancel.vue';
import LinkIcon from '@/components/icons/Link.vue';
import LinkCommonIcon from '@/components/icons/LinkCommon.vue';

import StarFilledIcon from '@/components/icons/StarFilled.vue';
import HandShakeFilledIcon from '@/components/icons/HandShakeFilled.vue';
import ManagementUserTableIcon from '@/components/icons/ManagementUserTable.vue';

export default {
	detail: {
		component: DetailIcon,
		action: 'openDetail',
		title: 'Mostar detalhes',
	},
	edit: {
		component: EditIcon,
		action: 'openEdit',
		title: 'Editar',
	},
	rate: {
		component: StarIcon,
		filled: StarFilledIcon,
		action: 'openRate',
		title: 'Avaliar',
	},
	term: {
		component: TermIcon,
		filled: HandShakeFilledIcon,
		action: 'openTerm',
		title: 'Abrir termo',
	},
	contract: {
		component: TermIcon,
		action: 'openContract',
		title: 'Abrir contratos',
	},
	map: {
		component: PointMapIcon,
		action: 'openMap',
		title: 'Abrir mapa',
	},
	occurrences: {
		component: OccurrencesIcon,
		action: 'openOccurrences',
		title: 'Abrir ocorrencias',
	},
	accept: {
		component: AcceptIcon,
		action: 'openAccept',
		title: 'Aceitar',
	},
	cancel: {
		component: CancelIcon,
		action: 'openCancel',
		title: 'Recusar',
	},
	trash: {
		component: TrashIcon,
		action: 'openTrash',
		title: 'Apagar',
	},
	photos: {
		component: PhotosIcon,
		action: 'openPhotos',
		title: 'Abrir fotos',
	},
	selfie: {
		component: SelfieIcon,
		action: 'openSelfie',
		title: 'Abrir imagem',
	},
	info: {
		component: InfoIcon,
		action: 'openInfo',
		title: 'Mais Informações',
	},
	link: {
		component: LinkIcon,
		action: 'openTravels',
		title: 'Abrir Viagens',
	},
	link_common: {
		component: LinkCommonIcon,
		action: 'linkTravel',
		title: 'Vincular Viagem',
	},
	toggle_user: {
		component: ManagementUserTableIcon,
		action: 'toggleUserAction',
		title: 'Alterar status do usuário',
	},
};
