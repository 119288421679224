








import { Component, Vue, Prop} from "vue-property-decorator";

@Component({})

export default class ArchivePDFIcon extends Vue {
  /* Props */
  @Prop({ default: "#FFFFFF" })
  public color!: string;
}
