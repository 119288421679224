














import { Vue, Component, Prop } from "vue-property-decorator";

import getMaskDefinition from "@/shared/util/masks";

@Component({})

export default class InputLogin extends Vue {
  /* Props */
  @Prop()
  public value!: string;

  @Prop({ default: 'login' })
  public name!: string;

  @Prop({ default: 'text' })
  public type!: string;

  @Prop({ default: 'CPF/CNPJ' })
  public placeholder!: string;

  /* Methods */
  maskDefinition(value) {
    return getMaskDefinition(value);
  }
}
