export const GET_ALL_AFFILIATES_REQUEST = 'GET_ALL_AFFILIATES_REQUEST';
export const GET_ALL_AFFILIATES_SUCCESS = 'GET_ALL_AFFILIATES_SUCCESS';
export const GET_ALL_AFFILIATES_FAILED = 'GET_ALL_AFFILIATES_FAILED';

export const GET_AFFILIATE_REQUEST = 'GET_AFFILIATE_REQUEST';
export const GET_AFFILIATE_SUCCESS = 'GET_AFFILIATE_SUCCESS';
export const GET_AFFILIATE_FAILED = 'GET_AFFILIATE_FAILED';

export const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILED = 'GET_CITIES_FAILED';

export const POST_AFFILIATE = 'POST_AFFILIATE';

export const PUT_AFFILIATE = 'PUT_AFFILIATE';

export const DELETE_AFFILIATE = 'DELETE_AFFILIATE';

export const SELECT_AFFILIATE = 'SELECT_AFFILIATE';
