export const situationlabel: Record <string, any> = {
  A: {
    label: "Pedido Aberto",
  },
  N: {
    label: "Negociação",
  },
  O: {
    label: "Aguardando Aceite de Contrato",
  },
  V: {
    label: "Pedido Contratado",
  },
  E: {
    label: "Pedido Em Andamento",
  },
  G: {
    label: "Aguardando Avaliação",
  },
  H: {
    label: "Aguardando Fechamento",
  },
  F: {
    label: "Finalizada",
  },
  C: {
    label: "Cancelada",
  },
}