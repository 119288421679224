


































































































































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import ButtonPictures from "@/components/widgets/atoms/ButtonPictures/ButtonPictures.vue";

import getMaskDefinition from "@/shared/util/masks";

import {
  TRANSIT_REGISTER_POST_TRUCK,
  SELECT_REGISTER_TRUCK,
  TRANSIT_REGISTER_PUT_TRUCK,
  TRANSIT_REGISTER_GET_SKETCH_TRUCK,
} from "@/store/TransitFacility/registers/actions";

import { GET_UF_REQUEST } from "@/store/locations/actions";

@Component({
  components: {
    Modal,
    ButtonCustom,
    ButtonPictures
  },
})

export default class NewTruckModal extends Vue {

  // private truckTypeOptions: any = [
  //   { value: null, text: 'Selecione', disabled: true },
  //   { value: '3 Eixos', text: '3 Eixos' },
  //   { value: '2 Eixos Alta', text: '2 Eixos Alta' },
  //   { value: 'Carreta Baixa', text: 'Carreta Baixa' },
  //   { value: 'Romeu e Julieta', text: 'Romeu e Julieta' },
  //   { value: 'Truck', text: 'Truck' },
  // ]

  private truckTypeOptions: any =[
    { value: null, text: 'Selecione', disabled: true },
    { value: 'Cavalo 4x2', text: 'Cavalo 4x2' },
    { value: 'Cavalo 6x2', text: 'Cavalo 6x2' },
    { value: 'Cavalo 6x4', text: 'Cavalo 6x4' },
    { value: 'Truck 6x2', text: 'Truck 6x2' },
    { value: 'Truck 6x4', text: 'Truck 6x4' },
    { value: 'Bitruck 8x2', text: 'Bitruck 8x2' },
    { value: 'Bitruck 8x4', text: 'Bitruck 8x4' },
    { value: 'Toco', text: 'Toco' },
    { value: '3/4', text: '3/4' },
    { value: 'Caminhonete', text: 'Caminhonete' },
    { value: 'Outro', text: 'Outro' },
  ]

  private brandOptions: any =[
    { value: null, text: 'Marca', disabled: true },
    { value: 'FORD', text: 'FORD' },
    { value: 'IVECO', text: 'IVECO' },
    { value: 'MERCEDES', text: 'MERCEDES' },
    { value: 'SCANIA', text: 'SCANIA' },
    { value: 'VOLKSWAGEN', text: 'VOLKSWAGEN' },
    { value: 'VOLVO', text: 'VOLVO' },
    { value: 'OUTRO', text: 'OUTRO' },
  ]

  private hasTrackerOptions: any =[
    { value: true, text: 'Sim' },
    { value: false, text: 'Não' },
  ]

  private licensePlateUFOptions: any =[]

  private selectedOptions: any = {
    licensePlateUF: null,
    licensePlateNumber: '',
    truckType: null,
    descriptionYear: '',
    descriptionModel: '',
    descriptionBrand: null,
    docChassi: '',
    docRenavam: '',
    selectedCRLV: null,
    selectedANTT: null,
    hasTracker: null,
    trackerType: '',
    selectedDriverSide: null,
    selectedFrontSide: null,
    selectedRideSide: null,
    selectedBackSide: null,
  };

  private isLoading: boolean = false;

  private busy: boolean = false;

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedRegisterTruck;
  }
  get selectedTruck() {
    return this.$store.getters.getRegisterTruck;
  }

  /* Methods */
  resetInfo(){
    this.selectedOptions = {
      licensePlateUF: null,
      licensePlateNumber: '',
      truckType: null,
      descriptionYear: '',
      descriptionModel: '',
      descriptionBrand: null,
      docChassi: '',
      docRenavam: '',
      selectedCRLV: null,
      selectedANTT: null,
      hasTracker: null,
      trackerType: '',
      selectedDriverSide: null,
      selectedFrontSide: null,
      selectedRideSide: null,
      selectedBackSide: null,
    };
    this.$store.dispatch(SELECT_REGISTER_TRUCK, null);
  }

  initInfo(){
    this.getUF();
    if(this.showModal){
      this.isLoading = true;
      this.$store.dispatch(TRANSIT_REGISTER_GET_SKETCH_TRUCK, this.selectedTruck)
      .then((truck) => {
        this.selectedOptions = {
          licensePlateUF: truck?.uf || null,
          licensePlateNumber: truck?.placa || '',
          truckType: truck?.tipo || null,
          descriptionYear: truck?.ano || '',
          descriptionModel: truck?.modelo || '',
          descriptionBrand: truck?.marca || null,
          docChassi: truck?.numeroChassi || '',
          docRenavam: truck?.numeroRenavam || '',
          selectedCRLV: null,
          selectedANTT: null,
          hasTracker: truck?.rastreador,
          trackerType: truck?.tipoRastreador || '',
          selectedDriverSide: null,
          selectedFrontSide: null,
          selectedRideSide: null,
          selectedBackSide: null,
        };
        truck.fotos.forEach((foto) => {
          if(foto.descricao == "CRLV"){
            this.selectedOptions.selectedCRLV = foto.urlDocumento;
          }
          if(foto.descricao == "ANTT"){
            this.selectedOptions.selectedANTT = foto.urlDocumento;
          }
          if(foto.descricao == "LATERAL_MOTORISTA"){
            this.selectedOptions.selectedDriverSide = foto.urlDocumento;
          }
          if(foto.descricao == "LATERAL_CARONA"){
            this.selectedOptions.selectedRideSide = foto.urlDocumento;
          }
          if(foto.descricao == "FRENTE"){
            this.selectedOptions.selectedFrontSide = foto.urlDocumento;
          }
          if(foto.descricao == "TRASEIRA"){
            this.selectedOptions.selectedBackSide = foto.urlDocumento;
          }
        });
      })
      .catch(() => {
        this.$toast.error("Erro ao carregar dados do caminhão");
      }).finally(() => {
        this.isLoading = false;
      })
    }
  }
  
  getUF() {
    this.$store
    .dispatch(GET_UF_REQUEST)
    .then(result => {
      const UFs = result.data.map(({sigla}) => (
        {value: sigla, text: sigla}
      ));
      this.licensePlateUFOptions = UFs;
    })
    .catch(() => {
      this.$toast.error("Erro ao carregar os estados");
    })
  }

  maskDefinition(value) {
    return getMaskDefinition(value);
  }

  onErasePhoto(type){
    this.selectedOptions[type] = null;
  }

  onPhotoSelected(event, type){
      this.selectedOptions[type] = event.target.files[0]
  }

  createNewCart(){
    this.busy = true;
    const fd = new FormData();

    fd.append('UF', this.selectedOptions.licensePlateUF)
    fd.append('Placa', this.selectedOptions.licensePlateNumber)
    fd.append('Tipo', this.selectedOptions.truckType)
    fd.append('Ano', this.selectedOptions.descriptionYear)
    fd.append('Modelo', this.selectedOptions.descriptionModel)
    fd.append('Marca', this.selectedOptions.descriptionBrand)
    fd.append('NumeroChassi', this.selectedOptions.docChassi)
    fd.append('NumeroRenavam', this.selectedOptions.docRenavam)
    fd.append('FotoCRLV', this.selectedOptions.selectedCRLV)
    fd.append('FotoANTT', this.selectedOptions.selectedANTT)
    fd.append('Rastreador', this.selectedOptions.hasTracker)
    fd.append('TipoRastreador', this.selectedOptions.trackerType)
    fd.append('FotoLateralMotorista', this.selectedOptions.selectedDriverSide)
    fd.append('FotoFrente', this.selectedOptions.selectedFrontSide)
    fd.append('FotoLateralCarona', this.selectedOptions.selectedRideSide)
    fd.append('FotoTraseira', this.selectedOptions.selectedBackSide)

    if (this.showModal) {
      fd.append('IdCaminhao', this.selectedTruck)
      this.$store
        .dispatch(TRANSIT_REGISTER_PUT_TRUCK, fd)
        .then(() => {
          this.$emit("message", "Caminhão atualizado com sucesso", "success", true);
        })
        .catch(() => {
          this.$emit("message", "Erro ao atualizar caminhão", "error");
        })
        .finally(() => {
          this.close();
          this.busy = false;
        });
      return;
    }

    this.$store
    .dispatch(TRANSIT_REGISTER_POST_TRUCK, fd)
    .then(() => {
      this.$emit("message", "Caminhão criado com sucesso", "success", true);
    })
    .catch(() => {
      this.$emit("message", "Erro ao criar caminhão", "error");
    }).finally(() => {
      this.busy = false;
      this.close()
    })
  }

  close() {
    this.$bvModal.hide("NewTruckModal");
  }
}
