











































import { Vue, Component, Prop } from "vue-property-decorator";
import Disclosure from "@/components/widgets/molecules/Disclosure/Disclosure.vue";
import TableSelect from "@/components/widgets/organisms/TableSelect/TableSelect.vue";

import { TRANSIT_GET_PARTNERS } from "@/store/TransitFacility/travels/actions";

@Component({
  components: {
    Disclosure,
    TableSelect
  },
})

export default class DisclosurePayers extends Vue {
  /* Props */
  @Prop({ default: {} })
  public payer!: any;

  @Prop({ default: {} })
  public takerPay!: any;

  @Prop({ default: () => [] })
  public sender!: Array<any>;

  @Prop({ default: () => [] })
  public receiver!: Array<any>;
  
  @Prop({ default: () => [] })
  public taker!: Array<any>;

  /* Data */
  private selectOptions: any = {
    takers: []
  }

  /* Computed */
  get isLoading() {
    return this.$store.getters.isTransitPartnerLoading;
  }

  /* Lifecycle */
  mounted() {
    this.getTakers();
  }

  /* Methods */
  getTakers(search = null) {
    this.$store
      .dispatch(TRANSIT_GET_PARTNERS, { page: 1, size: 20, search })
      .then((response) => {
        this.selectOptions.takers = response.results.map(partner => (
          { value: partner.idParceiro, text: partner.nome }
        ));
      })
      .catch((e) => {
        this.$toast.error(e);
      })
  }
}
