/* Auth Actions for Store */
export const ORDER_ORIGIN_RANCHER_REQUEST = 'ORDER_ORIGIN_RANCHER_REQUEST';
export const ORDER_DESTINY_RANCHER_REQUEST = 'ORDER_DESTINY_RANCHER_REQUEST';
export const TRAVEL_ORIGIN_RANCHER_REQUEST = 'TRAVEL_ORIGIN_RANCHER_REQUEST';
export const TRAVEL_DESTINY_RANCHER_REQUEST = 'TRAVEL_DESTINY_RANCHER_REQUEST';

export const ORDER_ORIGIN_FARM_REQUEST = 'ORDER_ORIGIN_FARM_REQUEST';
export const ORDER_DESTINY_FARM_REQUEST = 'ORDER_DESTINY_FARM_REQUEST';
export const TRAVEL_ORIGIN_FARM_REQUEST = 'TRAVEL_ORIGIN_FARM_REQUEST';
export const TRAVEL_DESTINY_FARM_REQUEST = 'TRAVEL_DESTINY_FARM_REQUEST';

export const TRACKING_TYPES_REQUEST = 'TRACKING_TYPES_REQUEST';
export const OPERATIONAL_SITUATION_REQUEST = 'OPERATIONAL_SITUATION_REQUEST';
export const DRIVERS_REQUEST = 'DRIVERS_REQUEST'
export const PARTNER_MAP_REQUEST = 'PARTNER_MAP_REQUEST';
export const DRIVER_MAP_REQUEST = 'DRIVER_MAP_REQUEST';
