




import { Component, Vue } from "vue-property-decorator";
import Permissions from "@/components/pages/modules/rancher/Permissions/index.vue";

@Component({
  components: {
    Permissions
  }
})

export default class RancherPermissions extends Vue {}
