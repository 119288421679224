















































































































import { Vue, Component, Watch } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import Multiselect from "@/components/widgets/atoms/Multiselect/Multiselect.vue";

import getMaskDefinition from "@/shared/util/masks";

import {
  GET_AFFILIATE_REQUEST,
  GET_CITIES_REQUEST,
  POST_AFFILIATE,
  PUT_AFFILIATE,
  SELECT_AFFILIATE
} from "@/store/permissions/affiliate/actions";

import {
  GET_UF_REQUEST,
  GET_CEP_REQUEST
} from "@/store/locations/actions";

@Component({
  components: {
    Modal,
    ButtonCustom,
    Multiselect
  },
})

export default class NewAffiliateModal extends Vue {
  private selectOptions: any = {
    cities: [],
    ufs: []
  }

  private form: any = {
    discription: null,
    ie: null,
    codeJbs: null,
    cep: null,
    address: null,
    number: null,
    district: null,
    complement: null,
    uf: null,
    city: null,
    lat: null,
    long: null,
    observation: null
  };

  private isLoading: boolean = false;

  private busy: boolean = false;

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedAffiliate;
  }

  get selectedAffiliate() {
    return this.$store.getters.getSelectedAffiliate;
  }

  get title() {
    if (this.showModal) return "Editar Filial";
    return "Nova Filial";
  }

  get isLoadingCities() {
    return this.$store.getters.isCitiesLoading;
  }

  get isUfLoading() {
    return this.$store.getters.isUfLoading;
  }

  get validation() {
    if (
      this.form.discription &&
      this.form.ie &&
      this.form.codeJbs &&
      this.form.cep &&
      this.form.address &&
      this.form.number &&
      this.form.district &&
      this.form.city &&
      this.form.lat &&
      this.form.long
    ) return false;
    return true;
  }

  /* Watcher */
  @Watch("form.uf")
  onOriginUFChange(value) {
    this.form.city = null;
    this.$nextTick(() => {
      if (value) this.getCities(value?.text);
    })
  }

  @Watch("form.cep")
  onCepChange(value) {
    if (value && value.length === 8) this.getCEP(value);
  }

  /* Methods */
  resetInfo(){
    this.form = {
      discription: null,
      ie: null,
      codeJbs: null,
      cep: null,
      address: null,
      number: null,
      district: null,
      complement: null,
      uf: null,
      city: null,
      lat: null,
      long: null,
      observation: null
    };
    this.$store.dispatch(SELECT_AFFILIATE, null);
  }

  initInfo() {
    this.getUF();
    if (this.showModal) {
      this.isLoading = true;
      this.$store
        .dispatch(GET_AFFILIATE_REQUEST, this.selectedAffiliate)
        .then((affiliate) => {
          this.form.discription = affiliate.descricao;
          this.form.ie = affiliate.inscricaoEstadual,
          this.form.codeJbs = affiliate.codJbs;
          this.form.cep = affiliate.cep;
          this.form.address = affiliate.endereco;
          this.form.number = affiliate.numero;
          this.form.district = affiliate.bairro;
          this.form.complement = affiliate.complemento;
          this.form.uf = { value: affiliate.estado, text: affiliate.estado };
          this.form.lat = affiliate.latitude;
          this.form.long = affiliate.longitude;
          this.form.observation = affiliate.observacao;
          /* Load values after select erase values in watcher */
          this.$nextTick(() => {
            this.form.address = affiliate.endereco;
            this.form.city = { value: affiliate.idMunicipio, text: affiliate.municipio };
          })
        })
        .catch(() => {
          this.$toast.error("Erro ao carregar dados da filial");
        }).finally(() => {
          this.isLoading = false;
        })
    }
  }

  getCEP(cep) {
    this.$store
      .dispatch(GET_CEP_REQUEST, cep)
      .then(result => {
        this.form.address = result.data.logradouro;
      })
      .catch(() => {
        this.$toast.error("Erro ao encontrar CEP");
      })
  }

  getUF() {
    this.$store
      .dispatch(GET_UF_REQUEST)
      .then(result => {
        this.selectOptions.ufs = result.data.map(({sigla}) => ({value: sigla, text: sigla}));
      })
      .catch(() => {
        this.$toast.error("Erro ao carregar os estados");
      })
  }

  getCities(uf) {
    const params = { state: uf };
    this.$store
      .dispatch(GET_CITIES_REQUEST, params)
      .then(({ results }) => {
        this.selectOptions.cities = results.map(city => ({ value: city.idMunicipio, text: city.nome }));
      })
      .catch(() => {
        this.$toast.error("Erro ao carregar os municípios");
      })
  }

  onSave(){
    const requestData = {
      descricao: this.form.discription,
      inscricaoEstadual: this.form.ie,
      codJbs: this.form.codeJbs,
      cep: this.form.cep,
      endereco: this.form.address,
      numero: this.form.number,
      bairro: this.form.district,
      complemento: this.form.complement,
      idMunicipio: this.form.city?.value || null,
      latitude: this.form.lat,
      longitude: this.form.long,
      observacao: this.form.observation
    };
    this.busy = true;
    if (this.showModal) {
      requestData['idFazenda'] = this.selectedAffiliate;
      this.$store
        .dispatch(PUT_AFFILIATE, requestData)
        .then(() => {
          this.$emit("message", "Filial atualizada com sucesso", "success", true);
        })
        .catch(() => {
          this.$emit("message", "Erro ao atualizar filial", "error");
        })
        .finally(() => {
          this.busy = false;
          this.close();
        });
      return;
    }
    this.$store
      .dispatch(POST_AFFILIATE, requestData)
      .then(() => {
        this.$emit("message", "Filial criada com sucesso", "success", true);
      })
      .catch(() => {
        this.$emit("message", "Erro ao criar nova filial", "error");
      }).finally(() => {
        this.busy = false;
        this.close();
      })
  }
  
  maskDefinition(value) {
    return getMaskDefinition(value);
  }

  close() {
    this.$bvModal.hide("NewAffiliateModal");
  }
}
