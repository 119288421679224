import { 
	PARTNER_MAP_REQUEST,
	DRIVER_MAP_REQUEST
} from '@/store/filters/actions';

import {
  AvailableFilters
} from "@/models/filters/available-filters.interface";

// This must be a function so the state of the filters can be erased from one page to the next
export const AvailableFiltersDefinitions = () => {
	const definition = {};

  definition[ AvailableFilters.Driver ] = {
		driverSelected: '',
		driverDispatch: DRIVER_MAP_REQUEST,
		isLoading: false,
	}

  definition[ AvailableFilters.Travel ] = {
		travelSelected: '',
	}

  definition[ AvailableFilters.Truck ] = {
		truckSelected: '',
	}

	definition[ AvailableFilters.OriginRancher ] = {
		originRancherSelected: '',
		originRancherDispatch: PARTNER_MAP_REQUEST,
		isLoading: false
	}

	return definition;
}