

















import { Component, Vue } from "vue-property-decorator";
import Layout from '@/components/layouts/ModuleLayout/ModuleLayout.vue';
import Header from '@/components/widgets/organisms/Header/Header.vue'
import Tabs from "@/components/widgets/molecules/Tabs/Tabs.vue";

import Control from "./template/Control/Control.vue";
import Profiles from "./template/Profiles/Profiles.vue";
import Users from "./template/Users/Users.vue";
import Affiliates from "./template/Affiliates/Affiliates.vue";

@Component({
  components: {
    Layout,
    Header,
    Tabs,
    Control,
    Profiles,
    Users,
    Affiliates,
  },
})

export default class RancherModulePermissions extends Vue {
  /* Data */
  private permissionName: any =  {
    key: "control",
    name: ""
  };

   /* Computed */
  get tabsComponents() {
    return [Control, Profiles, Users, Affiliates];
  }

  get hasButton() {
    return this.permissionName.key !== "control";
  }

  showIndex(index){
    switch (index) {
      case 0:
        this.permissionName = { key: "control", name: "" }
        break;
      case 1:
        this.permissionName = { key: "profile", name: "Novo Perfil" }
        break;
      case 2:
        this.permissionName = { key: "users", name: "Novo Usuário" }
        break;
      case 3:
        this.permissionName = { key: "affiliate", name: "Nova Filial" }
        break;
    }
  }
  
  openRegisterModal(type){
    switch (type) {
      case "profile":
        this.$bvModal.show("NewProfileModal");
        break;
      case "users":
        this.$bvModal.show("NewUserModal");
        break;
      case "affiliate":
        this.$bvModal.show("NewAffiliateModal");
        break;
    }
  }
}
