













































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})

export default class MapThumbIcon extends Vue {
  /* Props */
  @Prop({ default: "#FFFFFF" })
  public color!: string;
}
