





























import { Component, Vue } from "vue-property-decorator";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue'

import NewAffiliateModal from "../../organisms/Modals/NewAffiliate/NewAffiliate.vue";
import BooleanModal from "@/components/modals/BooleanModal/BooleanModal.vue";

import { 
  GET_ALL_AFFILIATES_REQUEST,
  DELETE_AFFILIATE,
  SELECT_AFFILIATE
} from "@/store/permissions/affiliate/actions";

@Component({
  components: {
    Table,
    Pagination,
    NewAffiliateModal,
    BooleanModal
  },
})

export default class RancherAffiliates extends Vue {
  /* Data */
  private modal: any = {
    title: "",
    content: "",
    data: null,
    busy: false,
  };

  private params: any = {
    page: 1,
    size: 14,
  };

  private fixedHeader: any = [
    { key: "descricao", label: "Descrição", thClass: "dark-header" },
    { key: "cep", label: "CEP", thClass: "dark-header" },
    { key: "endereco", label: "Endereço", thClass: "dark-header" },
    { key: "numero", label: "Número", thClass: "dark-header" },
    { key: "bairro", label: "Bairro", thClass: "dark-header" },
    { key: "municipio", label: "Município", thClass: "dark-header" },
    { key: "actions", label: "Ações", tdClass: "td-action" },
  ]

  private rows: any = [];

  private totalPages: number = 0;

  /* Computed */
  get isLoading() {
    return this.$store.getters.isAllAffiliatesLoading;
  }

  /* Lifecycle */
  mounted() {
    this.getAffiliates(this.params);
  }

  /* Methods */
  getAffiliates(params: any) {
    this.$store
      .dispatch(GET_ALL_AFFILIATES_REQUEST, params)
      .then(({totalCount, results}) => {
        if (results.length == 0) this.$toast.error("Nenhuma filial encontrada");
        this.totalPages = totalCount;
        this.rows = results.map((affiliate) => {
          return {
            ...affiliate,
            actions: {
              id: affiliate.idFazenda,
              buttons: ['edit', 'trash'],
            }
          };
        });
      })
      .catch((e) => {
        this.$toast.error(e);
      })
  }

  message(message, variant, reload = false) {
    if(reload) this.getAffiliates(this.params);
    this.$nextTick(() => {
      this.$toast.open({
        message,
        type: variant,
      });
    });
  }

  onChangePage(page) {
    this.params.page = page;
    this.getAffiliates(this.params);
  }

  onChangeSize(size) {
    this.params.size = size;
    this.params.page = 1;
    this.getAffiliates(this.params);
  }

  openEdit(value) {
    this.$store.dispatch(SELECT_AFFILIATE, value.id);
  }

  openTrash(value) {
    this.modal = {
      title: "Excluir Filial",
      content:"Você tem certeza que deseja prosseguir com a ação de excluir a filial selecionada?",
      data: { id: value.id },
      busy: false,
    };
    this.$bvModal.show("DeleteAffiliate");
  }

  onAcceptDelete(data) {
    this.modal.busy = true;
    this.$store
      .dispatch(DELETE_AFFILIATE, data.id)
      .then(() => {
        this.getAffiliates(this.params);
        this.$toast.success("Filial excluida com sucesso");
      })
      .catch(() => {
        this.$toast.error("Erro ao excluir filial");
      }).finally(() => {
        this.modal.busy = false;
        this.$bvModal.hide("DeleteAffiliate");
      })
  }
}
