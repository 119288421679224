















import { Component, Vue, Prop, Model } from "vue-property-decorator";

@Component({})

export default class Tabs extends Vue {
	/* Props */
	@Prop({ required: true })
	public titles!: boolean;

	@Prop({ required: true })
	public components!: boolean;

	@Prop({ default: false })
	public center!: boolean;

  @Prop({ default: false })
	public lazy!: boolean;

  @Prop({ default: () => [] })
	public componentProps!: object | Array<any>;

  /* Model */
  @Model('change', { default: 0 }) readonly value!: number;

  /* Computed */
  get tabIndex() {
    return this.value;
  }

  set tabIndex(value) {
    this.$emit('change', value);
  }
}
