/* Imports */
import AuthState from '@/models/auth-state/auth-state.interface';
import api from '@/shared/util/api';
import { PERMISSIONS } from '@/constants/users';

/* Actions */
import {
	AUTH_LOGIN_REQUEST,
	AUTH_LOGIN_SUCCESS,
	AUTH_LOGIN_FAILED,
	AUTH_LOGIN_TEMP,
	AUTH_MFA_LOGIN_REQUEST,
	AUTH_MFA_LOGIN_SUCCESS,
	AUTH_MFA_LOGIN_FAILED,
	AUTH_LOGOUT,
	AUTH_VERIFY_TOKEN,
	AUTH_SET_LOGIN_TYPE,
	RECOVER_PASSWORD,
	CHANGE_PASSWORD,
	RESEND_MFA_TOKEN_REQUEST,
	RESEND_MFA_TOKEN_SUCCESS,
	RESEND_MFA_TOKEN_FAILED,
} from './actions';

const { http } = api.getInstance();

/* Initial AuthState object */
const state: AuthState = {
	loginStatus: '',
	loginType: 'rancher',
	token: sessionStorage.getItem('token') || null,
	userName: '',
	userRole: '',
	userPermissions: [],
};

/* Vuex Auth Getters */
const getters = {
	isAuthenticated: (state: AuthState): boolean => !!state.token,
	isLoginLoading: (state: AuthState): boolean => state.loginStatus === 'loading',
	loginType: (state: AuthState): string => state.loginType,
	getUserRole: (state: AuthState): string => state.userRole,
	getUserPermissions: (state: AuthState): Array<any> => state.userPermissions,
	getUserName: (state: AuthState): string => state.userName,
};

/* Vuex Auth Mutations */
const mutations = {
	[AUTH_LOGIN_REQUEST]: (state: AuthState) => {
		state.loginStatus = 'loading';
	},
	[AUTH_LOGIN_SUCCESS]: (
		state: AuthState,
		{ accessToken, nome, tipoUsuario, permissions = [] },
	) => {
		sessionStorage.setItem('token', accessToken);
		state.token = accessToken;
		state.loginStatus = 'success';
		state.userRole = PERMISSIONS.USER_TYPES[tipoUsuario];
		state.userPermissions = permissions;
		state.userName = nome;
	},
	[AUTH_LOGIN_FAILED]: (state: AuthState) => {
		state.token = null;
		state.loginStatus = 'error';
	},
	[AUTH_LOGIN_TEMP]: (state: AuthState) => {
		state.loginStatus = 'success';
	},
	[AUTH_VERIFY_TOKEN]: (state: AuthState, accessToken) => {
		state.token = accessToken;
	},
	[AUTH_SET_LOGIN_TYPE]: (state: AuthState, loginType) => {
		state.loginType = loginType;
	},
	[AUTH_LOGOUT]: (state: AuthState) => {
		state.token = null;
		state.loginStatus = '';
		state.userRole = '';
	},
	[AUTH_MFA_LOGIN_REQUEST]: (state: AuthState) => {
		state.loginStatus = 'loading';
	},
	[AUTH_MFA_LOGIN_SUCCESS]: (
		state: AuthState,
		{ accessToken, nome, tipoUsuario, permissions = [] },
	) => {
		sessionStorage.setItem('token', accessToken);
		state.token = accessToken;
		state.loginStatus = 'success';
		state.userRole = PERMISSIONS.USER_TYPES[tipoUsuario];
		state.userPermissions = permissions;
		state.userName = nome;
	},
	[AUTH_MFA_LOGIN_FAILED]: (state: AuthState) => {
		state.token = null;
		state.loginStatus = 'error';
	},
	[RESEND_MFA_TOKEN_REQUEST]: (state: AuthState) => {
		state.loginStatus = 'loading';
	},
	[RESEND_MFA_TOKEN_SUCCESS]: (state: AuthState) => {
		state.loginStatus = 'success';
	},
	[RESEND_MFA_TOKEN_FAILED]: (state: AuthState) => {
		state.loginStatus = 'error';
	},
};

/* Vuex Auth Actions */
const actions = {
	[AUTH_LOGIN_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { cnpjTransit, login, senha, type, routerName } = requestData;
			const codcgcTransportadora = cnpjTransit ?? undefined;
			const codcgc = login.length > 11 ? login : undefined;
			const codcpf = login.length <= 11 ? login : undefined;

			commit(AUTH_LOGIN_REQUEST);
			commit(AUTH_SET_LOGIN_TYPE, routerName);

			http({
				method: 'post',
				url: `/${type}/login`,
				data: {
					codcgcTransportadora,
					codcgc,
					codcpf,
					senha,
					tipo: 'string',
				},
			})
				.then(({ data }) => {
					if (data.isTemporaryPassword) {
						commit(AUTH_LOGIN_TEMP);
					} else {
						if (type != 'uboiweb') {
							http.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
							if (data.permissions && data.role) data.permissions.push(data.role);
						}

						commit(AUTH_LOGIN_SUCCESS, data);
					}
					resolve(data);
				})
				.catch(error => {
					sessionStorage.removeItem('token');
					commit(AUTH_LOGIN_FAILED);
					reject(error);
				});
		});
	},
	[AUTH_MFA_LOGIN_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
			const { hashcode, type, token, routerName, cnpjTransit } = requestData;
			const codcgcTransportadora = cnpjTransit ?? undefined;
			commit(AUTH_MFA_LOGIN_REQUEST);
			commit(AUTH_SET_LOGIN_TYPE, routerName);

			http({
				method: 'post',
				url: `/${type}/login/mfa-login`,
				data: {
					hashcode,
					token,
					codcgcTransportadora,
				},
			})
				.then(({ data }) => {
					http.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
					if (data.permissions && data.role) data.permissions.push(data.role);
					commit(AUTH_MFA_LOGIN_SUCCESS, data);

					resolve(data);
				})
				.catch(error => {
					sessionStorage.removeItem('token');
					commit(AUTH_MFA_LOGIN_FAILED);
					reject(error);
				});
		});
	},
	[RESEND_MFA_TOKEN_REQUEST]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const { hashcode, type } = requestData;

			http({
				method: 'post',
				url: `/${type}/login/resend-mfa-token/${hashcode}`,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[AUTH_VERIFY_TOKEN]: ({ commit }: any) => {
		commit(AUTH_VERIFY_TOKEN, sessionStorage.getItem('token'));
	},
	[AUTH_LOGOUT]: ({ commit }: any) => {
		return new Promise<void>((resolve, reject) => {
			try {
				sessionStorage.removeItem('token');
				delete http.defaults.headers.common['Authorization'];
				http.auth = null;
				commit(AUTH_LOGOUT);
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	},
	[RECOVER_PASSWORD]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const { login, type } = requestData;
			const codcgc = login.length > 11 ? login : undefined;
			const codcpf = login.length <= 11 ? login : undefined;

			http({
				method: 'post',
				url: `/${type}/login/recuperar-senha`,
				data: {
					codcgc,
					codcpf,
				},
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	[CHANGE_PASSWORD]: ({ commit }: any, requestData: any) => {
		return new Promise<void>((resolve, reject) => {
			const { cnpjTransit, login, senhaAtual, novaSenha, type } = requestData;
			const codcgcTransportadora = cnpjTransit ?? undefined;
			const codcgc = login.length > 11 ? login : undefined;
			const codcpf = login.length <= 11 ? login : undefined;

			commit(AUTH_LOGIN_REQUEST);

			http({
				method: 'post',
				url: `/${type}/login/alterar-senha`,
				data: {
					codcgc,
					codcpf,
					senhaAtual,
					novaSenha,
					codcgcTransportadora,
				},
			})
				.then(({ data }) => {
					http.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
					if (data.permissions && data.role) data.permissions.push(data.role);
					commit(AUTH_LOGIN_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					sessionStorage.removeItem('token');
					commit(AUTH_LOGIN_FAILED);
					reject(error);
				});
		});
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
