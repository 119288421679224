



















import { Vue, Component, Prop} from "vue-property-decorator";
import CircledArrowIcon from '@/components/icons/CircledArrow.vue';

@Component({
  components: {
    CircledArrowIcon,
  }
})

export default class Disclosure extends Vue {
  /* Props */
  @Prop({ default: 'Legenda' })
  public title!: string;

  @Prop()
  public subtitle!: string;

  @Prop({ default: false })
  public open!: boolean;

  /* Data */
  private active: boolean = false;

  /* Methods */
  onClick() {
    this.active = !this.active;
    if (this.active) return this.$emit('open');
    this.$emit('close');
  }
}
