




import { Component, Vue } from "vue-property-decorator";
import Map from "@/components/pages/modules/rancher/Map/index.vue";

@Component({
  components: {
    Map
  }
})

export default class RancherMap extends Vue {}
