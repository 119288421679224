export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const GET_FARMS_REQUEST = 'GET_FARMS_REQUEST';
export const GET_FARMS_SUCCESS = 'GET_FARMS_SUCCESS';
export const GET_FARMS_FAILED = 'GET_FARMS_FAILED';

export const POST_USER = 'POST_USER';

export const PUT_USER = 'PUT_USER';

export const DELETE_USER = 'DELETE_USER';

export const SELECT_USER = 'SELECT_USER';
