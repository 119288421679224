


















import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";

@Component({
  components: {
    Modal,
  },
})

export default class MessageModal extends Vue {
  /* Props */
  @Prop({ required: true })
  public title!: string;

  @Prop({ required: false })
  public subtitle!: string;

  @Prop({ required: true })
  public content!: string;

  @Prop({ default: "MessageModal" })
  public id!: string;
}
