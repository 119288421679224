




















































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Modal from '@/components/widgets/molecules/Modal/Modal.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';
import Button from '@/components/widgets/atoms/Button/Button.vue';
import PlusIcon from '@/components/icons/Plus.vue';
import Card from '@/components/widgets/molecules/Card/Card.vue';
import FormInput from '@/components/widgets/molecules/FormInput/FormInput.vue';
import TravelForm from '../../TravelForm/TravelForm.vue';
import DisclosureTravel from '../../DisclosureTravel/DisclosureTravel.vue';

import TravelFields from '@/models/contracts/travel-fields.interface';
import { formatDateHour } from '@/shared/util/formatDate';
import formatMoney from '@/shared/util/formatMoney';
import formatNumber from '@/shared/util/formatNumber';

import {
	TRANSIT_CONTRACT_DETAIL_REQUEST,
	TRANSIT_SELECT_CONTRACT,
	TRANSIT_TRUCK_TYPES_REQUEST,
	TRANSIT_POST_CONTRACT,
	TRANSIT_PUT_CONTRACT,
} from '@/store/TransitFacility/orders/actions';

import {
	TRANSIT_TRAVEL_GET_DRIVERS,
	TRANSIT_GET_TRUCKS,
	TRANSIT_GET_CARTS,
} from '@/store/TransitFacility/travels/actions';

@Component({
	components: {
		Modal,
		Table,
		TravelForm,
		DisclosureTravel,
		Card,
		FormInput,
		Button,
		ButtonCustom,
		PlusIcon,
	},
})
export default class ContractModal extends Vue {
	/* Prop */
	@Prop({ default: false })
	public reload!: boolean;

	/* Data */
	private orderHeader: Array<any> = [
		{ key: 'idPedido', label: 'Pedido Uboi' },
		{ key: 'pecuaristaOrigem', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaOrigem', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'pecuaristaDestino', label: 'Pecuarista', thClass: 'dark-brown' },
		{ key: 'fazendaDestino', label: 'Fazenda', thClass: 'dark-brown' },
		{ key: 'kmViagem', label: 'KM Viagem' },
		{ key: 'valorTotal', label: 'Valor Total' },
		{ key: 'previsaoEmbarque', label: 'Embarque Estimado' },
		{ key: 'previsaoDesembarque', label: 'Desembarque Estimado' },
	];

	private orderRows: Array<any> = [];

	private animalsHeader: Array<any> = [
		{ key: 'categoria', label: 'Categoria', tdClass: 'brown-td' },
		{ key: 'bois', label: 'Bois', thClass: 'light-gray-header text-bold' },
		{ key: 'vacas', label: 'Vacas', thClass: 'light-gray-header text-bold' },
		{
			key: 'novilhas',
			label: 'Novilhas',
			thClass: 'light-gray-header text-bold',
		},
		{
			key: 'bezerros',
			label: 'Bezerros',
			thClass: 'light-gray-header text-bold',
		},
	];

	private animalsRows: Array<any> = [
		{
			categoria: 'Quantidade',
			bois: '0',
			vacas: '0',
			novilhas: '0',
			bezerros: '0',
		},
		{ categoria: 'Peso Médio', bois: '0', vacas: '0', novilhas: '0', bezerros: '0' },
	];

	private selectOptions: any = {
		truckTypes: [],
		drivers: [],
		trucks: [],
		carts: [],
	};

	private buttonBusy: any = {
		saveButton: false,
		contractButton: false,
	};

	private form: any = {
		roadMap: null,
	};

	private hasContract: boolean = false;

	private travelForms: Array<number> = [];

	private travels: Array<TravelFields> = [];

	private shippingForecast: string = '';

	private landingForecast: string = '';

	/* Computed */
	get showModal() {
		return this.$store.getters.hasSelectedTransitContract;
	}

	get selectedContract() {
		return this.$store.getters.getSelectedTransitContract;
	}

	get isLoading() {
		return this.$store.getters.isTransitContractDetailLoading;
	}

	get animalsOptions() {
		const animals: Array<any> = [];
		Object.keys(this.animalsRows[0]).forEach((item) => {
			if (this.animalsRows[0][item] > 0) {
				animals.push({
					value: item,
					text: item,
					max: this.animalsRows[0][item],
				});
			}
		});
		return animals;
	}

	//pode ser que a regra de negócio mude no futuro
	get allCartsHavePlates() {
		return this.travels.every((travel) =>
			travel.carts.some((cart) => cart.cart && cart.cart.text.trim() !== ''),
		);
	}
	//pode ser que a regra de negócio mude no futuro
	get allTrucksHavePlates() {
		return this.travels.every((travel) => travel.truck !== null && travel.truck.text.trim() !== '');
	}
	//pode ser que a regra de negócio mude no futuro
	get allTravelsHaveDrivers() {
		return this.travels.every(
			(travel) => travel.driver !== null && travel.driver.value !== undefined,
		);
	}

	get getRoadMap() {
		return this.$store.getters.ContractRoadMap;
	}

	get validateFields() {
		return (
			!this.allCartsHavePlates ||
			!this.allTrucksHavePlates ||
			!this.allTravelsHaveDrivers ||
			!this.form.roadMap ||
			this.hasContract ||
			this.animalsOptions.length !== 0
		);
	}

	setRoadMap(value) {
		this.form.roadMap = value;
		this.$store.commit('SET_ROAD_MAP', value);
	}

	/* Watcher */
	@Watch('reload')
	onReload(value) {
		if (value) this.getInfo();
	}

	/* Methods */
	resetInfo() {
		this.travels = [];
		this.travelForms = [];
		this.animalsRows = [
			{
				categoria: 'Quantidade',
				bois: '0',
				vacas: '0',
				novilhas: '0',
				bezerros: '0',
			},
			{
				categoria: 'Peso Médio',
				bois: '0',
				vacas: '0',
				novilhas: '0',
				bezerros: '0',
			},
		];
		this.selectOptions = {
			truckTypes: [],
			drivers: [],
			trucks: [],
			carts: [],
		};
		this.shippingForecast = '';
		this.landingForecast = '';
		this.$store.dispatch(TRANSIT_SELECT_CONTRACT, null);
	}

	initInfo() {
		this.getInfo();
		this.getTruckTypes();
		this.getDrivers();
		this.getTrucks();
		this.getCarts();
	}

	getInfo() {
		this.$store
			.dispatch(TRANSIT_CONTRACT_DETAIL_REQUEST, this.selectedContract)
			.then((response) => {
				const { viagens, animais } = response;

				this.hasContract = response.contratoGerado;

				this.form.roadMap = response.roteiro;

				this.animalsRows = [
					{
						categoria: 'Quantidade',
						bois: animais?.qtdBoi || 0,
						vacas: animais?.qtdVaca || 0,
						novilhas: animais?.qtdNovilho || 0,
						bezerros: animais?.qtdBezerro || 0,
					},
					{
						categoria: 'Peso Médio',
						bois: formatNumber(animais?.pesoMedioBoi) || 0,
						vacas: formatNumber(animais?.pesoMedioVaca) || 0,
						novilhas: formatNumber(animais?.pesoMedioNovilho) || 0,
						bezerros: formatNumber(animais?.pesoMedioBezerro) || 0,
					},
				];

				this.shippingForecast = response.previsaoEmbarque;
				this.landingForecast = response.previsaoDesembarque;
				this.travels = viagens.map((travel) => {
					return {
						travelId: travel.idViagem ?? travel.idPropostaViagem,
						driver: travel?.motorista
							? {
									...travel.motorista,
									value: travel.motorista.idMotorista,
									text: travel.motorista.nomeMotorista,
							  }
							: null,
						truck: travel?.caminhao
							? {
									value: travel.caminhao.idCaminhao,
									text: travel.caminhao.placa,
							  }
							: null,
						carts: travel?.carretas
							? travel.carretas.map((cart) => ({
									cart: { value: cart.idCarreta, text: cart.placa },
							  }))
							: [{ cart: null }],
						truckType: {
							value: travel.tipoCaminhao,
							text: travel.tipoCaminhao,
						},
						dateEnd: travel.previsaoFimViagem,
						dateInit: travel.previsaoInicioViagem,
						category: travel.categoriaAnimal,
						quantity: travel.qtdAnimais,
						size: formatNumber(travel.peso_Animal),
						total: travel.valorTotal,
						freight: travel.valorFrete,
						extra: travel.pedagioBalsa,
						data: travel,
						animalsRows: JSON.parse(JSON.stringify(this.animalsRows)),
					};
				});

				this.orderRows = [
					{
						...response,
						valorTotal: formatMoney(response.valorTotal),
						previsaoEmbarque: formatDateHour(response.previsaoEmbarque),
						previsaoDesembarque: formatDateHour(response.previsaoDesembarque),
					},
				];

				this.removeAllocatedAnimals(viagens);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao carregar contrato', 'error');
			});
	}

	getTruckTypes() {
		this.$store
			.dispatch(TRANSIT_TRUCK_TYPES_REQUEST)
			.then((response) => {
				this.selectOptions.truckTypes = response.map((truck) => ({
					value: truck.descricao,
					text: truck.descricao,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	getDrivers(search = null) {
		this.$store
			.dispatch(TRANSIT_TRAVEL_GET_DRIVERS, { page: 1, size: 20, search })
			.then(({ results }) => {
				this.selectOptions.drivers = results.map((driver) => ({
					...driver,
					value: driver.idParceiro,
					text: driver.nome,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	getTrucks(search = null) {
		this.$store
			.dispatch(TRANSIT_GET_TRUCKS, { page: 1, size: 20, search })
			.then(({ results }) => {
				this.selectOptions.trucks = results.map((truck) => ({
					value: truck.idCaminhao,
					text: truck.placa,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	getCarts(search = null) {
		this.$store
			.dispatch(TRANSIT_GET_CARTS, { page: 1, size: 20, search })
			.then(({ results }) => {
				this.selectOptions.carts = results.map((cart) => ({
					value: cart.idCarreta,
					text: cart.placa,
				}));
			})
			.catch((e) => {
				this.$toast.error(e);
			});
	}

	removeAllocatedAnimals(travels) {
		// index: 0 = Quantity animal row // index: 1 = Size animal row;
		travels.forEach((travel, index) => {
			Object.keys(travel).forEach((key) => {
				if (['qtdBoi', 'qtdVaca', 'qtdNovilho', 'qtdBezerro'].includes(key)) {
					const column = {
						qtdBoi: 'bois',
						qtdVaca: 'vacas',
						qtdNovilho: 'novilhas',
						qtdBezerro: 'bezerros',
					};
					this.animalsRows[0][column[key]] -= travels[index][key];
				}
			});
		});
		Object.keys(this.animalsRows[1]).forEach((animal) => {
			if (['bois', 'vacas', 'novilhas', 'bezerros'].includes(animal)) {
				this.animalsRows[1][animal] =
					this.animalsRows[0][animal] > 0 ? this.animalsRows[1][animal] : 0;
			}
		});
	}

	addTravel() {
		this.travelForms.push(this.travelForms.length + 1);
	}

	removeTravel(index) {
		this.travelForms.splice(index, 1);
	}

	onCreateTravel(index) {
		this.removeTravel(index);
		this.getInfo();
	}

	onUpdateTravel() {
		this.travels = [];
		this.travelForms = [];
		this.animalsRows = [
			{
				categoria: 'Quantidade',
				bois: '0',
				vacas: '0',
				novilhas: '0',
				bezerros: '0',
			},
			{
				categoria: 'Peso',
				bois: '0',
				vacas: '0',
				novilhas: '0',
				bezerros: '0',
			},
		];
		this.selectOptions = {
			truckTypes: [],
			drivers: [],
			trucks: [],
			carts: [],
		};
		this.getInfo();
	}

	onSave(loader = true) {
		const travels = this.travels.map((travel) => ({
			idPropostaViagem: travel.travelId,
			idMotorista: travel.driver?.value || null,
			idCaminhao: travel.truck?.value || null,
			idCarretas: travel.carts.map((cart) => cart.cart?.value || null).filter(Boolean),
		}));

		const requestData = {
			idProposta: this.selectedContract,
			roteiro: this.form.roadMap,
			viagens: travels,
		};

		if (loader) this.buttonBusy.saveButton = true;
		return new Promise((resolve, reject) => {
			this.$store
				.dispatch(TRANSIT_PUT_CONTRACT, requestData)
				.then(() => {
					if (loader) this.$toast.success('Proposta de contrato salva com sucesso');
					resolve('success');
				})
				.catch((err) => {
					if (loader) this.$toast.error('Erro ao salvar proposta de contrato');
					reject(err);
				})
				.finally(() => {
					if (loader) this.buttonBusy.saveButton = false;
				});
		});
	}

	generateContract() {
		this.buttonBusy.contractButton = true;
		this.onSave(false)
			.then(() => {
				this.$store
					.dispatch(TRANSIT_POST_CONTRACT, this.selectedContract)
					.then(() => {
						this.$emit('message', 'Contrato gerado com sucesso', 'success', true);
					})
					.catch(() => {
						this.$emit('message', 'Erro ao gerar contrato viagem', 'error');
					})
					.finally(() => {
						this.buttonBusy.contractButton = false;
						this.close();
					});
			})
			.catch(() => {
				this.$toast.error('Erro ao salvar propostas de contrato');
			});
	}

	close() {
		this.$bvModal.hide('TransitContractModal');
	}
}
