






















import { Vue, Component, Prop } from "vue-property-decorator";
import Multiselect from "@/components/widgets/atoms/Multiselect/Multiselect.vue";

@Component({
  components: {
    Multiselect
  }
})
export default class SwitchInputSelect extends Vue {
  /* Props */
  @Prop({ required: true })
  public value!: { select: {value: any; text: any} | null; input: any };

  @Prop({ default: false })
  public isInput!: boolean;

  @Prop({ default: false })
  public isSearch!: boolean;

  @Prop({ default: () => [] })
  public options!: Array<{value: any; text: any}>;

  @Prop({ default: false })
  public loading!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  private saveSearch: any = '';

  /* Computed */
  get selectValue() {
    if (this.value.select) return this.value.select;
    return null;
  }

  set selectValue(select) {
    this.$emit('input', { select, input: '' });
  }

  get inputValue() {
    if (this.value.input) return this.value.input;
    return '';
  }

  set inputValue(input) {
    this.$emit('input', { select: null, input });
  }

  /* Methods */
  useInput(e) {
    const value = this.saveSearch;
    this.$nextTick(() => {
      if(!e && !this.selectValue) this.inputValue = value;
    })
  }

  getInput(value) {
    this.saveSearch = value;
  }

  verifyValue(event) {
    if (!event) {
      this.saveSearch = "";
      this.$emit('empty', event);
    } 
  }
}
