






























































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import TableAnimals from "@/components/widgets/organisms/TableAnimals/TableAnimals.vue";
import Tracking from "@/components/widgets/organisms/Tracking/Tracking.vue";
import Label from "@/components/widgets/atoms/Label/Label.vue";
import MapIcon from "@/components/icons/PointMap.vue";

import { formatDateHour } from "@/shared/util/formatDate";
import formatMoney from "@/shared/util/formatMoney";
import formatNumber from "@/shared/util/formatNumber";

import {
  SELECT_DETAIL_ORDER,
  TRAVEL_DETAIL_REQUEST,
  SELECT_MAP,
} from "@/store/travels/actions";

import { SELECT_DOCUMENTS } from "@/store/photos/actions";

@Component({
  components: {
    Modal,
    Table,
    TableAnimals,
    Tracking,
    Label,
    MapIcon,
  },
})
export default class ReportModal extends Vue {
  /* Data */
  private header: any = [
    { key: "pedido", label: "Pedido" },
    { key: "viagem", label: "Viagem" },
    { key: "motorista", label: "Motorista", tdClass: "no-overflow-sm" },
    { key: "placaCavalo", label: "Placa Cavalo", tdClass: "no-overflow-sm" },
    { key: "placaCarreta", label: "Placa Carreta", tdClass: "no-overflow-sm" },
    { key: "embarqueProgramado", label: "Embarque Previsto" },
    { key: "desembarqueProgramado", label: "Desembarque Previsto" },
    { key: "kmPrevisto", label: "KM Estimado", thClass: "dark-header" },
    { key: "kmRealizado", label: "KM Realizado", thClass: "dark-header" },
    { key: "valorPrevisto", label: "Valor Estimado", thClass: "dark-header" },
    {
      key: "actions",
      label: "Detalhes",
      thClass: "gray-header",
      tdClass: "td-action",
    },
  ];

  private rows: any = [];

  private animalsData: any = [];

  private animalsInfo: any = {
    travel: "",
    driver: "",
    plates: "",
  };

  private trackingData: any = [];

  private originFarm: string | null = null;

  /* Computed */
  get isLoading() {
    return this.$store.getters.isTravelDetailLoading;
  }

  get showModal() {
    return this.$store.getters.hasSelectedDetailOrder;
  }

  get selectedOrder() {
    return this.$store.getters.getSelectedOrder;
  }

  /* Methods */
  resetInfo() {
    this.originFarm = null;
    this.animalsInfo = {
      travel: "",
      driver: "",
      plate: "",
    };
    this.$store.dispatch(SELECT_DETAIL_ORDER, null);
  }

  initInfo() {
    this.$store
      .dispatch(TRAVEL_DETAIL_REQUEST, this.selectedOrder)
      .then((response) => {
        const { rastreamento, viagemAnimais, viagemItem } = response;
        this.originFarm = viagemItem?.fazendaOrigem || null;
        this.trackingData = rastreamento.map((step) => {
          const foto = step?.fotos
            ? step.fotos.map((foto) => {
                return {
                  idDocumento: foto.tipo,
                  descricao: foto.descricao,
                  urlDocumento: foto.url,
                  formId: step.formId,
                  tipoForm: step.tipoForm,
                };
              })
            : [];
          const [data, hora] = formatDateHour(step.data)?.split(" ");
          return {
            descricao: step.descricao,
            data,
            hora,
            fotos: {
              type: `Fotos ${step.descricao} - ${viagemItem.pedido} / ${viagemItem.viagem}`,
              docs: foto,
            },
          };
        });

        viagemItem.embarqueProgramado = formatDateHour(
          viagemItem.embarqueProgramado
        );
        viagemItem.desembarqueProgramado = formatDateHour(
          viagemItem.desembarqueProgramado
        );
        viagemItem.kmPrevisto = formatNumber(viagemItem?.kmPrevisto);
        viagemItem.kmRealizado = formatNumber(viagemItem?.kmRealizado);
        viagemItem.valorPrevisto = formatMoney(viagemItem?.valorPrevisto);
        viagemItem.actions = { id: viagemItem.viagem, buttons: ["map"] };

        this.rows = [viagemItem];

        const plates: Array<any> = [];
        if (viagemItem?.placaCavalo) plates.push(viagemItem.placaCavalo);
        if (viagemItem?.placaCarreta) plates.push(viagemItem.placaCarreta);

        this.animalsInfo = {
          travel: viagemItem.viagem,
          driver: viagemItem?.motorista || "",
          plates: plates.join(", "),
        };

        this.animalsData = [
          {
            animal: "Boi",
            qty: viagemAnimais?.qtdBoi || "-",
            size: formatNumber(viagemAnimais?.pesoMedioBoi) || "-",
            scheduled: viagemAnimais?.qtdBoi_Programado || "-",
            boarding: viagemAnimais?.qtdBoi_Embarque || "-",
            landing: viagemAnimais?.qtdBoi_Desembarque || "-",
          },
          {
            animal: "Vaca",
            qty: viagemAnimais?.qtdVaca || "-",
            size: formatNumber(viagemAnimais?.pesoMedioVaca) || "-",
            scheduled: viagemAnimais?.qtdVaca_Programado || "-",
            boarding: viagemAnimais?.qtdVaca_Embarque || "-",
            landing: viagemAnimais?.qtdVaca_Desembarque || "-",
          },
          {
            animal: "Novilho",
            qty: viagemAnimais?.qtdNovilho || "-",
            size: formatNumber(viagemAnimais?.pesoMedioNovilho) || "-",
            scheduled: viagemAnimais?.qtdNovilho_Programado || "-",
            boarding: viagemAnimais?.qtdNovilho_Embarque || "-",
            landing: viagemAnimais?.qtdNovilho_Desembarque || "-",
          },
          {
            animal: "Bezerro",
            qty: viagemAnimais?.qtdBezerro || "-",
            size: formatNumber(viagemAnimais?.pesoMedioBezerro) || "-",
            scheduled: viagemAnimais?.qtdBezerro_Programado || "-",
            boarding: viagemAnimais?.qtdBezerro_Embarque || "-",
            landing: viagemAnimais?.qtdBezerro_Desembarque || "-",
          },
        ];
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  openMap(viagem) {
    this.$store.dispatch(SELECT_MAP, viagem.id);
  }

  openModalPhoto(documents) {
    this.$store.dispatch(SELECT_DOCUMENTS, documents);
  }
}
