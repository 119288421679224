const validateCpfCnpj = (val) => {
  /* Treat string */
  const identification = val.trim().replace(/\./g, '').replace('-', '').replace('/', '');

  /* Validate CPF */
  if (identification.length == 11) {
    if (
      identification == "00000000000" ||
      identification == "11111111111" ||
      identification == "22222222222" ||
      identification == "33333333333" ||
      identification == "44444444444" ||
      identification == "55555555555" ||
      identification == "66666666666" ||
      identification == "77777777777" ||
      identification == "88888888888" ||
      identification == "99999999999"
    ) return false;

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) sum = sum + parseInt(identification.substring(i - 1, i)) * (11 - i);
    remainder = (sum * 10) % 11;

    if ((remainder == 10) || (remainder == 11)) remainder = 0;
    if (remainder != parseInt(identification.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(identification.substring(i - 1, i)) * (12 - i);
    remainder = (sum * 10) % 11;

    if ((remainder == 10) || (remainder == 11)) remainder = 0;
    if (remainder != parseInt(identification.substring(10, 11))) return false;
    return true;
  }

  /* Validate CNPJ */
  if (identification.length == 14) {
    if (
      identification == "00000000000000" ||
      identification == "11111111111111" ||
      identification == "22222222222222" ||
      identification == "33333333333333" ||
      identification == "44444444444444" ||
      identification == "55555555555555" ||
      identification == "66666666666666" ||
      identification == "77777777777777" ||
      identification == "88888888888888" ||
      identification == "99999999999999"
    ) return false;

    let size = identification.length - 2
    let num = identification.substring(0, size);
    const dig = identification.substring(size);
    let sum = 0;
    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += num.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let resultado = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (resultado != dig.charAt(0)) return false;

    size = size + 1;
    num = identification.substring(0, size);
    sum = 0;
    pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += num.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }

    resultado = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (resultado != dig.charAt(1)) return false;
    return true;
  }
  /* Return false if is not valid cpf or cnpj  */
  return false
}

export default validateCpfCnpj;