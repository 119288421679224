


































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import Disclosure from "@/components/widgets/molecules/Disclosure/Disclosure.vue";
import FormInput from "@/components/widgets/molecules/FormInput/FormInput.vue";
import FormDate from "@/components/widgets/molecules/FormDate/FormDate.vue";
import FormSelect from "@/components/widgets/molecules/FormSelect/FormSelect.vue";
import Button from "@/components/widgets/atoms/Button/Button.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import PlusIcon from "@/components/icons/Plus.vue";
import LessIcon from "@/components/icons/Less.vue";
import EditIcon from "@/components/icons/Edit.vue";
import TrashIcon from "@/components/icons/Trash.vue";
import CategoryFields from "../CategoryFields/CategoryFields.vue";

import hasDuplicateValues from "@/shared/util/hasDuplicateValues";
import { formatDateISO } from "@/shared/util/formatDate";

import { TRANSIT_PUT_TRAVEL_REQUEST } from "@/store/TransitFacility/orders/actions";

import {
  TRANSIT_TRAVEL_GET_DRIVERS,
  TRANSIT_GET_TRUCKS,
  TRANSIT_GET_CARTS,
} from "@/store/TransitFacility/travels/actions";
import TravelForm from "../TravelForm/TravelForm.vue";

@Component({
  components: {
    Disclosure,
    FormInput,
    FormDate,
    FormSelect,
    PlusIcon,
    LessIcon,
    EditIcon,
    TrashIcon,
    Button,
    ButtonCustom,
    CategoryFields,
  },
})
export default class DisclosureTravel extends Vue {
  /* Props */
  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: null })
  public travel!: string | number | null;

  @Prop({ required: true })
  public animalsRows!: Array<any>;

  @Prop({ required: true })
  public animals!: Array<any>;

  @Prop({ default: {} })
  public fields!: any;

  @Prop({ default: () => [] })
  public truckTypes!: Array<any>;

  @Prop({ default: () => [] })
  public drivers!: Array<any>;

  @Prop({ default: () => [] })
  public trucks!: Array<any>;

  @Prop({ default: () => [] })
  public carts!: Array<any>;

  @Prop({ default: () => [] })
  public forms!: TravelForm;

  /* Data */
  private selectOptions: any = {
    animalsRows: JSON.parse(JSON.stringify(this.animalsRows)),
    truckTypes: this.truckTypes,
    drivers: this.drivers,
    trucks: this.trucks,
    carts: this.carts,
  };

  private form: any = {
    truckType: null,
    dateInit: null,
    dateEnd: null,
    freight: 0,
    extra: 0,
    categoryAnimals: [],
  };

  private buttonBusy: any = {
    saveButton: false,
  };

  private active: boolean = false;

  private isEdit: boolean = false;

  /* Computed */

  get selectedOrder() {
    return this.$store.getters.getSelectedTransitContract;
  }

  get isLoading() {
    return this.$store.getters.isTransitPutTravelLoading;
  }

  get copyAnimalsRows() {
    return JSON.parse(JSON.stringify(this.selectOptions.animalsRows));
  }

  get animalsOptions() {
    const animals: Array<any> = [];
    Object.keys(this.copyAnimalsRows[0]).forEach((item) => {
      if (this.copyAnimalsRows[0][item] > 0) {
        animals.push({
          value: item,
          text: item,
          max: this.copyAnimalsRows[0][item],
        });
      }
    });
    return animals;
  }

  get availableCarts() {
    const carts = this.fields.carts
      .map((selectCart) => {
        return selectCart.cart?.value;
      })
      .filter(Boolean);
    return this.selectOptions.carts.filter((cart) => {
      return !carts.includes(cart.value);
    });
  }

  get validation() {
    return (
      !this.fields.carts.every((selectCart) => selectCart.cart) ||
      !(this.selectOptions.carts.length > this.fields.carts.length)
    );
  }

  /* Lifecycle */
  mounted() {
    Object.keys(this.fields.data).forEach((key) => {
      if (["qtdBoi", "qtdVaca", "qtdNovilho", "qtdBezerro"].includes(key)) {
        const column = {
          qtdBoi: "bois",
          qtdVaca: "vacas",
          qtdNovilho: "novilhas",
          qtdBezerro: "bezerros",
        };
        this.selectOptions.animalsRows[0][column[key]] += this.fields.data[key];
      }
    });
    this.resetInfo();
  }

  /* Methods */
  getDrivers(search = null) {
    this.$store
      .dispatch(TRANSIT_TRAVEL_GET_DRIVERS, { page: 1, size: 20, search })
      .then(({ results }) => {
        this.selectOptions.drivers = results.map((driver) => ({
          ...driver,
          value: driver.idParceiro,
          text: driver.nome,
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  getTrucks(search = null) {
    this.$store
      .dispatch(TRANSIT_GET_TRUCKS, { page: 1, size: 20, search })
      .then(({ results }) => {
        this.selectOptions.trucks = results.map((truck) => ({
          value: truck.idCaminhao,
          text: truck.placa,
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  getCarts(search = null) {
    this.$store
      .dispatch(TRANSIT_GET_CARTS, { page: 1, size: 20, search })
      .then(({ results }) => {
        this.selectOptions.carts = results.map((cart) => ({
          value: cart.idCarreta,
          text: cart.placa,
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  setAnimals(animal, quantity, max) {
    return { animal: { value: animal, text: animal, max }, quantity };
  }

  resetInfo() {
    const categoryAnimals: Array<any> = [];

    const columns = {
      qtdBoi: "bois",
      qtdVaca: "vacas",
      qtdNovilho: "novilhas",
      qtdBezerro: "bezerros",
    };

    Object.keys(columns).forEach((column) => {
      if (this.fields.data[column]) {
        const qty = this.fields.data[column];
        const max = this.selectOptions.animalsRows[0][columns[column]];
        categoryAnimals.push(this.setAnimals(columns[column], qty, max));
      }
    });

    this.form.truckType = this.fields.truckType;
    this.form.dateInit = this.fields.dateInit;
    this.form.dateEnd = this.fields.dateEnd;
    this.form.freight = this.fields.freight;
    this.form.extra = this.fields.extra;
    this.form.categoryAnimals = categoryAnimals;
  }

  onSave() {
    const animalsInfo = {
      bois: { qty: 0, size: 0 },
      vacas: { qty: 0, size: 0 },
      novilhas: { qty: 0, size: 0 },
      bezerros: { qty: 0, size: 0 },
    };

    const categories = this.form.categoryAnimals
      .map((category) => {
        const animal = category.animal?.value;
        if (!animal) return null;
        animalsInfo[animal].qty = category.quantity;
        animalsInfo[animal].size =
          parseFloat(this.animalsRows[1][animal]) * category.quantity;
        return animal[0].toUpperCase() + animal.slice(1);
      })
      .filter(Boolean);

    if (hasDuplicateValues(categories)) {
      return this.$toast.error("Viagem com categorias duplicadas");
    }

    const invalidValues = this.form.categoryAnimals.some((category) => {
      const animal = category.animal?.value;
      return category.quantity > this.copyAnimalsRows[0][animal];
    });

    if (invalidValues) {
      return this.$toast.error("A quantidade de animais inválida");
    }

    const params = {
      idViagem: this.travel,
      categoriaAnimal: categories.length > 0 ? categories.join(", ") : null,
      tipoCaminhao: this.form.truckType?.value || null,
      previsaoInicioViagem: formatDateISO(this.form.dateInit),
      previsaoFimViagem: formatDateISO(this.form.dateEnd),
      qtdBoi: animalsInfo.bois.qty,
      qtdVaca: animalsInfo.vacas.qty,
      qtdNovilho: animalsInfo.novilhas.qty,
      qtdBezerro: animalsInfo.bezerros.qty,
      pesoMedioBoi: animalsInfo.bois.size,
      pesoMedioVaca: animalsInfo.vacas.size,
      pesoMedioNovilho: animalsInfo.novilhas.size,
      pesoMedioBezerro: animalsInfo.bezerros.size,
      valorFrete: this.form.freight,
      valorTerceiro: this.form.extra,
    };

    if (Object.keys(params).some((i) => !(params[i] || params[i] === 0))) {
      return this.$toast.error("Viagem com valores incompletos");
    }

    this.buttonBusy.saveButton = true;

    this.$store
      .dispatch(TRANSIT_PUT_TRAVEL_REQUEST, params)
      .then(() => {
        this.$toast.success("Viagem atualizada com sucesso!");
        this.$emit("success");
      })
      .catch(() => {
        this.$toast.error("Erro ao atualizar viagem!");
      })
      .finally(() => {
        this.buttonBusy.saveButton = false;
      });
  }

  addCart() {
    this.fields.carts.push({ cart: null });
  }

  removeCart(index) {
    this.fields.carts.splice(index, 1);
  }

  clearCart() {
    this.fields.carts[0].cart = null;
  }

  onEdit() {
    this.isEdit = !this.isEdit;
    if (!this.isEdit) this.resetInfo();
  }

  onOpen() {
    this.active = true;
  }

  onClose() {
    this.active = false;
  }
}
