




import { Component, Vue } from "vue-property-decorator";
import Map from "@/components/pages/modules/transitFacility/Map/index.vue";

@Component({
  components: {
    Map
  }
})

export default class TransitMap extends Vue {}
