




















































import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/components/widgets/molecules/Modal/Modal.vue';
import Multiselect from '@/components/widgets/atoms/Multiselect/Multiselect.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';
import {
	TRANSIT_MANAGEMENT_GET_USER_REQUEST,
	TRANSIT_MANAGEMENT_POST_USER_REQUEST,
	TRANSIT_MANAGEMENT_PUT_USER_REQUEST,
	TRANSIT_MANAGEMENT_USER_SELECTED,
} from '@/store/TransitFacility/management/actions';
import { mask } from 'vue-the-mask';

@Component({
	components: {
		Modal,
		Multiselect,
		ButtonCustom,
	},
	directives: { mask },
})
export default class NewUserManagementModal extends Vue {
	/* Data */
	private selectOptions: any = [
		{ value: 'Ativo', text: 'Ativo' },
		{ value: 'Inativo', text: 'Inativo' },
	];

	private isLoading: boolean = false;

	private busy: boolean = false;

	private form: any = {
		nome: '',
		cpf: '',
		email: '',
		status: '',
	};

	/* Computed */

	get showModal() {
		return this.$store.getters.hasUserManagementSelected;
	}

	get selectedUser() {
		return this.$store.getters.getUserManagementSelected;
	}

	get title() {
		if (this.showModal) return 'Editar Usuário';
		return 'Novo Usuário';
	}

	get validation() {
		if (this.form.nome && this.form.email && this.form.cpf && this.form.status?.value) {
			return false;
		}
		return true;
	}

	/* Watcher */

	/* Methods */

	resetInfo() {
		this.form = {
			nome: '',
			email: '',
			cpf: '',
			status: '',
		};
		this.$store.dispatch(TRANSIT_MANAGEMENT_USER_SELECTED, null);
	}

	initInfo() {
		if (this.showModal) {
			this.isLoading = true;
			this.$store
				.dispatch(TRANSIT_MANAGEMENT_GET_USER_REQUEST, this.selectedUser)
				.then((user) => {
					this.form.nome = user.nome;
					this.form.cpf = user.codCpf;
					this.form.email = user.email;
					this.form.status = this.selectOptions[user.ativo ? 0 : 1];
				})
				.catch(() => {
					this.$toast.error('Erro ao buscar usuário');
				})
				.finally(() => (this.isLoading = false));
		}
	}

	onSave() {
		this.busy = true;

		const params = {
			nome: this.form.nome,
			codCpf: this.form.cpf.replace(/\D/g, ''),
			email: this.form.email,
			ativo: this.form.status?.value === 'Ativo' ? true : false,
		};

		if (this.showModal) {
			params['idUsuario'] = this.selectedUser;
			this.$store
				.dispatch(TRANSIT_MANAGEMENT_PUT_USER_REQUEST, params)
				.then(() => this.$emit('message', 'Usuário atualizado com sucesso', 'success', true))
				.catch(() => this.$emit('message', 'Erro ao atualizar usuário', 'error'))
				.finally(() => {
					this.busy = false;
					this.close();
				});
			return;
		}
		this.$store
			.dispatch(TRANSIT_MANAGEMENT_POST_USER_REQUEST, params)
			.then(() => this.$emit('message', 'Usuário criado com sucesso', 'success', true))
			.catch(() => this.$emit('message', 'Erro ao criar novo usuário', 'error'))
			.finally(() => {
				this.busy = false;
				this.close();
			});
	}

	close() {
		this.$bvModal.hide('NewUserManagementModal');
	}
}
