





























import { Vue, Component } from 'vue-property-decorator';
import Card from '@/components/widgets/molecules/Card/Card.vue';
import ListRender from '@/components/widgets/molecules/ListRender/ListRender.vue';
import Multiselect from '@/components/widgets/atoms/Multiselect/Multiselect.vue';
import FiltersIcon from '@/components/icons/Config.vue';
import { AvailableFilters } from '@/models/filters/available-filters.interface';
import { AvailableFiltersDefinitions } from '@/models/filters/transit-facility/management-filter.interface';
import { mask } from 'vue-the-mask';

@Component({
	components: {
		Card,
		FiltersIcon,
		Multiselect,
		ListRender,
	},
	directives: { mask },
})
export default class ManagementFilters extends Vue {
	/* Data */
	public active = false;

	/* Ts Workaround to import the Enum */

	public statusOptions: any = [
		{ value: 'Ativo', text: 'Ativo' },
		{ value: 'Inativo', text: 'Inativo' },
	];

	public filtersOptions = {
		nome: '',
		status: '',
		cpf: '',
		email: '',
	};

	public filterDefinitions: any = this.definitions;
	public availableFilters: any = AvailableFilters;

	private inputList = [
		{
			label: 'Nome',
			placeholder: 'Nome do usuário',
			modelValue: this.filterDefinitions[AvailableFilters.Name].nameSelected,
			type: 'input',
			filterKey: AvailableFilters.Name,
			nome: 'name',
		},
		{
			label: 'Email',
			placeholder: 'Email do usuário',
			modelValue: this.filterDefinitions[AvailableFilters.Email].emailSelected,
			type: 'input',
			filterKey: AvailableFilters.Email,
			nome: 'email',
		},
		{
			label: 'CPF',
			mask: "'###.###.###-##'",
			placeholder: '000.000.000-00',
			modelValue: this.filterDefinitions[AvailableFilters.Cpf].cpfSelected,
			type: 'input',
			filterKey: AvailableFilters.Cpf,
			nome: 'cpf',
		},
		{
			type: 'multiselect',
			isSearch: true,
			label: 'Status',
			modelValue: this.filterDefinitions[AvailableFilters.Status].statusSelected,
			options: this.statusOptions,
			filterKey: AvailableFilters.Status,
			nome: 'status',
		},
	];
	/* Watcher */

	/* Computed */
	get definitions() {
		return AvailableFiltersDefinitions();
	}

	/* Lifecycle */
	created() {
		this.active = window.innerWidth > 1367;
	}

	handleInputUpdate(event) {
		const { newValue, filterKey, input } = event;
		const definitionKey = `${input}Selected`;
		if (this.filterDefinitions && this.filterDefinitions[filterKey])
			this.$set(this.filterDefinitions[filterKey], definitionKey, newValue);
	}

	/* Methods */
	clearFilters(): void {
		this.filterDefinitions = AvailableFiltersDefinitions();
		this.inputList.forEach((item, index) =>
			this.$set(this.inputList, index, { ...item, modelValue: item.type === 'input' ? '' : [] }),
		);
		this.$emit('onClear');
	}

	applyFilters(): void {
		this.$emit('onSubmit', this.filterDefinitions);
	}
}
