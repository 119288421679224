

























































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import DisclosurePayers from "../../DisclosurePayers/DisclosurePayers.vue";
import DisclosureTransport from "../../DisclosureTransport/DisclosureTransport.vue";
import DisclosureInvoice from "../../DisclosureInvoice/DisclosureInvoice.vue";
import DisclosureDocuments from "../../DisclosureDocuments/DisclosureDocuments.vue";
import DisclosureObservations from "../../DisclosureObservations/DisclosureObservations.vue";
import DisclosureShippingCompany from "../../DisclosureShippingCompany/DisclosureShippingCompany.vue";

import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";

import { formatDateHour } from "@/shared/util/formatDate";
import formatNumber from "@/shared/util/formatNumber";
import { formatCNPJ, formatCPF } from "@/shared/util/farmatCPF";

import {
  TRANSIT_TRAVEL_DETAIL_REQUEST,
  TRANSIT_GET_PARTNER_REQUEST,
  TRANSIT_PUT_TRAVEL_DETAIL,
  SELECT_TRANSIT_TRAVEL_DETAIL,
} from "@/store/TransitFacility/travels/actions";

@Component({
  components: {
    Modal,
    DisclosurePayers,
    DisclosureShippingCompany,
    DisclosureTransport,
    DisclosureInvoice,
    DisclosureDocuments,
    DisclosureObservations,
    ButtonCustom,
  },
})
export default class TravelDetailModal extends Vue {
  /* Data */
  private baseTableRows: Array<any> = [
    { label: "Nome", value: "-" },
    { label: "Fazenda", value: "-" },
    { label: "CNPJ/CPF", value: "-" },
    { label: "Data de Embarque", value: "-" },
    { label: "Cód. ERP", value: "-" },
    { label: "UF", value: "-" },
    { label: "Município", value: "-" },
    { label: "Inscrição", value: "-" },
  ];

  private show_components: boolean = false

  private selectRows: any = {
    sender: JSON.parse(JSON.stringify(this.baseTableRows)),
    receiver: JSON.parse(JSON.stringify(this.baseTableRows)),
    taker: JSON.parse(JSON.stringify(this.baseTableRows)),
  };

  private takerPay: any = {
    sender: { text: "sender", id: null },
    receiver: { text: "receiver", id: null },
    taker: { text: "taker", id: null },
  };

  private form: any = {
    payers: {
      activePay: { text: null, id: null },
    },
    transport: {
      driver: null,
      cpf: null,
      transitFacility: null,
      kmDriven: null,
      toll: 0,
      freight: 0,
      truck: null,
      carts: [{ cart: null }],
    },
    invoice: {
      nfNumber: null,
      nfKey: null,
      nfValue: 0,
      bois: 0,
      vacas: 0,
      bezerros: 0,
      novilhas: 0,
      totalAnimals: 0,
    },
    documents: {
      cte: null,
      mdfe: null,
      pagamento: null
    },
    transportadora: {
      frota: null,
      liberacao: null,
      cdCiot: null,
      adiantamento: 0,
      cdTransportadora: null,
      label: null,
    }
  };

  private busy: any = {
    verifyButton: false,
    saveButton: false,
  };

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedTransitTravel;
  }

  get selectedTravel() {
    return this.$store.getters.getSelectedTransitTravel;
  }

  get isLoading() {
    return this.$store.getters.isTransitTravelDetailLoading;
  }

  /* Methods */
  resetInfo() {
    this.form = {
      payers: {
        activePay: { text: null, id: null },
      },
      transport: {
        driver: null,
        cpf: null,
        transitFacility: null,
        kmDriven: null,
        toll: 0,
        freight: 0,
        truck: null,
        carts: [{ cart: null }],
      },
      invoice: {
        nfNumber: null,
        nfKey: null,
        nfValue: 0,
        bois: 0,
        vacas: 0,
        bezerros: 0,
        novilhas: 0,
        totalAnimals: 0,
      },
      documents: {
        cte: null,
        mdfe: null,
      },
      transportadora: {
        frota: null,
        liberacao: null,
        cdCiot: null,
        adiantamento:null,
        cdTransportadora: null,
        label: null,
      }
    };
    this.takerPay = {
      sender: { text: "sender", id: null },
      receiver: { text: "receiver", id: null },
      taker: { text: "taker", id: null },
    };
    this.$store.dispatch(SELECT_TRANSIT_TRAVEL_DETAIL, null);
  }

  initInfo() {
    this.getInfo();
  }

  getInfo() {

    this.show_components = true

    this.$store
      .dispatch(TRANSIT_TRAVEL_DETAIL_REQUEST, this.selectedTravel)
      .then(({ destinatario, remetente, tomador, viagem }) => {
        // Set invoice transport
        this.form.transport.driver = viagem?.idMotorista
          ? { value: viagem.idMotorista, text: viagem.nomeMotorista }
          : null;
        this.form.transport.cpf = viagem?.cpfMotorista || null;
        this.form.transport.transitFacility = viagem?.idTransportadora || null;
        this.form.transport.kmDriven = formatNumber(viagem?.kmRodado) || null;
        this.form.transport.toll = viagem?.valorPedagioBalsa || 0;
        this.form.transport.freight = viagem?.valorFrete || 0;
        this.form.transport.truck = viagem?.caminhao
          ? { value: viagem.caminhao.idCaminhao, text: viagem.caminhao.placa }
          : null;
        this.form.transport.carts =
          viagem.carretas.length > 0
            ? viagem.carretas.map((cart) => ({
                cart: { value: cart.idCarreta, text: cart.placa },
              }))
            : [{ cart: null }];

        // Set invoice informations
        this.form.invoice.nfNumber = viagem?.numeroNF || null;
        this.form.invoice.nfKey = viagem?.chaveNF || null;
        this.form.invoice.nfValue = viagem?.valorNF || 0;
        this.form.invoice.bois = viagem?.qtdBoi || 0;
        this.form.invoice.vacas = viagem?.qtdVaca || 0;
        this.form.invoice.bezerros = viagem?.qtdBezerro || 0;
        this.form.invoice.novilhas = viagem?.qtdNovilho || 0;

        // Set documents informations
        this.form.documents.cte = viagem?.numeroCTE || null;
        this.form.documents.mdfe = viagem?.numeroMDFE || null;
        this.form.documents.pagamento = viagem?.transpBoleto || null;

        // Set shippingCompany informations
        this.form.transportadora.frota = viagem?.transpFrota || null;
        this.form.transportadora.liberacao = viagem?.transpLiberacao || null;
        this.form.transportadora.cdCiot = viagem?.numeroCIOT || null;
        this.form.transportadora.adiantamento = viagem?.transpValorAdiantamento || null;
        this.form.transportadora.cdTransportadora = viagem?.codigoTransportadora || null;

        // Set payers informations
        const pay = { text: "", id: null };
        if (remetente.idParceiro === tomador.idParceiro) {
          this.setRow("taker", remetente);
          pay.text = "sender";
          pay.id = remetente.idParceiro;
        } else if (destinatario.idParceiro === tomador.idParceiro) {
          this.setRow("taker", destinatario);
          pay.text = "receiver";
          pay.id = destinatario.idParceiro;
        } else if (tomador?.idParceiro) {
          this.setRow("taker", tomador);
          pay.text = "taker";
          pay.id = tomador.idParceiro;
        }

        this.setRow("sender", remetente);
        this.setRow("receiver", destinatario);
        this.form.payers.activePay = pay;

        this.show_components = false
      })
      .catch(() => {
        this.$toast.error("Erro ao carregar os dados");
      });
  }

  updateActivePay(pay) {
    if (pay.text != "taker") {
      this.selectRows.taker = JSON.parse(
        JSON.stringify(this.selectRows[pay.text])
      );
    }
    if (pay.text == "taker") {
      this.setRow("taker", []);
    }
    this.form.payers.activePay = { text: pay.text, id: pay.id };
  }

  getTomador(tomador) {
    if (tomador) {
      this.$store
        .dispatch(TRANSIT_GET_PARTNER_REQUEST, tomador.value)
        .then((response) => {
          this.setRow("taker", response);
          this.form.payers.activePay = {
            text: "taker",
            id: response.idParceiro,
          };
        })
        .catch(() => {
          this.$emit("message", "Erro ao carregar os dados", "error");
        });
      return;
    }
    this.setRow("taker", tomador);
  }

  setRow(rowName, values) {
    this.takerPay[rowName].id = values?.idParceiro;
    this.selectRows[rowName][0].value = values?.nome || "-";
    this.selectRows[rowName][1].value = values?.fazenda || "-";
    this.selectRows[rowName][2].value =
      formatCPF(values?.codCpf) || formatCNPJ(values?.codCgc) || "-";
    this.selectRows[rowName][3].value = values?.dataEmbarque
      ? formatDateHour(values?.dataEmbarque)
      : "-";
    this.selectRows[rowName][4].value = values?.codERP || "-";
    this.selectRows[rowName][5].value = values?.uf || "-";
    this.selectRows[rowName][6].value = values?.municipio || "-";
    this.selectRows[rowName][7].value = values?.inscricao || "-";
  }

  onSave() {
    this.busy.saveButton = true;

    const payers = this.form.payers;
    const tranport = this.form.transport;
    const invoice = this.form.invoice;
    const documents = this.form.documents;
    const transportadora = this.form.transportadora;

    const frota = (this as any).$refs.shippingCompany.getFrota();
    const liberacao = (this as any).$refs.shippingCompany.getLiberacao();
    const pagamento = (this as any).$refs.documents.getPagamento()

    const params = {
      idViagem: this.selectedTravel,
      idTomador: payers.activePay.id,
      idMotorista: tranport.driver?.value || null,
      idCaminhao: tranport.truck?.value || null,
      idCarretas: tranport.carts
        .map((cart) => cart.cart?.value || null)
        .filter(Boolean),
      valorFrete: tranport.freight,
      valorPedagioBalsa: tranport.toll,
      numeroNF: invoice.nfNumber,
      chaveNF: invoice.nfKey,
      valorNF: invoice.nfValue,
      qtdBoi: invoice.bois,
      qtdVaca: invoice.vacas,
      qtdBezerro: invoice.bezerros,
      qtdNovilho: invoice.novilhas,
      numeroCTE: documents.cte,
      numeroMDFE: documents.mdfe,
      transpBoleto: pagamento.value,
      transpFrota: frota.value,
      transpLiberacao: liberacao.value,
      numeroCIOT: transportadora.cdCiot,
      transpValorAdiantamento:transportadora.adiantamento,
      codigoTransportadora: transportadora.cdTransportadora,
    };

    this.$store
      .dispatch(TRANSIT_PUT_TRAVEL_DETAIL, params)
      .then(() => {
        this.$toast.success("Detalhes salvos com sucesso");
      })
      .catch(() => {
        this.$toast.error(
          "Erro ao salvar detalhes da viagem. Verifique os campos!"
        );
      })
      .finally(() => {
        this.busy.saveButton = false;
      });
  }

  close() {
    this.$bvModal.hide("TravelDetailModal");
  }
}
