














































import { Vue, Component, Prop } from "vue-property-decorator";
import Disclosure from "@/components/widgets/molecules/Disclosure/Disclosure.vue";
import FormInput from "@/components/widgets/molecules/FormInput/FormInput.vue";
import FormSelect from "@/components/widgets/molecules/FormSelect/FormSelect.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import Label from "@/components/widgets/atoms/Label/Label.vue";

@Component({
  components: {
    Disclosure,
    FormInput,
    FormSelect,
    ButtonCustom,
    Label,
  },
})
export default class DisclosureShippingCompany extends Vue {

  /* Props */
  @Prop({ default: {} })
  public fields!: any;

  /* Data */

  private selectOptions: any = {
    frota: [{text:'Frota', value:true},{text:'Subcontratado', value:false},],
    liberacao: [{text:'Liberação', value:true},{text:'Pós Viagem',value:false}]
  }

private frota_value: any = null
private liberacao_value: any = null

  /* Computed */
  get selectedTravel() {
    return this.$store.getters.getSelectedTransitTravel;
  }

  created(){
    this.frota_value = null;
    this.liberacao_value = null;
  }

  mounted(){
    this.frota_value = this.fields.frota === true ? this.selectOptions.frota[0] : this.selectOptions.frota[1];
    this.liberacao_value = this.fields.liberacao === true ? this.selectOptions.liberacao[0]: this.selectOptions.liberacao[1];
  }

  getFrota() {
    return this.frota_value;
  }

  getLiberacao() {
    return this.liberacao_value;
  }

 }
