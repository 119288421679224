




import { Component, Vue } from "vue-property-decorator";
import TravelDetached from "@/components/pages/modules/transitFacility/TravelDetached/index.vue";

@Component({
  components: {
    TravelDetached
  }
})

export default class TransitTravelDetached extends Vue {}
