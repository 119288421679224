import transitOrders from './orders';
import transitNewOrder from './newOrder';
import transitFilters from './filters';
import transitTravels from './travels';
import transitRegisters from './registers';
import transitContracts from './contracts';
import transitiManagement from './management';

export default {
	transitOrders,
	transitNewOrder,
	transitTravels,
	transitFilters,
	transitRegisters,
	transitContracts,
	transitiManagement,
};
