





















































import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";
import Multiselect from "@/components/widgets/atoms/Multiselect/Multiselect.vue";

@Component({
  components: {
    Multiselect
  }
})

export default class TableSelect extends Vue {
  /* Props */
  @Prop({ required: true })
  public title!: any;

  @Prop({ required: true })
  public rows!: any;

  @Prop({ default: () => []})
  public selectOptions!: Array<any>;

  @Prop({ default: false })
  public searchable!: boolean;

  @Prop({ default: false })
  public loading!: boolean;

  @Prop({ required: true })
  public valueType!: any;

  @Model('change') readonly activeModel!: any

  /* Data */
  private active: boolean = false;
  
  private partnerValue: any = null;

  /* Computed */
  get model() {
		return this.activeModel.text
	}

	set model(value) {
		this.$emit('change', { text: value, id: this.valueType.id });
	}

  /* Watcher */
  @Watch("rows")
  onRowsEmpty(value) {
    if (value) this.partnerValue = null;
  }
}
