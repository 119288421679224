









/* Imports */
import { Vue, Component } from 'vue-property-decorator';
import Sidebar from '@/components/widgets/organisms/Sidebar/Sidebar.vue';
import MainLayout from '@/components/layouts/MainLayout/MainLayout.vue';

import { mainRoutes, rancherRoutes, transitFacilityRoutes } from "@/router";
import routeIcons from "@/constants/routeIcons";
import INav from "@/models/navigation/Nav.interface";

/* Main App Class */
@Component({
  components: {
    Sidebar,
    MainLayout
  },
})

export default class Home extends Vue {
  /* Computed */
  get isAuthenticated(): boolean {
    return this.$store.getters.isAuthenticated;
  }

  get routeList() {
    switch (this.userRole) {
      case 'RANCHER': 
        return rancherRoutes;
      case 'TRANSIT_FACILITY':
        return transitFacilityRoutes;
      default: 
        return mainRoutes;
    }
  }

  get userRole() {
    return this.$store.getters.getUserRole;
  }

  get permissions() {
    return this.$store.getters.getUserPermissions;
	}

  get filteredItems() {
    const items = (this.routeList[0]?.children || []).filter(
      el =>
        el.meta.roles.some(permission => this.permissions.includes(permission)) ||
        el.meta.roles.length == 0
    );
    return items.map((route: any): INav => {
      const routeName = route.name.split(' ')[1];
      return {
        active: false,
        name: routeName,
        path: route.path,
        icon: routeIcons[routeName],
      }
    });
  }

  get firstRouteFiltered() {
    return this.filteredItems;
  }

  mounted() {
    if (this.filteredItems.length === 0) {
      this.$router.push({ path: '/permission-denied' });
    }
    if (this.$router.currentRoute.name === "Home") {
      this.$router.push({ path: this.firstRouteFiltered[0].path });
    }
  }
}
