import permissionControl from './control';
import permissionProfile from './profile';
import permissionUsers from './users';
import permissionAffiliate from './affiliate';

export default { 
  permissionControl,
  permissionProfile, 
  permissionUsers,
  permissionAffiliate
}