

























































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Steps from "@/components/widgets/molecules/Steps/Steps.vue";
import Multiselect from "@/components/widgets/atoms/Multiselect/Multiselect.vue";
import DatePicker from "@/components/widgets/atoms/VueDatePicker/VueDatePicker.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import TableSelect from "../../TableSelect/TableSelect.vue";

import { formatDateISO } from "@/shared/util/formatDate";
import { formatCNPJ, formatCPF } from "@/shared/util/farmatCPF";

import {
  GET_TRANSIT_ORIGIN_PARTNER_REQUEST,
  GET_TRANSIT_ORIGIN_FARM_REQUEST,
  GET_TRANSIT_DESTINY_PARTNER_REQUEST,
  GET_TRANSIT_DESTINY_FARM_REQUEST,
  POST_TRANSIT_NEW_ORDER,
} from "@/store/TransitFacility/newOrder/actions";

import { TRANSIT_SELECT_CONTRACT } from "@/store/TransitFacility/orders/actions";

@Component({
  components: {
    Modal,
    Table,
    Steps,
    ButtonCustom,
    Multiselect,
    TableSelect,
    DatePicker,
  },
})
export default class NewTransitOrderModal extends Vue {
  /* Data */
  private filtersOptions: any = {
    partnerOrigin: [],
    farmOrigin: [],
    partnerDestination: [],
    farmDestination: [],
  };

  private selectRows: any = {
    remetente: [],
    destinatario: [],
    tomador: [],
  };

  private takerPay: any = {
    remetente: { text: "remetente", id: null },
    destinatario: { text: "destinatario", id: null },
    tomador: { text: "tomador", id: null },
  };

  private animalsHeader: any = [
    { key: "animais", label: "Animais", tdClass: "mid-brown" },
    { key: "quantidade", label: "Quantidade" },
    { key: "peso", label: "Peso Médio" },
  ];

  private animalsRows: any = [
    {
      animais: "Boi",
      quantidade: {
        value: null,
        type: "number",
        isInput: true,
        isDisabled: false,
      },
      peso: { value: null, type: "number", isInput: true, isDisabled: false },
    },
    {
      animais: "Vaca",
      quantidade: {
        value: null,
        type: "number",
        isInput: true,
        isDisabled: false,
      },
      peso: { value: null, type: "number", isInput: true, isDisabled: false },
    },
    {
      animais: "Novilho",
      quantidade: {
        value: null,
        type: "number",
        isInput: true,
        isDisabled: false,
      },
      peso: { value: null, type: "number", isInput: true, isDisabled: false },
    },
    {
      animais: "Bezerro",
      quantidade: {
        value: null,
        type: "number",
        isInput: true,
        isDisabled: false,
      },
      peso: { value: null, type: "number", isInput: true, isDisabled: false },
    },
  ];

  private form: any = {
    partnerOrigin: null,
    partnerDestination: null,
    farmDestination: null,
    farmOrigin: null,
    originUF: null,
    originCity: null,
    destinationUF: null,
    destinationCity: null,
    distance: null,
    initialDate: null,
    finalDate: null,
    clientTravelCode: null,
  };

  private busy: boolean = false;

  private taker: any = false;

  private activePay: any = { text: null, id: null };

  private baseTableRows: Array<any> = [
    { label: "Nome", value: "-" },
    { label: "Fazenda", value: "-" },
    { label: "CNPJ/CPF", value: "-" },
  ];

  constructor() {
    super();
    this.selectRows.destinatario = JSON.parse(
      JSON.stringify(this.baseTableRows)
    );
    this.selectRows.remetente = JSON.parse(JSON.stringify(this.baseTableRows));
    this.selectRows.tomador = JSON.parse(JSON.stringify(this.baseTableRows));
  }

  /* Computed */
  get isOriginPartnerLoading() {
    return this.$store.getters.isTOriginPartnerLoading;
  }

  get isOriginFarmLoading() {
    return this.$store.getters.isTOriginFarmLoading;
  }

  get isDestinyPartnerLoading() {
    return this.$store.getters.isTDestinyPartnerLoading;
  }

  get isDestinyFarmLoading() {
    return this.$store.getters.isTDestinyFarmLoading;
  }

  get validationStep1() {
    if (
      this.form.partnerOrigin &&
      this.form.farmOrigin &&
      this.form.originUF &&
      this.form.originCity
    )
      return false;
    return true;
  }

  get validationStep2() {
    if (
      this.form.partnerDestination &&
      this.form.farmDestination &&
      this.form.destinationUF &&
      this.form.destinationCity
    )
      return false;
    return true;
  }

  get validationStep3() {
    return !this.activePay.id;
  }

  get validationStep4() {
    return !this.animalsRows.some(
      (animal) => animal.quantidade.value && animal.peso.value
    );
  }

  /* Watcher */
  @Watch("form.partnerOrigin")
  onPartnerOriginChange(value) {
    this.form.farmOrigin = null;
    if (value) return this.getOriginFarm(value);
  }

  @Watch("form.partnerDestination")
  onPartnerDestinationChange(value) {
    this.form.farmDestination = null;
    if (value) return this.getDestinyFarm(value);
  }

  @Watch("form.farmOrigin")
  onFarmOriginChange(value) {
    if (value) {
      this.setRow("remetente", {
        id: value.partnerId,
        farm: value.text,
        name: value.partner,
        cpf: value.cpf,
      });
      this.form.originUF = value.state;
      this.form.originCity = value.city;
      return;
    }
    this.setRow("remetente", {});
    this.form.originUF = null;
    this.form.originCity = null;
  }

  @Watch("form.farmDestination")
  onFarmDestinationChange(value) {
    if (value) {
      this.setRow("destinatario", {
        id: value.partnerId,
        farm: value.text,
        name: value.partner,
        cpf: value.cpf,
      });
      this.form.destinationUF = value.state;
      this.form.destinationCity = value.city;
      return;
    }
    this.setRow("destinatario", {});
    this.form.destinationUF = null;
    this.form.destinationCity = null;
  }

  /* Methods */
  resetInfo() {
    this.animalsRows = [
      {
        animais: "Boi",
        quantidade: {
          value: null,
          type: "number",
          isInput: true,
          isDisabled: false,
        },
        peso: { value: null, type: "number", isInput: true, isDisabled: false },
      },
      {
        animais: "Vaca",
        quantidade: {
          value: null,
          type: "number",
          isInput: true,
          isDisabled: false,
        },
        peso: { value: null, type: "number", isInput: true, isDisabled: false },
      },
      {
        animais: "Novilho",
        quantidade: {
          value: null,
          type: "number",
          isInput: true,
          isDisabled: false,
        },
        peso: { value: null, type: "number", isInput: true, isDisabled: false },
      },
      {
        animais: "Bezerro",
        quantidade: {
          value: null,
          type: "number",
          isInput: true,
          isDisabled: false,
        },
        peso: { value: null, type: "number", isInput: true, isDisabled: false },
      },
    ];
    this.activePay = { text: null, id: null };
    this.form = {
      partnerOrigin: null,
      partnerDestination: null,
      farmDestination: null,
      farmOrigin: null,
      originUF: null,
      originCity: null,
      destinationUF: null,
      destinationCity: null,
      distance: null,
      clientTravelCode: null,
      initialDate: null,
      finalDate: null,
    };
  }

  initInfo() {
    this.getOriginPartner();
    this.getDestinyPartner();
  }

  getOriginPartner(search = null) {
    this.$store
      .dispatch(GET_TRANSIT_ORIGIN_PARTNER_REQUEST, { search })
      .then(({ results }) => {
        this.filtersOptions.partnerOrigin = results.map((partner) => ({
          value: partner.idParceiro,
          text: partner.nome,
          cpf: formatCPF(partner?.codCpf) || formatCNPJ(partner?.codCgc),
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  getOriginFarm(partner) {
    this.$store
      .dispatch(GET_TRANSIT_ORIGIN_FARM_REQUEST, partner.value)
      .then(({ results }) => {
        this.filtersOptions.farmOrigin = results.map((farm) => ({
          value: farm.idEndereco,
          text: farm.descricao,
          state: farm.estado,
          city: farm.municipio,
          partner: partner.text,
          partnerId: partner.value,
          cpf: partner.cpf,
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  getDestinyPartner(search = null) {
    this.$store
      .dispatch(GET_TRANSIT_DESTINY_PARTNER_REQUEST, { search })
      .then(({ results }) => {
        this.filtersOptions.partnerDestination = results.map((partner) => ({
          value: partner.idParceiro,
          text: partner.nome,
          cpf: formatCPF(partner?.codCpf) || formatCNPJ(partner?.codCgc),
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  getDestinyFarm(partner) {
    this.$store
      .dispatch(GET_TRANSIT_DESTINY_FARM_REQUEST, partner.value)
      .then(({ results }) => {
        this.filtersOptions.farmDestination = results.map((farm) => ({
          value: farm.idEndereco,
          text: farm.descricao,
          state: farm.estado,
          city: farm.municipio,
          partner: partner.text,
          partnerId: partner.value,
          cpf: partner.cpf,
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  setTaker(taker) {
    if (taker.text !== "tomador") {
      this.selectRows.tomador = JSON.parse(
        JSON.stringify(this.selectRows[taker.text])
      );
    }
    if (taker.text === "tomador") {
      this.setRow(taker.text, []);
      this.activePay = { text: "tomador", id: null };
    }
  }

  getTaker(tomador) {
    this.setRow("tomador", {
      id: tomador.text,
      name: tomador.text,
      cpf: tomador.cpf,
    });
    this.activePay = { text: "tomador", id: tomador.text };
  }

  setRow(rowName, obj) {
    this.takerPay[rowName].id = obj?.id;
    this.selectRows[rowName][0].value = obj?.name || "-";
    this.selectRows[rowName][1].value = obj?.farm || "-";
    this.selectRows[rowName][2].value = obj?.cpf || "-";
  }

  onSave() {
    this.busy = true;
    const params = {
      idParceiroOrigem: this.form.partnerOrigin.value,
      idParceiroDestino: this.form.partnerDestination.value,
      idPecuarista: this.activePay.id,
      idFazendaOrigem: this.form.farmOrigin.value,
      idFazendaDestino: this.form.farmDestination.value,
      qtdVaca: this.animalsRows[1].quantidade.value,
      pesoMedioVaca: this.animalsRows[1].peso.value,
      qtdBoi: this.animalsRows[0].quantidade.value,
      pesoMedioBoi: this.animalsRows[0].peso.value,
      qtdNovilho: this.animalsRows[2].quantidade.value,
      pesoMedioNovilho: this.animalsRows[2].peso.value,
      qtdBezerro: this.animalsRows[3].quantidade.value,
      pesoMedioBezerro: this.animalsRows[3].peso.value,
      previsaoColeta: formatDateISO(this.form.initialDate),
      previsaoEntrega: formatDateISO(this.form.finalDate),
      kmEntrePontos: this.form.distance,
      codigoViagemCliente: this.form.clientTravelCode,
    };
    this.$store
      .dispatch(POST_TRANSIT_NEW_ORDER, params)
      .then((res) => {
        setTimeout(() => {
          this.$store.dispatch(TRANSIT_SELECT_CONTRACT, res.idContrato);
        }, 500);
        this.$emit("goContracts");
        this.$emit("message", "Pedido criado com sucesso", "success");
      })
      .catch(() => {
        this.$emit("message", "Erro ao criar pedido", "error");
      })
      .finally(() => {
        this.busy = false;
        this.close();
      });
  }

  close() {
    this.$bvModal.hide("NewTransitOrderModal");
  }
}
