




























import { Component, Vue, Prop } from "vue-property-decorator";
import INav from "@/models/navigation/Nav.interface";
import LogoutIcon from "@/components/icons/Logout.vue";

import { AUTH_LOGOUT } from "@/store/auth/actions";

@Component({
  components: {
    LogoutIcon
  }
})

export default class MenuContent extends Vue {
  @Prop({ required: true })
  public colapse!: boolean;

  @Prop({ required: true })
  public navItems!: Array<INav>;

  logout() {
    this.$store.dispatch(AUTH_LOGOUT);
    this.$router.push(`/login/${this.$store.getters.loginType}`);
  }
}
