



























































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Tracking from "@/components/widgets/organisms/Tracking/Tracking.vue";

import { formatDateHour } from "@/shared/util/formatDate";
import formatMoney from "@/shared/util/formatMoney";
import formatNumber from "@/shared/util/formatNumber";

import {
  SELECT_TRANSIT_RESUME,
  TRANSIT_GET_RESUME,
} from "@/store/TransitFacility/travels/actions";
import { SELECT_DOCUMENTS } from "@/store/photos/actions";

@Component({
  components: {
    Modal,
    Table,
    Tracking,
  },
})

export default class ReportModal extends Vue {
  /* Data */
  private travelHeader: any = [
    { key: "pedido", label: "Pedido" },
    { key: "viagem", label: "Viagem" },
    { key: "motorista", label: "Motorista" },
    { key: "placaCavalo", label: "Placa Cavalo" },
    { key: "placaCarreta", label: "Placa Carreta" },
    { key: "embarqueProgramado", label: "Embarque Previsto" },
    { key: "desembarqueProgramado", label: "Desembarque Previsto" },
    { key: "kmPrevisto", label: "KM Estimado", thClass: "dark-header" },
    { key: "kmRealizado", label: "KM Realizado", thClass: "dark-header" },
    { key: "valorPrevisto", label: "Valor Estimado", thClass: "dark-header" },
    {
      key: "valor_realizado",
      label: "Valor Realizado",
      thClass: "dark-header",
    },
  ];

  private travelRows: any = [];

  private animalsHeader: any = [
    { key: "animais", label: "Animais", tdClass: "dark-td" },
    { key: "quantidade", label: "Quantidade" },
    { key: "peso", label: "Peso" },
    { key: "quantidade_embarque", label: "Quantidade" },
  ];

  private animalsRows: any = [];

  private forecastHeader: any = [
    { key: "chegada_embarque", label: "Previsão de Chegada de Embarque" },
    { key: "chegada_desembarque", label: "Previsão de Chegada de Desembarque" },
  ];

  private forecastRow: any = [];

  private occurrenceHeader: any = [
    { key: "ocorrencia", label: "", thClass: "hide-th", tdClass: "half-td" },
    {
      key: "numero_ocorrencia",
      label: "",
      thClass: "hide-th",
      tdClass: "half-td",
    },
  ];

  private occurrenceRows: any = [
    { ocorrencia: "Problema com caminhão", numero_ocorrencia: "" },
    { ocorrencia: "Estrada interditada", numero_ocorrencia: "" },
    { ocorrencia: "Acidente", numero_ocorrencia: "" },
    { ocorrencia: "Problema", numero_ocorrencia: "" },
    { ocorrencia: "Outro", numero_ocorrencia: "" },
  ];

  private trackingData: any = [];

  /* Computed */
  get isLoading() {
    return this.$store.getters.isTransitTravelDetailLoading;
  }

  get showModal() {
    return this.$store.getters.hasSelectedTransitResume;
  }

  get selectedOrder() {
    return this.$store.getters.getSelectedTransitResume;
  }
 
  /* Methods */
  resetInfo() {
    this.$store.dispatch(SELECT_TRANSIT_RESUME, null);
  }

  initInfo() {
    this.$store
      .dispatch(TRANSIT_GET_RESUME, this.selectedOrder)
      .then((response) => {
        const { rastreamento, viagemAnimais, viagemItem } = response;
        this.travelRows = [{
          ...viagemItem,
          embarqueProgramado: formatDateHour(viagemItem.embarqueProgramado),
          desembarqueProgramado: formatDateHour(viagemItem.desembarqueProgramado),
          kmPrevisto: formatNumber(viagemItem?.kmPrevisto),
          kmRealizado: formatNumber(viagemItem?.kmRealizado),
          valorPrevisto: formatMoney(viagemItem?.valorPrevisto || 0),
          valorRealizado: formatMoney(viagemItem?.valorRealizado || 0)
        }];
        this.trackingData = rastreamento.map((step) => {
          const foto = step?.fotos ? step.fotos.map((foto) => {
            return {
              idDocumento: foto.tipo,
              descricao: foto.descricao,
              urlDocumento: foto.url,
              formId: step.formId,
              tipoForm: step.tipoForm
            }
          }) : []
          const [data, hora] = formatDateHour(step.data)?.split(" ")
          return {
            descricao: step.descricao,
            data,
            hora,
            fotos: {
              type: `Fotos ${step.descricao} - ${viagemItem.pedido} / ${viagemItem.viagem}`,
              docs: foto
            }
          }
        })
        this.animalsRows = [
          {
            animais: "Boi",
            quantidade: viagemAnimais?.qtdBoi || '-',
            peso: viagemAnimais?.pesoMedioBoi || '-',
            quantidade_embarque: viagemAnimais?.qtdBoi_Embarque || '-',
          },
          { animais: "Vaca", 
            quantidade: viagemAnimais?.qtdVaca || '-',
            peso: viagemAnimais?.pesoMedioVaca || '-',
            quantidade_embarque: viagemAnimais?.qtdVaca_Embarque || '-',
          },
          { animais: "Novilho", 
            quantidade: viagemAnimais?.qtdNovilho || '-',
            peso: viagemAnimais?.pesoMedioNovilho || '-',
            quantidade_embarque: viagemAnimais?.qtdNovilho_Embarque || '-',
          },
          { animais: "Bezerro", 
            quantidade: viagemAnimais?.qtdBezerro || '-',
            peso: viagemAnimais?.pesoMedioBezerro || '-',
            quantidade_embarque: viagemAnimais?.qtdBezerro_Embarque || '-',
          },
        ];
        this.forecastRow = [
          {
            chegada_embarque: formatDateHour(viagemItem?.previsaoChegadaEmbarque) || '-',
            chegada_desembarque: formatDateHour(viagemItem?.previsaoChegadaDesembarque) || '-', 
          }
        ]
      })
      .catch((e) => {
        Vue.$toast.error(e);
      });
  }
  
  openModalPhoto(documents){
    this.$store.dispatch(SELECT_DOCUMENTS, documents);
  }
}
