



















import { Component, Vue } from "vue-property-decorator";
import UboiLogo from "@/components/icons/UboiLogo.vue";

import { CONSTANTS } from '@/constants/constants';

@Component({
  components: {
    UboiLogo
  },
})

export default class Login extends Vue {
  /* Computed */
  get routerName() {
    return this.$route.meta.route
  }
  
  get version() {
    return CONSTANTS.SYSTEM_VERSION;
  }
}
