/* Import Libs */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '../store';

/* Import Login Views */
import Login from '@/views/Login/index.vue';
import LoginRancher from '@/views/Login/Rancher/index.vue';
import LoginTransit from '@/views/Login/Transit/index.vue';
import LoginForm from '@/components/pages/login/organisms/LoginForm/index.vue';
import ForgotForm from '@/components/pages/login/organisms/ForgotForm/index.vue';

/* Import Recover Views */
import RecoverPassword from '@/views/RecoverPassword/index.vue';
import RecoverRancher from '@/views/RecoverPassword/Rancher/index.vue';
import RecoverTransit from '@/views/RecoverPassword/Transit/index.vue';

/* Import First Login Views */
import FirstLogin from '@/views/FirstLogin/index.vue';
import FirstLoginRancher from '@/views/FirstLogin/Rancher/index.vue';
import FirstLoginTransit from '@/views/FirstLogin/Transit/index.vue';

/* Import Two Factor Authentication Views */
import TwoFactorAuthentication from '@/views/TwoFactorAuthentication/index.vue';
import TwoAuthRancher from '@/views/TwoFactorAuthentication/Rancher/index.vue';
import TwoAuthTransit from '@/views/TwoFactorAuthentication/Transit/index.vue';

/* Import Views */
import Home from '@/views/Home/Home.vue';
import Rancher from '@/views/Rancher/index.vue';
import RancherOrder from '@/views/Rancher/Order/Order.vue';
import RancherTravel from '@/views/Rancher/Travel/Travel.vue';
import RancherMap from '@/views/Rancher/Map/Map.vue';
import RancherPermissions from '@/views/Rancher/Permissions/Permissions.vue';

import TransitFacility from '@/views/TransitFacility/index.vue';
import TransitFacilityOrder from '@/views/TransitFacility/Order/Order.vue';
import TransitFacilityTravel from '@/views/TransitFacility/Travel/Travel.vue';
import TransitTravelDetached from '@/views/TransitFacility/TravelDetached/TravelDetached.vue';
import TransitFacilityMap from '@/views/TransitFacility/Map/Map.vue';
import TransitFacilityRegister from '@/views/TransitFacility/Register/Register.vue';
import TransitFacilityManagement from '@/views/TransitFacility/Manegement/Management.vue';
// import TransitFacilityPermissions from "@/views/TransitFacility/Permissions/Permissions.vue";

import PermissionDenied from '@/views/PermissionDenied/PermissionDenied.vue';
import Terms from '@/views/Terms/Terms.vue';
import Policy from '@/views/Policy/Policy.vue';

import { SYSTEM_PERMISSIONS } from '@/constants/permissions';
import { AUTH_VERIFY_TOKEN } from '@/store/auth/actions';

/* Vue Router Setup */
Vue.use(VueRouter);

/* Auth Guards */
const authGuard = (to: any, from: any, next: any) => {
	store.dispatch(AUTH_VERIFY_TOKEN);
	if (store.getters.isAuthenticated) {
		return next();
	}
	next(`/login/${store.getters.loginType}`);
};

const userHasPermissions = (systemPermissions, userPermissions) => {
	if (systemPermissions.length > 1) {
		return systemPermissions.some(permission => userPermissions.includes(permission));
	}
	return systemPermissions.every(permission => userPermissions.includes(permission));
};

function validatePermissions({ to, from, next }, parent, permissions) {
	const role = store.getters.getUserRole;
	const userPermissions = store.getters.getUserPermissions;
	if (parent == role && userHasPermissions(permissions, userPermissions)) {
		return authGuard(to, from, next);
	}
	next(`/`);
}

export const rancherRoutes: Array<RouteConfig> = [
	{
		path: '/rancher',
		name: 'Pecuarista',
		redirect: '/rancher/orders',
		component: Rancher,
		children: [
			{
				path: '/rancher/orders',
				name: 'Pecuarista Pedidos',
				meta: { roles: SYSTEM_PERMISSIONS.RANCHER.ORDER.ALL },
				component: RancherOrder,
				beforeEnter: (to, from, next) =>
					validatePermissions({ to, from, next }, 'RANCHER', SYSTEM_PERMISSIONS.RANCHER.ORDER.ALL),
			},
			{
				path: '/rancher/travels',
				name: 'Pecuarista Viagens',
				meta: { roles: SYSTEM_PERMISSIONS.RANCHER.TRAVEL.ALL },
				component: RancherTravel,
				beforeEnter: (to, from, next) =>
					validatePermissions({ to, from, next }, 'RANCHER', SYSTEM_PERMISSIONS.RANCHER.TRAVEL.ALL),
			},
			{
				path: '/rancher/map',
				name: 'Pecuarista Mapa',
				meta: { roles: SYSTEM_PERMISSIONS.RANCHER.MAP.ALL },
				component: RancherMap,
				beforeEnter: (to, from, next) =>
					validatePermissions({ to, from, next }, 'RANCHER', SYSTEM_PERMISSIONS.RANCHER.MAP.ALL),
			},
			{
				path: '/rancher/permissions',
				name: 'Pecuarista Permissões',
				meta: { roles: SYSTEM_PERMISSIONS.RANCHER.PERMISSIONS.ALL },
				component: RancherPermissions,
				beforeEnter: (to, from, next) =>
					validatePermissions(
						{ to, from, next },
						'RANCHER',
						SYSTEM_PERMISSIONS.RANCHER.PERMISSIONS.ALL,
					),
			},
		],
	},
];

export const transitFacilityRoutes: Array<RouteConfig> = [
	{
		path: '/transit-facility',
		name: 'Transportadora',
		redirect: '/transit-facility/orders',
		component: TransitFacility,
		children: [
			{
				path: '/transit-facility/orders',
				name: 'Transportadora Pedidos',
				meta: { roles: SYSTEM_PERMISSIONS.TRANSIT_FACILITY.ORDER.ALL },
				component: TransitFacilityOrder,
				beforeEnter: (to, from, next) =>
					validatePermissions(
						{ to, from, next },
						'TRANSIT_FACILITY',
						SYSTEM_PERMISSIONS.TRANSIT_FACILITY.ORDER.ALL,
					),
			},
			{
				path: '/transit-facility/travels',
				name: 'Transportadora Viagens',
				meta: { roles: SYSTEM_PERMISSIONS.TRANSIT_FACILITY.TRAVEL.ALL },
				component: TransitFacilityTravel,
				beforeEnter: (to, from, next) =>
					validatePermissions(
						{ to, from, next },
						'TRANSIT_FACILITY',
						SYSTEM_PERMISSIONS.TRANSIT_FACILITY.TRAVEL.ALL,
					),
			},
			{
				path: '/transit-facility/detached-travels',
				name: 'Transportadora Avulsas',
				meta: { roles: SYSTEM_PERMISSIONS.TRANSIT_FACILITY.TRAVEL.ALL },
				component: TransitTravelDetached,
				beforeEnter: (to, from, next) =>
					validatePermissions(
						{ to, from, next },
						'TRANSIT_FACILITY',
						SYSTEM_PERMISSIONS.TRANSIT_FACILITY.TRAVEL.ALL,
					),
			},
			{
				path: '/transit-facility/map',
				name: 'Transportadora Mapa',
				meta: { roles: SYSTEM_PERMISSIONS.TRANSIT_FACILITY.MAP.ALL },
				component: TransitFacilityMap,
				beforeEnter: (to, from, next) =>
					validatePermissions(
						{ to, from, next },
						'TRANSIT_FACILITY',
						SYSTEM_PERMISSIONS.TRANSIT_FACILITY.MAP.ALL,
					),
			},
			{
				path: '/transit-facility/register',
				name: 'Transportadora Cadastro',
				meta: { roles: SYSTEM_PERMISSIONS.TRANSIT_FACILITY.REGISTER.ALL },
				component: TransitFacilityRegister,
				beforeEnter: (to, from, next) =>
					validatePermissions(
						{ to, from, next },
						'TRANSIT_FACILITY',
						SYSTEM_PERMISSIONS.TRANSIT_FACILITY.REGISTER.ALL,
					),
			},
			{
				path: '/transit-facility/management',
				name: 'Transportadora Gestão',
				meta: { roles: SYSTEM_PERMISSIONS.TRANSIT_FACILITY.MANAGEMENT.ALL },
				component: TransitFacilityManagement,
				beforeEnter: (to, from, next) =>
					validatePermissions(
						{ to, from, next },
						'TRANSIT_FACILITY',
						SYSTEM_PERMISSIONS.TRANSIT_FACILITY.MANAGEMENT.ALL,
					),
			},
			// {
			//   path: "/transit-facility/permissions",
			//   name: "Transportadora Permissões",
			//   meta: { roles: SYSTEM_PERMISSIONS.TRANSIT_FACILITY.PERMISSIONS.ALL },
			//   component: TransitFacilityPermissions,c
			//   beforeEnter: (to, from, next) =>
			//     validatePermissions({ to, from, next }, 'TRANSIT_FACILITY', SYSTEM_PERMISSIONS.TRANSIT_FACILITY.PERMISSIONS.ALL),
			// },
		],
	},
];

/* Route Collections and Routes */
export const mainRoutes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		beforeEnter: authGuard,
		children: [
			...rancherRoutes,
			...transitFacilityRoutes,
			{
				path: '/permission-denied',
				name: 'Sem Permissão',
				component: PermissionDenied,
			},
		],
	},
];

export const secondaryRoutes: Array<RouteConfig> = [
	{
		path: '/terms',
		name: 'Termos de uso',
		component: Terms,
		beforeEnter: authGuard,
	},
	{
		path: '/policy',
		name: 'Policy',
		component: Policy,
		beforeEnter: authGuard,
	},
	{
		path: '*',
		redirect: '/',
	},
];

export const unauthenticatedRoutes: Array<RouteConfig> = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
		redirect: '/login/rancher',
		children: [
			{
				path: 'rancher',
				name: 'Login Pecuarista ',
				alias: ['/login/pecuarista', '/pecuarista'],
				redirect: '/login/rancher/enter',
				component: LoginRancher,
				children: [
					{
						path: 'enter',
						name: 'Entrar Pecuarista',
						meta: { route: 'rancher' },
						component: LoginForm,
					},
					{
						path: 'forgot',
						name: 'Esqueceu Pecuarista',
						meta: { route: 'rancher' },
						component: ForgotForm,
					},
				],
			},
			{
				path: 'transit-facility',
				name: 'Login Transportadora ',
				alias: ['/login/transportadora', '/transportadora'],
				redirect: '/login/transit-facility/enter',
				component: LoginTransit,
				children: [
					{
						path: 'enter',
						name: 'Entrar Transportadora',
						meta: { route: 'transit-facility' },
						component: LoginForm,
					},
					{
						path: 'forgot',
						name: 'Esqueceu Transportadora',
						meta: { route: 'transit-facility' },
						component: ForgotForm,
					},
				],
			},
		],
	},
	{
		path: '/first-login',
		name: 'Primeiro Acesso',
		component: FirstLogin,
		redirect: '/first-login/rancher',
		children: [
			{
				path: 'rancher',
				name: 'Primeiro Acesso Pecuarista',
				meta: { route: 'rancher' },
				component: FirstLoginRancher,
			},
			{
				path: 'transit-facility',
				name: 'Primeiro Acesso Transportadora ',
				meta: { route: 'transit-facility' },
				component: FirstLoginTransit,
			},
		],
	},
	{
		path: '/recover-password',
		name: 'Recuperar Senha',
		component: RecoverPassword,
		redirect: '/recover-password/rancher',
		children: [
			{
				path: 'rancher',
				name: 'Recuperar Pecuarista',
				meta: { route: 'rancher' },
				component: RecoverRancher,
			},
			{
				path: 'transit-facility',
				name: 'Recuperar Transportadora ',
				meta: { route: 'transit-facility' },
				component: RecoverTransit,
			},
		],
	},
	{
		path: '/two-factor-auth',
		name: 'Autenticação',
		component: TwoFactorAuthentication,
		redirect: '/two-factor-auth/rancher',
		children: [
			{
				path: 'rancher',
				name: 'Autenticação Pecuarista',
				meta: { route: 'rancher' },
				component: TwoAuthRancher,
			},
			{
				path: 'transit-facility',
				name: 'Autenticação Transportadora ',
				meta: { route: 'transit-facility' },
				component: TwoAuthTransit,
			},
		],
	},
];

/* Vue Router */
const router = new VueRouter({
	mode: 'history',
	base: process.env.VUE_APP_URL,
	routes: [...mainRoutes, ...secondaryRoutes, ...unauthenticatedRoutes],
	linkActiveClass: 'menu__link--active',
	linkExactActiveClass: 'menu__link--exact-active',
});

const DEFAULT_TITLE = 'Uboi Web';
router.afterEach(to =>
	Vue.nextTick(() => (document.title = to.name ? DEFAULT_TITLE + ' - ' + to.name : DEFAULT_TITLE)),
);

export default router;
