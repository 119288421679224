export const LOAD_TRAVEL_REQUEST = 'LOAD_TRAVEL_REQUEST';
export const LOAD_TRAVEL_SUCCESS = 'LOAD_TRAVEL_SUCCESS';
export const LOAD_TRAVEL_FAILED = 'LOAD_TRAVEL_FAILED';

export const TRAVEL_DETAIL_REQUEST = 'TRAVEL_DETAIL_REQUEST';
export const TRAVEL_DETAIL_SUCCESS = 'TRAVEL_DETAIL_SUCCESS';
export const TRAVEL_DETAIL_FAILED = 'TRAVEL_DETAIL_FAILED';

export const GET_DOWNLOAD_IMAGE = 'GET_DOWNLOAD_IMAGE';
export const GET_COLUMNS = 'GET_COLUMNS';
export const GET_KM_OCCURRENCE = 'GET_KM_OCCURRENCE';
export const GET_ANIMAL_OCCURRENCE = 'GET_ANIMAL_OCCURRENCE';
export const GET_TRUCK_OCCURRENCE = 'GET_TRUCK_OCCURRENCE';
export const GET_MAP_PATH = 'GET_MAP_PATH';
export const GET_ACTUAL_LOCALIZATION = 'GET_ACTUAL_LOCALIZATION';

export const PUT_COLUMNS = 'PUT_COLUMNS';

export const POST_KM_OBSERVATION = 'POST_KM_OBSERVATION';
export const POST_ANIMAL_BOARDING_OBSERVATION = 'POST_ANIMAL_BOARDING_OBSERVATION';
export const POST_ANIMAL_TRAVEL_OBSERVATION = 'POST_ANIMAL_TRAVEL_OBSERVATION';
export const POST_ANIMAL_LANDING_OBSERVATION = 'POST_ANIMAL_LANDING_OBSERVATION';
export const POST_TRUCK_OBSERVATION = 'POST_TRUCK_OBSERVATION';

export const SELECT_DETAIL_ORDER = 'SELECT_DETAIL_ORDER';
export const SELECT_MAP = 'SELECT_MAP';
export const SELECT_OCCURRENCE = 'SELECT_OCCURRENCE';
