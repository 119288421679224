


















import { Vue, Component, Prop } from "vue-property-decorator";
import WarningIcon from "@/components/icons/WarningFilled.vue";

@Component({
  components: {
    WarningIcon,
  },
})
export default class ButtonVerify extends Vue {
  /* Props */
  @Prop({ required: true })
  public title!: string;

  @Prop({ required: true })
  public busy!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;
}
