import { render, staticRenderFns } from "./TransitForm.vue?vue&type=template&id=49d1b030&scoped=true&"
import script from "./TransitForm.vue?vue&type=script&lang=ts&"
export * from "./TransitForm.vue?vue&type=script&lang=ts&"
import style0 from "./TransitForm.vue?vue&type=style&index=0&id=49d1b030&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d1b030",
  null
  
)

export default component.exports