export const TRANSIT_MANAGEMENT_GET_ALL_USERS_REQUEST = 'TRANSIT_MANAGEMENT_GET_ALL_USERS_REQUEST';

export const TRANSIT_MANAGEMENT_GET_ALL_USERS_SUCCESS = 'TRANSIT_MANAGEMENT_GET_ALL_USERS_SUCCESS';

export const TRANSIT_MANAGEMENT_GET_ALL_USERS_FAILED = 'TRANSIT_MANAGEMENT_GET_ALL_USERS_FAILED';

export const TRANSIT_MANAGEMENT_PUT_USER_REQUEST = 'TRANSIT_MANAGEMENT_PUT_USER_REQUEST';

export const TRANSIT_MANAGEMENT_PUT_USER_SUCCESS = 'TRANSIT_MANAGEMENT_PUT_USER_SUCCESS';

export const TRANSIT_MANAGEMENT_PUT_USER_FAILED = 'TRANSIT_MANAGEMENT_PUT_USER_FAILED';

export const TRANSIT_MANAGEMENT_USER_SELECTED = 'TRANSIT_MANAGEMENT_USER_SELECTED';

export const TRANSIT_MANAGEMENT_GET_USER_REQUEST = 'TRANSIT_MANAGEMENT_GET_USER_REQUEST';

export const TRANSIT_MANAGEMENT_GET_USER_FAILED = 'TRANSIT_MANAGEMENT_GET_USER_FAILED';

export const TRANSIT_MANAGEMENT_GET_USER_SUCCESS = 'TRANSIT_MANAGEMENT_GET_USER_SUCCESS';

export const TRANSIT_MANAGEMENT_POST_USER_REQUEST = 'TRANSIT_MANAGEMENT_POST_USER_REQUEST';

export const TRANSIT_MANAGEMENT_POST_USER_SUCCESS = 'TRANSIT_MANAGEMENT_POST_USER_SUCCESS';

export const TRANSIT_MANAGEMENT_POST_USER_FAILED = 'TRANSIT_MANAGEMENT_POST_USER_FAILED';
