




import { Component, Vue } from "vue-property-decorator";
import Order from "@/components/pages/modules/transitFacility/Order/index.vue";

@Component({
  components: {
    Order
  }
})

export default class TransitOrder extends Vue {}
