































import {Vue, Component, Prop} from "vue-property-decorator";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";

@Component({
  components: {
    ButtonCustom
  },
})

export default class Steps extends Vue {
  /* Props */
  @Prop({ default: 1, required: true })
  public stepNumber!: number;

  @Prop({ default: false })
  public showAll!: boolean;

  @Prop({ default: true })
  public showIndicators!: boolean;

  @Prop({ default: "" })
  public buttonsClass!: string;

  @Prop({ default: 8 })
  public buttonsPaddingY!: number;

  @Prop({ default: false })
  public buttonsW100!: boolean;

  @Prop({ default: () => [] })
  public stepValidations!: Array<boolean>;

  private activeStep: number = 1;

  backStap() {
    if (this.activeStep > 1) this.activeStep--;
  }

  nextStep() {
    if (this.activeStep < this.stepNumber) this.activeStep++;
  }
}
