
























import { Component, Vue } from "vue-property-decorator";
import Layout from "@/components/layouts/ModuleLayout/ModuleLayout.vue";
import Header from "@/components/widgets/organisms/Header/Header.vue";
import Tabs from "@/components/widgets/molecules/Tabs/Tabs.vue";

import NewOrderModal from "./organisms/Modals/NewOrder/NewOrder.vue";

import Contracts from "./template/Contracts/Contracts.vue";
import Orders from "./template/Orders/Orders.vue";

import { TRANSIT_EXPORTXLSX } from "@/store/TransitFacility/orders/actions";

@Component({
  components: {
    Layout,
    Header,
    Tabs,
    NewOrderModal,
  },
})
export default class TransitModuleOrder extends Vue {
  private tabName: string = "orders";

  private tabIndex: number = 0;

  /* Computed */
  get tabsComponents() {
    return [Orders, Contracts];
  }

  get isOrderDownload() {
    return this.$store.getters.hasContractExportXlsx;
  }

  get isContractDownload() {
    return this.$store.getters.hasOrderExportXlsx;
  }

  /* Methods */
  showIndex(index) {
    switch (index) {
      case 0:
        this.tabName = "orders";
        break;
      case 1:
        this.tabName = "contracts";
        break;
    }
  }

  message(message, variant) {
    this.$nextTick(() => {
      this.$toast.open({
        message,
        type: variant,
      });
    });
  }

  goContracts() {
    this.tabIndex = 1;
  }

  newOrder() {
    this.$bvModal.show("NewTransitOrderModal");
  }

  exportXLS() {
    this.$store.dispatch(TRANSIT_EXPORTXLSX, this.tabName);
  }
}
