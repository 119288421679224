






























import { Component, Vue, Prop } from "vue-property-decorator";
import ButtonCustom from "../../atoms/ButtonCustom/ButtonCustom.vue";
import ArchiveXlsIcon from "@/components/icons/ArchiveXLS.vue";
import ArchiveNewIcon from "@/components/icons/ArchiveNew.vue";

@Component({
  components: {
    ArchiveNewIcon,
    ArchiveXlsIcon,
    ButtonCustom
  },
})

export default class Header extends Vue {
  /* Props */
  @Prop({ required: true })
  public title!: string;

  @Prop({ default: false })
  public hasButton!: boolean;

  @Prop({ default: "Button" })
  public btnTitle!: string;

  @Prop({ default: "Download" })
  public downloadTitle!: string;

  @Prop({ default: false })
  public hasDownload!: boolean;

  @Prop({ default: false })
  public busyDownload!: boolean;

  /* Components */
  get newIcon() {
		return ArchiveNewIcon;
	}

  get xlsIcon() {
		return ArchiveXlsIcon;
	}

  get username() {
		return this.$store.getters.getUserName;
	}
}
