import {
  TRANSIT_ORDER_ORIGIN_RANCHER_REQUEST,
  TRANSIT_ORDER_DESTINY_RANCHER_REQUEST,
  TRANSIT_ORDER_ORIGIN_FARM_REQUEST,
  TRANSIT_ORDER_DESTINY_FARM_REQUEST,
  TRANSIT_OPERATIONAL_SITUATION_REQUEST,
  TRANSIT_STATUS_PROPOSAL_REQUEST,
} from '@/store/TransitFacility/filters/actions';

import {
  AvailableFilters
} from "@/models/filters/available-filters.interface";

// This must be a function so the state of the filters can be erased from one page to the next
export const AvailableFiltersDefinitions = () => {
	const definition = {};

	definition[ AvailableFilters.Date ] = {
		startDateSelected: '',
		endDateSelected: '',
	}

	definition[ AvailableFilters.Origin ] = {
		rancherSelected: '',
		farmSelected: '',
    rancherDispatch: TRANSIT_ORDER_ORIGIN_RANCHER_REQUEST,
    farmDispatch: TRANSIT_ORDER_ORIGIN_FARM_REQUEST,
		isLoading: false,
	}

	definition[ AvailableFilters.Destiny ] = {
		rancherSelected: '',
		farmSelected: '',
    rancherDispatch: TRANSIT_ORDER_DESTINY_RANCHER_REQUEST,
    farmDispatch: TRANSIT_ORDER_DESTINY_FARM_REQUEST,
		isLoading: false,
	}

	definition[ AvailableFilters.Situation ] = {
		situationSelected: '',
    situationDispatch: TRANSIT_OPERATIONAL_SITUATION_REQUEST,
		isLoading: false,
	}

  definition[ AvailableFilters.Status ] = {
		statusSelected: '',
    statusDispatch: TRANSIT_STATUS_PROPOSAL_REQUEST,
		isLoading: false,
	}

	definition[ AvailableFilters.Order ] = {
		orderSelected: '',
	}

	return definition;
}