export const TRANSIT_REGISTER_GET_DRIVERS = 'TRANSIT_REGISTER_GET_DRIVERS';
export const TRANSIT_REGISTER_GET_CARTS = 'TRANSIT_REGISTER_GET_CARTS';
export const TRANSIT_REGISTER_GET_TRUCKS = 'TRANSIT_REGISTER_GET_TRUCKS';

export const TRANSIT_REGISTER_GET_SKETCH_DRIVER = 'TRANSIT_REGISTER_GET_SKETCH_DRIVER';
export const TRANSIT_REGISTER_GET_SKETCH_CART = 'TRANSIT_REGISTER_GET_SKETCH_CART';
export const TRANSIT_REGISTER_GET_SKETCH_TRUCK = 'TRANSIT_REGISTER_GET_SKETCH_TRUCK';

export const TRANSIT_REGISTER_GET_SUCCESS = 'TRANSIT_REGISTER_GET_SUCCESS';
export const TRANSIT_REGISTER_GET_FAILED = 'TRANSIT_REGISTER_GET_FAILED';

export const TRANSIT_REGISTER_POST_DRIVER = 'TRANSIT_REGISTER_POST_DRIVER';
export const TRANSIT_REGISTER_POST_CART = 'TRANSIT_REGISTER_POST_CART';
export const TRANSIT_REGISTER_POST_TRUCK = 'TRANSIT_REGISTER_POST_TRUCK';

export const TRANSIT_REGISTER_PUT_DRIVER = 'TRANSIT_REGISTER_PUT_DRIVER';
export const TRANSIT_REGISTER_PUT_CART = 'TRANSIT_REGISTER_PUT_CART';
export const TRANSIT_REGISTER_PUT_TRUCK = 'TRANSIT_REGISTER_PUT_TRUCK';

export const SELECT_REGISTER_DRIVER = 'SELECT_REGISTER_DRIVER';
export const SELECT_REGISTER_CART = 'SELECT_REGISTER_CART';
export const SELECT_REGISTER_TRUCK = 'SELECT_REGISTER_TRUCK';













