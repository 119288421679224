















import { Component, Vue, Prop } from "vue-property-decorator";
import MenuContent from '../../molecules/MenuContent/MenuContent.vue';
import UboiLogo from "@/components/icons/UboiLogo.vue";
import ArrowIcon from "@/components/icons/MenuArrow.vue";

import INav from "@/models/navigation/Nav.interface";

@Component({
  components: {
    ArrowIcon,
    UboiLogo,
    MenuContent
  }
})
export default class Sidebar extends Vue {
  /* Props */
  @Prop({ required: true })
  public navItems!: Array<INav>;

  /* Data */
  private colapse: boolean = true;

  /* Methods */
  toggleColapse(bool) {
    this.colapse = bool;
  }
}
