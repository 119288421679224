































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import ActivityHistory from "@/components/widgets/organisms/Tracking/Tracking.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";

import { formatDateHour } from "@/shared/util/formatDate";

import {
  TRANSIT_GET_ACTIVITY_HISTORY,
  SELECT_TRANSIT_HISTORICAL,
} from "@/store/TransitFacility/travels/actions";

@Component({
  components: {
    Modal,
    ActivityHistory,
    ButtonCustom,
  },
})
export default class TravelDetailModal extends Vue {
  /* Data */

  private isLoaded: boolean = true;

  private historicData: Array<any> = [];

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedHistorical;
  }

  get selectedTravel() {
    return this.$store.getters.getSelectedHistorical;
  }

  /* Methods */
  resetInfo() {
    this.historicData = [];
    this.$store.dispatch(SELECT_TRANSIT_HISTORICAL, null);
  }

  initInfo() {
    this.getInfo();
  }

  getInfo() {
    this.isLoaded = false;
    this.$store
      .dispatch(TRANSIT_GET_ACTIVITY_HISTORY, this.selectedTravel)
      .then((response) => {
        this.historicData = response.map((step) => {
          const [data, hora] = formatDateHour(step.data)?.split(" ");
          const descricao = step.descricao.split(" ");
          let tipoHistorico = descricao.shift();
          if (tipoHistorico == "VerificarNF.") {
            tipoHistorico = "Verificar NF";
          } else {
            tipoHistorico = "Verificar CTE";
          }
          return {
            descricao: descricao.join(" "),
            data,
            hora,
            tipoHistorico,
          };
        });
      })
      .catch(() => {
        Vue.$toast.error(
          "Erro ao carregar os dados do histórico de atividades"
        );
      })
      .finally(() => {
        this.isLoaded = true;
      });
  }

  close() {
    this.$bvModal.hide("HistoricalModal");
  }
}
