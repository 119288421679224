



































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import Card from "@/components/widgets/molecules/Card/Card.vue";
import Multiselect from "@/components/widgets/atoms/Multiselect/Multiselect.vue";
import FiltersIcon from "@/components/icons/Config.vue";

import {
  AvailableFilters
} from "@/models/filters/available-filters.interface";

import {
  AvailableFiltersDefinitions
} from "@/models/filters/transit-facility/travel-filters.interface";

@Component({
  components: {
    Card,
    FiltersIcon,
    Multiselect,
  },
})

export default class OrderFilters extends Vue {
  /* Data */
  private active = false;

  /* Ts Workaround to import the Enum */
  private filterDefinitions: any = this.definitions;

  private disabled: boolean = false;

  private orderDisabled: boolean = false;

  private travelDisabled: boolean = false;

  private filtersOptions: any = {
    originRancher: [],
    originFarm: [],
    destinyRancher: [],
    destinyFarm: [],
    tracking: [],
    situation: [],
    drivers: [],
  };

  /* Watcher */
  @Watch("originRancherSelected")
  onSelectOriginRancher(value) {
    this.filtersOptions['originFarm'] = [];
    this.getFarm(this.availableFilters.Origin, "originFarm", value?.value);
  }

  @Watch("destinyRancherSelected")
  onSelectDestinyRancher(value) {
    this.filtersOptions['destinyFarm'] = [];
    this.getFarm(this.availableFilters.Destiny, "destinyFarm", value?.value);
  }

  @Watch("orderSelected")
  onSelectOrder(value) {
    if (value) {
      this.disabled = true;
      this.travelDisabled = true;
      Object.keys(this.filterDefinitions).forEach(index => {
        if (+index !== this.availableFilters.Order) {
          Object.keys(this.filterDefinitions[index]).forEach(item => {
            if (item.includes('Selected')) this.filterDefinitions[index][item] = "";
          })
        }
      })
      return;
    }
    this.disabled = false;
    this.travelDisabled = false;
  }

  @Watch("travelSelected")
  onSelectTravel(value) {
    if (value) {
      this.disabled = true;
      this.orderDisabled = true;
      Object.keys(this.filterDefinitions).forEach(index => {
        if (+index !== this.availableFilters.Travel) {
          Object.keys(this.filterDefinitions[index]).forEach(item => {
            if (item.includes('Selected')) this.filterDefinitions[index][item] = "";
          })
        }
      })
      return;
    }
    this.disabled = false;
    this.orderDisabled = false;
  }


  /* Computed */
  get definitions() {
    return AvailableFiltersDefinitions();
  }

  get availableFilters() {
    return AvailableFilters;
  }

  get originRancherSelected() {
    return this.filterDefinitions[this.availableFilters.Origin].rancherSelected;
  }

  get destinyRancherSelected() {
    return this.filterDefinitions[this.availableFilters.Destiny].rancherSelected;
  }

  get orderSelected() {
    return this.filterDefinitions[this.availableFilters.Order].orderSelected;
  }

  get travelSelected() {
    return this.filterDefinitions[this.availableFilters.Travel].travelSelected;
  }


  /* Lifecycle */
  created() {
    this.active = window.innerWidth > 1367;
    let index = 0;
    Object.keys(this.filterDefinitions).forEach(filter => {
      Object.keys(this.filterDefinitions[filter]).forEach(item => {
        if (item.includes('Dispatch')) {
          this.requestFilter(
            Object.keys(this.filtersOptions)[index],
            this.filterDefinitions[filter][item],
            filter
          );
          index++;
        }
      })
    })
  }

  /* Methods */
  requestFilter(filter, endpoint: string, filterIndex): void {
    this.filterDefinitions[filterIndex].isLoading = true;
    this.$store
      .dispatch(endpoint, { page: 1, size: 20 })
      .then((response) => {
        const data = filter == "tracking" || filter == "situation" ? response : response?.results;
        this.filtersOptions[filter] = data.map((i) => {
          if (["originRancher", "destinyRancher", "drivers"].includes(filter)) {
            return {
              value: i.idParceiro,
              text: i.nome,
            };
          } else if (["originFarm", "destinyFarm"].includes(filter)) {
            return {
              value: i.idEndereco,
              text: i.descricao,
            };
          } else if (filter === "tracking") {
            return {
              value: i.tipo_Evento,
              text: i.descricao,
            };
          } else if (filter === "situation") {
            return {
              value: i.codigo,
              text: i.descricao,
            };
          }
        });
      })
      .catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.filterDefinitions[filterIndex].isLoading = false;
      });
  }

  getFarm(filter, field, rancherId) {
    this.filterDefinitions[filter].isLoading = true;
    this.$store
      .dispatch(this.filterDefinitions[filter].farmDispatch, { page: 1, size: 20, id: rancherId })
      .then(({ results }) => {
        this.filtersOptions[field] = results.map((i) => (
          { value: i.idEndereco, text: i.descricao }
        ))
      }).catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.filterDefinitions[filter].isLoading = false;
      });
  }

  search(filter, type, field, value) {
    this.filterDefinitions[filter].isLoading = true;
    if (type === "rancher") {
      this.$store
      .dispatch(this.filterDefinitions[filter].rancherDispatch, {page: 1, size: 20, search: value}).then(({ results }) => {
        this.filtersOptions[field] = results.map((i) => (
          { value: i.idParceiro, text: i.nome }
        ))
      }).catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
          this.filterDefinitions[filter].isLoading = false;
      });
    }
    if (type === "farm") {
      this.$store
      .dispatch(this.filterDefinitions[filter].farmDispatch, {page: 1, size: 20, search: value})
        .then(({ results }) => {
          this.filtersOptions[field] = results.map((i) => (
            { value: i.idEndereco, text: i.descricao }
          ))
        }).catch((e) => {
          this.$toast.error(e);
        })
        .finally(() => {
          this.filterDefinitions[filter].isLoading = false;
        });
    }
    if(type === "drivers") {
      this.$store
        .dispatch(this.filterDefinitions[filter].driverDispatch, { page: 1, size: 20, search: value })
        .then(({ results }) => {
          this.filtersOptions[field] = results.map(i => (
            { value: i.idParceiro, text: i.nome }
          ))
        }).catch((e) => {
          this.$toast.error(e);
        })
        .finally(() => {
          this.filterDefinitions[filter].isLoading = false;
        });
    }
  }

  clearFilters(): void {
    this.$emit("onClear");
    this.filterDefinitions = AvailableFiltersDefinitions();
  }

  applyFilters(): void {
    this.$emit("onSubmit", this.filterDefinitions);
  }
}
