



















































































import { Vue, Component } from "vue-property-decorator";
import {
  MglMap,
  MglMarker,
  MglGeojsonLayer,
  MglNavigationControl,
} from "vue-mapbox";
import CloseIcon from "@/components/icons/Close.vue";
import InitTravelIcon from "@/components/icons/TravelInit.vue";
import EndTravelIcon from "@/components/icons/TravelEnd.vue";
import MapThumbIcon from "@/components/icons/MapThumb.vue";
import SatelliteIcon from "@/components/icons/MapSatellite.vue";
import Mapbox from "mapbox-gl";

import MAP_STYLE from "@/shared/util/mapStyle";
import { formatDateHour } from "@/shared/util/formatDate";
import { CONSTANTS } from '@/constants/constants';
import "mapbox-gl/dist/mapbox-gl.css";

import { SELECT_MAP, GET_MAP_PATH } from "@/store/travels/actions";

@Component({
  components: {
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglNavigationControl,
    CloseIcon,
    InitTravelIcon,
    EndTravelIcon,
    SatelliteIcon,
    MapThumbIcon,
  },
})

export default class MapModal extends Vue {
  /* Data */
  private mapStyle: any = MAP_STYLE;
  
  private mapbox: any;

  private map: any;

  private hoverId = 0;

  private firstLoad: boolean = true;

  private pointsCoordinates: any = [];

  private routeCoordinates: any = {};

  private isLoaded: boolean = false;

  private initialPoint: any = {};

  private centerPoint: Array<number> | undefined = undefined;

  private details!: any;

  private showDetails = false;

  private detailTimeout: any;

  private zoom: Array<number> = [1.7, 16];

  private selectedView: string = "default";

  private lineColor: string = "#4B4B4B";

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedMap;
  }

  get selectedMap() {
    return this.$store.getters.getSelectedMap;
  }

  get accessToken() {
    return CONSTANTS.MAP_ACCESS_TOKEN;
  }

  /* Lifecycle */
  mounted() {
    this.mapbox = Mapbox;
  }

  /* Methods */
  resetInfo() {
    this.$store.dispatch(SELECT_MAP, null);
    this.initialPoint = {};
    this.isLoaded = false;
    this.firstLoad = true;
    this.zoom = [1.7, 16];
    this.selectedView = "default";
    this.mapStyle = MAP_STYLE;
    this.lineColor = "#4B4B4B";
  }

  initInfo() {
    this.$store
      .dispatch(GET_MAP_PATH, this.selectedMap)
      .then((response) => {
        this.pointsCoordinates = response;
        if (response.length > 0) {
          response.forEach((i) => {
            if (i.titulo == "Início de Viagem") {
              this.initialPoint = i;
            }
          });
          if (Object.keys(this.initialPoint).length === 0) {
            this.initialPoint = response[0];
          }
        }
        if (Object.keys(this.initialPoint).length === 0) {
          this.initialPoint = {
            longitude: -55,
            latitude: -12,
          };
          this.zoom[1] = 3.5;
        }
        this.isLoaded = true;
      })
      .catch((e) => {
        Vue.$toast.error(e);
      });
  }

  async onMapLoad(event) {
    this.map = event.component.map;
    const asyncActions = event.component.actions;
    if(this.firstLoad){
      await asyncActions.flyTo({
        center: [this.initialPoint.longitude, this.initialPoint.latitude],
        zoom: this.zoom[1],
        speed: 1,
      });
      this.firstLoad = false;
    } else {
      this.map.jumpTo({
        center: this.centerPoint,
        zoom: this.zoom[0],
      });
    }
    if (this.pointsCoordinates.length > 0) {
      this.pointsCoordinates.forEach((point, index) => {
        if (index != 0) {
          this.getPath(
            this.pointsCoordinates[index - 1],
            point,
            event.component.map,
            index
          );
        }
      });
    }
  }
  
  changeView(view) {
    this.isLoaded = false;
    this.selectedView = view;
    this.zoom[0] = this.map.getZoom();
    const {lng, lat} = this.map.getCenter();
    this.centerPoint = [];
    this.centerPoint.push(lng);
    this.centerPoint.push(lat);
    if (view == "satellite") {
      this.lineColor = "#FFF";
      this.mapStyle = "mapbox://styles/mapbox/satellite-streets-v11";
      this.$nextTick(() => {
        this.isLoaded = true;
      });
      return
    }
    this.lineColor = "#4B4B4B";
    this.mapStyle = MAP_STYLE;
    this.$nextTick(() => {
      this.isLoaded = true;
    });
  }

  async getPath(init, end, map, index) {
    const url =
      "https://api.mapbox.com/directions/v5/mapbox/driving/" +
      init.longitude +
      "," +
      init.latitude +
      ";" +
      end.longitude +
      "," +
      end.latitude +
      "?geometries=geojson&steps=true&overview=full&&access_token=" +
      this.accessToken;
    const req = new XMLHttpRequest();
    req.responseType = "json";
    req.open("GET", url, true);
    req.onload = () => {
      const jsonResponse = req.response;
      map.addSource(`route-${index}`, {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: jsonResponse.routes[0].geometry,
        },
      });
      map.addLayer({
        id: `route-${index}`,
        type: "line",
        source: `route-${index}`,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": this.lineColor,
          "line-width": 4,
        },
      });
    };
    req.send();
  }
  getTooltipLabel(point) {
    const label = `
      <div class="tooltip-route-content type-${point.tipo}">
        <span class="title"><b>${point.titulo}</b></span><br/>
        <span class="descricao">${point.descricao}<br/></span>
        <span class="date">${formatDateHour(point.dataRegistro)}</span>
      </div>`;
    return label;
  }
  mouseOut(): void {
    this.hoverId = 0;
    this.detailTimeout = setTimeout(() => (this.showDetails = false), 200);
  }

  mouseOver(c: any): void {
    this.hoverId = c.id;
    this.details = c;
    this.showDetails = true;
  }
}
