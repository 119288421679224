

















import { Vue, Component, Prop } from "vue-property-decorator";
import Label from '../../atoms/Label/Label.vue';
import InputPassword from '../../atoms/InputPassword/InputPassword.vue';
import PasswordRules from '../../atoms/PasswordRules/PasswordRules.vue';

@Component({
  components: {
    Label,
    InputPassword,
    PasswordRules
  }
})

export default class PasswordInput extends Vue {
  /* Props */
  @Prop()
  public label!: string;

  @Prop()
  public value!: string;

  @Prop({ default: 'password' })
  public name!: string;

  @Prop()
  public min!: number;

  @Prop()
  public max!: number;

  @Prop()
  public placeholder!: string;

  @Prop({ default: false })
  public hasTip!: boolean;
}
