




import { Component, Vue } from "vue-property-decorator";
import Travel from "@/components/pages/modules/rancher/Travel/index.vue";

@Component({
  components: {
    Travel
  }
})

export default class RancherTravel extends Vue {}
