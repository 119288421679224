



















































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FilterIcon from "@/components/icons/Filter.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import Loader from "@/components/widgets/atoms/Loader/Loader.vue";

@Component({
  components: {
    FilterIcon,
    Loader,
    ButtonCustom
  },
})
export default class AdvancedFilters extends Vue {
  /* Data */
  private selectedAdvacedFilters: Array<number> = [];

  private showAdvancedFilter: boolean = false;

  /* Props */
  @Prop({ default: false })
  public columns!: any;

  @Prop({ required: true })
  public isLoaded!: boolean;

  @Prop({ default: [] })
  public previouslySelected!: any;

  @Watch("previouslySelected") 
  onColunsChange() {
    this.selectedAdvacedFilters = this.previouslySelected;
  }

  /* Lifecycle */
  created() {
    this.clearCheckboxes();
  }

  /* Methods */
  clearCheckboxes(): void {
    this.$emit("onClear");
    this.selectedAdvacedFilters = [];
  }
  
  applyFilters(): void {
    this.$emit("onSubmit", this.selectedAdvacedFilters);
  }
}
