<template>
  <div class="no-data">
    <div class="no-data__content">
      <div class="no-data__head">
        <h4 class="ml-3 my-auto">Sem Permissão</h4>
      </div>
      <div class="no-data__body">
        <div class="manage-container">
          <p class="content">Você não possuí permissão para acessar os módulos, entre em contato com o seu administrador.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component()

export default class PermissionDenied extends Vue{}
</script>

<style lang="scss" scoped>
@import "./PermissionDenied.scss";
</style>
