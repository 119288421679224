





























import { Component, Vue } from 'vue-property-decorator';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue';

import NewUserModal from '../../organisms/Modals/NewUser/NewUser.vue';
import BooleanModal from '@/components/modals/BooleanModal/BooleanModal.vue';

import { formatCNPJ, formatCPF } from '@/shared/util/farmatCPF';
import { formatDate } from '@/shared/util/formatDate';
import formatPhone from '@/shared/util/farmatPhone';

import { GET_ALL_USERS_REQUEST, DELETE_USER, SELECT_USER } from '@/store/permissions/users/actions';

@Component({
	components: {
		Table,
		Pagination,
		NewUserModal,
		BooleanModal,
	},
})
export default class RancherUsers extends Vue {
	/* Data */
	private modal: any = {
		title: '',
		content: '',
		data: null,
		busy: false,
	};

	private params: any = {
		page: 1,
		size: 14,
	};

	private fixedHeader: any = [
		{ key: 'nome', label: 'Nome', thClass: 'dark-header' },
		{ key: 'codCpf', label: 'CPF/CNPJ', thClass: 'dark-header' },
		{ key: 'dataNascimento', label: 'Data Nascimento', thClass: 'dark-header' },
		{ key: 'email', label: 'E-mail', thClass: 'dark-header' },
		{ key: 'filial', label: 'Filiais', thClass: 'dark-header', tdClass: 'no-overflow' },
		{ key: 'telefone', label: 'Telefone', thClass: 'dark-header' },
		{ key: 'actions', label: 'Ações', tdClass: 'td-action' },
	];

	private rows: any = [];

	private totalPages: number = 0;

	/* Computed */
	get isLoading() {
		return this.$store.getters.isAllUsersLoading;
	}

	/* Lifecycle */
	mounted() {
		this.getUsers(this.params);
	}

	/* Methods */
	getUsers(params: any) {
		this.$store
			.dispatch(GET_ALL_USERS_REQUEST, params)
			.then(({ totalCount, results }) => {
				if (results.length == 0) this.$toast.error('Nenhum usuário encontrado');
				this.totalPages = totalCount;
				this.rows = results.map(user => {
					let identification = '';
					if (user.codcpf) {
						identification = formatCPF(user.codcpf);
					} else if (user.codcgc) {
						identification = formatCNPJ(user.codcgc);
					}
					return {
						id: user.idUsuario,
						nome: user.nome,
						codCpf: identification,
						dataNascimento: formatDate(user.dataNascimento),
						email: user.email,
						telefone: formatPhone(user.telefone),
						filial: user.fazendas.map(farm => farm.descricao).join(', '),
						actions: {
							id: user.idUsuario,
							farms: user.fazendas,
							buttons: ['edit', 'trash'],
						},
					};
				});
			})
			.catch(e => {
				Vue.$toast.error(e);
			});
	}

	message(message, variant, reload = false) {
		if (reload) this.getUsers(this.params);
		this.$nextTick(() => {
			Vue.$toast.open({
				message,
				type: variant,
			});
		});
	}

	onChangePage(page) {
		this.params.page = page;
		this.getUsers(this.params);
	}

	onChangeSize(size) {
		this.params.size = size;
		this.params.page = 1;
		this.getUsers(this.params);
	}

	openEdit(value) {
		this.$store.dispatch(SELECT_USER, value.id);
	}

	openTrash(value) {
		this.modal = {
			title: 'Excluir Usuário',
			content:
				'Você tem certeza que deseja prosseguir com a ação de excluir o usuário selecionado?',
			data: { id: value.id },
			busy: false,
		};
		this.$bvModal.show('DeleteUser');
	}

	onAcceptDelete(data) {
		this.modal.busy = true;
		this.$store
			.dispatch(DELETE_USER, data.id)
			.then(() => {
				this.getUsers(this.params);
				this.$toast.success('Usuário excluido com sucesso');
			})
			.catch(() => {
				this.$toast.error('Erro ao excluir usuário');
			})
			.finally(() => {
				this.modal.busy = false;
				this.$bvModal.hide('DeleteUser');
			});
	}
}
