


































































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import InputRate from "@/components/widgets/atoms/InputRate/InputRate.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";

import { SYSTEM_PERMISSIONS } from "@/constants/permissions";

import { 
  GET_ORDER_RESUME,
  POST_RATE,
  SELECT_RATE_ORDER,
} from "@/store/orders/actions";

@Component({
  components: {
    Modal,
    InputRate,
    ButtonCustom,
  },
})

export default class RatingModal extends Vue {
  /* Data */
  private busy: boolean = false;

  private hasRated: boolean = false;

  private isLoading: boolean = false;

  private rateRows: any = [
    { avaliacao_titulo: "Sistema", isRate: false,  rate: 0 },
    { avaliacao_titulo: "Atendimento Equipe Uboi", isRate: false, rate: 0 },
    { avaliacao_titulo: "Motorista", isRate: false, rate: 0 },
    { avaliacao_titulo: "Caminhão", isRate: false, rate: 0 },
    { avaliacao_titulo: "Nota Geral", isRate: false, rate: 0 },
  ];

  private observation: string | null = null;

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedRate;
  }

  get selectedRate() {
    return this.$store.getters.getSelectedRate;
  }

  get permissions() {
    return this.$store.getters.getUserPermissions;
  }

  get systemPermissions() {
    return SYSTEM_PERMISSIONS.RANCHER.ORDER;
  }

  /* Methods */
  resetInfo() {
    this.observation = null;
    this.rateRows = [
      { avaliacao_titulo: "Sistema", isRate: false,  rate: 0 },
      { avaliacao_titulo: "Atendimento Equipe Uboi", isRate: false, rate: 0 },
      { avaliacao_titulo: "Motorista", isRate: false, rate: 0 },
      { avaliacao_titulo: "Caminhão", isRate: false, rate: 0 },
      { avaliacao_titulo: "Nota Geral", isRate: false, rate: 0 },
    ];
    this.hasRated = false;
    this.$store.dispatch(SELECT_RATE_ORDER, null);
  }

  initInfo() {
    this.isLoading = true;
    this.$store
    .dispatch(GET_ORDER_RESUME, this.selectedRate)
    .then(({ avaliacao }) => {
      if (avaliacao?.idAvaliacao) {
        this.hasRated = true;
        this.observation = avaliacao.observacao
        this.rateRows = [
          { avaliacao_titulo: "Sistema", isRate: true,  rate: avaliacao.aplicativo || 0 },
          { avaliacao_titulo: "Atendimento Equipe Uboi", isRate: true, rate: avaliacao.atendimento || 0 },
          { avaliacao_titulo: "Motorista", isRate: true, rate: avaliacao.motorista || 0 },
          { avaliacao_titulo: "Caminhão", isRate: true, rate: avaliacao.caminhao || 0 },
          { avaliacao_titulo: "Nota Geral", isRate: true, rate: avaliacao.notaGeral || 0 },
        ];
      }
    })
    .catch((e) => {
      this.$toast.error(e);
    })
    .finally(() => {
      this.isLoading = false;
    })
  }
  
  rate() {
    this.busy = true;
    const params = {
      idPedido: this.selectedRate,
      aplicativo: this.rateRows[0].rate,
      atendimento: this.rateRows[1].rate,
      motorista: this.rateRows[2].rate,
      caminhao: this.rateRows[3].rate,
      notaGeral: this.rateRows[4].rate,
      observacao: this.observation
    };
    this.$store
      .dispatch(POST_RATE, params)
      .then(() => {
        this.$emit("message", "Avaliação enviada com sucesso", "success", true);
      })
      .catch(() => {
        this.$emit("message", "Erro ao enviar avaliação", "error");
      })
      .finally(() => {
        this.busy = false;
        this.close();
      });
  }

  close() {
    this.$bvModal.hide("RatingModal");
  }
}
