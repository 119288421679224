




import { Component, Vue } from "vue-property-decorator";
import Order from "@/components/pages/modules/rancher/Order/index.vue";

@Component({
  components: {
    Order
  }
})

export default class RancherOrder extends Vue {}
