/* Imports */
import PermissionUserState from '@/models/permission/users-state/users-state.interface';
import api from '@/shared/util/api';
const { http } = api.getInstance();

/* Actions */
import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILED,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  GET_FARMS_REQUEST,
  GET_FARMS_SUCCESS,
  GET_FARMS_FAILED,
  POST_USER,
  PUT_USER,
  DELETE_USER,
  SELECT_USER
} from './actions';


/* Initial AuthState object */
const state: PermissionUserState = {
  allUsersStatus: 'loading',
  userStatus: 'loading',
  farmsStatus: 'loading',
  selectedUser: null,
};

/* Vuex Auth Getters */
const getters = {
  isAllUsersLoading: (state: PermissionUserState): boolean => state.allUsersStatus === 'loading',
  isUserLoading: (state: PermissionUserState): boolean => state.userStatus === 'loading',
  isFarmsLoading: (state: PermissionUserState): boolean => state.farmsStatus === 'loading',
  getSelectedUser: (state: PermissionUserState) => state.selectedUser,
  hasSelectedUser: (state: PermissionUserState): boolean => state.selectedUser !== null,
};

/* Vuex Auth Mutations */
const mutations = {
  [GET_ALL_USERS_REQUEST]: (state: PermissionUserState) => { state.allUsersStatus = 'loading' },
  [GET_ALL_USERS_SUCCESS]: (state: PermissionUserState) => { state.allUsersStatus = 'success' },
  [GET_ALL_USERS_FAILED]: (state: PermissionUserState) => { state.allUsersStatus = 'error' },
  [GET_USER_REQUEST]: (state: PermissionUserState) => { state.userStatus = 'loading' },
  [GET_USER_SUCCESS]: (state: PermissionUserState) => { state.userStatus = 'success' },
  [GET_USER_FAILED]: (state: PermissionUserState) => { state.userStatus = 'error' },
  [GET_FARMS_REQUEST]: (state: PermissionUserState) => { state.farmsStatus = 'loading' },
  [GET_FARMS_SUCCESS]: (state: PermissionUserState) => { state.farmsStatus = 'success' },
  [GET_FARMS_FAILED]: (state: PermissionUserState) => { state.farmsStatus = 'error' },
  [SELECT_USER]: (state: PermissionUserState, userId) => { state.selectedUser = userId },
};

/* Vuex Auth Actions */
const actions = {
  [GET_ALL_USERS_REQUEST]: ({ commit }: any, params: any = {}) => {
    return new Promise((resolve, reject) => {
      const queryParams = [`page=${params?.page || 1}`, `size=${params?.size || 20}`];
      commit(GET_ALL_USERS_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/usuario?${queryParams.join('&')}`,
      })
        .then(({ data }) => {
          commit(GET_ALL_USERS_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(GET_ALL_USERS_FAILED);
          reject(error);
        });
    })
  },
  [GET_USER_REQUEST]: ({ commit }: any, userId: number) => {
    return new Promise((resolve, reject) => {
      commit(GET_USER_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/usuario/${userId}`,
      })
        .then(({ data }) => {
          commit(GET_USER_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(GET_USER_FAILED);
          reject(error);
        });
    })
  },
  [GET_FARMS_REQUEST]: ({ commit }: any, params: any = {}) => {
    return new Promise((resolve, reject) => {
      const queryParams = [`page=${params?.page || 1}`, `size=${params?.size || 20}`];
      if (params.search) {
        queryParams.push(`name=${params.search}`);
      }
      commit(GET_FARMS_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/usuario/fazenda/busca?${queryParams.join('&')}`,
      })
        .then(({ data }) => {
          commit(GET_FARMS_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(GET_FARMS_FAILED);
          reject(error);
        });
    })
  },
  [POST_USER]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      http({
        method: 'post',
        url: `/uboiweb/jbspermissao/usuario`,
        data: { ...requestData },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [PUT_USER]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      http({
        method: 'put',
        url: `/uboiweb/jbspermissao/usuario`,
        data: { ...requestData },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [DELETE_USER]: ({ commit }: any, userId: number) => {
    return new Promise((resolve, reject) => {
      http({
        method: 'delete',
        url: `/uboiweb/jbspermissao/usuario/${userId}`,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [SELECT_USER]: ({ commit }, userId) => {
    commit(SELECT_USER, userId);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};