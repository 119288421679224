import { 
  TRAVEL_ORIGIN_RANCHER_REQUEST,
  TRAVEL_DESTINY_RANCHER_REQUEST,
  TRAVEL_ORIGIN_FARM_REQUEST,
  TRAVEL_DESTINY_FARM_REQUEST,
	TRACKING_TYPES_REQUEST, 
	OPERATIONAL_SITUATION_REQUEST, 
	DRIVERS_REQUEST, 
} from '@/store/filters/actions';

import {
  AvailableFilters
} from "@/models/filters/available-filters.interface";

// This must be a function so the state of the filters can be erased from one page to the next
export const AvailableFiltersDefinitions = () => {
	const definition = {};

	definition[ AvailableFilters.Date ] = {
		startDateSelected: '',
		endDateSelected: '',
	}

	definition[ AvailableFilters.Origin ] = {
		rancherSelected: '',
		farmSelected: '',
    rancherDispatch: TRAVEL_ORIGIN_RANCHER_REQUEST,
    farmDispatch: TRAVEL_ORIGIN_FARM_REQUEST,
		isLoading: false,
	}

	definition[ AvailableFilters.Destiny ] = {
		rancherSelected: '',
		farmSelected: '',
    rancherDispatch: TRAVEL_DESTINY_RANCHER_REQUEST,
    farmDispatch: TRAVEL_DESTINY_FARM_REQUEST,
		isLoading: false,
	}

	definition[ AvailableFilters.Tracking ] = {
		trackingSelected: '',
		trackingDispatch: TRACKING_TYPES_REQUEST,
		isLoading: false,
	}

  definition[ AvailableFilters.Order ] = {
		orderSelected: '',
	}

  definition[ AvailableFilters.Travel ] = {
		travelSelected: '',
	}

  definition[ AvailableFilters.Driver ] = {
		driverSelected: '',
		driverDispatch: DRIVERS_REQUEST,
		isLoading: false,
	}

  definition[ AvailableFilters.Truck ] = {
		truckSelected: '',
	}

  definition[ AvailableFilters.Cart ] = {
		cartSelected: '',
	}

	definition[ AvailableFilters.Situation ] = {
		situationSelected: '',
    situationDispatch: OPERATIONAL_SITUATION_REQUEST,
		isLoading: false,
	}

	return definition;
}