











import { Vue, Component, Prop } from "vue-property-decorator";

import StarIcon from "@/components/icons/Star.vue";
import StarFilledIcon from "@/components/icons/StarFilled.vue";

@Component({
  components: {
    StarIcon,
    StarFilledIcon
  },
})

export default class Rating extends Vue {
  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: 0 })
  public value!: number;

  /* Data */
  private rateStar: number = this.value;

  /* Methods */
  rate(star) {
    if (this.disabled) return;
    if (star <= 5 && star >= 0) {
      this.rateStar = this.rateStar === star ? star - 1 : star;
    }
    this.$emit('input', this.rateStar);
  }
}
