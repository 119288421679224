




































































import { Component, Vue } from "vue-property-decorator";
import {
  MglMap,
  MglMarker,
  MglGeojsonLayer,
  MglNavigationControl,
} from "vue-mapbox";

import Layout from '@/components/layouts/ModuleLayout/ModuleLayout.vue';
import Header from '@/components/widgets/organisms/Header/Header.vue'
import Filters from "./organisms/Filter/Filter.vue";
import ReportModal from "@/components/modals/rancher/ReportModal/ReportModal.vue";
import CarouselModal from "@/components/modals/CarouselModal/CarouselModal.vue";
import MapModal from "@/components/modals/rancher/MapModal/MapModal.vue";
import Container from "@/components/layouts/Container/Container.vue";

import TruckIcon from "@/components/icons/Truck.vue";
import MapThumbIcon from "@/components/icons/MapThumb.vue";
import SatelliteIcon from "@/components/icons/MapSatellite.vue";

import { AvailableFilters } from "@/models/filters/available-filters.interface";
import { MAP_STATUS } from "@/constants/mapStatus";
import { CONSTANTS } from '@/constants/constants';
import { formatDateHour } from "@/shared/util/formatDate";
import { SYSTEM_PERMISSIONS } from "@/constants/permissions";
import MAP_STYLE from "@/shared/util/mapStyle";
import "mapbox-gl/dist/mapbox-gl.css";

import { GET_ACTUAL_LOCALIZATION } from "@/store/travels/actions";

import { 
  GET_DOWNLOAD_IMAGE,
  SELECT_DETAIL_ORDER
} from "@/store/travels/actions";

@Component({
  components: {
    Layout,
    Header,
    Filters,
    ReportModal,
    MapModal,
    CarouselModal,
    Container,
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglNavigationControl,
    TruckIcon,
    SatelliteIcon,
    MapThumbIcon
  },
})

export default class RancherModuleMap extends Vue {
  /* Data */
  private availableFilters: any = AvailableFilters;

  private mapStyle: any = MAP_STYLE;

  private mapbox: any;

  private pointsCoordinates: any = [];

  private reqTime: number = 1;

  private busy: any = {
    image: false,
  };

  private initialPoint: any = {
    longitude: -55,
    latitude: -12,
  };

  private params: any = {
    idViagem: "",
    placaCavalo: "",
    motoristaId: "",
    pecuaristaOrigemId: "",
  };

  private timer: any;

  private selectedView: string = "default";

  private lineColor: string = "#4B4B4B";

  /* Computed */
  get permissions() {
    return this.$store.getters.getUserPermissions;
  }

  get systemPermissions() {
    return SYSTEM_PERMISSIONS.RANCHER.TRAVEL;
  }

  get mapStatus(){
    return MAP_STATUS
  }

  get accessToken() {
    return CONSTANTS.MAP_ACCESS_TOKEN;
  }

  /* LifeCycle */
  created() {
    this.$nextTick(() => {
      this.timer = setInterval(() => {
        this.getCoordinates(this.params);
      }, 3000);
    })
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }

  /* Methods */
  getPointClass(status) {
    return this.mapStatus[status]?.color || this.mapStatus['default']?.color;
  }

  getCoordinates(params) {
    this.$store
      .dispatch(GET_ACTUAL_LOCALIZATION, params)
      .then((response) => {
        this.reqTime++;
        this.pointsCoordinates = response.filter((i) => i.status !== "Trânsito para Finalização");
      })
      .catch((e) => {
        if (e.response.status === 401) {
          clearInterval(this.timer);
        }
        Vue.$toast.error(e);
      });
  }

  changeView(view) {
    this.selectedView = view;
    if (view == "satellite") {
      this.lineColor = "#FFF";
      this.mapStyle = "mapbox://styles/mapbox/satellite-streets-v11";
      return;
    }
    this.mapStyle = MAP_STYLE;
    this.lineColor = "#4B4B4B";
  }

  getTooltipLabel(point) {
    const label = `
      <div class="tooltip-content">
        <div class="title"><span>Viagem:</span> <span>${point.idViagem}</span></div>
        <div class="status ${this.getPointClass(point.status)}">${point.status}</div>
        <div class="body">
          <p><span><b>Placa:</b></span><span>${point?.placa || '-'}</span></p>
          <p><span><b>Motorista:</b></span><span>${point?.motorista || '-'}</span></p>
          <p><span><b>Pecuarista Origem:</b></span><span>${point?.pecuaristaOrigem || '-'}</span></p>
          <p><span><b>Ultima Atualização:</b></span><span>${formatDateHour(point?.dataAtualizacao) || '-'}</span></p>
        </div>
      </div>`;
      return label;
  }
  
  submitFilter(filterDefinition: any) {
    Object.keys(filterDefinition).forEach((index) => {
      const numberIndex = +index;
      switch (numberIndex) {
        case this.availableFilters.Travel:
          this.params.idViagem = filterDefinition[numberIndex].travelSelected|| "";
          break;
        case this.availableFilters.Truck:
          this.params.placaCavalo = filterDefinition[numberIndex].truckSelected || "";
          break;
        case this.availableFilters.Driver:
          this.params.motoristaId = filterDefinition[numberIndex].driverSelected?.value || "";
          break;
        case this.availableFilters.OriginRancher:
          this.params.pecuaristaOrigemId = filterDefinition[numberIndex].originRancherSelected?.value || "";
          break;
      }
    });
    this.getCoordinates(this.params);
  }

  clearFilters() {
    this.getCoordinates(this.params);
  }

  async onMapLoad(event) {
    const asyncActions = event.component.actions;
    await asyncActions.flyTo({
      center: [this.initialPoint.longitude, this.initialPoint.latitude],
      zoom: 3.5,
      speed: 1,
    });
  }

  downloadImage(params) {
    this.busy.image = true;
    this.$store
      .dispatch(GET_DOWNLOAD_IMAGE, params)
      .then(() => {
        this.$toast.success('Download realizado com sucesso!');
      })
      .catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.busy.image = false;
      })
  }

  openTerm(viagem) {
    this.$store.dispatch(SELECT_DETAIL_ORDER, viagem);
  }
}
