



































import { Component, Vue, Watch } from "vue-property-decorator";
import Filters from "../../organisms/Filter/Filter.vue";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Pagination from "@/components/widgets/organisms/Pagination/Pagination.vue";

import ContractModal from "../../organisms/Modals/Contract/Contract.vue";
import BooleanModal from "@/components/modals/BooleanModal/BooleanModal.vue";

import { formatDateHour, formatDateISO } from "@/shared/util/formatDate";
import formatMoney from "@/shared/util/formatMoney";
import formatNumber from "@/shared/util/formatNumber";
import { saveXLSX } from "@/helpers/ExportXLSX";
import { AvailableFilters } from "@/models/filters/available-filters.interface";

import {
  TRANSIT_LOAD_CONTRACTS_REQUEST,
  TRANSIT_SELECT_CONTRACT,
  TRANSIT_EXPORTXLSX,
  TRANSIT_DELETE_TRAVEL,
} from "@/store/TransitFacility/orders/actions";

@Component({
  components: {
    ContractModal,
    BooleanModal,
    Table,
    Pagination,
    Filters,
  },
})
export default class TransitContracts extends Vue {
  /* Data */
  private availableFilters: any = AvailableFilters;

  private isLoaded: boolean = true;

  private modal: any = {
    title: "",
    content: "",
    data: null,
    busy: false,
  };

  private rows: any = [];

  private totalPages: number = 0;

  private params: any = {
    page: 1,
    size: 16,
    dataInicio: "",
    dataFim: "",
    pecuaristaOrigemId: "",
    pecuaristaDestinoId: "",
    fazendaOrigemId: "",
    fazendaDestinoId: "",
    pedidoId: "",
    status: "",
  };

  private header: any = [];

  private fixedHeader: any = [
    { key: "idPropostaContrato", label: "Contrato Uboi" },
    { key: 'codigoInterno', label: 'Código Interno' },
    { key: "idPedido", label: "Pedido Uboi" },
    { key: "pecuaristaOrigem", label: "Pecuarista", thClass: "dark-brown" },
    { key: "fazendaOrigem", label: "Fazenda", thClass: "dark-brown" },
    { key: "pecuaristaDestino", label: "Pecuarista", thClass: "dark-brown" },
    { key: "fazendaDestino", label: "Fazenda", thClass: "dark-brown" },
    { key: "qtdAnimais", label: "Quantidade", thClass: "dark-brown" },
    { key: "categoriaAnimais", label: "Categoria", thClass: "dark-brown" },
    { key: "peso", label: "Peso Médio", thClass: "dark-brown" },
    { key: "kmEntrePontos", label: "Km Viagem" },
    { key: "valorCotacao", label: "Valor Total" },
    { key: "previsaoEmbarque", label: "Embarque" },
    { key: "previsaoDesembarque", label: "Desembarque" },
    { key: "status", label: "Status", thClass: "dark-header" },
    {
      key: "actions",
      label: "Ações",
      thClass: "gray-header",
      tdClass: "td-action",
    },
  ];

  /* Computed */
  get exportXLSX() {
    return this.$store.getters.hasContractExportXlsx;
  }

  /* Watcher */
  @Watch("exportXLSX")
  onExportChange(value) {
    if (value) this.exportXLS();
  }

  /* LifeCycle */
  mounted() {
    this.getOrders(this.params);
  }

  /* Methods */
  submitFilter(filterDefinition: any): void {
    Object.keys(filterDefinition).forEach((index) => {
      const numberIndex = +index;
      switch (numberIndex) {
        case this.availableFilters.Date:
          this.params.dataInicio = formatDateISO(
            filterDefinition[numberIndex].startDateSelected
          );
          this.params.dataFim = formatDateISO(
            filterDefinition[numberIndex].endDateSelected
          );
          break;
        case this.availableFilters.Origin:
          this.params.pecuaristaOrigemId =
            filterDefinition[numberIndex].rancherSelected?.value || "";
          this.params.fazendaOrigemId =
            filterDefinition[numberIndex].farmSelected?.value || "";
          break;
        case this.availableFilters.Destiny:
          this.params.pecuaristaDestinoId =
            filterDefinition[numberIndex].rancherSelected?.value || "";
          this.params.fazendaDestinoId =
            filterDefinition[numberIndex].farmSelected?.value || "";
          break;
        case this.availableFilters.Status:
          this.params.status =
            filterDefinition[index].statusSelected?.value || "";
          break;
        case this.availableFilters.Order:
          this.params.pedidoId = filterDefinition[index].orderSelected;
          break;
      }
    });
    this.params.page = 1;
    this.getOrders(this.params);
  }

  getOrders(params: any) {
    this.isLoaded = false;
    this.$store
      .dispatch(TRANSIT_LOAD_CONTRACTS_REQUEST, params)
      .then(({ totalCount, results }) => {
        if (results.length == 0)
          return this.$toast.error("Nenhum contrato encontrado");
        this.header = this.fixedHeader;
        this.totalPages = totalCount;
        this.rows = results.map((i) => {
          return {
            ...i,
            status: i.status,
            previsaoEmbarque: formatDateHour(i.previsaoEmbarque),
            previsaoDesembarque: formatDateHour(i.previsaoDesembarque),
            valorCotacao: formatMoney(i.valorCotacao),
            peso: formatNumber(i.peso),
            kmEntrePontos: formatNumber(i.kmEntrePontos),
            actions: {
              id: i.idPropostaContrato,
              buttons: ["detail"],
            },
          };
        });
      })
      .catch(() => {
        this.$toast.error("Erro ao carregar contratos");
      })
      .finally(() => {
        this.isLoaded = true;
      });
  }

  message(message, variant, reload = false) {
    if (reload) this.getOrders(this.params);
    if (message)
      this.$nextTick(() => {
        this.$toast.open({
          message,
          type: variant,
        });
      });
  }

  changePage(page) {
    this.params.page = page;
    this.getOrders(this.params);
  }

  changeSize(size) {
    this.params.size = size;
    this.params.page = 1;
    this.getOrders(this.params);
  }

  exportXLS() {
    const params = { ...this.params };
    params.size = this.totalPages;
    let body = [];
    const header = this.header.map((item) => {
      switch (item?.key) {
        case "pecuaristaOrigem":
          return { label: "Pecuarista Origem", value: item?.key };
        case "fazendaOrigem":
          return { label: "Fazenda Origem", value: item?.key };
        case "pecuaristaDestino":
          return { label: "Pecuarista Destino", value: item?.key };
        case "fazendaDestino":
          return { label: "Fazenda Destino", value: item?.key };
        default:
          return { label: item?.label || item?.key, value: item?.key };
      }
    });
    header.pop();
    this.$store
      .dispatch(TRANSIT_LOAD_CONTRACTS_REQUEST, params)
      .then(({ results }) => {
        if (results.length == 0) {
          return;
        }
        body = results.map((i) => {
          return {
            ...i,
            status: i.status,
            previsaoEmbarque: formatDateHour(i.previsaoEmbarque),
            previsaoDesembarque: formatDateHour(i.previsaoDesembarque),
          };
        });
      })
      .catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.$store.dispatch(TRANSIT_EXPORTXLSX, null);
        saveXLSX(header, body, "tabela", "Lista-contratos");
      });
  }

  openDetail(contract) {
    this.$store.dispatch(TRANSIT_SELECT_CONTRACT, contract.id);
  }

  onDeleteTravel(travel) {
    this.modal = {
      title: `Viagem - ${travel}`,
      content: `Deseja realmente excluir a viagem ${travel}?`,
      data: { id: travel },
      busy: false,
    };
    this.$bvModal.show("BooleanModal");
  }

  onAcceptDelete(travel) {
    this.modal.busy = true;
    this.$store
      .dispatch(TRANSIT_DELETE_TRAVEL, travel.id)
      .then(() => {
        this.$toast.success("Viagem deletada com sucesso");
      })
      .catch(() => {
        this.$toast.error("Erro ao deletar viagem");
      })
      .finally(() => {
        this.modal.busy = false;
        this.$bvModal.hide("BooleanModal");
      });
  }
}
