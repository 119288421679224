export const GET_UF_REQUEST = 'GET_UF_REQUEST';
export const GET_UF_SUCCESS = 'GET_UF_SUCCESS';
export const GET_UF_FAILED = 'GET_UF_FAILED';

export const GET_CITY_REQUEST = 'GET_CITY_REQUEST';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAILED = 'GET_CITY_FAILED';

export const GET_CEP_REQUEST = 'GET_CEP_REQUEST';
export const GET_CEP_SUCCESS = 'GET_CEP_SUCCESS';
export const GET_CEP_FAILED = 'GET_CEP_FAILED';
