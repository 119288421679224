





















import { Vue, Component, Prop } from "vue-property-decorator";
import EyeOpen from "@/components/icons/EyeOpen.vue";
import EyeClose from "@/components/icons/EyeClose.vue";

@Component({
  components: {
    EyeOpen,
    EyeClose
  }
})

export default class InputPassword extends Vue {
  /* Props */
  @Prop()
  public value!: string;

  @Prop({ default: 'password' })
  public name!: string;

  @Prop()
  public min!: number;

  @Prop()
  public max!: number;

  @Prop()
  public placeholder!: string;

  /* Data */
  private showPassword: boolean = false;

  /* Computed */
  get fieldType() {
		return this.showPassword ? 'text' : 'password';
	}

  /* Methods */
  togglePasswordType() {
    this.showPassword = !this.showPassword;
  }
}
