export const LOAD_ORDER_REQUEST = 'LOAD_ORDER_REQUEST';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const LOAD_ORDER_FAILED = 'LOAD_ORDER_FAILED';

export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST';
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
export const ORDER_DETAIL_FAILED = 'ORDER_DETAIL_FAILED';

export const GET_SKETCH_REQUEST = 'GET_SKETCH_REQUEST';
export const GET_SKETCH_SUCCESS = 'GET_SKETCH_SUCCESS';
export const GET_SKETCH_FAILED = 'GET_SKETCH_FAILED';

export const GET_ORIGIN_PARTNER_REQUEST = 'GET_ORIGIN_PARTNER_REQUEST';
export const GET_ORIGIN_PARTNER_SUCCESS = 'GET_ORIGIN_PARTNER_SUCCESS';
export const GET_ORIGIN_PARTNER_FAILED = 'GET_ORIGIN_PARTNER_FAILED';

export const GET_ORIGIN_FARM_REQUEST = 'GET_ORIGIN_FARM_REQUEST';
export const GET_ORIGIN_FARM_SUCCESS = 'GET_ORIGIN_FARM_SUCCESS';
export const GET_ORIGIN_FARM_FAILED = 'GET_ORIGIN_FARM_FAILED';

export const GET_DESTINY_PARTNER_REQUEST = 'GET_DESTINY_PARTNER_REQUEST';
export const GET_DESTINY_PARTNER_SUCCESS = 'GET_DESTINY_PARTNER_SUCCESS';
export const GET_DESTINY_PARTNER_FAILED = 'GET_DESTINY_PARTNER_FAILED';

export const GET_DESTINY_FARM_REQUEST = 'GET_DESTINY_FARM_REQUEST';
export const GET_DESTINY_FARM_SUCCESS = 'GET_DESTINY_FARM_SUCCESS';
export const GET_DESTINY_FARM_FAILED = 'GET_DESTINY_FARM_FAILED';

export const GET_DOWNLOAD_IMAGE = 'GET_DOWNLOAD_IMAGE';
export const GET_ORDER_RESUME = 'GET_ORDER_RESUME';
export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_PROPOSAL_TERM = 'GET_PROPOSAL_TERM';
export const GET_TERM = 'GET_TERM';

export const POST_RATE = 'POST_RATE';
export const POST_NEW_SKETCH_ORDER = 'POST_NEW_SKETCH_ORDER';
export const POST_NEW_ORDER = 'POST_NEW_ORDER';
export const POST_PROPOSAL_TERM = 'POST_PROPOSAL_TERM';

export const PUT_SKETCH_ORDER = 'PUT_SKETCH_ORDER';

export const DELETE_SKETCH = 'DELETE_SKETCH';

export const SELECT_RATE_ORDER = 'SELECT_RATE_ORDER';
export const SELECT_ACCEPTANCE_TERM = 'SELECT_ACCEPTANCE_TERM';
export const SELECT_CONTRACTS = 'SELECT_CONTRACTS';
export const SELECT_ORDER_DETAIL = 'SELECT_ORDER_DETAIL';
export const SELECT_SKETCH = 'SELECT_SKETCH';
export const SELECT_MAP = 'SELECT_MAP';











