


















































import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";

import { 
  SELECT_DOCUMENTS
} from "@/store/photos/actions";

@Component({
  components: {
    Modal,
    ButtonCustom
  },
})
export default class CarouselModal extends Vue {
  /* Props */
  @Prop({ default: false })
  public canDownload!: boolean;

   @Prop({ default: false })
  public busyDownload!: boolean;

  /* Data */
  private sliding: boolean = false;

  private slide: number = 0;

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedDocuments;
  }

  get selectedDocuments() {
    return this.$store.getters.getDocuments;
  }

  get documents() {
    return this.selectedDocuments?.docs || [];
  }

  get docTitle() {
    return this.selectedDocuments?.type || "Fotos";
  }

  /* Methods */
   resetInfo() {
    this.$store.dispatch(SELECT_DOCUMENTS, null);
  }

  onSlideStart() {
    this.sliding = true
  }

  onSlideEnd() {
    this.sliding = false
  }

  downloadImage() {
    const params = {
      formId: this.documents[this.slide].formId,
      formType: this.documents[this.slide].tipoForm,
      ImageType: this.documents[this.slide].idDocumento,
      name: this.documents[this.slide].descricao
    }
    this.$emit('download', params);
  }

  close() {
    this.$bvModal.hide("CarouselModal");
  }
}
