
























import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";

@Component({
  components: {
    Modal,
    ButtonCustom,
  },
})

export default class BooleanModal extends Vue {
  /* Props */
  @Prop({ required: true })
  public title!: string;

  @Prop({ required: false })
  public subtitle!: string;

  @Prop({ required: true })
  public content!: string;

  @Prop({ default: false })
  public busy!: boolean;

  @Prop({ default: null })
  public data!: any;

  @Prop({ default: "BooleanModal" })
  public id!: string;

  /* Methods */
  close() {
    this.$bvModal.hide(this.id);
  }
}
