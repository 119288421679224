














































































































































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import ButtonPictures from "@/components/widgets/atoms/ButtonPictures/ButtonPictures.vue";

import getMaskDefinition from "@/shared/util/masks";
import DatePicker from "@/components/widgets/atoms/VueDatePicker/VueDatePicker.vue";
import { formatDateISO } from "@/shared/util/formatDate";

import {
  TRANSIT_REGISTER_POST_DRIVER,
  SELECT_REGISTER_DRIVER,
  TRANSIT_REGISTER_PUT_DRIVER,
  TRANSIT_REGISTER_GET_SKETCH_DRIVER,
} from "@/store/TransitFacility/registers/actions";

@Component({
  components: {
    Modal,
    ButtonCustom,
    ButtonPictures,
    DatePicker
  },
})

export default class NewDriverModal extends Vue {
  private identificationOptions: any =[
    { value: 'cpf', text: 'CPF' },
    { value: 'cnpj', text: 'CNPJ' },
  ]

  private categoryOptions: any =[
    { value: 'c', text: 'C' },
    { value: 'd', text: 'D' },
    { value: 'e', text: 'E' },
  ]

  private selectedOptions: any = {
    name: "",
    email: "",
    identification: "cpf",
    identificationNumber: "",
    dateNasc: "",
    numberCNH: "",
    validity: "",
    category: "c",
    telephone: "",
    selectedSelfie: null,
    selectedFrontPhotoCNH: null,
    selectedBackPhotoCNH: null,
    selectedAddress: null,
  };

  private isLoading: boolean = false;

  private busy: boolean = false;

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedRegisterDriver;
  }
  get selectedDriver() {
    return this.$store.getters.getRegisterDriver;
  }

  /* Methods */
  maskDefinition(value) {
    return getMaskDefinition(value);
  }

  onErasePhoto(type){
    this.selectedOptions[type] = null;
  }

  onPhotoSelected(event, type){
      this.selectedOptions[type] = event.target.files[0]
  }

  resetInfo(){
    this.selectedOptions = {
      name: "",
      email: "",
      identification: "cpf",
      identificationNumber: "",
      dateNasc: "",
      numberCNH: "",
      validity: "",
      category: "c",
      telephone: "",
      selectedSelfie: null,
      selectedFrontPhotoCNH: null,
      selectedBackPhotoCNH: null,
      selectedAddress: null,
    };
    this.$store.dispatch(SELECT_REGISTER_DRIVER, null);
  }

  initInfo(){
    if(this.showModal){
      this.isLoading = true;
      this.$store.dispatch(TRANSIT_REGISTER_GET_SKETCH_DRIVER, this.selectedDriver)
      .then((driver) => {
        this.selectedOptions = {
          name: driver.nome || "",
          email: driver.email || "",
          identification: driver?.CodCgc ? "cnpj" : "cpf",
          identificationNumber: driver?.CodCgc || driver?.codCpf || "",
          dateNasc: driver?.dataNascimento || "",
          numberCNH: driver.numeroCNH || "",
          validity: driver.validadeCNH || "",
          category: driver.categoriaCNH || "",
          telephone: driver.telefone || "",
          selectedSelfie: null,
          selectedFrontPhotoCNH: null,
          selectedBackPhotoCNH: null,
          selectedAddress: null,
        };
        driver.fotoDocumentos.forEach((foto) => {
          if(foto.descricao == "FOTO_PERFIL"){
            this.selectedOptions.selectedSelfie = foto.urlDocumento;
          }
          if(foto.descricao == "ENDERECO"){
            this.selectedOptions.selectedAddress = foto.urlDocumento;
          }
          if(foto.descricao == "VERSO_CNH"){
            this.selectedOptions.selectedBackPhotoCNH = foto.urlDocumento;
          }
          if(foto.descricao == "FRENTE_CNH"){
            this.selectedOptions.selectedFrontPhotoCNH = foto.urlDocumento;
          }
        });
      })
      .catch(() => {
        this.$toast.error("Erro ao carregar dados do motorista");
      }).finally(() => {
        this.isLoading = false;
      })
    }
  }

  createNewDriver(){
    this.busy = true;
    const fd = new FormData();

    fd.append('Nome', this.selectedOptions.name)
    fd.append('Email', this.selectedOptions.email)
    fd.append('NumeroCNH', this.selectedOptions.numberCNH)
    fd.append('ValidadeCNH', formatDateISO(this.selectedOptions.validity))
    fd.append('CategoriaCNH', this.selectedOptions.category)
    fd.append(this.selectedOptions.identification == "cpf" ? "CodCpf" : "CodCgc", this.selectedOptions.identificationNumber)
    fd.append('DataNascimento', formatDateISO(this.selectedOptions.dateNasc))
    fd.append('Telefone', this.selectedOptions.telephone)
    fd.append('FotoPerfil', this.selectedOptions.selectedSelfie)
    fd.append('FotoFrenteCNH', this.selectedOptions.selectedFrontPhotoCNH)
    fd.append('FotoVersoCNH', this.selectedOptions.selectedBackPhotoCNH)
    fd.append('ComprovanteEndereco', this.selectedOptions.selectedAddress)

    if (this.showModal) {
      fd.append('IdMotorista', this.selectedDriver)
      this.$store
        .dispatch(TRANSIT_REGISTER_PUT_DRIVER, fd)
        .then(() => {
          this.$emit("message", "Motorista atualizado com sucesso", "success", true);
        })
        .catch(() => {
          this.$emit("message", "Erro ao atualizar motorista", "error");
        })
        .finally(() => {
          this.busy = false;
          this.close();
        });
      return;
    }

    this.$store
    .dispatch(TRANSIT_REGISTER_POST_DRIVER, fd)
    .then(() => {
      this.$emit("message", "Motorista criado com sucesso", "success", true);
    })
    .catch(() => {
      this.$emit("message", "Erro ao criar novo motorista", "error");
    }).finally(() => {
      this.busy = false;
      this.close()
    })
  }

  close() {
    this.$bvModal.hide("NewDriverModal");
  }
}
