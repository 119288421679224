/* Imports */
import PermissionAffiliateState from '@/models/permission/affiliate-state/affiliate-state.interface';
import api from '@/shared/util/api';
const { http } = api.getInstance();

/* Actions */
import {
  GET_ALL_AFFILIATES_REQUEST,
  GET_ALL_AFFILIATES_SUCCESS,
  GET_ALL_AFFILIATES_FAILED,
  GET_AFFILIATE_REQUEST,
  GET_AFFILIATE_SUCCESS,
  GET_AFFILIATE_FAILED,
  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILED,
  POST_AFFILIATE,
  PUT_AFFILIATE,
  DELETE_AFFILIATE,
  SELECT_AFFILIATE
} from './actions';


/* Initial AuthState object */
const state: PermissionAffiliateState = {
	allAffiliatesStatus: 'loading',
  affiliateStatus: 'loading',
  citiesStatus: '',
  selectedAffiliate: null
};

/* Vuex Auth Getters */
const getters = {
  isAllAffiliatesLoading: (state: PermissionAffiliateState): boolean => state.allAffiliatesStatus === 'loading',
  isAffiliatesLoading: (state: PermissionAffiliateState): boolean => state.affiliateStatus === 'loading',
  isCitiesLoading: (state: PermissionAffiliateState): boolean => state.citiesStatus === 'loading',
  getSelectedAffiliate: (state: PermissionAffiliateState) => state.selectedAffiliate,
  hasSelectedAffiliate: (state: PermissionAffiliateState): boolean => state.selectedAffiliate !== null,
};

/* Vuex Auth Mutations */
const mutations = {
	[GET_ALL_AFFILIATES_REQUEST]: (state: PermissionAffiliateState) => { state.allAffiliatesStatus = 'loading' },
	[GET_ALL_AFFILIATES_SUCCESS]: (state: PermissionAffiliateState) => { state.allAffiliatesStatus = 'success' },
	[GET_ALL_AFFILIATES_FAILED]: (state: PermissionAffiliateState) => { state.allAffiliatesStatus = 'error' },
  [GET_AFFILIATE_REQUEST]: (state: PermissionAffiliateState) => { state.affiliateStatus = 'loading' },
	[GET_AFFILIATE_SUCCESS]: (state: PermissionAffiliateState) => { state.affiliateStatus = 'success' },
	[GET_AFFILIATE_FAILED]: (state: PermissionAffiliateState) => { state.affiliateStatus = 'error' },
  [GET_CITIES_REQUEST]: (state: PermissionAffiliateState) => { state.citiesStatus = 'loading' },
	[GET_CITIES_SUCCESS]: (state: PermissionAffiliateState) => { state.citiesStatus = 'success' },
	[GET_CITIES_FAILED]: (state: PermissionAffiliateState) => { state.citiesStatus = 'error' },
  [SELECT_AFFILIATE]: (state: PermissionAffiliateState, affiliateId) => { state.selectedAffiliate = affiliateId },
};

/* Vuex Auth Actions */
const actions = {
	[GET_ALL_AFFILIATES_REQUEST]: ({ commit }: any, params: any = {}) => {
		return new Promise((resolve, reject) => {
      const queryParams = [`page=${params?.page || 1}`, `size=${params?.size || 20}`];
      commit(GET_ALL_AFFILIATES_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/filial?${queryParams.join('&')}`,
      })
      .then(({ data }) => {
				commit(GET_ALL_AFFILIATES_SUCCESS);
        resolve(data);
      })
      .catch((error) => {
        commit(GET_ALL_AFFILIATES_FAILED);
        reject(error);
      });
		})
	},
  [GET_AFFILIATE_REQUEST]: ({ commit }: any, affiliateId: number) => {
		return new Promise((resolve, reject) => {
      commit(GET_AFFILIATE_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/filial/${affiliateId}`,
      })
      .then(({ data }) => {
				commit(GET_AFFILIATE_SUCCESS);
        resolve(data);
      })
      .catch((error) => {
        commit(GET_AFFILIATE_FAILED);
        reject(error);
      });
		})
	},
  [GET_CITIES_REQUEST]: ({ commit }: any, params: any = {}) => {
		return new Promise((resolve, reject) => {
      const queryParams = [`page=${params?.page || 1}`, `size=${params?.size || 1000}`];
      if (params.search) queryParams.push(`name=${params.search}`);
      if (params.state) queryParams.push(`state=${params.state}`);
      commit(GET_CITIES_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/filial/busca/municipio?${queryParams.join('&')}`,
      })
      .then(({ data }) => {
				commit(GET_CITIES_SUCCESS);
        resolve(data);
      })
      .catch((error) => {
        commit(GET_CITIES_FAILED);
        reject(error);
      });
		})
	},
  [POST_AFFILIATE]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
      http({
        method: 'post',
        url: `/uboiweb/jbspermissao/filial`,
        data: { ...requestData },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
		})
	},
  [PUT_AFFILIATE]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
      http({
        method: 'put',
        url: `/uboiweb/jbspermissao/filial`,
        data: { ...requestData },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
		})
	},
  [DELETE_AFFILIATE]: ({ commit }: any, affiliateId: number) => {
		return new Promise((resolve, reject) => {
      http({
        method: 'delete',
        url: `/uboiweb/jbspermissao/filial/${affiliateId}`,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
		})
	},
  [SELECT_AFFILIATE]: ({ commit }, affiliateId) => {
    commit(SELECT_AFFILIATE, affiliateId);
  },
};

export default {
	state,
	getters,
	actions,
	mutations,
};