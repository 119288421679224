




















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ArrowIcon from "@/components/icons/Arrow.vue";
import SearchIcon from "@/components/icons/Search.vue";

@Component({
  components: {
    ArrowIcon,
    SearchIcon
  },
})
export default class Pagination extends Vue {
  /* Props */
  @Prop({ default: 1 })
  public current!: number;

  @Prop({ default: 0 })
  public total!: number;

  @Prop({ default: 9 })
  public perPage!: number;

  @Prop({ default: 3 })
  public pageRange!: number;

  @Prop({ default: true })
  public hasResult!: boolean;

  /* Dada */
  private pagination: number | null = null;

  private pageSize: number | null = null;

  /* Watch */
  @Watch("perPage") 
  onPerPageChange(newValue) {
    this.pageSize = newValue;
  }

  @Watch("current") 
  onCurrentChange() {
    this.pagination = null;
  }

  @Watch("pagination") 
  onPaginationChange(newValue, oldValue) {
    if (newValue >= 0 && newValue <= this.totalPages) {
      this.pagination = newValue === 0 ? null : newValue;
      return;
    }
    this.pagination = oldValue;
  }

  @Watch("pageSize") 
  onSizeChange(newValue, oldValue) {
    if (newValue >= 0 && newValue <= 25) {
      this.pageSize = newValue === 0 ? null : newValue;
      return;
    }
    this.pageSize = oldValue;
  }

  /* Computed */
  get pages() {
    const pages: any = [];
    for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
      pages.push(i);
    }
    return pages;
  }

  get rangeStart() {
    const start = this.current - this.pageRange;
    return start > 0 ? start : 1;
  }

  get rangeEnd() {
    const end = this.current + this.pageRange;
    return end < this.totalPages ? end : this.totalPages;
  }

  get totalPages() {
    return Math.ceil(this.total / this.perPage);
  }

  get nextPage() {
    return this.current + 1;
  }

  get prevPage() {
    return this.current - 1;
  }

  get hasFirst() {
    return this.rangeStart !== 1;
  }

  get hasLast() {
    return this.rangeEnd < this.totalPages;
  }

  get hasPrev() {
    return this.current > 1;
  }

  get hasNext() {
    return this.current < this.totalPages;
  }

  /* Methods */
  changePage(page) {
    if (page > 0 && page <= this.totalPages) {
      this.$emit("page-changed", page);
    }
  }

  changeSize(size) {
    if (size > 0 && size <= 25) {
      this.$emit("size-changed", size);
    }
  }
}
