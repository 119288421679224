/* Auth Actions for Store */
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const AUTH_LOGIN_TEMP = 'AUTH_LOGIN_TEMP';
export const AUTH_MFA_LOGIN_REQUEST = 'AUTH_MFA_LOGIN_REQUEST';
export const AUTH_MFA_LOGIN_SUCCESS = 'AUTH_MFA_LOGIN_SUCCESS';
export const AUTH_MFA_LOGIN_FAILED = 'AUTH_MFA_LOGIN_FAILED';
export const RESEND_MFA_TOKEN_REQUEST = 'RESEND_MFA_TOKEN_REQUEST';
export const RESEND_MFA_TOKEN_SUCCESS = 'RESEND_MFA_TOKEN_SUCCESS';
export const RESEND_MFA_TOKEN_FAILED = 'RESEND_MFA_TOKEN_FAILED';
export const AUTH_VERIFY_TOKEN = 'AUTH_VERIFY_TOKEN';
export const AUTH_SET_LOGIN_TYPE = 'AUTH_SET_LOGIN_TYPE';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
