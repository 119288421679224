




































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import LiquorTree from 'liquor-tree';
import PadlockIcon from "@/components/icons/Padlock.vue";
import LessIcon from "@/components/icons/Less.vue";
import PlusIcon from "@/components/icons/Plus.vue";

@Component({
  components: {
    LiquorTree,
    PadlockIcon,
    LessIcon,
    PlusIcon
  }
})

export default class Tree extends Vue {
  /* Props */
  @Prop({ default: () => [], required: false })
  public data!: Array<any>;

  @Prop()
  public value!: any;

  /* Data */
  private treeOptions: any = {
    checkbox: true,
    checkOnSelect: true,
  }

  private treeModel: any = this.value;

  @Watch('treeModel')
  onModelChange(value) {
    this.$emit('input', value);
  }

  get treeData() {
    return this.data.map(m => {
      const submodule = m.children.map(sm => {
        const items = sm.children.map(i => ({
          text: i.text,
          data: { ...i.data },
          state: { checked: i.active }
        }))
        return {
          text: sm.text,
          data: { ...sm.data, type: "title" },
          children: items
        }
      })
      return {
        text: m.text,
        data: { ...m.data, type: "root" },
        children: submodule
      }
    })
  }
}
