import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueToast from 'vue-toast-notification';
import VueTheMask from 'vue-the-mask'
import VTooltip from 'v-tooltip';
import DatePicker from 'vue2-datepicker';
import Money from 'v-money'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue2-datepicker/locale/pt-br';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VTooltip);
Vue.use(VueToast,  {
  position: 'top-right'
});
Vue.use(VueTheMask)
Vue.use(DatePicker)
Vue.use(Money, {precision: 4})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
