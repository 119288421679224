








































































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";

import { formatDateHour } from "@/shared/util/formatDate";

import {
	SELECT_TRANSIT_OCCURRENCE,
	TRANSIT_GET_KM_OCCURRENCE,
	TRANSIT_GET_ANIMAL_OCCURRENCE,
	TRANSIT_GET_TRUCK_OCCURRENCE,
	TRANSIT_POST_KM_OBSERVATION,
	TRANSIT_POST_ANIMAL_BOARDING_OBSERVATION,
	TRANSIT_POST_ANIMAL_TRAVEL_OBSERVATION,
	TRANSIT_POST_ANIMAL_LANDING_OBSERVATION,
	TRANSIT_POST_TRUCK_OBSERVATION,
} from "@/store/TransitFacility/travels/actions";

@Component({
	components: {
		Modal,
		Table,
		ButtonCustom,
	},
})

export default class OccurrencesModal extends Vue {
  /* Data */
  private KmRows: any = [];

  private animalHeader: any = [
  	{ key: "tipo", label: "Tipo" },
  	{ key: "faseTransporte", label: "Fase do transporte" },
  	{ key: "horarioRegistro", label: "Horário Registo" },
  	{ key: "problema", label: "Problema" },
  	{ key: "descricao", label: "Descrição" },
  	{ key: "tratativa", label: "Tratativa", thClass: "dark-header deal-th" },
  ];

  private animalRows: any = []

  private truckHeader: any = [
  	{ key: "tipo", label: "Tipo" },
  	{ key: "faseTransporte", label: "Fase do transporte" },
  	{ key: "horarioRegistro", label: "Horário Registo" },
  	{ key: "descricao", label: "Descrição" },
  	{ key: "tratativa", label: "Tratativa", thClass: "dark-header deal-th" },
  ];

  private truckRows: any = [];

  private busy: boolean = false;

  private isLoadingKm: boolean = false;

  private isLoadingAnimal: boolean = false;

  private isLoadingTruck: boolean = false;

  /* Computed */
  get showModal() {
  	return this.$store.getters.hasSelectedTransitOccurence;
  }
  get selectedOccurence() {
  	return this.$store.getters.getSelectedTransitOccurence;
  }

  /* Methods */
  resetInfo() {
  	this.$store.dispatch(SELECT_TRANSIT_OCCURRENCE, null);
  }

  initInfo() {
  	this.isLoadingKm = true;
  	this.isLoadingAnimal = true;
  	this.isLoadingTruck = true;
  	this.$store
  		.dispatch(TRANSIT_GET_KM_OCCURRENCE, this.selectedOccurence)
  		.then((response) => {
  			const { itens, tratativa} = response;
  			this.KmRows[0] = {
  				titulo: "Quilometragem Programada",
  				inicioEmbarque: itens[0].kmProgramado,
  				embarqueDesembarque: itens[1].kmProgramado,
  				desembarqueEncerramento: itens[2].kmProgramado,
  				isTratavida: true,
  				tratativa: tratativa || ""
  			};
  			this.KmRows[1] = {
  				titulo: "Quilometragem Realizada",
  				inicioEmbarque: itens[0].kmRealizado,
  				embarqueDesembarque: itens[1].kmRealizado,
  				desembarqueEncerramento: itens[2].kmRealizado,
  			};
  			this.KmRows[2] = {
  				titulo: "Diferença",
  				inicioEmbarque: itens[0].kmDiferenca,
  				embarqueDesembarque: itens[1].kmDiferenca,
  				desembarqueEncerramento: itens[2].kmDiferenca,
  			};
  		})
  		.catch((e) => {
  			this.$toast.error(e);
  		})
  		.finally(() => {
  			this.isLoadingKm = false;
  		});

  	this.$store
  		.dispatch(TRANSIT_GET_ANIMAL_OCCURRENCE, this.selectedOccurence)
  		.then((response) => {
  			this.animalRows = response.map((occurence) => {
  				return {
  					tipo: occurence.tipo,
  					faseTransporte: occurence.faseTransporte,
  					horarioRegistro: formatDateHour(occurence?.data || 0),
  					problema: occurence.problema,
  					descricao: occurence.descricao,
  					tratativa: {
  						isInput: true,
  						value: occurence?.tratativa || "",
  						fase: occurence.faseTransporte,
  						id:
              occurence?.idOcorrenciaEmbarque ||
              occurence?.idOcorrenciaDesembarque ||
              occurence?.idOcorrenciaParadaAnimal,
  					},
  				};
  			});
  		})
  		.catch((e) => {
  			this.$toast.error(e);
  		})
  		.finally(() => {
  			this.isLoadingAnimal = false;
  		});

  	this.$store
  		.dispatch(TRANSIT_GET_TRUCK_OCCURRENCE, this.selectedOccurence)
  		.then((response) => {
  			this.truckRows = response.map((item) => {
  				return {
  					tipo: item.tipo,
  					faseTransporte: item.faseTransporte,
  					horarioRegistro: formatDateHour(item?.data || 0),
  					descricao: item.descricao,
  					tratativa: {
  						isInput: true,
  						value: item?.tratativa || "",
  						id: item.idOcorrencia,
  					},
  				};
  			});
  		})
  		.catch((e) => {
  			this.$toast.error(e);
  		})
  		.finally(() => {
  			this.isLoadingTruck = false;
  		});
  }

  saveOccurrences() {
  	this.busy = true;
  	const promiseList: any = [];
  	if (this.KmRows[0]?.tratativa) {
  		promiseList.push(this.postKMObservations());
  	}

  	this.animalRows.forEach((row) => {
  		if (row.tratativa.value) {
  			if (row.tratativa.fase == "Embarque") {
  				promiseList.push(this.postAnimalBoarding(row));
  			}
  			if (row.tratativa.fase == "Transporte") {
  				promiseList.push(this.postAnimalTravel(row));
  			}
  			if (row.tratativa.fase == "Desembarque") {
  				promiseList.push(this.postAnimalLanding(row));
  			}
  		}
  	});
    
  	const auxTruckPromises: Array<Promise<any>>  = [];
  	this.truckRows.forEach((row, index) => {
  		if (row.tratativa.value) {
  			auxTruckPromises[index] = this.postTruckObservation(row);
  			promiseList.push(auxTruckPromises[index]);
  		}
  	});

  	Promise.all(promiseList)
  		.then(() => {
  			this.$emit("message", "Tratativas enviadas", "success");
  		})
  		.catch(() => {
  			this.$emit("message", "Erro ao enviar tratativas", "error");
  		})
  		.finally(() => {
  			this.busy = false;
  			this.close();
  		})
  }

  postKMObservations(){
  	return new Promise<void>((resolve, reject) => {
  		this.$store
  			.dispatch(TRANSIT_POST_KM_OBSERVATION, {
  				idViagem: this.selectedOccurence,
  				tratativa: this.KmRows[0]?.tratativa,
  			})
  			.then(() => {
  				resolve()
  			})
  			.catch((e) => {
  				this.$toast.error(e);
  				reject()
  			});
  	})
  }

  postAnimalBoarding(row){
  	return new Promise<void>((resolve, reject) => {
  		this.$store
  			.dispatch(TRANSIT_POST_ANIMAL_BOARDING_OBSERVATION, {
  				idOcorrenciaEmbarque: row.tratativa.id,
  				tratativa: row.tratativa.value,
  			})
  			.then(() => {
  				resolve()
  			})
  			.catch((e) => {
  				this.$toast.error(e);
  				reject()
  			});
  	})
  }
  
  postAnimalTravel(row){
  	return new Promise<void>((resolve, reject) => {
  		this.$store
  			.dispatch(TRANSIT_POST_ANIMAL_TRAVEL_OBSERVATION, {
  				idOcorrenciaParadaAnimal: row.tratativa.id,
  				tratativa: row.tratativa.value,
  			})
  			.then(() => {
  				resolve()
  			})
  			.catch((e) => {
  				this.$toast.error(e);
  				reject()
  			});
  	})
  }

  postAnimalLanding(row){
  	return new Promise<void>((resolve, reject) => {
  		this.$store
  			.dispatch(TRANSIT_POST_ANIMAL_LANDING_OBSERVATION, {
  				idOcorrenciaDesembarque: row.tratativa.id,
  				tratativa: row.tratativa.value,
  			})
  			.then(() => {
  				resolve()
  			})
  			.catch((e) => {
  				this.$toast.error(e);
  				reject()
  			});
  	})
  }

  postTruckObservation(row){
  	return new Promise<void>((resolve, reject) => {
  		this.$store
  			.dispatch(TRANSIT_POST_TRUCK_OBSERVATION, {
  				idOcorrencia: row.tratativa.id,
  				tratativa: row.tratativa.value,
  			})
  			.then(() => {
  				resolve()
  			})
  			.catch((e) => {
  				this.$toast.error(e);
  				reject()
  			});
  	})
  }

  close() {
  	this.$bvModal.hide("OccurrencesModal");
  }
}
