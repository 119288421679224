




























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Disclosure from "@/components/widgets/molecules/Disclosure/Disclosure.vue";
import FormInput from "@/components/widgets/molecules/FormInput/FormInput.vue";
import FormDate from "@/components/widgets/molecules/FormDate/FormDate.vue";
import FormSelect from "@/components/widgets/molecules/FormSelect/FormSelect.vue";
import Button from "@/components/widgets/atoms/Button/Button.vue";
import PlusIcon from "@/components/icons/Plus.vue";

import { 
  TRANSIT_TRAVEL_GET_DRIVERS,
  TRANSIT_GET_TRUCKS,
  TRANSIT_GET_CARTS,
} from "@/store/TransitFacility/travels/actions";

@Component({
  components: {
    Disclosure,
    FormInput,
    FormDate,
    FormSelect,
    Button,
    PlusIcon
  },
})

export default class DisclosureTransport extends Vue {
  /* Props */
  @Prop({ default: {} })
  public fields!: any;

  /* Data */
  private selectOptions: any = {
    drivers: [],
    trucks: [],
    carts: []
  };

  /* Computed */
  get availableCarts() {
    const carts = this.fields.carts
      .map((selectCart) => {
        return selectCart.cart?.value;
      })
      .filter(Boolean);
    return this.selectOptions.carts.filter((cart) => {
      return !carts.includes(cart.value);
    });
  }

  get validation() {
    return (
      !this.fields.carts.every((selectCart) => selectCart.cart) ||
      !(this.selectOptions.carts.length > this.fields.carts.length)
    );
  }

  /* Watcher */
  @Watch('fields.driver')
  onDriverChange(driver) {
    if (driver && driver.idTransportadora) {
      this.fields.cpf = driver.codCpf || driver.codCgc;
      this.fields.transitFacility = driver.idTransportadora;
    }
  }

  /* Lifecycle */
  mounted() {
    this.getDrivers();
    this.getTrucks();
    this.getCarts();
  }

  /* Methods */
  getDrivers(search = null) {
    this.$store
      .dispatch(TRANSIT_TRAVEL_GET_DRIVERS, {page: 1, size: 20, search})
      .then(({results}) => {
        this.selectOptions.drivers = results.map(driver => ({
          ...driver,
          value: driver.idParceiro,
          text: driver.nome
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      })
  }

  getTrucks(search = null) {
    this.$store
      .dispatch(TRANSIT_GET_TRUCKS, { page: 1, size: 20, search })
      .then(({ results }) => {
        this.selectOptions.trucks = results.map((truck) => ({
          value: truck.idCaminhao,
          text: truck.placa,
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  getCarts(search = null) {
    this.$store
      .dispatch(TRANSIT_GET_CARTS, { page: 1, size: 20, search })
      .then(({ results }) => {
        this.selectOptions.carts = results.map((cart) => ({
          value: cart.idCarreta,
          text: cart.placa,
        }));
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  addCart() {
    this.fields.carts.push({ cart: null });
  }

  removeCart(index) {
    this.fields.carts.splice(index, 1);
  }

  clearCart() {
    this.fields.carts[0].cart = null;
  }
}
