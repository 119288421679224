





























































import { Vue, Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/widgets/molecules/Modal/Modal.vue';
import Card from '@/components/widgets/molecules/Card/Card.vue';
import FormInput from '@/components/widgets/molecules/FormInput/FormInput.vue';
import FormDate from '@/components/widgets/molecules/FormDate/FormDate.vue';
import FormSelect from '@/components/widgets/molecules/FormSelect/FormSelect.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';
import CardCategory from '../CardCategory/CardCategory.vue';

import { formatDateISO } from '@/shared/util/formatDate';
import hasDuplicateValues from '@/shared/util/hasDuplicateValues';

import { TRANSIT_POST_TRAVEL_REQUEST } from '@/store/TransitFacility/orders/actions';

@Component({
	components: {
		Modal,
		Card,
		FormInput,
		FormDate,
		FormSelect,
		CardCategory,
		ButtonCustom,
	},
})
export default class TravelForm extends Vue {
	/* Props */
	@Prop({ required: true })
	public animalsRows!: Array<any>;

	@Prop({ required: true })
	public animals!: Array<any>;

	@Prop({ default: () => [] })
	public truckTypes!: Array<any>;

	@Prop({ type: String })
	public shippingForecast!: string;

	@Prop({ type: String })
	public landingForecast!: string;

	/* Data */
	private form: any = {
		truckType: null,
		dateInit: null,
		dateEnd: null,
		freight: 0,
		extra: 0,
		categoryAnimals: [],
		multiplier: null,
	};

	private buttonBusy: any = {
		saveButton: false,
	};

	/* Computed */
	get selectedOrder() {
		return this.$store.getters.getSelectedTransitContract;
	}

	get isLoading() {
		return this.$store.getters.isTransitPostTravelLoading;
	}

	/* Lifecycle */
	mounted() {
		this.form.dateInit = this.shippingForecast;
		this.form.dateEnd = this.landingForecast;
	}

	/* Methods */
	postTravel(params) {
		return new Promise<void>((resolve, reject) => {
			this.$store
				.dispatch(TRANSIT_POST_TRAVEL_REQUEST, {
					pedidoId: this.selectedOrder,
					requestData: params,
				})
				.then(() => {
					resolve();
				})
				.catch(e => {
					this.$toast.error(e);
					reject();
				});
		});
	}

	onClear() {
		this.form = {
			truckType: null,
			dateInit: null,
			dateEnd: null,
			freight: 0,
			extra: 0,
			categoryAnimals: [],
			multiplier: null,
		};
	}

	onSave() {
		const animalsInfo = {
			bois: { qty: 0, size: 0 },
			vacas: { qty: 0, size: 0 },
			novilhas: { qty: 0, size: 0 },
			bezerros: { qty: 0, size: 0 },
		};

		const categories = this.form.categoryAnimals
			.map(category => {
				const animal = category.animal?.value;
				if (!animal) return null;
				animalsInfo[animal].qty = category.quantity;
				animalsInfo[animal].size =
					parseFloat(this.animalsRows[1][animal].split('.').join('')) * category.quantity;
				return animal[0].toUpperCase() + animal.slice(1);
			})
			.filter(Boolean);

		if (hasDuplicateValues(categories)) {
			return this.$toast.error('Viagem com categorias duplicadas');
		}
		const params = {
			tipoCaminhao: this.form.truckType?.value || null,
			categoriaAnimal: categories.length > 0 ? categories.join(', ') : null,
			qtdBoi: animalsInfo.bois.qty,
			qtdVaca: animalsInfo.vacas.qty,
			qtdNovilho: animalsInfo.novilhas.qty,
			qtdBezerro: animalsInfo.bezerros.qty,
			pesoMedioBoi: animalsInfo.bois.size,
			pesoMedioVaca: animalsInfo.vacas.size,
			pesoMedioNovilho: animalsInfo.novilhas.size,
			pesoMedioBezerro: animalsInfo.bezerros.size,
			previsaoInicioViagem: formatDateISO(this.form.dateInit),
			previsaoFimViagem: formatDateISO(this.form.dateEnd),
			valorFrete: this.form.freight,
			valorExtra: this.form.extra,
		};

		if (Object.keys(params).some(i => !(params[i] || params[i] === 0))) {
			return this.$toast.error('Viagem com valores incompletos');
		}

		const cantMultiplier = this.form.categoryAnimals.some(category => {
			const animal = category.animal?.value;
			const multiplier: number = this.form.multiplier || 1;
			return multiplier * category.quantity > this.animalsRows[0][animal];
		});

		if (cantMultiplier) {
			return this.$toast.error('Valores à multiplicar não correspondem');
		}

		this.buttonBusy.saveButton = true;

		const promiseList: any = [];

		if (this.form.multiplier > 0) {
			for (let count = 0; count < this.form.multiplier; count++) {
				promiseList.push(this.postTravel(params));
			}
		} else {
			promiseList.push(this.postTravel(params));
		}

		Promise.all(promiseList)
			.then(() => {
				this.$toast.success('Viagens criadas com sucesso');
				this.$emit('success');
			})
			.catch(() => {
				this.$toast.error('Erro ao criar viagem');
			})
			.finally(() => {
				this.buttonBusy.saveButton = false;
				this.form.multiplier = null;
			});
	}
}
