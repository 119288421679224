

























import { Component, Vue } from "vue-property-decorator";
import KeyIcon from "@/components/icons/Key.vue";
import Form from "@/components/pages/recoverPassword/organisms/TransitForm/TransitForm.vue";

import { CONSTANTS } from '@/constants/constants';

@Component({
  components: {
    KeyIcon,
    Form
  },
})

export default class FirstLoginTransit extends Vue {
  /* Computed */
  get routerName() {
    return this.$route.meta.route;
  }
  
  get version() {
    return CONSTANTS.SYSTEM_VERSION;
  }
}
