



































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Pagination from "@/components/widgets/organisms/Pagination/Pagination.vue";

import { formatDateHour } from "@/shared/util/formatDate";
import formatMoney from "@/shared/util/formatMoney";
import { SYSTEM_PERMISSIONS } from "@/constants/permissions";
import formatNumber from "@/shared/util/formatNumber";

import {
  SELECT_CONTRACTS,
  GET_CONTRACTS,
  SELECT_ACCEPTANCE_TERM,
} from "@/store/orders/actions";

@Component({
  components: {
    Modal,
    Table,
    Pagination,
  },
})
export default class ContractsModal extends Vue {
  /* Data */
  private isLoaded: boolean = true;

  private rows: any = [];

  private header: any = [];

  private fixedHeader: any = [
    {
      key: "idPropostaContrato",
      label: "Número Proposta",
      thClass: "dark-header",
    },
    {
      key: "pecuaristaDestino",
      label: "Pecuarista /Empresa Destino",
      tdClass: "no-overflow",
    },
    {
      key: "fazendaDestino",
      label: "Unidade /Fazenda Destino",
      tdClass: "no-overflow",
    },
    {
      key: "pecuaristaOrigem",
      label: "Pecuarista /Empresa Origem",
      tdClass: "no-overflow",
    },
    {
      key: "fazendaOrigem",
      label: "Unidade /Fazenda Origem",
      tdClass: "no-overflow",
    },
    { key: "kmEntrePontos", label: "KM entre pontos" },
    { key: "qtdAnimais", label: "Quantidade Animais" },
    { key: "categoriaAnimais", label: "Categoria" },
    { key: "peso", label: "Peso Médio" },
    { key: "previsaoEmbarque", label: "Previsão Embarque" },
    { key: "previsaoDesembarque", label: "Previsão Desembarque" },
    { key: "dataPedido", label: "Data Pedido", thClass: "dark-header" },
    { key: "valorCotacao", label: "Valor Cotação", thClass: "dark-header" },
    {
      key: "actions",
      label: "Ações",
      thClass: "gray-header",
      tdClass: "td-action",
    },
  ];

  private totalPages: number = 0;

  private params: any = {
    page: 1,
    size: 8,
  };

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedContracts;
  }

  get selectedContract() {
    return this.$store.getters.getSelectedContracts;
  }

  get permissions() {
    return this.$store.getters.getUserPermissions;
  }

  get systemPermissions() {
    return SYSTEM_PERMISSIONS.RANCHER.ORDER;
  }

  /* Methods */
  resetInfo() {
    this.rows = [];
    this.header = [];
    this.$store.dispatch(SELECT_CONTRACTS, null);
  }

  initInfo() {
    this.getContracts(this.params);
  }

  getContracts(params: any) {
    this.isLoaded = false;
    this.$store
      .dispatch(GET_CONTRACTS, { ...params, id: this.selectedContract })
      .then(({ totalCount, results }) => {
        if (results.length == 0) {
          this.$emit("message", "Nenhum contrato realizado", "error");
          this.close();
          return;
        }
        this.totalPages = totalCount;
        if (
          !this.permissions.includes(
            this.systemPermissions.ACCEPTANCE_TERM.SHOW
          )
        ) {
          this.fixedHeader.pop();
        }
        this.header = this.fixedHeader;
        this.rows = results.map((i) => {
          return {
            ...i,
            dataPedido: formatDateHour(i.dataPedido),
            previsaoEmbarque: formatDateHour(i.previsaoEmbarque),
            previsaoDesembarque: formatDateHour(i.previsaoDesembarque),
            valorCotacao: formatMoney(i.valorCotacao),
            peso: formatNumber(i.peso),
            actions: {
              id: i.idPropostaContrato,
              buttons: ["term"],
            },
          };
        });
      })
      .catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.isLoaded = true;
      });
  }

  changePage(page) {
    this.params.page = page;
    this.getContracts(this.params);
  }

  openTerm(contract) {
    this.$store.dispatch(SELECT_ACCEPTANCE_TERM, {
      id: contract.id,
      isProposal: true,
    });
  }

  close() {
    this.$bvModal.hide("ContractsModal");
  }
}
