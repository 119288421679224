























































































































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import DatePicker from "@/components/widgets/atoms/VueDatePicker/VueDatePicker.vue";
import Multiselect from "@/components/widgets/atoms/Multiselect/Multiselect.vue";

import getMaskDefinition from "@/shared/util/masks";
import validateCpfCnpj from '@/shared/util/validateCpfCnpj';

import {
  GET_USER_REQUEST,
  GET_FARMS_REQUEST,
  POST_USER,
  PUT_USER,
  SELECT_USER
} from "@/store/permissions/users/actions";

@Component({
  components: {
    Modal,
    DatePicker,
    Multiselect,
    ButtonCustom
  },
})

export default class NewUserModal extends Vue {
  private selectOptions: any = {
    affiliates: [],
    identifications: [
      { value: 'cpf', text: 'CPF' },
      { value: 'cnpj', text: 'CNPJ' },
    ]
  }

  private form: any = {
    name: "",
    identification: { value: 'cpf', text: 'CPF' },
    identificationNumber: "",
    dateBorn: "",
    email: "",
    telephone: "",
    affiliates: [],
  };

  private isLoading: boolean = false;

  private busy: boolean = false;

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedUser;
  }

  get selectedUser() {
    return this.$store.getters.getSelectedUser;
  }

  get isLoadingFarms() {
    return this.$store.getters.isFarmsLoading;
  }

  get title() {
    if (this.showModal) return "Editar Usuário";
    return "Novo Usuário";
  }

  get validation() {
    if (
      this.form.name &&
      this.form.identificationNumber &&
      this.form.dateBorn &&
      this.form.email &&
      this.form.telephone
    ) return false;
    return true;
  }

  /* Methods */
  resetInfo(){
    this.form = {
      name: "",
      identification: { value: 'cpf', text: 'CPF' },
      identificationNumber: "",
      dateBorn: "",
      email: "",
      telephone: "",
      affiliates: [],
    };
    this.$store.dispatch(SELECT_USER, null);
  }

  initInfo(){
    this.getFarms();
    if (this.showModal) {
      this.isLoading = true;
      this.$store
        .dispatch(GET_USER_REQUEST, this.selectedUser)
        .then((user) => {
          const affiliates = user.fazendas.map(farm => ({ value: farm.idFazenda, text: farm.descricao }));
          const identifications = {
            cpf: this.selectOptions.identifications[0],
            cnpj: this.selectOptions.identifications[1],
          }
          this.form = {
            name: user.nome,
            identification: user?.codcgc ? identifications.cnpj : identifications.cpf,
            identificationNumber: user?.codcgc || user?.codcpf || "",
            dateBorn: user.dataNascimento,
            email: user.email,
            telephone: user.telefone,
            affiliates,
          };
        })
        .catch(() => {
          this.$toast.error("Erro ao carregar dados do usuário");
        })
        .finally(() => {
          this.isLoading = false;
        })
    }
  }

  getFarms(search = null) {
    const params = {};
    if (search) params['search'] = search;
    this.$store
      .dispatch(GET_FARMS_REQUEST, params)
      .then(({ results }) => {
        this.selectOptions.affiliates = results.map((farm) => ({ value: farm.idFazenda, text: farm.descricao }));
      })
      .catch(() => {
        this.$toast.error("Erro ao carregar fazendas");
      })
  }

  removeFarm(tag){
    this.form.affiliates = this.form.affiliates.filter(user => user.value !== tag.value)
  }

  onSave(){
    if (!validateCpfCnpj(this.form.identificationNumber)) return this.$toast.error("CPF ou CNPJ inválido!");
    const requestData = {
      nome: this.form.name,
      codcgc: this.form.identification.value == "cnpj" ? this.form.identificationNumber : null,
      codcpf: this.form.identification.value == "cpf" ? this.form.identificationNumber : null,
      dataNascimento: this.form.dateBorn,
      email: this.form.email,
      telefone: this.form.telephone,
      idsFazenda: this.form.affiliates.map((farm) => (farm.value))
    };
    this.busy = true;
    if (this.showModal) {
      requestData['idUsuario'] = this.selectedUser;
      this.$store
        .dispatch(PUT_USER, requestData)
        .then(() => {
          this.$emit("message", "Usuário atualizado com sucesso", "success", true);
        })
        .catch(() => {
          this.$emit("message", "Erro ao atualizar usuário", "error");
        })
        .finally(() => {
          this.busy = false;
          this.close();
        });
      return;
    }
    this.$store
      .dispatch(POST_USER, requestData)
      .then(() => {
        this.$emit("message", "Usuário criado com sucesso", "success", true);
      })
      .catch(() => {
        this.$emit("message", "Erro ao criar novo usuário", "error");
      }).finally(() => {
        this.busy = false;
        this.close();
      })
  }

  maskDefinition(value) {
    return getMaskDefinition(value);
  }

  close() {
    this.$bvModal.hide("NewUserModal");
  }
}
