

































import { Component, Vue } from "vue-property-decorator";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue'

import NewProfileModal from "../../organisms/Modals/NewProfile/NewProfile.vue";
import BooleanModal from "@/components/modals/BooleanModal/BooleanModal.vue";
import MessageModal from "@/components/modals/MessageModal/MessageModal.vue";

import {
  GET_ALL_PROFILES_REQUEST,
  DELETE_PROFILE,
  SELECT_PROFILE
} from "@/store/permissions/profile/actions";

@Component({
  components: {
    Table,
    Pagination,
    NewProfileModal,
    BooleanModal,
    MessageModal
  },
})

export default class RancherProfiles extends Vue {
   /* Data */
  private modal: any = {
    title: "",
    content: "",
    data: null,
    busy: false,
  };

  private params: any = {
    page: 1,
    size: 14,
  };

  private fixedHeader: any = [
    { key: "usuarios", label: "Usuários", thClass: "dark-header" },
    { key: "perfil", label: "Perfil", thClass: "dark-header" },
    { key: "actions", label: "Ações", tdClass: "td-action" }
  ];

  private rows: any = [];

  private totalPages: number = 0;

  /* Computed */
  get isLoading() {
    return this.$store.getters.isAllProfilesLoading;
  }

  /* Lifecycle */
  mounted() {
    this.getProfiles(this.params);
  }
  
  /* Methods */
  getProfiles(params: any) {
    this.$store
      .dispatch(GET_ALL_PROFILES_REQUEST, params)
      .then(({ totalCount, results }) => {
        if (results.length == 0) this.$toast.error("Nenhum perfil encontrado");
        this.totalPages = totalCount;
        this.rows = results.map((profile) => {
          const users = profile.usuarios.map((user) => (user.nome));
          return {
            id: profile.idPerfil,
            perfil: profile.nome,
            usuarios: users.join(', '),
            actions: {
              id: profile.idPerfil,
              users: profile.usuarios,
              buttons: ["edit", "trash"],
            },
          }
        })
      })
      .catch((e) => {
        this.$toast.error(e);
      })
  }

  message(message, variant, reload = false) {
    if(reload) this.getProfiles(this.params);
    this.$nextTick(() => {
      this.$toast.open({
        message,
        type: variant,
      });
    });
  }

  onChangePage(page) {
    this.params.page = page;
    this.getProfiles(this.params);
  }

  onChangeSize(size) {
    this.params.size = size;
    this.params.page = 1;
    this.getProfiles(this.params);
  }

  openEdit(value) {
    this.$store.dispatch(SELECT_PROFILE, value.id);
  }

  openTrash(value) {
    if (value.users.length > 0) {
      this.modal.title = "Excluir Perfil";
      this.modal.content = "Você possui usuários atrelados a esse perfil, retire os usuários para conseguir excluí-lo.";
      this.$bvModal.show("MessageModal");
      return
    }
    this.modal = {
      title: "Excluir Perfil",
      content:"Você tem certeza que deseja prosseguir com a ação de excluir o perfil selecionado?",
      data: { id: value.id },
      busy: false,
    };
    this.$bvModal.show("DeleteProfile");
  }

  onAcceptDelete(data) {
    this.modal.busy = true;
    this.$store
      .dispatch(DELETE_PROFILE, data.id)
      .then(() => {
        this.getProfiles(this.params);
        this.$toast.success("Perfil excluido com sucesso");
      })
      .catch(() => {
        this.$toast.error("Erro ao excluir perfil");
      }).finally(() => {
        this.modal.busy = false;
        this.$bvModal.hide("DeleteProfile");
      })
  }
}
