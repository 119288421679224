




import { Component, Vue } from 'vue-property-decorator';
import Management from '@/components/pages/modules/transitFacility/Management/index.vue';

@Component({
	components: {
		Management,
	},
})
export default class TransitManagement extends Vue {}
