





















































































import { Vue, Component, Prop } from "vue-property-decorator";
import Disclosure from "@/components/widgets/molecules/Disclosure/Disclosure.vue";
import FormInput from "@/components/widgets/molecules/FormInput/FormInput.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";
import Label from "@/components/widgets/atoms/Label/Label.vue";
import FormSelect from "@/components/widgets/molecules/FormSelect/FormSelect.vue";

import {
  TRANSIT_GET_DOWNLOAD_FILE,
  TRANSIT_UPLOAD_FILE,
} from "@/store/TransitFacility/travels/actions";

@Component({
  components: {
    Disclosure,
    FormInput,
    ButtonCustom,
    Label,
    FormSelect
  },
})
export default class DisclosureDocuments extends Vue {
  /* Props */
  @Prop({ default: {} })
  public fields!: any;

  /* Data */
  private busy: any = {
    cte: {
      download: false,
      upload: false,
    },
    mdfe: {
      downlaod: false,
      uplaod: false,
    },
    guia: {
      download: false,
      upload: false,
    },
  };

  private pagamento_value = null

  private selectOptions: any = {
    pagamento: [{text: "Boleto", value: true}, {text: "Qualquer outro meio", value: false}]
  }

  /* Computed */
  get selectedTravel() {
    return this.$store.getters.getSelectedTransitTravel;
  }

  created(){
    this.pagamento_value = this.fields.pagamento === true? this.selectOptions.pagamento[0]: this.selectOptions.pagamento[1];
  }

  /* Mehotds */

  getPagamento(){
    return this.pagamento_value;
  }

  //upload de arquivos GUIA E MDFE

  uploadFile(event, name, type) {
    this.busy[name].upload = true;
    const fd = new FormData();

    fd.append("File", event.target.files[0]);
    fd.append("TipoArquivo", type);
    const params = {
      idViagem: this.selectedTravel,
      formData: fd,
    };
    this.$store
      .dispatch(TRANSIT_UPLOAD_FILE, params)
      .then(() => {
        Vue.$toast.success("Upload do arquivo realizado com sucesso!");
      })
      .catch(() => {
        Vue.$toast.error("Erro ao realizar upload do arquivo!");
      })
      .finally(() => {
        this.busy[name].upload = false;
      });
  }

  //download de arquivos de GUIA E MDFE
  downloadFile(name, type) {
    this.busy[name].download = true;
    const params = {
      idViagem: this.selectedTravel,
      tipoArquivo: type,
      nome: name,
    };
    this.$store
      .dispatch(TRANSIT_GET_DOWNLOAD_FILE, params)
      .then(() => {
        Vue.$toast.success("Download do arquivo realizado com sucesso!");
      })
      .catch(() => {
        Vue.$toast.error("Erro ao realizar download do arquivo!");
      })
      .finally(() => {
        this.busy[name].download = false;
      });
  }
}
