import {
	TRANSIT_TRAVEL_ORIGIN_RANCHER_REQUEST,
	TRANSIT_TRAVEL_DESTINY_RANCHER_REQUEST,
	TRANSIT_TRAVEL_ORIGIN_FARM_REQUEST,
	TRANSIT_TRAVEL_DESTINY_FARM_REQUEST,
	TRANSIT_TRACKING_TYPES_REQUEST,
	TRANSIT_OPERATIONAL_SITUATION_REQUEST,
	TRANSIT_DRIVERS_REQUEST,
	TRANSIT_TRAVEL_DRIVER_DETACHED_FORM_REQUEST,
} from '@/store/TransitFacility/filters/actions';

import { AvailableFilters } from '@/models/filters/available-filters.interface';

// This must be a function so the state of the filters can be erased from one page to the next
export const AvailableFiltersDefinitions = () => {
	const definition = {};

	definition[AvailableFilters.Date] = {
		startDateSelected: '',
		endDateSelected: '',
	};

	definition[AvailableFilters.DriverDetachedForm] = {
		driverSelected: '',
		driverDispatch: TRANSIT_TRAVEL_DRIVER_DETACHED_FORM_REQUEST,
		isLoading: false,
	};

	definition[AvailableFilters.Origin] = {
		rancherSelected: '',
		farmSelected: '',
		rancherDispatch: TRANSIT_TRAVEL_ORIGIN_RANCHER_REQUEST,
		farmDispatch: TRANSIT_TRAVEL_ORIGIN_FARM_REQUEST,
		isLoading: false,
	};

	definition[AvailableFilters.Destiny] = {
		rancherSelected: '',
		farmSelected: '',
		rancherDispatch: TRANSIT_TRAVEL_DESTINY_RANCHER_REQUEST,
		farmDispatch: TRANSIT_TRAVEL_DESTINY_FARM_REQUEST,
		isLoading: false,
	};

	definition[AvailableFilters.Tracking] = {
		trackingSelected: '',
		trackingDispatch: TRANSIT_TRACKING_TYPES_REQUEST,
		isLoading: false,
	};

	definition[AvailableFilters.Order] = {
		orderSelected: '',
	};

	definition[AvailableFilters.Travel] = {
		travelSelected: '',
	};

	definition[AvailableFilters.Driver] = {
		driverSelected: '',
		driverDispatch: TRANSIT_DRIVERS_REQUEST,
		isLoading: false,
	};

	definition[AvailableFilters.Truck] = {
		truckSelected: '',
	};

	definition[AvailableFilters.Cart] = {
		cartSelected: '',
	};

	definition[AvailableFilters.Situation] = {
		situationSelected: '',
		situationDispatch: TRANSIT_OPERATIONAL_SITUATION_REQUEST,
		isLoading: false,
	};

	return definition;
};
