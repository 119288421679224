/* Imports */
import OrderState from '@/models/TransitFacility/order-state/order-state.interface';
import api from '@/shared/util/api';
const { http } = api.getInstance();

/* Actions */
import {
  TRANSIT_TRUCK_TYPES_REQUEST,
  TRANSIT_LOAD_ORDER_REQUEST,
  TRANSIT_LOAD_ORDER_SUCCESS,
  TRANSIT_LOAD_ORDER_FAILED,
  TRANSIT_LOAD_CONTRACTS_REQUEST,
  TRANSIT_LOAD_CONTRACTS_SUCCESS,
  TRANSIT_LOAD_CONTRACTS_FAILED,
  TRANSIT_ORDER_DETAIL_REQUEST,
  TRANSIT_ORDER_DETAIL_SUCCESS,
  TRANSIT_ORDER_DETAIL_FAILED,
  TRANSIT_CONTRACT_DETAIL_REQUEST,
  TRANSIT_CONTRACT_DETAIL_SUCCESS,
  TRANSIT_CONTRACT_DETAIL_FAILED,
  TRANSIT_POST_TRAVEL_REQUEST,
  TRANSIT_POST_TRAVEL_SUCCESS,
  TRANSIT_POST_TRAVEL_FAILED,
  TRANSIT_POST_PROPOSAL,
  TRANSIT_POST_CONTRACT,
  TRANSIT_PUT_TRAVEL_REQUEST,
  TRANSIT_PUT_TRAVEL_SUCCESS,
  TRANSIT_PUT_TRAVEL_FAILED,
  TRANSIT_PUT_CONTRACT,
  TRANSIT_DELETE_TRAVEL,
  TRANSIT_SELECT_ORDER,
  TRANSIT_SELECT_CONTRACT,
  TRANSIT_RELOAD_TRAVEL,
  TRANSIT_EXPORTXLSX,
} from './actions';

/* Initial AuthState object */
const state: OrderState = {
  orderStatus: '',
  orderDetailStatus: '',
  contractStatus: '',
  contractDetailStatus: '',
  postTravelStatus: '',
  putTravelStatus: '',
  selectedTransitOrder: null,
  selectedTransitContract: null,
  reloadTravel: false,
  exportXlsx: null,
};

/* Vuex Auth Getters */
const getters = {
  isTransitOrderLoading: (state: OrderState): string => state.orderStatus,
  isTransitOrderDetailLoading: (state: OrderState): boolean => state.orderDetailStatus === 'loading',
  isTransitContractLoading: (state: OrderState): string => state.contractStatus,
  isTransitContractDetailLoading: (state: OrderState): boolean => state.contractDetailStatus === 'loading',
  isTransitPostTravelLoading: (state: OrderState): boolean => state.postTravelStatus === 'loading',
  isTransitPutTravelLoading: (state: OrderState): boolean => state.putTravelStatus === 'loading',
  getSelectedTransitOrder: (state: OrderState) => state.selectedTransitOrder,
  hasSelectedTransitOrder: (state: OrderState): boolean => state.selectedTransitOrder !== null,
  getSelectedTransitContract: (state: OrderState) => state.selectedTransitContract,
  hasSelectedTransitContract: (state: OrderState): boolean => state.selectedTransitContract !== null,
  isReloadTravel: (state: OrderState): boolean => state.reloadTravel,
  hasOrderExportXlsx: (state: OrderState): boolean => state.exportXlsx === "orders",
  hasContractExportXlsx: (state: OrderState): boolean => state.exportXlsx === 'contracts',
};

/* Vuex Auth Mutations */
const mutations = {
  [TRANSIT_LOAD_ORDER_REQUEST]: (state: OrderState) => {
    state.orderStatus = 'loading';
  },
  [TRANSIT_LOAD_ORDER_SUCCESS]: (state: OrderState) => {
    state.orderStatus = 'success';
  },
  [TRANSIT_LOAD_ORDER_FAILED]: (state: OrderState) => {
    state.orderStatus = 'error';
  },
  [TRANSIT_LOAD_CONTRACTS_REQUEST]: (state: OrderState) => {
    state.contractStatus = 'loading';
  },
  [TRANSIT_LOAD_CONTRACTS_SUCCESS]: (state: OrderState) => {
    state.contractStatus = 'success';
  },
  [TRANSIT_LOAD_CONTRACTS_FAILED]: (state: OrderState) => {
    state.contractStatus = 'error';
  },
  [TRANSIT_ORDER_DETAIL_REQUEST]: (state: OrderState) => {
    state.orderDetailStatus = 'loading';
  },
  [TRANSIT_ORDER_DETAIL_SUCCESS]: (state: OrderState) => {
    state.orderDetailStatus = 'success';
  },
  [TRANSIT_ORDER_DETAIL_FAILED]: (state: OrderState) => {
    state.orderDetailStatus = 'error';
  },
  [TRANSIT_CONTRACT_DETAIL_REQUEST]: (state: OrderState) => {
    state.contractDetailStatus = 'loading';
  },
  [TRANSIT_CONTRACT_DETAIL_SUCCESS]: (state: OrderState) => {
    state.contractDetailStatus = 'success';
  },
  [TRANSIT_CONTRACT_DETAIL_FAILED]: (state: OrderState) => {
    state.contractDetailStatus = 'error';
  },
  [TRANSIT_POST_TRAVEL_REQUEST]: (state: OrderState) => {
    state.postTravelStatus = 'loading';
  },
  [TRANSIT_POST_TRAVEL_SUCCESS]: (state: OrderState) => {
    state.postTravelStatus = 'success';
  },
  [TRANSIT_POST_TRAVEL_FAILED]: (state: OrderState) => {
    state.postTravelStatus = 'error';
  },
  [TRANSIT_PUT_TRAVEL_REQUEST]: (state: OrderState) => {
    state.putTravelStatus = 'loading';
  },
  [TRANSIT_PUT_TRAVEL_SUCCESS]: (state: OrderState) => {
    state.putTravelStatus = 'success';
  },
  [TRANSIT_PUT_TRAVEL_FAILED]: (state: OrderState) => {
    state.putTravelStatus = 'error';
  },
  [TRANSIT_SELECT_ORDER]: (state, order) => {
    state.selectedTransitOrder = order;
  },
  [TRANSIT_SELECT_CONTRACT]: (state, contract) => {
    state.selectedTransitContract = contract;
  },
  [TRANSIT_RELOAD_TRAVEL]: (state, reload) => {
    state.reloadTravel = reload;
  },
  [TRANSIT_EXPORTXLSX]: (state, xlsx) => {
    state.exportXlsx = xlsx;
  },
};

/* Vuex Auth Actions */
const actions = {
  [TRANSIT_TRUCK_TYPES_REQUEST]: () => {
    return new Promise<void>((resolve, reject) => {
      http({
        method: 'get',
        url: '/transportadora/jbsparceiro/caminhao/tipos',
      })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TRANSIT_LOAD_ORDER_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      Object.keys(requestData).forEach((data) => {
        if (requestData[data] &&
          data !== 'page' &&
          data !== 'size'
        ) {
          queryParams.push(`${data}=${requestData[data]}`);
        }
      })
      commit(TRANSIT_LOAD_ORDER_REQUEST);
      http({
        method: 'get',
        url: `/transportadora/jbspedido?${queryParams.join('&')}`,
      })
        .then(({
          data
        }) => {
          commit(TRANSIT_LOAD_ORDER_SUCCESS, data);
          resolve(data);
        })
        .catch((error) => {
          commit(TRANSIT_LOAD_ORDER_FAILED);
          reject(error);
        });
    })
  },
  [TRANSIT_ORDER_DETAIL_REQUEST]: ({ commit }: any, pedidoId: number) => {
    return new Promise((resolve, reject) => {
      commit(TRANSIT_ORDER_DETAIL_REQUEST);

      http({
        method: 'get',
        url: `/transportadora/jbspedido/detalhe/${pedidoId}`,
      })
        .then(({
          data
        }) => {
          commit(TRANSIT_ORDER_DETAIL_SUCCESS, data);
          resolve(data);
        })
        .catch((error) => {
          commit(TRANSIT_ORDER_DETAIL_FAILED);
          reject(error);
        });
    })
  },
  [TRANSIT_LOAD_CONTRACTS_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      Object.keys(requestData).forEach((data) => {
        if (requestData[data] &&
          data !== 'page' &&
          data !== 'size'
        ) {
          queryParams.push(`${data}=${requestData[data]}`);
        }
      })
      commit(TRANSIT_LOAD_CONTRACTS_REQUEST);
      http({
        method: 'get',
        url: `/transportadora/jbspedido/contrato-propostas?${queryParams.join('&')}`,
      })
        .then(({
          data
        }) => {
          commit(TRANSIT_LOAD_CONTRACTS_SUCCESS, data);
          resolve(data);
        })
        .catch((error) => {
          commit(TRANSIT_LOAD_CONTRACTS_FAILED);
          reject(error);
        });
    })
  },
  [TRANSIT_CONTRACT_DETAIL_REQUEST]: ({ commit }: any, pedidoId: number) => {
    return new Promise((resolve, reject) => {
      commit(TRANSIT_CONTRACT_DETAIL_REQUEST);

      http({
        method: 'get',
        url: `/transportadora/jbspedido/proposta/detalhe/${pedidoId}`,
      })
        .then(({
          data
        }) => {
          commit(TRANSIT_CONTRACT_DETAIL_SUCCESS, data);
          resolve(data);
        })
        .catch((error) => {
          commit(TRANSIT_CONTRACT_DETAIL_FAILED);
          reject(error);
        });
    })
  },
  [TRANSIT_DELETE_TRAVEL]: ({ commit }: any, travelId: number) => {
    return new Promise((resolve, reject) => {
      http({
        method: 'delete',
        url: `/transportadora/jbspedido/proposta/viagem/${travelId}`,
      })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [TRANSIT_POST_TRAVEL_REQUEST]: ({ commit }: any, params: any,) => {
    return new Promise((resolve, reject) => {
      const { pedidoId, requestData } = params;
      commit(TRANSIT_POST_TRAVEL_REQUEST);
      http({
        method: 'post',
        url: `/transportadora/jbspedido/proposta/viagem/${pedidoId}`,
        data: [requestData],
      })
        .then(({ data }) => {
          commit(TRANSIT_POST_TRAVEL_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(TRANSIT_POST_TRAVEL_FAILED);
          reject(error);
        });
    })
  },
  [TRANSIT_POST_PROPOSAL]: ({ commit }: any, pedidoId: any,) => {
    return new Promise((resolve, reject) => {
      http({
        method: 'post',
        url: `/transportadora/jbspedido/gerar-nova-proposta/${pedidoId}`,
      })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [TRANSIT_POST_CONTRACT]: ({ commit }: any, pedidoId: any) => {
    return new Promise((resolve, reject) => {
      http({
        method: 'post',
        url: `/transportadora/jbspedido/proposta/gerar-contrato/${pedidoId}`,
      })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [TRANSIT_PUT_TRAVEL_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      commit(TRANSIT_PUT_TRAVEL_REQUEST);
      http({
        method: 'put',
        url: '/transportadora/jbspedido/proposta/viagem',
        data: {
          ...requestData,
        },
      })
        .then(({
          data
        }) => {
          commit(TRANSIT_PUT_TRAVEL_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(TRANSIT_PUT_TRAVEL_FAILED);
          reject(error);
        });
    })
  },
  [TRANSIT_PUT_CONTRACT]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      http({
        method: 'put',
        url: '/transportadora/jbspedido/proposta',
        data: {
          ...requestData,
        },
      })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [TRANSIT_SELECT_ORDER]: ({ commit }, order) => {
    commit(TRANSIT_SELECT_ORDER, order);
  },
  [TRANSIT_SELECT_CONTRACT]: ({ commit }, contract) => {
    commit(TRANSIT_SELECT_CONTRACT, contract);
  },
  [TRANSIT_RELOAD_TRAVEL]: ({ commit }, reload: boolean) => {
    commit(TRANSIT_RELOAD_TRAVEL, reload);
  },
  [TRANSIT_EXPORTXLSX]: ({ commit }, xlsx) => {
    commit(TRANSIT_EXPORTXLSX, xlsx);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};