























import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/ModuleLayout/ModuleLayout.vue';
import Header from '@/components/widgets/organisms/Header/Header.vue';
import Tabs from '@/components/widgets/molecules/Tabs/Tabs.vue';
import { saveXLSX } from '@/helpers/ExportXLSX';

import Drivers from './template/Drivers/Drivers.vue';
import Trucks from './template/Trucks/Trucks.vue';
import Carts from './template/Carts/Carts.vue';

import CarouselModal from '@/components/modals/CarouselModal/CarouselModal.vue';

import { LOAD_TRAVEL_LOGGPS_REQUEST } from '@/store/TransitFacility/travels/actions';

import { formatDateHour } from '@/shared/util/formatDate';

@Component({
	components: {
		Layout,
		Header,
		Tabs,
		Drivers,
		Trucks,
		Carts,
		CarouselModal,
	},
})
export default class TransitModuleRegister extends Vue {
	/* Data */
	private registerName: any = {
		key: 'motorista',
		name: 'Novo Motorista',
	};

	private params: any = {
		page: 1,
		size: 1000,
	};

	private header: any = [
		{ key: 'idMotorista', label: 'Id Motorista', thClass: 'dark-header' },
		{ key: 'nomeMotorista', label: 'Nome', thClass: 'dark-header' },
		{ key: 'dataHora', label: 'Data Hora', thClass: 'dark-header' },
		{ key: 'versaoApp', label: 'Versao App', thClass: 'dark-header' },
		{ key: 'aparelho', label: 'Aparelho', thClass: 'dark-header' },
		{ key: 'modelo', label: 'Modelo', thClass: 'dark-header' },
		{ key: 'versaoFabricante', label: 'Versao Fabricante', thClass: 'dark-header' },
	];

	private busy: any = {
		xlsx: false,
		image: false,
	};

	/* Computed */
	get tabsComponents() {
		return [Drivers, Carts, Trucks];
	}

	/* Lifecycle */
	constructor() {
		super();
	}

	showIndex(index) {
		if (index == 0) {
			this.registerName = { key: 'motorista', name: 'Novo Motorista' };
		}
		if (index == 1) {
			this.registerName = { key: 'carreta', name: 'Nova Carreta' };
		}
		if (index == 2) {
			this.registerName = { key: 'caminhao', name: 'Novo Caminhão' };
		}
	}

	openRegisterModal(type) {
		if (type == 'motorista') {
			this.$bvModal.show('NewDriverModal');
		}
		if (type == 'carreta') {
			this.$bvModal.show('NewCartModal');
		}
		if (type == 'caminhao') {
			this.$bvModal.show('NewTruckModal');
		}
	}

	exportXLS() {
		const params = { ...this.params };
		this.busy.xlsx = true;
		let body = [];
		const header = this.header.map(item => {
			return { label: item?.label || item?.key, value: item?.key };
		});

		this.$store
			.dispatch(LOAD_TRAVEL_LOGGPS_REQUEST, params)
			.then(({ results }) => {
				if (results.length == 0) {
					return;
				}
				body = results.map(i => {
					return {
						...i,
						idMotorista: i?.idMotorista,
						nomeMotorista: i?.nomeMotorista,
						dataHora: formatDateHour(i?.dataHora),
						versaoApp: i?.versaoApp,
						aparelho: i?.aparelho,
						modelo: i?.modelo,
						versaoFabricante: i?.versaoFabricante,
					};
				});
			})
			.catch(e => {
				this.$toast.error(e);
			})
			.finally(() => {
				this.busy.xlsx = false;
				saveXLSX(header, body, 'tabela', 'Log-gps');
			});
	}
}
