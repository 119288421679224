import { AvailableFilters } from '@/models/filters/available-filters.interface';

export const AvailableFiltersDefinitions = () => {
	const definition = {};

	definition[AvailableFilters.Name] = {
		nameSelected: '',
	};

	definition[AvailableFilters.Email] = {
		emailSelected: '',
	};

	definition[AvailableFilters.Cpf] = {
		cpfSelected: '',
	};

	definition[AvailableFilters.Status] = {
		statusSelected: '',
	};

	return definition;
};
