/* Imports */
import PermissionControlState from '@/models/permission/control-state/control-state.interface';
import api from '@/shared/util/api';
const { http } = api.getInstance();

/* Actions */
import {
  GET_CONSTROL_USERS_REQUEST,
  GET_CONSTROL_USERS_SUCCESS,
  GET_CONSTROL_USERS_FAILED,
  PUT_CONTROL_STATUS
} from './actions';


/* Initial AuthState object */
const state: PermissionControlState = {
	allControlUsersStatus: 'loading',
};

/* Vuex Auth Getters */
const getters = {
  isAllControlUsersLoading: (state: PermissionControlState): boolean => state.allControlUsersStatus === 'loading',
};

/* Vuex Auth Mutations */
const mutations = {
	[GET_CONSTROL_USERS_REQUEST]: (state: PermissionControlState) => { state.allControlUsersStatus = 'loading' },
	[GET_CONSTROL_USERS_SUCCESS]: (state: PermissionControlState) => { state.allControlUsersStatus = 'success' },
	[GET_CONSTROL_USERS_FAILED]: (state: PermissionControlState) => { state.allControlUsersStatus = 'error' },
};

/* Vuex Auth Actions */
const actions = {
	[GET_CONSTROL_USERS_REQUEST]: ({ commit }: any, params: any = {}) => {
		return new Promise((resolve, reject) => {
      const queryParams = [`page=${params?.page || 1}`, `size=${params?.size || 20}`];
      commit(GET_CONSTROL_USERS_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/controle/usuario?${queryParams.join('&')}`,
      })
      .then(({ data }) => {
				commit(GET_CONSTROL_USERS_SUCCESS);
        resolve(data);
      })
      .catch((error) => {
        commit(GET_CONSTROL_USERS_FAILED);
        reject(error);
      });
		})
	},
  [PUT_CONTROL_STATUS]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
      http({
        method: 'put',
        url: `/uboiweb/jbspermissao/controle/status/usuario`,
        data: { ...requestData },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
		})
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};