























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ManagementUserTableIcon extends Vue {
	/* Props */
	@Prop({ default: '#865946' })
	public color!: string;
}
