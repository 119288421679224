






















import { Component, Vue } from "vue-property-decorator";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue';

import NewDriverModal from "../../organisms/Modals/NewDriver/NewDriver.vue";

import formatPhone from "@/shared/util/farmatPhone";
import { formatCNPJ, formatCPF } from "@/shared/util/farmatCPF";
import { formatDate } from "@/shared/util/formatDate";

import {
  TRANSIT_REGISTER_GET_DRIVERS,
  SELECT_REGISTER_DRIVER
} from "@/store/TransitFacility/registers/actions";

import { SELECT_DOCUMENTS } from "@/store/photos/actions";

@Component({
  components: {
    Table,
    Pagination,
    NewDriverModal,
  },
})
export default class Home extends Vue {
  /* Data */
  private isLoaded: boolean = true;

  private params: any = {
    page: 1,
    size: 14,
  };

  private fixedHeader: any = [
    { key: "nome", label: "Nome", thClass: "dark-header" },
    { key: "email", label: "E-mail", thClass: "dark-header" },
    { key: "codCpf", label: "CPF/CNPJ", thClass: "dark-header" },
    { key: "dataNascimento", label: "Data Nascimento", thClass: "dark-header" },
    { key: "numeroCNH", label: "CNH", thClass: "dark-header" },
    { key: "telefone", label: "Telefone", thClass: "dark-header" },
    { key: "foto", label: "Comprovante Endereço", thClass: "dark-header", tdClass: "td-photo"},
    { key: "selfie", label: "Selfie", thClass: "dark-header", tdClass: "td-photo"},
    { key: "actions", label: "Ações", tdClass: "td-action" }
  ];

  private rows: any = [];

  private totalPages: number = 0;

  /* Lifecycle */
  created() {
    this.getDrivers(this.params);
  }
  
  /* Methods */
  getDrivers(params: any) {
    this.isLoaded = false
    this.$store
      .dispatch(TRANSIT_REGISTER_GET_DRIVERS, params)
      .then(({totalCount ,results}) => {
        if (results.length == 0) {
          Vue.$toast.error("Nenhum motorista encontrado");
          return;
        }
        this.totalPages = totalCount;
        this.rows = results.map((i) => {
          let identification = "";
          if(i.codCpf){
            identification = formatCPF(i.codCpf) || ""
          } else {
            identification = formatCNPJ(i.codCgc) || ""
          }

          const urlSelfie = i.fotoDocumentos.filter((doc) => doc.descricao == "FOTO_PERFIL");

          const urlEndereco = i.fotoDocumentos.filter((doc) => doc.descricao == "ENDERECO");

          return {
            ...i,
            codCpf: identification,
            dataNascimento: formatDate(i.dataNascimento),
            telefone: formatPhone(i.telefone),
            selfie: {
              type: "Selfie Motorista",
              docs: urlSelfie
            },
            foto: {
              type: "Comprovante de Endereço",
              docs: urlEndereco
            },
            actions: {
              id: i.idMotorista,
              buttons: ["edit"],
            },
          };
        });
      })
      .catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.isLoaded = true;
      });
  }

  message(message, variant, reload = false) {
    if (reload) this.getDrivers(this.params);
    this.$nextTick(() => {
      this.$toast.open({
        message,
        type: variant,
      });
    });
  }

  onChangePage(page) {
    this.params.page = page;
    this.getDrivers(this.params);
  }

  onChangeSize(size) {
    this.params.size = size;
    this.params.page = 1;
    this.getDrivers(this.params);
  }

  openSelfie(selfie){
    this.$store.dispatch(SELECT_DOCUMENTS, selfie);
  }

  openDocuments(documents){
    this.$store.dispatch(SELECT_DOCUMENTS, documents);
  }

  openEdit(value) {
    this.$store.dispatch(SELECT_REGISTER_DRIVER, value.id);
  }
}
