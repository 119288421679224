export const TRANSIT_TRUCK_TYPES_REQUEST = 'TRANSIT_TRUCK_TYPES_REQUEST';

export const TRANSIT_LOAD_ORDER_REQUEST = 'TRANSIT_LOAD_ORDER_REQUEST';
export const TRANSIT_LOAD_ORDER_SUCCESS = 'TRANSIT_LOAD_ORDER_SUCCESS';
export const TRANSIT_LOAD_ORDER_FAILED = 'TRANSIT_LOAD_ORDER_FAILED';

export const TRANSIT_LOAD_CONTRACTS_REQUEST = 'TRANSIT_LOAD_CONTRACTS_REQUEST';
export const TRANSIT_LOAD_CONTRACTS_SUCCESS = 'TRANSIT_LOAD_CONTRACTS_SUCCESS';
export const TRANSIT_LOAD_CONTRACTS_FAILED = 'TRANSIT_LOAD_CONTRACTS_FAILED';

export const TRANSIT_ORDER_DETAIL_REQUEST = 'TRANSIT_ORDER_DETAIL_REQUEST';
export const TRANSIT_ORDER_DETAIL_SUCCESS = 'TRANSIT_ORDER_DETAIL_SUCCESS';
export const TRANSIT_ORDER_DETAIL_FAILED = 'TRANSIT_ORDER_DETAIL_FAILED';

export const TRANSIT_CONTRACT_DETAIL_REQUEST = 'TRANSIT_CONTRACT_DETAIL_REQUEST';
export const TRANSIT_CONTRACT_DETAIL_SUCCESS = 'TRANSIT_CONTRACT_DETAIL_SUCCESS';
export const TRANSIT_CONTRACT_DETAIL_FAILED = 'TRANSIT_CONTRACT_DETAIL_FAILED';

export const TRANSIT_POST_TRAVEL_REQUEST = 'TRANSIT_POST_TRAVEL_REQUEST';
export const TRANSIT_POST_TRAVEL_SUCCESS = 'TRANSIT_POST_TRAVEL_SUCCESS';
export const TRANSIT_POST_TRAVEL_FAILED = 'TRANSIT_POST_TRAVEL_FAILED';

export const TRANSIT_POST_PROPOSAL = 'TRANSIT_POST_PROPOSAL';
export const TRANSIT_POST_CONTRACT = 'TRANSIT_POST_CONTRACT';

export const TRANSIT_PUT_TRAVEL_REQUEST = 'TRANSIT_PUT_TRAVEL_REQUEST';
export const TRANSIT_PUT_TRAVEL_SUCCESS = 'TRANSIT_PUT_TRAVEL_SUCCESS';
export const TRANSIT_PUT_TRAVEL_FAILED = 'TRANSIT_PUT_TRAVEL_FAILED';

export const TRANSIT_PUT_CONTRACT = 'TRANSIT_PUT_CONTRACT';

export const TRANSIT_DELETE_TRAVEL = 'TRANSIT_DELETE_TRAVEL';

export const TRANSIT_SELECT_ORDER = 'TRANSIT_SELECT_ORDER';
export const TRANSIT_SELECT_CONTRACT = 'TRANSIT_SELECT_CONTRACT';

export const TRANSIT_RELOAD_TRAVEL = 'TRANSIT_RELOAD_TRAVEL';

export const TRANSIT_EXPORTXLSX = 'TRANSIT_EXPORTXLSX';













