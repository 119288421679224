/* Imports */
import OrderState from '@/models/order-state/order-state.interface';
import api from '@/shared/util/api';
const { http } = api.getInstance();

/* Actions */
import {
  LOAD_ORDER_REQUEST,
  LOAD_ORDER_SUCCESS,
  LOAD_ORDER_FAILED,
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAILED,
  GET_SKETCH_REQUEST,
  GET_SKETCH_SUCCESS,
  GET_SKETCH_FAILED,
  GET_ORIGIN_PARTNER_REQUEST,
  GET_ORIGIN_PARTNER_SUCCESS,
  GET_ORIGIN_PARTNER_FAILED,
  GET_ORIGIN_FARM_REQUEST,
  GET_ORIGIN_FARM_SUCCESS,
  GET_ORIGIN_FARM_FAILED,
  GET_DESTINY_PARTNER_REQUEST,
  GET_DESTINY_PARTNER_SUCCESS,
  GET_DESTINY_PARTNER_FAILED,
  GET_DESTINY_FARM_REQUEST,
  GET_DESTINY_FARM_SUCCESS,
  GET_DESTINY_FARM_FAILED,
  GET_ORDER_RESUME,
  GET_CONTRACTS,
  GET_TERM,
  GET_PROPOSAL_TERM,
  POST_RATE,
  POST_NEW_SKETCH_ORDER,
  POST_NEW_ORDER,
  POST_PROPOSAL_TERM,
  PUT_SKETCH_ORDER,
  DELETE_SKETCH,
  SELECT_RATE_ORDER,
  SELECT_ACCEPTANCE_TERM,
  SELECT_CONTRACTS,
  SELECT_ORDER_DETAIL,
  SELECT_SKETCH
} from './actions';

/* Initial AuthState object */
const state: OrderState = {
  orderStatus: '',
  orderDetailStatus: '',
  sketchStatus: '',
  originPartnerStatus: '',
  originFarmStatus: '',
  destinyPartnerStatus: '',
  destinyFarmStatus: '',
  selectedRate: null,
  selectedTerm: null,
  selectedContracts: null,
  selectedOrderDetail: null,
  selectedSketch: null,
};

/* Vuex Auth Getters */
const getters = {
  isOrderLoading: (state: OrderState): string => state.orderStatus,
  isOrderDetailLoading: (state: OrderState): boolean => state.orderDetailStatus === 'loading',
  isSketchLoading: (state: OrderState): boolean => state.sketchStatus === 'loading',
  isOriginPartnerLoading: (state: OrderState): boolean => state.originPartnerStatus === 'loading',
  isOriginFarmLoading: (state: OrderState): boolean => state.originFarmStatus === 'loading',
  isDestinyPartnerLoading: (state: OrderState): boolean => state.destinyPartnerStatus === 'loading',
  isDestinyFarmLoading: (state: OrderState): boolean => state.destinyFarmStatus === 'loading',
  getSelectedRate: (state: OrderState) => state.selectedRate,
  hasSelectedRate: (state: OrderState): boolean => state.selectedRate !== null,
  getSelectedTerm: (state: OrderState) => state.selectedTerm,
  hasSelectedTerm: (state: OrderState): boolean => state.selectedTerm !== null,
  getSelectedContracts: (state: OrderState) => state.selectedContracts,
  hasSelectedContracts: (state: OrderState): boolean => state.selectedContracts !== null,
  getSelectedOrderDetail: (state: OrderState) => state.selectedOrderDetail,
  hasSelectedOrderDetail: (state: OrderState): boolean => state.selectedOrderDetail !== null,
  getSelectedSketch: (state: OrderState) => state.selectedSketch,
  hasSelectedSketch: (state: OrderState): boolean => state.selectedSketch !== null,
};

/* Vuex Auth Mutations */
const mutations = {
  [LOAD_ORDER_REQUEST]: (state: OrderState) => state.orderStatus = 'loading',
  [LOAD_ORDER_SUCCESS]: (state: OrderState) => state.orderStatus = 'success',
  [LOAD_ORDER_FAILED]: (state: OrderState) => state.orderStatus = 'error',
  [ORDER_DETAIL_REQUEST]: (state: OrderState) => state.orderDetailStatus = 'loading',
  [ORDER_DETAIL_SUCCESS]: (state: OrderState) => state.orderDetailStatus = 'success',
  [ORDER_DETAIL_FAILED]: (state: OrderState) => state.orderDetailStatus = 'error',
  [GET_SKETCH_REQUEST]: (state: OrderState) => state.sketchStatus = 'loading',
  [GET_SKETCH_SUCCESS]: (state: OrderState) => state.sketchStatus = 'success',
  [GET_SKETCH_FAILED]: (state: OrderState) => state.sketchStatus = 'error',
  [GET_ORIGIN_PARTNER_REQUEST]: (state: OrderState) => state.originPartnerStatus = 'loading',
  [GET_ORIGIN_PARTNER_SUCCESS]: (state: OrderState) => state.originPartnerStatus = 'success',
  [GET_ORIGIN_PARTNER_FAILED]: (state: OrderState) => state.originPartnerStatus = 'error',
  [GET_ORIGIN_FARM_REQUEST]: (state: OrderState) => state.originFarmStatus = 'loading',
  [GET_ORIGIN_FARM_SUCCESS]: (state: OrderState) => state.originFarmStatus = 'success',
  [GET_ORIGIN_FARM_FAILED]: (state: OrderState) => state.originFarmStatus = 'error',
  [GET_DESTINY_PARTNER_REQUEST]: (state: OrderState) => state.destinyPartnerStatus = 'loading',
  [GET_DESTINY_PARTNER_SUCCESS]: (state: OrderState) => state.destinyPartnerStatus = 'success',
  [GET_DESTINY_PARTNER_FAILED]: (state: OrderState) => state.destinyPartnerStatus = 'error',
  [GET_DESTINY_FARM_REQUEST]: (state: OrderState) => state.destinyFarmStatus = 'loading',
  [GET_DESTINY_FARM_SUCCESS]: (state: OrderState) => state.destinyFarmStatus = 'success',
  [GET_DESTINY_FARM_FAILED]: (state: OrderState) => state.destinyFarmStatus = 'error',
  [SELECT_RATE_ORDER]: (state: OrderState, rate) => state.selectedRate = rate,
  [SELECT_ACCEPTANCE_TERM]: (state: OrderState, term) => state.selectedTerm = term,
  [SELECT_CONTRACTS]: (state: OrderState, contracts) => state.selectedContracts = contracts,
  [SELECT_ORDER_DETAIL]: (state: OrderState, detail) => state.selectedOrderDetail = detail,
  [SELECT_SKETCH]: (state: OrderState, id) => state.selectedSketch = id,
};

/* Vuex Auth Actions */
const actions = {
  [LOAD_ORDER_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      Object.keys(requestData).forEach((data) => {
        if (requestData[data] &&
          data !== 'page' &&
          data !== 'size' &&
          data !== 'colunas'
        ) {
          queryParams.push(`${data}=${requestData[data]}`);
        }
      })
      commit(LOAD_ORDER_REQUEST);
      http({
          method: 'get',
          url: `/uboiweb/jbspedido?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          commit(LOAD_ORDER_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(LOAD_ORDER_FAILED);
          reject(error);
        });
    })
  },
  [ORDER_DETAIL_REQUEST]: ({ commit }: any, pedidoId: number) => {
    return new Promise((resolve, reject) => {
      commit(ORDER_DETAIL_REQUEST);
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/detalhe/${pedidoId}`,
        })
        .then(({
          data
        }) => {
          commit(ORDER_DETAIL_SUCCESS, data);
          resolve(data);
        })
        .catch((error) => {
          commit(ORDER_DETAIL_FAILED);
          reject(error);
        });
    })
  },
  [GET_SKETCH_REQUEST]: ({ commit }: any, rascunhoId: number) => {
    return new Promise((resolve, reject) => {
      commit(GET_SKETCH_REQUEST);
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/pedido-rascunho/${rascunhoId}`,
        })
        .then(({ data }) => {
          commit(GET_SKETCH_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(GET_SKETCH_FAILED);
          reject(error);
        });
    })
  },
  [GET_ORIGIN_PARTNER_REQUEST]: ({ commit }: any, name: any) => {
    return new Promise < void > ((resolve, reject) => {
      commit(GET_ORIGIN_PARTNER_REQUEST);
      http({
          method: 'get',
          url: `/uboiweb/jbsparceiro/origem?page=${1}&size=${20}&name=${name || ''}`,
        })
        .then(({ data }) => {
          commit(GET_ORIGIN_PARTNER_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(GET_ORIGIN_PARTNER_FAILED);
          reject(error);
        });
    });
  },
  [GET_DESTINY_PARTNER_REQUEST]: ({ commit }: any, name: any) => {
    return new Promise < void > ((resolve, reject) => {
      commit(GET_DESTINY_PARTNER_REQUEST);
      http({
          method: 'get',
          url: `/uboiweb/jbsparceiro/destino?page=${1}&size=${20}&name=${name || ''}`,
        })
        .then(({ data }) => {
          commit(GET_DESTINY_PARTNER_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(GET_DESTINY_PARTNER_FAILED);
          reject(error);
        });
    });
  },
  [GET_ORIGIN_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${1}`, `size=${20}`];
      if (requestData.search) {
        queryParams.push(`name=${requestData.search}`);
      }
      if (requestData.idParceiro) {
        queryParams.push(`idParceiroOrigem=${requestData.idParceiro}`);
      }
      commit(GET_ORIGIN_FARM_REQUEST);
      http({
          method: 'get',
          url: `/uboiweb/jbsparceiro/fazenda/origem?${queryParams.join('&')}`,
        })
        .then(({ data }) => {
          commit(GET_ORIGIN_FARM_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(GET_ORIGIN_FARM_FAILED);
          reject(error);
        });
    });
  },
  [GET_DESTINY_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${1}`, `size=${20}`];
      if (requestData.search) {
        queryParams.push(`name=${requestData.search}`);
      }
      if (requestData.idParceiro) {
        queryParams.push(`idParceiroDestino=${requestData.idParceiro}`);
      }
      commit(GET_DESTINY_FARM_REQUEST);
      http({
          method: 'get',
          url: `/uboiweb/jbsparceiro/fazenda/destino?${queryParams.join('&')}`,
        })
        .then(({ data }) => {
          commit(GET_DESTINY_FARM_SUCCESS);
          resolve(data);
        })
        .catch((error) => {
          commit(GET_DESTINY_FARM_FAILED);
          reject(error);
        });
    });
  },
  [GET_ORDER_RESUME]: ({ commit }: any, pedidoId: number) => {
    return new Promise((resolve, reject) => {
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/resumo/${pedidoId}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [GET_CONTRACTS]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/contrato-propostas/${requestData.id}?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [GET_TERM]: ({ commit }: any, pedidoId: number) => {
    return new Promise((resolve, reject) => {
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/termo-aceite/${pedidoId}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [GET_PROPOSAL_TERM]: ({ commit }: any, pedidoId: number) => {
    return new Promise((resolve, reject) => {
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/proposta/termo-aceite/${pedidoId}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [POST_RATE]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      http({
          method: 'post',
          url: '/uboiweb/jbspedido/avaliacao',
          data: {
            ...requestData,
          },
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [POST_NEW_SKETCH_ORDER]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      http({
          method: 'post',
          url: '/uboiweb/jbspedido/novo/pedido-rascunho',
          data: {
            ...requestData,
          },
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [POST_NEW_ORDER]: ({ commit }: any, pedidoRascunhoId: any) => {
    return new Promise((resolve, reject) => {
      http({
          method: 'post',
          url: `/uboiweb/jbspedido/pedido-rascunho/criar-pedido/${pedidoRascunhoId}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [POST_PROPOSAL_TERM]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      http({
          method: 'post',
          url: '/uboiweb/jbspedido/proposta/termo-aceite',
          data: {
            ...requestData,
          },
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [PUT_SKETCH_ORDER]: ({ commit }: any, requestData: any) => {
    return new Promise((resolve, reject) => {
      http({
          method: 'put',
          url: '/uboiweb/jbspedido/atualizar/pedido-rascunho',
          data: {
            ...requestData,
          },
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [DELETE_SKETCH]: ({ commit }: any, rascunhoId: any) => {
    return new Promise((resolve, reject) => {
      http({
          method: 'delete',
          url: `/uboiweb/jbspedido/pedido-rascunho/${rascunhoId}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  [SELECT_RATE_ORDER]: ({ commit }: any, rate) => {
    commit(SELECT_RATE_ORDER, rate);
  },
  [SELECT_ACCEPTANCE_TERM]: ({ commit }: any, term) => {
    commit(SELECT_ACCEPTANCE_TERM, term);
  },
  [SELECT_CONTRACTS]: ({ commit }: any, contracts) => {
    commit(SELECT_CONTRACTS, contracts);
  },
  [SELECT_ORDER_DETAIL]: ({ commit }: any, detail) => {
    commit(SELECT_ORDER_DETAIL, detail);
  },
  [SELECT_SKETCH]: ({ commit }: any, data) => {
    commit(SELECT_SKETCH, data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};