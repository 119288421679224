




























































































































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import PdfIcon from "@/components/icons/ArchivePDF.vue";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Tracking from "@/components/widgets/organisms/Tracking/Tracking.vue";
import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";

import { formatDate, formatDateHour } from "@/shared/util/formatDate";
import { formatCNPJ, formatCPF } from "@/shared/util/farmatCPF";
import formatMoney from "@/shared/util/formatMoney";
import { SYSTEM_PERMISSIONS } from "@/constants/permissions";

import {
  GET_TERM,
  GET_PROPOSAL_TERM,
  SELECT_ACCEPTANCE_TERM,
  POST_PROPOSAL_TERM,
} from "@/store/orders/actions";

@Component({
  components: {
    Modal,
    PdfIcon,
    Table,
    Tracking,
    ButtonCustom,
  },
})
export default class AcceptanceTermModal extends Vue {
  /* Data */
  private isLoading: boolean = false;

  private busy: boolean = false;

  private termsInfo: any = {
    idPedido: 0,
    dataPedido: "",
    previsaoEmbarque: "",
    previsaoDesembarque: "",
    dataTermoAceite: "",
    valorTotal: 0,
    urlTermo: "",
    qtdBoi: 0,
    qtdNovilho: 0,
    qtdVaca: 0,
    qtdBezerro: 0,
  };

  private originRancher: any = {
    idParceiro: 0,
    nome: "",
    fazenda: "",
    codIdentificacao: "",
  };

  private destinyRancher: any = {
    idParceiro: 0,
    nome: "",
    fazenda: "",
    codIdentificacao: "",
  };

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedTerm;
  }

  get termEndPoint() {
    if (this.$store.getters.getSelectedTerm.isProposal)
      return GET_PROPOSAL_TERM;
    return GET_TERM;
  }

  get selectedTerm() {
    return this.$store.getters.getSelectedTerm.id;
  }

  get hasAcceptTerm() {
    return this.$store.getters.getSelectedTerm?.hasAcceptTerm || false;
  }

  get permissions() {
    return this.$store.getters.getUserPermissions;
  }

  get systemPermissions() {
    return SYSTEM_PERMISSIONS.RANCHER.ORDER;
  }

  /* Methods */
  resetInfo() {
    this.termsInfo = {
      idPedido: 0,
      dataPedido: "",
      previsaoEmbarque: "",
      previsaoDesembarque: "",
      dataTermoAceite: "",
      valorTotal: 0,
      urlTermo: "",
      qtdBoi: 0,
      qtdNovilho: 0,
      qtdVaca: 0,
      qtdBezerro: 0,
    };
    this.originRancher = {
      idParceiro: 0,
      nome: "",
      fazenda: "",
      codIdentificacao: "",
    };
    this.destinyRancher = {
      idParceiro: 0,
      nome: "",
      fazenda: "",
      codIdentificacao: "",
    };
    this.$store.dispatch(SELECT_ACCEPTANCE_TERM, null);
  }

  initInfo() {
    this.isLoading = true;
    this.$store
      .dispatch(this.termEndPoint, this.selectedTerm)
      .then((response) => {
        const { pecuaristaOrigem, pecuaristaDestino } = response;
        this.termsInfo.idPedido = response?.idPedido;
        this.termsInfo.dataPedido = formatDate(response?.dataPedido);
        this.termsInfo.qtdBoi = response?.qtdBoi || 0;
        this.termsInfo.qtdNovilho = response?.qtdNovilho || 0;
        this.termsInfo.qtdVaca = response?.qtdVaca || 0;
        this.termsInfo.qtdBezerro = response?.qtdBezerro || 0;
        this.termsInfo.urlTermo = response.termoUrl;
        this.termsInfo.valorTotal = formatMoney(response?.valorTotal || 0);
        this.termsInfo.previsaoEmbarque = formatDateHour(
          response?.previsaoEmbarque
        );
        this.termsInfo.previsaoDesembarque = formatDateHour(
          response?.previsaoDesembarque
        );
        this.termsInfo.dataTermoAceite = formatDateHour(
          response?.dataTermoAceite
        );

        this.originRancher.idParceiro = pecuaristaOrigem?.idParceiro;
        this.originRancher.nome = pecuaristaOrigem?.nome;
        this.originRancher.fazenda = pecuaristaOrigem?.fazenda;
        this.originRancher.codIdentificacao =
          formatCPF(pecuaristaOrigem?.codCpf) ||
          formatCNPJ(pecuaristaOrigem?.codCgc);

        this.destinyRancher.idParceiro = pecuaristaDestino?.idParceiro;
        this.destinyRancher.nome = pecuaristaDestino?.nome;
        this.destinyRancher.fazenda = pecuaristaDestino?.fazenda;
        this.destinyRancher.codIdentificacao =
          formatCPF(pecuaristaDestino?.codCpf) ||
          formatCNPJ(pecuaristaDestino?.codCgc);
      })
      .catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  postTerm() {
    this.busy = true;
    const params = {
      idProposta: this.selectedTerm,
      aceitar: true,
    };
    this.$store
      .dispatch(POST_PROPOSAL_TERM, params)
      .then(() => {
        this.$emit("message", "Termo aceito com sucesso", "success", true);
      })
      .catch(() => {
        this.$emit("message", "Erro ao aceitar termo", "error");
      })
      .finally(() => {
        this.busy = false;
        this.$bvModal.hide("ContractsModal");
        this.close();
      });
  }

  close() {
    this.$bvModal.hide("AcceptanceTermModal");
  }
}
