



































import { Component, Vue, Prop } from 'vue-property-decorator';
import Card from '../Card/Card.vue';
import Multiselect from '@/components/widgets/atoms/Multiselect/Multiselect.vue';
import { AvailableFiltersDefinitions } from '@/models/filters/transit-facility/management-filter.interface';

@Component({
	components: { Card, Multiselect },
})
export default class ListRender extends Vue {
	@Prop({ default: () => [] })
	public listRender!: Array<{
		label: string;
		placeholder?: string;
		modelValue: any;
		mask?: string;
		type?: string;
		options?: Array<any>;
		filterKey: string;
		nome: string;
	}>;

	@Prop({ default: '' })
	public containerClass!: string;

	@Prop() filterDefinitions!: ReturnType<typeof AvailableFiltersDefinitions>;

	clearFilters() {
		this.$emit('clearFilters');
	}

	applyFilters() {
		this.$emit('applyFilters');
	}

	emitInputUpdate(newValue: any, filterKey: any, input: string): void {
		this.$emit('inputUpdate', { newValue, filterKey, input });
	}
}
