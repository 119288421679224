






























import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/layouts/ModuleLayout/ModuleLayout.vue';
import Header from '@/components/widgets/organisms/Header/Header.vue';
import Tabs from '@/components/widgets/molecules/Tabs/Tabs.vue';
import Filters from '@/components/pages/modules/transitFacility/Management/organisms/Filter/Filter.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import Pagination from '@/components/widgets/organisms/Pagination/Pagination.vue';
import NewUserManagementModal from '../Management/organisms/Modals/NewUserManagementModal.vue';
import {
	TRANSIT_MANAGEMENT_PUT_USER_REQUEST,
	TRANSIT_MANAGEMENT_GET_ALL_USERS_REQUEST,
	TRANSIT_MANAGEMENT_USER_SELECTED,
	TRANSIT_MANAGEMENT_GET_USER_REQUEST,
} from '@/store/TransitFacility/management/actions';
import { AvailableFilters } from '@/models/filters/available-filters.interface';
import { formatCPF } from '@/shared/util/farmatCPF';
import { capitalizeFirstLetter } from '@/shared/util/capitalize';

@Component({
	components: {
		Layout,
		Header,
		Tabs,
		Filters,
		Table,
		Pagination,
		NewUserManagementModal,
	},
})
export default class TransitModuleManagement extends Vue {
	public fixedHeader: any = [
		{ key: 'nome', label: 'Nome do usuário', thClass: 'dark-header', sortable: true },
		{ key: 'email', label: 'Email do usuário', thClass: 'dark-header', sortable: true },
		{ key: 'codCpf', label: 'CPF', thClass: 'dark-header', sortable: true },
		{ key: 'status', label: 'Status', thClass: 'dark-header', sortable: true },
		{
			key: 'actions',
			label: 'Ações',
			thClass: 'gray-header',
			tdClass: 'td-action',
		},
	];

	public availableFilters: any = AvailableFilters;

	public isLoaded: boolean = true;
	public rows: any = [];

	public totalPages: number = 0;

	public params: any = {
		page: 1,
		size: 16,
		nome: '',
		email: '',
		cpf: '',
		status: '',
		orderBy: '',
		orderDirection: '',
		idUsuario: '',
	};

	message(message, variant, reload = false) {
		if (reload) this.getUsersData(this.params);
		this.$nextTick(() => {
			Vue.$toast.open({
				message,
				type: variant,
			});
		});
	}

	created() {
		this.getTotalPages();
		this.getUsersData(this.params);
	}

	handleSort(values) {
		const { sortBy, sortDesc } = values;
		this.params.orderBy = capitalizeFirstLetter(sortBy?.toLowerCase());
		(this.params.orderDirection = sortDesc ? 'desc' : 'asc'),
			this.getUsersData(this.params);
		this.params.orderBy = '';
		this.params.orderDirection = '';
	}

	submitFilter(filterDefinition: any): void {
		Object.keys(filterDefinition).forEach((index) => {
			const numberIndex = +index;
			switch (numberIndex) {
				case this.availableFilters.Name:
					this.params.nome = filterDefinition[numberIndex].nameSelected;
					break;
				case this.availableFilters.Email:
					this.params.email = filterDefinition[numberIndex].emailSelected;
					break;
				case this.availableFilters.Cpf:
					this.params.cpf = filterDefinition[numberIndex].cpfSelected.replace(/\D/g, '');
					break;
				case this.availableFilters.Status: {
					const statusValue = filterDefinition[numberIndex].statusSelected?.value;
					this.params.status =
						statusValue === 'Ativo' ? true : statusValue === 'Inativo' ? false : '';
					break;
				}
			}
		});

		this.$emit('resetarOrdenacao');
		this.params.page = 1;
		this.getUsersData(this.params);
	}

	getTotalPages() {
		this.totalPages = Math.ceil(this.rows.length);
	}

	changePage(page) {
		this.params.page = page;
		this.getUsersData(this.params);
	}

	openEdit(value) {
		this.$store.dispatch(TRANSIT_MANAGEMENT_USER_SELECTED, value.id);
	}

	changeSize(size) {
		this.params.size = size;
		this.params.page = 1;
		this.getUsersData(this.params);
	}

	toggleUserAction(value) {
		this.$store
			.dispatch(TRANSIT_MANAGEMENT_GET_USER_REQUEST, value.id)
			.then((data) => {
				const userToUpdate = {
					nome: data.nome,
					codCpf: data.codCpf,
					email: data.email,
					idUsuario: data.idUsuario,
					ativo: !data.ativo,
				};
				this.handleToggleStatus(userToUpdate);
			})
			.catch(() => this.$toast.error('Não foi possível buscar as informações do usuário'));
	}

	handleToggleStatus(params) {
		this.$store
			.dispatch(TRANSIT_MANAGEMENT_PUT_USER_REQUEST, params)
			.then(() => {
				this.$toast.success('Usuário editado com sucesso!');
				this.getUsersData(this.params);
			})
			.catch(() => this.$toast.error('Erro ao tentar atualizar o usuário'));
	}

	openModal() {
		this.$bvModal.show('NewUserManagementModal');
	}

	resetDataTable(values) {
		const { orderBy, orderDirection } = values;
		this.params.orderBy = orderBy;
		this.params.orderDirection = orderDirection;
		this.getUsersData(this.params);
	}

	getUsersData(params) {
		(this.isLoaded = false),
			this.$store
				.dispatch(TRANSIT_MANAGEMENT_GET_ALL_USERS_REQUEST, params)
				.then(({ results, totalCount }) => {
					if (results.length === 0) {
						this.$toast.error('Nenhum usuário encontrado');
					}
					this.totalPages = totalCount;
					this.rows = results.map((user) => {
						const status = user.ativo ? 'Ativo' : 'Inativo';
						const buttons: Array<any> = [];
						buttons.push('edit');
						buttons.push({
							icon: 'toggle_user',
							color: user.ativo ? '#865946' : '#A4ADC6',
						});
						const codCpf = formatCPF(user.codCpf);
						delete user.codCpf;
						delete user.ativo;
						return {
							...user,
							status,
							codCpf,
							actions: {
								id: user.idUsuario,
								buttons,
							},
						};
					});
				})
				.catch(() => {
					this.$toast.error('Erro ao carregar usuários');
				})
				.finally(() => {
					this.isLoaded = true;
				});
	}
}
