






import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})

export default class Label extends Vue {
  /* Props */
  @Prop({ default: 'default'})
  public variant!: string;

  @Prop({ default: null})
  public name!: string | null;

  /* Computed */
  get variantClass() {
    return `label--${this.variant}`;
  }
}
