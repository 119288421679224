import api from '@/shared/util/api';
const { http } = api.getInstance();

import {
	TRANSIT_MANAGEMENT_GET_ALL_USERS_REQUEST,
	TRANSIT_MANAGEMENT_GET_ALL_USERS_SUCCESS,
	TRANSIT_MANAGEMENT_GET_ALL_USERS_FAILED,
	TRANSIT_MANAGEMENT_PUT_USER_REQUEST,
	TRANSIT_MANAGEMENT_PUT_USER_FAILED,
	TRANSIT_MANAGEMENT_PUT_USER_SUCCESS,
	TRANSIT_MANAGEMENT_USER_SELECTED,
	TRANSIT_MANAGEMENT_GET_USER_REQUEST,
	TRANSIT_MANAGEMENT_GET_USER_FAILED,
	TRANSIT_MANAGEMENT_GET_USER_SUCCESS,
	TRANSIT_MANAGEMENT_POST_USER_REQUEST,
	TRANSIT_MANAGEMENT_POST_USER_SUCCESS,
	TRANSIT_MANAGEMENT_POST_USER_FAILED,
} from './actions';

// State
const state = {
	usersStatus: '',
	usersData: [],
	userStatus: '',
	userData: '',
	userManagementSelected: null,
	postStatus: '',
};

// Getters
const getters = {
	getUsersData: state => state.usersData,
	getUserManagementSelected: state => state.userManagementSelected,
	hasUserManagementSelected: (state): boolean => state.userManagementSelected !== null,
};

// Mutations
const mutations = {
	[TRANSIT_MANAGEMENT_GET_ALL_USERS_REQUEST]: state => {
		state.usersStatus = 'loading';
	},
	[TRANSIT_MANAGEMENT_GET_ALL_USERS_SUCCESS]: (state, data) => {
		state.usersStatus = 'success';
		state.usersData = data;
	},
	[TRANSIT_MANAGEMENT_GET_ALL_USERS_FAILED]: state => {
		state.usersStatus = 'error';
	},
	[TRANSIT_MANAGEMENT_PUT_USER_REQUEST]: state => {
		state.usersStatus = 'loading';
	},
	[TRANSIT_MANAGEMENT_PUT_USER_SUCCESS]: state => {
		state.usersStatus = 'success';
	},
	[TRANSIT_MANAGEMENT_PUT_USER_FAILED]: state => {
		state.usersStatus = 'error';
	},
	[TRANSIT_MANAGEMENT_USER_SELECTED]: (state, userId) => {
		state.userManagementSelected = userId;
	},
	[TRANSIT_MANAGEMENT_GET_USER_REQUEST]: state => {
		state.userStatus = 'loading';
	},
	[TRANSIT_MANAGEMENT_GET_USER_SUCCESS]: (state, data) => {
		state.userStatus = 'success';
		state.userData = data;
	},
	[TRANSIT_MANAGEMENT_GET_USER_FAILED]: state => {
		state.userStatus = 'error';
	},
	[TRANSIT_MANAGEMENT_POST_USER_REQUEST]: state => {
		state.userStatus = 'loading';
	},
	[TRANSIT_MANAGEMENT_POST_USER_SUCCESS]: state => {
		state.userStatus = 'success';
	},
	[TRANSIT_MANAGEMENT_POST_USER_FAILED]: state => {
		state.userStatus = 'error';
	},
};

// Actions
const actions = {
	[TRANSIT_MANAGEMENT_GET_ALL_USERS_REQUEST]: ({ commit }, requestData) => {
		return new Promise((resolve, reject) => {
			commit(TRANSIT_MANAGEMENT_GET_ALL_USERS_REQUEST);
			const queryParams = [
				`page=${requestData.page}`,
				`size=${requestData.size}`,
				`${requestData.status !== undefined ? `ativo=${requestData.status}` : ''}`,
			];
			Object.keys(requestData).forEach(key => {
				if (
					requestData[key] &&
					key !== 'page' &&
					key !== 'size' &&
					key !== 'ativo' &&
					key !== 'status'
				) {
					queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(requestData[key])}`);
				}
			});
			http({
				method: 'get',
				url: `transportadora/jbscadastro/sub-usuario?${queryParams.join('&')}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_MANAGEMENT_GET_ALL_USERS_SUCCESS, data);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_MANAGEMENT_GET_ALL_USERS_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_MANAGEMENT_PUT_USER_REQUEST]: ({ commit }: any, params: any) => {
		return new Promise((resolve, reject) => {
			commit(TRANSIT_MANAGEMENT_PUT_USER_REQUEST);
			http({
				method: 'PUT',
				url: 'transportadora/jbscadastro/sub-usuario',
				data: params,
			})
				.then(({ data }) => {
					commit(TRANSIT_MANAGEMENT_PUT_USER_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_MANAGEMENT_PUT_USER_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_MANAGEMENT_GET_USER_REQUEST]: ({ commit }, userId: number) => {
		return new Promise((resolve, reject) => {
			commit(TRANSIT_MANAGEMENT_GET_USER_REQUEST);
			http({
				method: 'GET',
				url: `transportadora/jbscadastro/sub-usuario/${userId}`,
			})
				.then(({ data }) => {
					commit(TRANSIT_MANAGEMENT_GET_USER_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_MANAGEMENT_GET_USER_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_MANAGEMENT_POST_USER_REQUEST]: ({ commit }, params) => {
		return new Promise((resolve, reject) => {
			commit(TRANSIT_MANAGEMENT_POST_USER_REQUEST);
			http({ method: 'POST', url: 'transportadora/jbscadastro/sub-usuario', data: params })
				.then(({ data }) => {
					commit(TRANSIT_MANAGEMENT_POST_USER_SUCCESS);
					resolve(data);
				})
				.catch(error => {
					commit(TRANSIT_MANAGEMENT_POST_USER_FAILED);
					reject(error);
				});
		});
	},
	[TRANSIT_MANAGEMENT_USER_SELECTED]: ({ commit }, userId) => {
		commit(TRANSIT_MANAGEMENT_USER_SELECTED, userId);
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
