






















































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";

import Layout from "@/components/layouts/ModuleLayout/ModuleLayout.vue";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import Pagination from "@/components/widgets/organisms/Pagination/Pagination.vue";
import BooleanModal from '@/components/modals/BooleanModal/BooleanModal.vue';

import ButtonCustom from "@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue";

import { formatDate } from "@/shared/util/formatDate";

import {
  TRANSIT_TRAVEL_DETACHED_FORM_TRIP_REQUEST,
  SELECT_TRANSIT_TRAVEL_DETACHED_FORM_TRIP,
  SELECT_TRANSIT_TRAVEL_DETACHED_FORM,
  TRANSIT_TRAVEL_POST_LINK_DETACHED_FORM,
  SELECT_TRANSIT_DRIVER_DETACHED_FORM
} from "@/store/TransitFacility/travels/actions";

@Component({
  components: {
    Modal,
    ButtonCustom,
    Layout,
    Table,
    Pagination,
    BooleanModal,
  },
})
export default class TravelDetachedModal extends Vue {
  /* Data */

  private show_components: boolean = false

  private isLoaded: boolean = false;

  private params: any = {
    page: 1,
    size: 16,
    dataInicio: null,
    idMotorista: null,
    viagemVinculada: "false"
  };

  private modal: any = {
		title: '',
		subtitle: '',
		content: '',
		data: null,
		busy: false,
	};

  private fixedHeader: any = [
    { key: "pedido", label: "Pedido", thClass: "white-header" },
    { key: "pecuaristaOrigem", label: "Pecuarista Origem", thClass: "white-header" },
    { key: "fazendaOrigem", label: "Fazenda Origem", thClass: "white-header" },
    { key: "municipioOrigem", label: "Município", thClass: "white-header" },
    { key: "ufOrigem", label: "UF", thClass: "white-header" },
    { key: "pecuaristaDestino", label: "Pecuarista Destino", thClass: "white-header" },
    { key: "municipioDestino", label: "Município", thClass: "white-header" },
    { key: "ufDestino", label: "UF", thClass: "white-header" },
    { key: "dataEmbarque", label: "Data de Embarque", thClass: "white-header" },
    { key: "motorista", label: "Motorista", thClass: "white-header" },
    { key: "viagem", label: "Número da Viagem", thClass: "white-header" },
    {
      key: "actions",
      label: "",
      thClass: "white-header"
    },
  ];

  private header: any = [];

  private columns: Array<any> = [];

  private rows: any = [];

  private totalPages: number = 0;

  /* Computed */
  get showModal() {
    return this.$store.getters.hasSelectedTransitTravelDetachedForm;
  }

  get isLoading() {
    return this.$store.getters.isTransitTravelDetailLoading;
  }

  close() {
    this.$bvModal.hide("TravelDetachedModal");
  }

  /* Lifecycle */
  // created() {
  // }

  getTravels(params: any) {
    this.isLoaded = false;

    if (this.$store.getters.hasSelectedDriverDetachedForm)
      params.idMotorista = this.$store.getters.getSelectedDriverDetachedForm;

    this.$store
      .dispatch(TRANSIT_TRAVEL_DETACHED_FORM_TRIP_REQUEST, params)
      .then(({ totalCount, results }) => {
        if (results.length == 0)
          return this.$toast.error("Nenhuma viagem encontrada");
        
        this.header = this.fixedHeader;
        this.totalPages = totalCount;
        this.rows = results.map((i) => {
          return {
            ...i,
            pedido: i.pedido,
            pecuaristaOrigem: i.pecuaristaOrigem,
            fazendaOrigem: i.fazendaOrigem,
            municipioOrigem: i.municipioOrigem,
            ufOrigem: i.ufOrigem,		
            pecuaristaDestino: i.pecuaristaDestino,
            municipioDestino: i.municipioDestino,
            ufDestino: i.ufDestino,	
            dataEmbarque: formatDate(i.dataEmbarque),
            motorista: i.motorista,
            viagem: i.viagem,
            actions: {
              id: i.viagem,
              idPedido: i.pedido,
              buttons: ["link_common"],
            },
          };
        });
      })
      .catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.isLoaded = true;
      });
  }

  message(message, variant) {
    this.$nextTick(() => {
      this.$toast.open({
        message,
        type: variant,
      });
    });
  }

  changePage(page) {
    this.params.page = page;
    this.getTravels(this.params);
  }

  changeSize(size) {
    this.params.size = size;
    this.params.page = 1;
    this.getTravels(this.params);
  }

  linkTravel(viagem) {
    this.$store.dispatch(SELECT_TRANSIT_TRAVEL_DETACHED_FORM_TRIP, viagem.id);

    const idViagemAvulsa = this.$store.getters.getSelectedTransitTravelDetachedForm;

    this.modal = {
			title: 'Vincular Viagem',
			subtitle: '',
			content: `Deseja confirmar o vínculo da viagem ao pedido ${viagem.idPedido}?`,
			data: { idViagemAvulsa: idViagemAvulsa, idViagem: viagem.id},
			busy: false,
		};
		this.$bvModal.show('BooleanModal');
  }

  resetInfo() {
    this.$store.dispatch(SELECT_TRANSIT_TRAVEL_DETACHED_FORM, null);
    this.$store.dispatch(SELECT_TRANSIT_TRAVEL_DETACHED_FORM_TRIP, null);
    this.$store.dispatch(SELECT_TRANSIT_DRIVER_DETACHED_FORM, null);
    this.$emit("onModalClose");
  }

  initInfo() {
    this.getInfo();
  }

  getInfo() {
    this.$nextTick(() => {
      this.getTravels(this.params);
      this.show_components = true;
    });
  }

  onAccept(data) {
		this.modal.busy = true;
		this.$store
			.dispatch(TRANSIT_TRAVEL_POST_LINK_DETACHED_FORM, 
      {
        idViagemAvulsa: data.idViagemAvulsa,
        idViagem: data.idViagem
      })
			.then(() => {
				this.$emit('message', 'Viagem vinculada com sucesso', 'success', true);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao vincular viagem', 'error');
			})
			.finally(() => {
				this.modal.busy = false;
				this.getTravels(this.params);
				this.$bvModal.hide('BooleanModal');
        this.resetInfo();
			});
	}
}
