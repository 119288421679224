export const MAP_STATUS = {
  'Trânsito para Carregar': {
    color: 'green',
    text: 'Trânsito para Carregar'
  },
  'Carregando': {
    color: 'blue',
    text: 'Carregando'
  },
  'Trânsito para Descarregar': {
    color: 'yellow',
    text: 'Trânsito para Descarregar'
  },
  'Descarregando': {
    color: 'red',
    text: 'Descarregando'
  },
  'default': {
    color: 'gray',
    text: 'Outros'
  },
}