
















































import { Component, Vue, Prop} from "vue-property-decorator";

@Component({})

export default class TableAnimals extends Vue {
  /* Props */
  @Prop({ required: true })
  public data!: any;
}
