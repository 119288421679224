/* Imports */
import PhotoState from '@/models/photo-state/photo-state.interface';

/* Actions */
import {
  SELECT_DOCUMENTS
} from './actions';

/* Initial AuthState object */
const state: PhotoState = {
  selectedDocuments: null,
};

/* Vuex Auth Getters */
const getters = {
  getDocuments: (state: PhotoState) => state.selectedDocuments,
  hasSelectedDocuments: (state: PhotoState): boolean => state.selectedDocuments !== null,
};

/* Vuex Auth Mutations */
const mutations = {
  [SELECT_DOCUMENTS]: (state, docs) => {
    state.selectedDocuments = docs;
  },
};

/* Vuex Auth Actions */
const actions = {
  [SELECT_DOCUMENTS]: ({ commit }, docs) => {
    commit(SELECT_DOCUMENTS, docs);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};