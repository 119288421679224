export const RULES = [
  {
    rule: "No mínimo 8 caracteres",
    regex: /^(?=.*\d).{8,}$/,
  },
  {
    rule: "Letra maiúscula (A-Z)",
    regex: /^(?=.*[A-Z])/,
  },
  {
    rule: "Letra minúscula (a-z)",
    regex: /^(?=.*[a-z])/,
  },
  {
    rule: "Números (0-9)",
    regex: /^(?=.*[0-9])/,
  },
  {
    rule: "Caracteres especiais (!@$%*?)",
    regex: /^(?=.*[@$!%*?&])/,
  }
];