




import { Component, Vue } from "vue-property-decorator";
import Register from "@/components/pages/modules/transitFacility/Register/index.vue";

@Component({
  components: {
    Register
  }
})

export default class TransitRegister extends Vue {}
