






import { Component, Vue, Prop} from "vue-property-decorator";

@Component({})

export default class StarFilledIcon extends Vue {
  /* Props */
  @Prop({ default: "#00C000" })
  public color!: string;
}
