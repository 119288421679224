




















































































import { Vue, Component, Watch } from "vue-property-decorator";
import Card from "@/components/widgets/molecules/Card/Card.vue";
import Multiselect from "@/components/widgets/atoms/Multiselect/Multiselect.vue";
import FiltersIcon from "@/components/icons/Config.vue";

import {
  AvailableFilters
} from "@/models/filters/available-filters.interface";

import {
  AvailableFiltersDefinitions
} from "@/models/filters/transit-facility/map-filters.interface";

@Component({
  components: {
    Card,
    FiltersIcon,
    Multiselect,
  },
})

export default class MapFilters extends Vue {
  /* Data */
  private active = false;

  /* Ts Workaround to import the Enum */
  private filterDefinitions: any = this.definitions;

  private availableFilters: any = AvailableFilters;

  private disabled: boolean = false;

  private filtersOptions: any = {
    drivers: [],
    originRancher: []
  }

  /* Watcher */
  @Watch("travelSelected")
  onSelectOrder(value) {
    if (value) {
      this.disabled = true;
      Object.keys(this.filterDefinitions).forEach(index => {
        if (+index !== this.availableFilters.Travel) {
          Object.keys(this.filterDefinitions[index]).forEach(item => {
            if (item.includes('Selected')) this.filterDefinitions[index][item] = "";
          })
        }
      })
      return;
    }
    this.disabled = false;
  }


  /* Computed */
  get definitions() {
    return AvailableFiltersDefinitions();
  }

  get travelSelected() {
    return this.filterDefinitions[this.availableFilters.Travel].travelSelected;
  }

  /* Lifecycle */
  created() {
    this.active = window.innerWidth > 1367;
    let index = 0;
    Object.keys(this.filterDefinitions).forEach(filter => {
      Object.keys(this.filterDefinitions[filter]).forEach(item => {
        if (item.includes('Dispatch')) {
          this.requestFilter(
            Object.keys(this.filtersOptions)[index],
            this.filterDefinitions[filter][item],
            filter
          );
          index++;
        }
      })
    })
  }

  /* Methods */
  requestFilter(filter, endpoint: string, filterIndex): void {
    this.filterDefinitions[filterIndex].isLoading = true;
    this.$store
      .dispatch(endpoint, { page: 1, size: 20 })
      .then((response) => {
        const data = response?.results;
        this.filtersOptions[filter] = data.map((i) => (
          { value: i.idParceiro, text: i.nome }
        ));
      })
      .catch((e) => {
        this.$toast.error(e);
      })
      .finally(() => {
        this.filterDefinitions[filterIndex].isLoading = false;
      });
  }

  search(filter, type, field, value) {
    this.filterDefinitions[filter].isLoading = true;
    if (type === "originRancher") {
      this.$store
        .dispatch(this.filterDefinitions[filter].originRancherDispatch, {page: 1, size: 20, search: value})
        .then(({ results }) => {
          this.filtersOptions[field] = results.map(i => (
            { value: i.idParceiro, text: i.nome }
          ))
        }).catch((e) => {
          this.$toast.error(e);
        })
        .finally(() => {
            this.filterDefinitions[filter].isLoading = false;
        });
    }
    if(type === "drivers") {
      this.$store
        .dispatch(this.filterDefinitions[filter].driverDispatch, { page: 1, size: 20, search: value })
        .then(({ results }) => {
          this.filtersOptions[field] = results.map(i => (
            { value: i.idParceiro, text: i.nome }
          ))
        }).catch((e) => {
          this.$toast.error(e);
        })
        .finally(() => {
          this.filterDefinitions[filter].isLoading = false;
        });
    }
  }

  clearFilters(): void {
    this.$emit("onClear");
    this.filterDefinitions = AvailableFiltersDefinitions();
  }

  applyFilters(): void {
    this.$emit("onSubmit", this.filterDefinitions);
  }
}
