





















import { Vue, Component, Prop } from "vue-property-decorator";
import Label from "@/components/widgets/atoms/Label/Label.vue";
import Multiselect from "@/components/widgets/atoms/Multiselect/Multiselect.vue";

@Component({
  components: {
    Label,
    Multiselect,
  },
})
export default class FormSelect extends Vue {
  /* Props */
  @Prop({ default: "Label" })
  public label!: string;

  @Prop()
  public value!: string;

  @Prop({ default: () => [] })
  public options!: string;

  @Prop({ default: "select" })
  public name!: string;

  @Prop({ default: "value" })
  public trackBy!: string;

  @Prop({ default: "text" })
  public selectLabel!: string;

  @Prop({ default: "default" })
  public variant!: string;

  @Prop({ default: false })
  public isSearch!: boolean;

  @Prop({ default: false })
  public loading!: boolean;

  @Prop({ default: false })
  public hasDelete!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;
}
