
































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class ButtonCustom extends Vue {
  /* Props */
  @Prop({ default: "Botão" })
  public title!: string;

  @Prop({ default: false })
  public busy!: boolean;

  @Prop({ default: "primary" })
  public variant!: string;

  @Prop({ default: null })
  public component!: any;

  @Prop({ default: false })
  public disabled!: boolean;

  @Prop({ default: 8 })
  public paddingY!: number;

  @Prop({ default: 30 })
  public paddingX!: number;

  @Prop({ default: false })
  public w100!: boolean;

  @Prop({ default: false })
  public small!: boolean;

  @Prop({ default: false })
  public isFile!: boolean;

  /* Computed */
  get pY() {
    if (this.small) return 8;
    return this.paddingY;
  }

  get pX() {
    if (this.small) return 20;
    return this.paddingX;
  }

  /* Methods */
  onClick() {
    this.$emit("onClick");
  }

  onFileSelect(event) {
    this.$emit("onClick", event);
  }
}
