/* Imports */
import api from '@/shared/util/api';

/* Actions */
import {
  ORDER_ORIGIN_RANCHER_REQUEST,
  ORDER_DESTINY_RANCHER_REQUEST,
  TRAVEL_ORIGIN_RANCHER_REQUEST,
  TRAVEL_DESTINY_RANCHER_REQUEST,
  ORDER_ORIGIN_FARM_REQUEST,
  ORDER_DESTINY_FARM_REQUEST,
  TRAVEL_ORIGIN_FARM_REQUEST,
  TRAVEL_DESTINY_FARM_REQUEST,
  TRACKING_TYPES_REQUEST,
  OPERATIONAL_SITUATION_REQUEST,
  DRIVERS_REQUEST,
  PARTNER_MAP_REQUEST,
  DRIVER_MAP_REQUEST,
} from './actions';

const {
  http
} = api.getInstance();


/* Vuex Auth Mutations */
const mutations = {};

/* Vuex Auth Actions */
const actions = {
  [ORDER_ORIGIN_RANCHER_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.search) {
        queryParams.push(`name=${requestData.search}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/parceiro/origem/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ORDER_DESTINY_RANCHER_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.search) {
        queryParams.push(`name=${requestData.search}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/parceiro/destino/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TRAVEL_ORIGIN_RANCHER_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.search) {
        queryParams.push(`name=${requestData.search}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbsviagem/parceiro/origem/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TRAVEL_DESTINY_RANCHER_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.search) {
        queryParams.push(`name=${requestData.search}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbsviagem/parceiro/destino/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ORDER_ORIGIN_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.id) {
        queryParams.push(`idParceiro=${requestData.id}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/fazenda/origem/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ORDER_DESTINY_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.id) {
        queryParams.push(`idParceiro=${requestData.id}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbspedido/fazenda/destino/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TRAVEL_ORIGIN_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.id) {
        queryParams.push(`idParceiro=${requestData.id}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbsviagem/fazenda/origem/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TRAVEL_DESTINY_FARM_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.id) {
        queryParams.push(`idParceiro=${requestData.id}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbsviagem/fazenda/destino/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [TRACKING_TYPES_REQUEST]: ({ commit }: any) => {
    return new Promise < void > ((resolve, reject) => {
      http({
          method: 'get',
          url: '/uboiweb/jbsparceiro/rastreamento/tipos',
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [OPERATIONAL_SITUATION_REQUEST]: ({ commit }: any) => {
    return new Promise < void > ((resolve, reject) => {
      http({
          method: 'get',
          url: '/uboiweb/jbsparceiro/situacao-operacional/tipos',
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DRIVERS_REQUEST]: ({ commit }: any) => {
    return new Promise < void > ((resolve, reject) => {
      http({
          method: 'get',
          url: '/uboiweb/jbsparceiro/motorista',
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [PARTNER_MAP_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.search) {
        queryParams.push(`name=${requestData.search}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbsviagem/parceiro/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DRIVER_MAP_REQUEST]: ({ commit }: any, requestData: any) => {
    return new Promise < void > ((resolve, reject) => {
      const queryParams = [`page=${requestData.page}`, `size=${requestData.size}`];
      if (requestData.search) {
        queryParams.push(`name=${requestData.search}`);
      }
      http({
          method: 'get',
          url: `/uboiweb/jbsviagem/motorista/busca?${queryParams.join('&')}`,
        })
        .then(({
          data
        }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  actions,
  mutations,
};