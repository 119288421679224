

































































import { Vue, Component } from "vue-property-decorator";
import Modal from "@/components/widgets/molecules/Modal/Modal.vue";
import Table from "@/components/widgets/organisms/Table/Table.vue";
import TableAnimals from "@/components/widgets/organisms/TableAnimals/TableAnimals.vue";
import Tracking from "@/components/widgets/organisms/Tracking/Tracking.vue";
import Label from "@/components/widgets/atoms/Label/Label.vue";
import MapIcon from "@/components/icons/PointMap.vue";

import { formatDateHour } from "@/shared/util/formatDate";
import formatMoney from "@/shared/util/formatMoney";
import formatNumber from "@/shared/util/formatNumber";

import {
  ORDER_DETAIL_REQUEST,
  SELECT_ORDER_DETAIL,
  SELECT_MAP,
} from "@/store/orders/actions";

import { SELECT_DOCUMENTS } from "@/store/photos/actions";

@Component({
  components: {
    Modal,
    Table,
    TableAnimals,
    Tracking,
    Label,
    MapIcon,
  },
})
export default class OrderDetailModal extends Vue {
  /* Data */
  private header: any = [
    { key: "pedido", label: "Pedido" },
    { key: "viagem", label: "Viagem" },
    { key: "status", label: "Status da Viagem" },
    { key: "motorista", label: "Motorista", tdClass: "no-overflow-sm" },
    { key: "placaCavalo", label: "Placa Cavalo", tdClass: "no-overflow-sm" },
    {
      key: "placaCarreta",
      label: "Placas Carretas",
      tdClass: "no-overflow-sm",
    },
    { key: "embarqueProgramado", label: "Embarque Previsto" },
    { key: "desembarqueProgramado", label: "Desembarque Previsto" },
    { key: "kmEstimado", label: "KM Estimado", thClass: "dark-header" },
    { key: "kmRealizado", label: "KM Realizado", thClass: "dark-header" },
    { key: "valorEstimado", label: "Valor Estimado", thClass: "dark-header" },
    {
      key: "actions",
      label: "Detalhes",
      thClass: "gray-header",
      tdClass: "td-action",
    },
  ];

  private rows: any = [];

  private animalsData: any = [];

  private animalsInfo: any = {
    travel: "",
    driver: "",
    plates: "",
  };

  private trackingData: any = [];

  private orderData: any = [];

  private originFarm: string | null = null;

  /* Computed */
  get isLoading() {
    return this.$store.getters.isOrderDetailLoading;
  }

  get showModal() {
    return this.$store.getters.hasSelectedOrderDetail;
  }

  get selectedOrder() {
    return this.$store.getters.getSelectedOrderDetail;
  }

  /* Methods */
  resetInfo() {
    this.originFarm = null;
    this.animalsInfo = {
      travel: "",
      driver: "",
      plate: "",
    };
    this.$store.dispatch(SELECT_ORDER_DETAIL, null);
  }

  initInfo() {
    this.$store
      .dispatch(ORDER_DETAIL_REQUEST, this.selectedOrder)
      .then((response) => {
        this.orderData = response;
        this.rows = response.map((travel, index) => {
          return {
            pedido: travel.pedido,
            viagem: travel.viagem,
            status: travel?.status || "",
            motorista: travel?.motorista || "",
            placaCavalo: travel?.placaCavalo || "",
            placaCarreta: travel?.placaCarreta || "",
            embarqueProgramado: formatDateHour(travel.previsaoEmbarque),
            desembarqueProgramado: formatDateHour(travel.previsaoDesembarque),
            kmEstimado: formatNumber(travel?.kmEstimado),
            kmRealizado: formatNumber(travel?.kmRealizado),
            valorEstimado: formatMoney(travel?.valorEstimado || 0),
            actions: {
              id: travel.viagem,
              index,
              buttons: [{ icon: "info", color: "#865946" }, "map"],
            },
          };
        });
        this.setDetail(0);
      })
      .catch((e) => {
        this.$toast.error(e);
      });
  }

  setDetail(index) {
    const rastreamento = this.orderData[index]?.rastreamento || [];
    const viagemAnimais = this.orderData[index]?.viagemAnimais || [];
    const travel = this.orderData[index];

    this.originFarm = travel?.fazendaOrigem || null;

    this.rows.forEach((row) => (row.actions.buttons[0].color = "#865946"));

    this.rows[index].actions.buttons[0].color = "#25B883";

    const plates: Array<any> = [];
    if (travel?.placaCavalo) plates.push(travel.placaCavalo);
    if (travel?.placaCarreta) plates.push(travel.placaCarreta);

    this.animalsInfo = {
      travel: travel.viagem,
      driver: travel?.motorista || "",
      plates: plates.join(", "),
    };

    this.trackingData = rastreamento[0]
      ? rastreamento[0]?.items.map((step) => {
          const foto = step?.fotos
            ? step.fotos.map((foto) => {
                return {
                  idDocumento: foto.tipo,
                  descricao: foto.descricao,
                  urlDocumento: foto.url,
                  formId: step.formId,
                  tipoForm: step.tipoForm,
                };
              })
            : [];
          const [data, hora] = formatDateHour(step.data)?.split(" ");
          return {
            descricao: step.descricao,
            data,
            hora,
            fotos: {
              type: `Fotos ${step.descricao} - ${this.rows[index].pedido} / ${this.rows[index].viagem}`,
              docs: foto,
            },
          };
        })
      : [];

    this.animalsData = [
      {
        animal: "Boi",
        qty: viagemAnimais?.qtdBoi || "-",
        size: formatNumber(viagemAnimais?.pesoMedioBoi) || "-",
        scheduled: viagemAnimais?.qtdBoi_Programado || "-",
        boarding: viagemAnimais?.qtdBoi_Embarque || "-",
        landing: viagemAnimais?.qtdBoi_Desembarque || "-",
      },
      {
        animal: "Vaca",
        qty: viagemAnimais?.qtdVaca || "-",
        size: formatNumber(viagemAnimais?.pesoMedioVaca) || "-",
        scheduled: viagemAnimais?.qtdVaca_Programado || "-",
        boarding: viagemAnimais?.qtdVaca_Embarque || "-",
        landing: viagemAnimais?.qtdVaca_Desembarque || "-",
      },
      {
        animal: "Novilho",
        qty: viagemAnimais?.qtdNovilho || "-",
        size: formatNumber(viagemAnimais?.pesoMedioNovilho) || "-",
        scheduled: viagemAnimais?.qtdNovilho_Programado || "-",
        boarding: viagemAnimais?.qtdNovilho_Embarque || "-",
        landing: viagemAnimais?.qtdNovilho_Desembarque || "-",
      },
      {
        animal: "Bezerro",
        qty: viagemAnimais?.qtdBezerro || "-",
        size: formatNumber(viagemAnimais?.pesoMedioBezerro) || "-",
        scheduled: viagemAnimais?.qtdBezerro_Programado || "-",
        boarding: viagemAnimais?.qtdBezerro_Embarque || "-",
        landing: viagemAnimais?.qtdBezerro_Desembarque || "-",
      },
    ];
  }

  openModalPhoto(documents) {
    this.$store.dispatch(SELECT_DOCUMENTS, documents);
  }

  openMap(viagem) {
    this.$store.dispatch(SELECT_MAP, viagem.id);
  }
}
