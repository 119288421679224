










































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Modal from '@/components/widgets/molecules/Modal/Modal.vue';
import Table from '@/components/widgets/organisms/Table/Table.vue';
import Steps from '@/components/widgets/molecules/Steps/Steps.vue';
import SwitchInputSelect from '@/components/widgets/molecules/SwitchInputSelect/SwitchInputSelect.vue';
import DatePicker from '@/components/widgets/atoms/VueDatePicker/VueDatePicker.vue';
import ButtonCustom from '@/components/widgets/atoms/ButtonCustom/ButtonCustom.vue';

import { formatDateISO } from '@/shared/util/formatDate';
import { SYSTEM_PERMISSIONS } from '@/constants/permissions';

import {
	GET_SKETCH_REQUEST,
	GET_ORIGIN_PARTNER_REQUEST,
	GET_ORIGIN_FARM_REQUEST,
	GET_DESTINY_PARTNER_REQUEST,
	GET_DESTINY_FARM_REQUEST,
	POST_NEW_SKETCH_ORDER,
	PUT_SKETCH_ORDER,
	SELECT_SKETCH,
} from '@/store/orders/actions';

import { GET_UF_REQUEST, GET_CITY_REQUEST } from '@/store/locations/actions';

@Component({
	components: {
		Modal,
		Table,
		Steps,
		ButtonCustom,
		SwitchInputSelect,
		DatePicker,
	},
})
export default class NewOrderModal extends Vue {
	/* Data */
	private filtersOptions: any = {
		partnerOrigin: [],
		farmOrigin: [],
		partnerDestination: [],
		farmDestination: [],
		originUF: [],
		originCity: [],
		destinationUF: [],
		destinationCity: [],
	};

	private animalsHeader: any = [
		{ key: 'animais', label: 'Animais', tdClass: 'mid-brown' },
		{ key: 'quantidade', label: 'Quantidade' },
		{ key: 'peso', label: 'Peso Médio' },
	];

	private animalsRows: any = [
		{
			animais: 'Boi',
			quantidade: { value: null, type: 'number', isInput: true, isDisabled: false },
			peso: { value: null, type: 'number', isInput: true, isDisabled: false },
		},
		{
			animais: 'Vaca',
			quantidade: { value: null, type: 'number', isInput: true, isDisabled: false },
			peso: { value: null, type: 'number', isInput: true, isDisabled: false },
		},
		{
			animais: 'Novilho',
			quantidade: { value: null, type: 'number', isInput: true, isDisabled: false },
			peso: { value: null, type: 'number', isInput: true, isDisabled: false },
		},
		{
			animais: 'Bezerro',
			quantidade: { value: null, type: 'number', isInput: true, isDisabled: false },
			peso: { value: null, type: 'number', isInput: true, isDisabled: false },
		},
	];

	private form: any = {
		partnerOrigin: { select: null, input: '' },
		partnerDestination: { select: null, input: '' },
		farmDestination: { select: null, input: '' },
		farmOrigin: { select: null, input: '' },
		originUF: { select: null, input: '' },
		originCity: { select: null, input: '' },
		destinationUF: { select: null, input: '' },
		destinationCity: { select: null, input: '' },
		distance: null,
		internalCode: null,
		initialDate: null,
		finalDate: null,
	};

	private busy: boolean = false;

	/* Computed */
	get showModal() {
		return this.$store.getters.hasSelectedSketch;
	}

	get selectedSketch() {
		return this.$store.getters.getSelectedSketch;
	}

	get permissions() {
		return this.$store.getters.getUserPermissions;
	}

	get cantEditPermission() {
		return !this.permissions.includes(SYSTEM_PERMISSIONS.RANCHER.ORDER.ORDER_SKETCH.EDIT);
	}

	get label() {
		if (this.showModal && this.cantEditPermission) return 'Vizualizar Pedido';
		if (this.showModal) return 'Editar Pedido';
		return 'Novo Pedido - Cadastro';
	}

	get isLoading() {
		return this.$store.getters.isSketchLoading;
	}

	get isOriginPartnerLoading() {
		return this.$store.getters.isOriginPartnerLoading;
	}

	get isOriginFarmLoading() {
		return this.$store.getters.isOriginFarmLoading;
	}

	get isDestinyPartnerLoading() {
		return this.$store.getters.isDestinyPartnerLoading;
	}

	get isDestinyFarmLoading() {
		return this.$store.getters.isDestinyFarmLoading;
	}

	get isUfLoading() {
		return this.$store.getters.isUfLoading;
	}

	get isCityLoading() {
		return this.$store.getters.isCityLoading;
	}

	get validationStep1() {
		if (
			(this.form.partnerOrigin.select || this.form.partnerOrigin.input) &&
			(this.form.farmOrigin.select || this.form.farmOrigin.input) &&
			(this.form.originUF.select || this.form.originUF.input) &&
			(this.form.originCity.select || this.form.originCity.input)
		)
			return false;
		return true;
	}

	get validationStep2() {
		if (
			(this.form.partnerDestination.select || this.form.partnerDestination.input) &&
			(this.form.farmDestination.select || this.form.farmDestination.input) &&
			(this.form.destinationUF.select || this.form.destinationUF.input) &&
			(this.form.destinationCity.select || this.form.destinationCity.input)
		)
			return false;
		return true;
	}

	get validationStep3() {
		return !this.animalsRows.some(animal => animal.quantidade.value && animal.peso.value);
	}

	/* Watcher */
	@Watch('form.partnerOrigin.select')
	onPartnerOriginChange(value) {
		this.form.farmOrigin = { select: null, input: '' };
		this.filtersOptions.farmOrigin = [];
		this.$nextTick(() => {
			if (value) this.getOriginFarm(null, value.value);
		});
	}

	@Watch('form.partnerDestination.select')
	onPartnerDestinationChange(value) {
		this.form.farmDestination = { select: null, input: '' };
		this.filtersOptions.farmDestination = [];
		this.$nextTick(() => {
			if (value) this.getDestinyFarm(null, value.value);
		});
	}

	@Watch('form.farmOrigin')
	onFarmOriginChange(value) {
		if (value.select) {
			this.form.originUF = { select: null, input: value.select.state };
			this.form.originCity = { select: null, input: value.select.city };
			return;
		}
		if (value.input.length > 0) return;
		this.form.originUF = { select: null, input: '' };
		this.form.originCity = { select: null, input: '' };
	}

	@Watch('form.farmDestination')
	onFarmDestinationChange(value) {
		if (value.select) {
			this.form.destinationUF = { select: null, input: value.select.state };
			this.form.destinationCity = { select: null, input: value.select.city };
			return;
		}
		if (value.input.length > 0) return;
		this.form.destinationUF = { select: null, input: '' };
		this.form.destinationCity = { select: null, input: '' };
	}

	@Watch('form.originUF.select')
	onOriginUFChange(value) {
		this.form.originCity = { select: null, input: '' };
		this.$nextTick(() => {
			if (value) this.getCity(value?.text, 'originCity');
		});
	}

	@Watch('form.destinationUF.select')
	onDestinationUFChange(value) {
		this.form.destinationCity = { select: null, input: '' };
		this.$nextTick(() => {
			if (value) this.getCity(value?.text, 'destinationCity');
		});
	}

	/* Methods */

	resetInfo() {
		this.animalsRows = [
			{
				animais: 'Boi',
				quantidade: { value: null, type: 'number', isInput: true, isDisabled: false },
				peso: { value: null, type: 'number', isInput: true, isDisabled: false },
			},
			{
				animais: 'Vaca',
				quantidade: { value: null, type: 'number', isInput: true, isDisabled: false },
				peso: { value: null, type: 'number', isInput: true, isDisabled: false },
			},
			{
				animais: 'Novilho',
				quantidade: { value: null, type: 'number', isInput: true, isDisabled: false },
				peso: { value: null, type: 'number', isInput: true, isDisabled: false },
			},
			{
				animais: 'Bezerro',
				quantidade: { value: null, type: 'number', isInput: true, isDisabled: false },
				peso: { value: null, type: 'number', isInput: true, isDisabled: false },
			},
		];

		this.form = {
			partnerOrigin: { select: null, input: '' },
			partnerDestination: { select: null, input: '' },
			farmDestination: { select: null, input: '' },
			farmOrigin: { select: null, input: '' },
			originUF: { select: null, input: '' },
			originCity: { select: null, input: '' },
			destinationUF: { select: null, input: '' },
			destinationCity: { select: null, input: '' },
			distance: null,
			internalCode: null,
			initialDate: null,
			finalDate: null,
		};
		this.$store.dispatch(SELECT_SKETCH, null);
	}

	initInfo() {
		if (!this.cantEditPermission) {
			this.getOriginPartner();
			this.getDestinyPartner();
			this.getUF();
		}
		if (this.showModal) {
			this.$store
				.dispatch(GET_SKETCH_REQUEST, this.selectedSketch)
				.then(response => {
					this.form.distance = response.kmEntrePontos;
					this.form.internalCode = response.codigoInterno;
					this.form.initialDate = response.previsaoColeta;
					this.form.finalDate = response.previsaoEntrega;
					this.form.partnerOrigin = {
						select: response.idParceiroOrigem
							? {
									value: response.idParceiroOrigem,
									text: response.parceiroOrigem,
							  }
							: null,
						input: response.idParceiroOrigem ? '' : response?.parceiroOrigem,
					};
					this.form.partnerDestination = {
						select: response.idParceiroDestino
							? {
									value: response.idParceiroDestino,
									text: response.parceiroDestino,
							  }
							: null,
						input: response.idParceiroDestino ? '' : response.parceiroDestino,
					};
					/* Load values after select erase values in watcher */
					this.$nextTick(() => {
						this.form.farmOrigin = {
							select: response.idEndOrigem
								? {
										value: response.idEndOrigem,
										text: response.endOrigem,
								  }
								: null,
							input: response.idEndOrigem ? '' : response.endOrigem,
						};
						this.form.farmDestination = {
							select: response.idEndDestino
								? {
										value: response.idEndDestino,
										text: response.endDestino,
								  }
								: null,
							input: response.idEndDestino ? '' : response.endDestino,
						};
						/* Load values after select erase values in watcher */
						this.$nextTick(() => {
							this.form.originUF = {
								select: !response.idEndOrigem
									? {
											value: response.origemLocalidadeEstadoTexto,
											text: response.origemLocalidadeEstadoTexto,
									  }
									: null,
								input: !response.idEndOrigem ? '' : response.origemLocalidadeEstadoTexto,
							};
							this.form.destinationUF = {
								select: !response.idEndDestino
									? {
											value: response.destinoLocalidadeEstadoTexto,
											text: response.destinoLocalidadeEstadoTexto,
									  }
									: null,
								input: !response.idEndDestino ? '' : response.destinoLocalidadeEstadoTexto,
							};
							/* Load values after select erase values in watcher */
							this.$nextTick(() => {
								this.form.originCity = {
									select: !response.idEndOrigem
										? {
												value: response.origemLocalidadeCidadeTexto,
												text: response.origemLocalidadeCidadeTexto,
										  }
										: null,
									input: !response.idEndOrigem ? '' : response.origemLocalidadeCidadeTexto,
								};
								this.form.destinationCity = {
									select: !response.idEndDestino
										? {
												value: response.destinoLocalidadeCidadeTexto,
												text: response.destinoLocalidadeCidadeTexto,
										  }
										: null,
									input: !response.idEndDestino ? '' : response.destinoLocalidadeCidadeTexto,
								};
							});
						});
					});
					this.animalsRows[1].quantidade.value = response.qtdVaca;
					this.animalsRows[1].quantidade.isDisabled = this.cantEditPermission;
					this.animalsRows[1].peso.value = response.pesoMedioVaca;
					this.animalsRows[1].peso.isDisabled = this.cantEditPermission;
					this.animalsRows[0].quantidade.value = response.qtdBoi;
					this.animalsRows[0].quantidade.isDisabled = this.cantEditPermission;
					this.animalsRows[0].peso.value = response.pesoMedioBoi;
					this.animalsRows[0].peso.isDisabled = this.cantEditPermission;
					this.animalsRows[2].quantidade.value = response.qtdNovilho;
					this.animalsRows[2].quantidade.isDisabled = this.cantEditPermission;
					this.animalsRows[2].peso.value = response.pesoMedioNovilho;
					this.animalsRows[2].peso.isDisabled = this.cantEditPermission;
					this.animalsRows[3].quantidade.value = response.qtdBezerro;
					this.animalsRows[3].quantidade.isDisabled = this.cantEditPermission;
					this.animalsRows[3].peso.value = response.pesoMedioBezerro;
					this.animalsRows[3].peso.isDisabled = this.cantEditPermission;
				})
				.catch(() => {
					this.$toast.error('Erro ao carregar dados do rascunho');
				});
		}
	}

	verifyValue(type) {
		switch (type) {
			case 'partnerOrigin':
				return this.getOriginPartner();
			case 'farmOrigin':
				return this.getOriginFarm(null, this.form.partnerOrigin.select.value);
			case 'partnerDestination':
				return this.getDestinyPartner();
			case 'farmDestination':
				return this.getDestinyFarm(null, this.form.partnerDestination.select.value);
		}
	}

	getOriginPartner(search = null) {
		this.$store
			.dispatch(GET_ORIGIN_PARTNER_REQUEST, search)
			.then(({ results }) => {
				this.filtersOptions.partnerOrigin = results.map(partner => ({
					value: partner.idParceiro,
					text: partner.nome,
				}));
			})
			.catch(e => {
				this.$toast.error(e);
			});
	}

	getOriginFarm(search = null, idParceiro = null) {
		this.$store
			.dispatch(GET_ORIGIN_FARM_REQUEST, { search, idParceiro })
			.then(({ results }) => {
				this.filtersOptions.farmOrigin = results.map(farm => ({
					value: farm.idEndereco,
					text: farm.descricao,
					state: farm.estado,
					city: farm.municipio,
				}));
			})
			.catch(e => {
				this.$toast.error(e);
			});
	}

	getDestinyPartner(search = null) {
		this.$store
			.dispatch(GET_DESTINY_PARTNER_REQUEST, search)
			.then(({ results }) => {
				this.filtersOptions.partnerDestination = results.map(partner => ({
					value: partner.idParceiro,
					text: partner.nome,
				}));
			})
			.catch(e => {
				this.$toast.error(e);
			});
	}

	getDestinyFarm(search = null, idParceiro = null) {
		this.$store
			.dispatch(GET_DESTINY_FARM_REQUEST, { search, idParceiro })
			.then(({ results }) => {
				this.filtersOptions.farmDestination = results.map(farm => ({
					value: farm.idEndereco,
					text: farm.descricao,
					state: farm.estado,
					city: farm.municipio,
				}));
			})
			.catch(e => {
				this.$toast.error(e);
			});
	}

	getUF() {
		this.$store
			.dispatch(GET_UF_REQUEST)
			.then(result => {
				const UFs = result.data.map(({ sigla }) => ({ value: sigla, text: sigla }));
				this.filtersOptions.originUF = UFs;
				this.filtersOptions.destinationUF = UFs;
			})
			.catch(() => {
				this.$toast.error('Erro ao carregar os estados');
			});
	}

	getCity(uf, field) {
		this.$store
			.dispatch(GET_CITY_REQUEST, uf)
			.then(result => {
				const cities = result.data.map(({ nome }) => ({ value: nome, text: nome }));
				this.filtersOptions[field] = cities;
			})
			.catch(() => {
				this.$toast.error('Erro ao carregar as cidades');
			});
	}

	onSave() {
		this.busy = true;
		const params = {
			idParceiroOrigem: this.form.partnerOrigin.select?.value,
			idParceiroDestino: this.form.partnerDestination.select?.value,
			idEndOrigem: this.form.farmOrigin.select?.value,
			idEndDestino: this.form.farmDestination.select?.value,
			endViaOrigemTexto: this.form.farmOrigin.select?.value || this.form.farmOrigin.input,
			endViaDestinoTexto:
				this.form.farmDestination.select?.value || this.form.farmDestination.input,
			parceiroViaOrigemTexto:
				this.form.partnerOrigin.select?.value || this.form.partnerOrigin.input,
			parceiroViaDestinoTexto:
				this.form.partnerDestination.select?.value || this.form.partnerDestination.input,
			origemLocalidadeEstadoTexto: this.form.originUF.select?.value || this.form.originUF.input,
			origemLocalidadeCidadeTexto: this.form.originCity.select?.value || this.form.originCity.input,
			destinoLocalidadeEstadoTexto:
				this.form.destinationUF.select?.value || this.form.destinationUF.input,
			destinoLocalidadeCidadeTexto:
				this.form.destinationCity.select?.value || this.form.destinationCity.input,
			qtdVaca: this.animalsRows[1].quantidade.value,
			pesoMedioVaca: this.animalsRows[1].peso.value,
			qtdBoi: this.animalsRows[0].quantidade.value,
			pesoMedioBoi: this.animalsRows[0].peso.value,
			qtdNovilho: this.animalsRows[2].quantidade.value,
			pesoMedioNovilho: this.animalsRows[2].peso.value,
			qtdBezerro: this.animalsRows[3].quantidade.value,
			pesoMedioBezerro: this.animalsRows[3].peso.value,
			previsaoColeta: formatDateISO(this.form.initialDate),
			previsaoEntrega: formatDateISO(this.form.finalDate),
			kmEntrePontos: this.form.distance,
			codigoInterno: this.form.internalCode,
		};
		if (this.showModal) {
			params['idViagemRascunho'] = this.selectedSketch;
			this.$store
				.dispatch(PUT_SKETCH_ORDER, params)
				.then(() => {
					this.$emit('message', 'Rascunho atualizado com sucesso', 'success');
				})
				.catch(() => {
					this.$emit('message', 'Erro ao atualizar rascunho', 'error');
				})
				.finally(() => {
					this.close();
					this.busy = false;
				});
			return;
		}
		this.$store
			.dispatch(POST_NEW_SKETCH_ORDER, params)
			.then(() => {
				this.$emit('message', 'Rascunho criado com sucesso', 'success', true);
			})
			.catch(() => {
				this.$emit('message', 'Erro ao criar rascunho', 'error');
			})
			.finally(() => {
				this.close();
				this.busy = false;
			});
	}

	close() {
		this.$bvModal.hide('NewOrderModal');
	}
}
