






































import { Vue, Component } from "vue-property-decorator";
import Disclosure from "@/components/widgets/molecules/Disclosure/Disclosure.vue";
import Button from "@/components/widgets/atoms/Button/Button.vue";
import ButtonVerify from "@/components/widgets/atoms/ButtonVerify/ButtonVerify.vue";
import ClockHistoryIcon from "@/components/icons/ClockHistoric.vue";

import {
  TRANSIT_POST_ACTIVITY_HISTORY,
  SELECT_TRANSIT_HISTORICAL,
} from "@/store/TransitFacility/travels/actions";

@Component({
  components: {
    Disclosure,
    Button,
    ButtonVerify,
    ClockHistoryIcon,
  },
})
export default class DisclosureTransport extends Vue {
  /* Data */
  private form: any = {
    discription: null,
  };

  private busy: any = {
    verifyButton: false,
  };

  /* Computed */
  get selectedTravel() {
    return this.$store.getters.getSelectedTransitTravel;
  }

  verifyDescription(type) {
    this.busy.verifyButton = true;
    const params = {
      idViagem: this.selectedTravel,
      tipoHistoricoAtividade: type,
      descricao: this.form.discription,
    };
    this.$store
      .dispatch(TRANSIT_POST_ACTIVITY_HISTORY, params)
      .then(() => {
        Vue.$toast.success("Adicionado ao histórico de atividades");
      })
      .catch(() => {
        Vue.$toast.error("Erro ao adicionar ao histórico de atividades");
      })
      .finally(() => {
        this.form.discription = null;
        this.busy.verifyButton = false;
      });
  }

  openHistorical() {
    this.$store.dispatch(SELECT_TRANSIT_HISTORICAL, this.selectedTravel);
  }
}
