







import { Vue, Component, Prop} from "vue-property-decorator";

@Component({})

export default class Card extends Vue {
  /* Props */
  @Prop({ default: '' })
  public title!: string;
}
