/* Imports */
import PermissionProfileState from '@/models/permission/profile-state/profile-state.interface';
import api from '@/shared/util/api';
const { http } = api.getInstance();

/* Actions */
import {
  GET_ALL_PROFILES_REQUEST,
  GET_ALL_PROFILES_SUCCESS,
  GET_ALL_PROFILES_FAILED,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILED,
  POST_PROFILE,
  PUT_PROFILE,
  DELETE_PROFILE,
  SELECT_PROFILE
} from './actions';


/* Initial AuthState object */
const state: PermissionProfileState = {
	allProfilesStatus: 'loading',
  profileStatus: 'loading',
  permissionsStatus: 'loading',
  usersStatus: 'loading',
  selectedProfile: null,
};

/* Vuex Auth Getters */
const getters = {
  isAllProfilesLoading: (state: PermissionProfileState): boolean => state.allProfilesStatus === 'loading',
  isProfilesLoading: (state: PermissionProfileState): boolean => state.profileStatus === 'loading',
  isPermissionsLoading: (state: PermissionProfileState): boolean => state.permissionsStatus === 'loading',
  isUsersLoading: (state: PermissionProfileState): boolean => state.usersStatus === 'loading',
  getSelectedProfile: (state: PermissionProfileState) => state.selectedProfile,
  hasSelectedProfile: (state: PermissionProfileState): boolean => state.selectedProfile !== null,
};

/* Vuex Auth Mutations */
const mutations = {
	[GET_ALL_PROFILES_REQUEST]: (state: PermissionProfileState) => { state.allProfilesStatus = 'loading' },
	[GET_ALL_PROFILES_SUCCESS]: (state: PermissionProfileState) => { state.allProfilesStatus = 'success' },
	[GET_ALL_PROFILES_FAILED]: (state: PermissionProfileState) => { state.allProfilesStatus = 'error' },
  [GET_PROFILE_REQUEST]: (state: PermissionProfileState) => { state.profileStatus = 'loading' },
	[GET_PROFILE_SUCCESS]: (state: PermissionProfileState) => { state.profileStatus = 'success' },
	[GET_PROFILE_FAILED]: (state: PermissionProfileState) => { state.profileStatus = 'error' },
  [GET_PERMISSIONS_REQUEST]: (state: PermissionProfileState) => { state.permissionsStatus = 'loading' },
	[GET_PERMISSIONS_SUCCESS]: (state: PermissionProfileState) => { state.permissionsStatus = 'success' },
	[GET_PERMISSIONS_FAILED]: (state: PermissionProfileState) => { state.permissionsStatus = 'error' },
  [GET_USERS_REQUEST]: (state: PermissionProfileState) => { state.usersStatus = 'loading' },
	[GET_USERS_SUCCESS]: (state: PermissionProfileState) => { state.usersStatus = 'success' },
	[GET_USERS_FAILED]: (state: PermissionProfileState) => { state.usersStatus = 'error' },
  [SELECT_PROFILE]: (state: PermissionProfileState, profileId) => { state.selectedProfile = profileId },
};

/* Vuex Auth Actions */
const actions = {
	[GET_ALL_PROFILES_REQUEST]: ({ commit }: any, params: any = {}) => {
		return new Promise((resolve, reject) => {
      const queryParams = [`page=${params?.page || 1}`, `size=${params?.size || 20}`];
      commit(GET_ALL_PROFILES_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/perfil?${queryParams.join('&')}`,
      })
      .then(({ data }) => {
				commit(GET_ALL_PROFILES_SUCCESS);
        resolve(data);
      })
      .catch((error) => {
        commit(GET_ALL_PROFILES_FAILED);
        reject(error);
      });
		})
	},
  [GET_PROFILE_REQUEST]: ({ commit }: any, profileId: number) => {
		return new Promise((resolve, reject) => {
      commit(GET_PROFILE_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/perfil/${profileId}`,
      })
      .then(({ data }) => {
				commit(GET_PROFILE_SUCCESS);
        resolve(data);
      })
      .catch((error) => {
        commit(GET_PROFILE_FAILED);
        reject(error);
      });
		})
	},
  [GET_PERMISSIONS_REQUEST]: ({ commit }: any) => {
		return new Promise((resolve, reject) => {
      commit(GET_PERMISSIONS_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/perfil/lista-permissoes`,
      })
      .then(({ data }) => {
				commit(GET_PERMISSIONS_SUCCESS);
        resolve(data);
      })
      .catch((error) => {
        commit(GET_PERMISSIONS_FAILED);
        reject(error);
      });
		})
	},
  [GET_USERS_REQUEST]: ({ commit }: any, params: any = {}) => {
		return new Promise((resolve, reject) => {
      const queryParams = [`page=${params?.page || 1}`, `size=${params?.size || 20}`];
      if (params.search) {
				queryParams.push(`name=${params.search}`);
			}
      commit(GET_USERS_REQUEST);
      http({
        method: 'get',
        url: `/uboiweb/jbspermissao/perfil/usuario/busca?${queryParams.join('&')}`,
      })
      .then(({ data }) => {
				commit(GET_USERS_SUCCESS);
        resolve(data);
      })
      .catch((error) => {
        commit(GET_USERS_FAILED);
        reject(error);
      });
		})
	},
  [POST_PROFILE]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
      http({
        method: 'post',
        url: `/uboiweb/jbspermissao/perfil`,
        data: { ...requestData },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
		})
	},
  [PUT_PROFILE]: ({ commit }: any, requestData: any) => {
		return new Promise((resolve, reject) => {
      http({
        method: 'put',
        url: `/uboiweb/jbspermissao/perfil`,
        data: { ...requestData },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
		})
	},
  [DELETE_PROFILE]: ({ commit }: any, profileId: number) => {
		return new Promise((resolve, reject) => {
      http({
        method: 'delete',
        url: `/uboiweb/jbspermissao/perfil/${profileId}`,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
		})
	},
  [SELECT_PROFILE]: ({ commit }, profileId) => {
    commit(SELECT_PROFILE, profileId);
  },
};

export default {
	state,
	getters,
	actions,
	mutations,
};